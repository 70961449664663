export const countriesReducer = (
  state = {
    countries: [],
    countriesForSelect: [],

    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_COUNTRIES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_COUNTRIES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_COUNTRIES_FULFILLED": {
      state = { ...state, updating: false, updated: true, countries: action.payload.items };
      break;
    }
    case "GET_COUNTRIES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_COUNTRY_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_COUNTRY_SELECT_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_COUNTRY_FULFILLED": {
      let countries = state.countries;
      countries = countries.filter(s => s.id !== action.payload.id);
      countries.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        countries
      };
      break;
    }
    case "GET_COUNTRIES_SELECT_FULFILLED": {
      let countries = state.countries;
      action.payload.forEach(country => {
        if (!countries.some(t => t.id === country.id)) {
          countries.push(country);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        countriesForSelect: countries
      };
      break;
    }
    case "GET_COUNTRY_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default countriesReducer;
