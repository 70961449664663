export const taxesReducer = (
  state = {
    taxes: [],
    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_TAXES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_TAXES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_TAXES_FULFILLED": {
      state = { ...state, updating: false, updated: true, taxes: action.payload.items };
      break;
    }
    case "GET_TAXES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_TAXE_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_TAXE_FULFILLED": {
      let taxes = state.taxes;
      taxes = taxes.filter(s => s.id !== action.payload.id);
      taxes.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        taxes
      };
      break;
    }
    case "GET_TAXES_SELECT_FULFILLED": {
      let taxes = state.taxes;
      action.payload.forEach(taxe => {
        if (!taxes.some(t => t.id === taxe.id)) {
          taxes.push(taxe);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        taxes: taxes
      };
      break;
    }
    case "GET_TAXE_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default taxesReducer;
