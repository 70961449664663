import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "reactstrap";

import { isEmpty } from "utils/utils";

import { updateProductModal } from "redux/actions/products";
import DatePickerWithLabel from "components/FormControls/DatePickerWithLabel";
import InputWithLabel from "components/FormControls/InputWithLabel";
import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";
import { showModalMessage } from "redux/actions/message";

function GeneralInformationSection({ productTypes = [] }) {
  const productsReducer = useSelector((state) => state.productsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const updateModel = (value, model, field, isBlurring) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    if (field === "deactivationDateTime") {
      if (value && value.getTime() < new Date().getTime()) {
        model.isActive = false;
      } else {
        model.isActive = true;
      }
    }

    model[field] = value;

    dispatch(updateProductModal(model));
  };

  const updateProductTypeIds = (value, model, field, isBlurring) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    let deletedValues = model[field].filter((m) => !value.some((v) => v === m.id));
    if (deletedValues.some((dv) => !dv.isDeletable)) {
      dispatch(
        showModalMessage({
          type: "error",
          titleKey: "error",
          message: t("cannot_delete_producttype"),
        })
      );
    } else {
      let newValues = value
        .filter((v) => !model[field].some((modelValue) => modelValue.id === v))
        .map((v) => ({ id: v, isDeletable: true }));
      let oldValues = model[field].filter((m) => value.some((v) => v === m.id));

      let values = [...oldValues, ...newValues];

      model[field] = values;

      dispatch(updateProductModal(model));
    }
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  var sortedProductTypes = productTypes
    .filter((p) => !p.isDeleted || productsReducer.productModal.productType_Ids.some((pt) => pt.id === p.id))
    .sort((a, b) => (a[referenceName] ? a[referenceName].localeCompare(b[referenceName]) : 0));

  return (
    <div className="card mt-2 zIndex-1030">
      <div className="card-body">
        <h5 className="card-title">{t("general_information")}</h5>
        <div className="card-text">
          <hr />
          <Row>
            <Col md="6">
              <InputWithLabel
                labelText={t("nameFrench")}
                inputType="text"
                disabled={!accountReducer.isManager}
                fieldToUpdate="nameFrench"
                objectToUpdate={productsReducer.productModal}
                persistOnBlur
                updateAction={updateModel}
              />
            </Col>
            <Col md="6">
              <InputWithLabel
                labelText={t("nameEnglish")}
                inputType="text"
                disabled={!accountReducer.isManager}
                fieldToUpdate="nameEnglish"
                objectToUpdate={productsReducer.productModal}
                persistOnBlur
                updateAction={updateModel}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <MultiSelectWithLabel
                filterable
                labelText={t("productType_Ids")}
                selectPlaceholder={t("productType_Ids_placeholder")}
                data={sortedProductTypes}
                textField={referenceName}
                disabled={!accountReducer.isManager}
                valueField="id"
                value={productsReducer.productModal.productType_Ids}
                objectToUpdate={productsReducer.productModal}
                updateAction={updateProductTypeIds}
                fieldToUpdate="productType_Ids"
              />
            </Col>
            <Col md="5">
              <DatePickerWithLabel
                labelText={"Date de désactivation"}
                objectToUpdate={productsReducer.productModal}
                fieldToUpdate={`deactivationDateTime`}
                updateAction={updateModel}
                dateFormat={t("date_format")}
                locale={t("locale")}
                disabled={!accountReducer.isManager || productsReducer.productModal.deactivationDateTime}
                persistOnBlur
              />
            </Col>
          </Row>
          {/* {productsReducer.productModal.productType_Ids &&
            productsReducer.productModal.productType_Ids.some(
              pid => pid === productTypes.find(pt => pt.internalName === product_type_name_enum().ACTIVITY).id
            ) && (
              <Row>
                <Col md="12">
                  <InputWithLabel
                    labelText={t("maximumParticipantPerGuide")}
                    inputType="number"
                    fieldToUpdate="maximumParticipantPerGuide"
                    objectToUpdate={productsReducer.productModal}
                    persistOnBlur
                    updateAction={updateModel}
                  />
                </Col>
              </Row>
            )} */}
          <Row>
            <Col md="12">
              <InputWithLabel
                labelText={t("isActive")}
                inputType="checkbox"
                fieldToUpdate="isActive"
                objectToUpdate={productsReducer.productModal}
                persistOnBlur
                updateAction={updateModel}
                disabled
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default GeneralInformationSection;
