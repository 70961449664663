import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import uuidv4 from "uuid/v4";

import { Row, Col } from "reactstrap";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";

import { isEmpty } from "utils/utils";
import { TaxTypeTarget } from "utils/enums";

import SelectWithLabel from "components/FormControls/SelectWithLabel";
import InputWithLabel from "components/FormControls/InputWithLabel";
import { getTaxTypes } from "redux/actions/tax-types";
import { getTaxTypeApplicationsForSelect } from "redux/actions/tax-type-applications";
import ReactTooltip from "react-tooltip";
import { persistProductContractPriceDetail } from "redux/actions/product-price-details";

const DragHandle = sortableHandle(() => <i className="fas fa-2x fa-bars text-default mt-4 grabbable"></i>);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const SortableItem = sortableElement(
  ({ tax, taxTypes, taxTypeApplications = [], taxTypeTargets = [], itemIndex, cpd, contract, isManager }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [forceRefresh, setForceRefresh] = useState(0);

    const removeTax = () => {
      const taxTypes = [...cpd.taxTypes];
      taxTypes.splice(itemIndex, 1);

      const updateModel = { contractPriceDetail: cpd, contract, fieldValues: [{ value: taxTypes, fieldName: "taxTypes" }] };

      dispatch(persistProductContractPriceDetail(updateModel));
    };

    const updateModelTax = (value, model, field) => {
      if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

      const currentTaxTypes = [...cpd.taxTypes];
      const taxTypeToUpdate = currentTaxTypes.find((tt) => tt.id === model.id);

      taxTypeToUpdate[field] = value;

      if (
        field === "taxTypeTarget_Id" &&
        taxTypeTargets.find((ttt) => ttt.id === Number(value)).internalName === TaxTypeTarget.APPLIED_BY_PERCENTAGE
      ) {
        taxTypeToUpdate["taxTypeApplication_Id"] = null;
      }

      if (field === "taxType_Id") {
        let taxType = taxTypes.find((t) => t.id === value);
        model.value = taxType.defaultValue;
        model.taxTypeApplication_Id = taxType.taxTypeApplication_Id;
        model.taxTypeTarget_Id = taxType.taxTypeTarget_Id ?? taxTypeTargets[0].id;

        setForceRefresh(forceRefresh + 1);
      }

      const updateModel = { contractPriceDetail: cpd, contract, fieldValues: [{ value: currentTaxTypes, fieldName: "taxTypes" }] };

      dispatch(persistProductContractPriceDetail(updateModel));
    };

    const isEnglish = t("locale") === "en";
    const referenceName = isEnglish ? "nameEnglish" : "nameFrench";

    return (
      <Col md="12" className="">
        <Row>
          <Col md="1">{!tax.disabled && isManager && <DragHandle />}</Col>

          <Col md={"3"}>
            <SelectWithLabel
              filterable
              labelText={t("taxType_Id")}
              selectPlaceholder={t("taxType_Id_placeholder")}
              data={taxTypes}
              textField={referenceName}
              valueField="id"
              value={tax.taxType_Id}
              objectToUpdate={tax}
              updateAction={updateModelTax}
              fieldToUpdate="taxType_Id"
              disabled={tax.disabled || !isManager}
            />
            <InputWithLabel
              labelText={t("is_taxable")}
              inputType="checkbox"
              fieldToUpdate="isTaxable"
              objectToUpdate={tax}
              inputNamePrefix={tax.uniqueIdentifier || tax.id}
              persistOnBlur
              updateAction={updateModelTax}
              disabled={tax.disabled || !isManager}
            />
          </Col>

          <Col md={"3"}>
            {/* {tax.isPercentage ? ( */}
            <InputWithLabel
              key={forceRefresh}
              labelText={t("value")}
              groupAppend={
                tax.taxTypeTarget_Id &&
                taxTypeTargets.length > 0 &&
                taxTypeTargets.find((ttt) => tax.taxTypeTarget_Id === ttt.id).internalName === TaxTypeTarget.APPLIED_BY_PERCENTAGE
                  ? "%"
                  : "$"
              }
              isDecimal
              inputType="number"
              fieldToUpdate="value"
              objectToUpdate={tax}
              persistOnBlur
              updateAction={updateModelTax}
              disabled={tax.disabled || (taxTypes.find((tt) => tt.id === tax.taxType_Id) || {}).isFixed || !isManager}
            />
            {/* ) : (
             <CurrencyInput value={tax.value} onBlur={e => updateModelTax(e, tax, "value")} className="form-control text-right" />
           )}*/}
          </Col>
          <Col md={"4"}>
            <Row>
              <Col md="6">
                <div className="form-group">
                  <label className="form-control-label">{t("payment_target")}</label>

                  {taxTypeTargets.map((ttt) => (
                    <div className="form-check" key={`${ttt.id}-${tax.id || tax.uniqueIdentifier}`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`taxTypeTarget_Id-${ttt.id}-${tax.id || tax.uniqueIdentifier}`}
                        name={`taxTypeTarget_Id-${tax.id || tax.uniqueIdentifier}`}
                        value={ttt.id}
                        checked={tax.taxTypeTarget_Id === ttt.id}
                        onChange={(e) => updateModelTax(Number(e.target.value), tax, "taxTypeTarget_Id")}
                        disabled={tax.disabled || (taxTypes.find((tt) => tt.id === tax.taxType_Id) || {}).isFixed || !isManager}
                      />
                      <label className="form-check-label" htmlFor={`taxTypeTarget_Id-${ttt.id}-${tax.id || tax.uniqueIdentifier}`}>
                        {ttt[referenceName]}
                      </label>
                    </div>
                  ))}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-control-label">{t("payment_calculation")}</label>

                  {taxTypeApplications.map((tta) => (
                    <div className="form-check" key={`${tta.id}-${tax.uniqueIdentifier}`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`taxTypeApplication_Id-${tta.id}-${tax.uniqueIdentifier}`}
                        name={`taxTypeApplication_Id-${tax.uniqueIdentifier}`}
                        value={tta.id}
                        checked={tax.taxTypeApplication_Id === tta.id}
                        onChange={(e) => updateModelTax(Number(e.target.value), tax, "taxTypeApplication_Id")}
                        disabled={
                          tax.disabled ||
                          (taxTypes.find((tt) => tt.id === tax.taxType_Id) || {}).isFixed ||
                          !isManager ||
                          !tax.taxTypeTarget_Id ||
                          (tax.taxTypeTarget_Id &&
                            taxTypeTargets.find((ttt) => ttt.id === tax.taxTypeTarget_Id).internalName ===
                              TaxTypeTarget.APPLIED_BY_PERCENTAGE)
                        }
                      />
                      <label className="form-check-label" htmlFor={`taxTypeApplication_Id-${tta.id}-${tax.uniqueIdentifier}`}>
                        {tta[referenceName]}
                        <ReactTooltip
                          id={`taxTypeApplication_Id-tooltip-${tta.id}-${tax.uniqueIdentifier}`}
                          type="info"
                          effect="solid"
                          place="top"
                          border
                        />
                        <i
                          className="fas fa-info-circle text-info ml-1"
                          data-tip={isEnglish ? tta.extraInformationEnglish : tta.extraInformationFrench}
                          data-for={`taxTypeApplication_Id-tooltip-${tta.id}-${tax.uniqueIdentifier}`}
                        ></i>
                      </label>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="1">
            <button className="mt-125 btn btn-danger float-right" disabled={tax.disabled || !isManager} onClick={(_) => removeTax()}>
              <span className="icon icon-circle-with-minus" />
            </button>
          </Col>
        </Row>
      </Col>
    );
  }
);

export default function TaxSection({ cpd, contract }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const taxTypesReducer = useSelector((state) => state.taxTypesReducer);
  const establishmentsReducer = useSelector((state) => state.establishmentsReducer);
  const productsReducer = useSelector((state) => state.productsReducer);
  const taxTypeApplicationsReducer = useSelector((state) => state.taxTypeApplicationsReducer);
  const taxTypeTargetsReducer = useSelector((state) => state.taxTypeTargetsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const {
    establishment: { taxes: establishmentTaxes },
  } = establishmentsReducer;

  const { establishmentProducts } = productsReducer;

  useEffect(() => {
    if (taxTypesReducer.taxTypes.length === 0 && !taxTypesReducer.updating) dispatch(getTaxTypes());
    if (taxTypeApplicationsReducer.taxTypeApplications.length === 0 && !taxTypeApplicationsReducer.updating)
      dispatch(getTaxTypeApplicationsForSelect());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const product = contract.product_Id;
  const currentProduct = establishmentProducts.find((x) => x.id === product);

  if (!currentProduct) return null;

  const productTaxes = currentProduct.taxes;

  const addTax = () => {
    const taxTypes = [...cpd.taxTypes] || [];

    var newTaxType = taxTypesReducer.taxTypes[0];

    taxTypes.push({
      baseContractPriceDetail_Id: cpd.id,
      taxType_Id: newTaxType.id,
      TaxTypeTarget_Id: taxTypeTargetsReducer.taxTypeTargets[0].id,
      uniqueIdentifier: uuidv4(),
      order: taxTypes.length > 0 ? Math.max(...taxTypes.map((tt) => tt.order || 0)) + 1 : 0,
      value: newTaxType.defaultValue,
    });

    const updateModel = { contractPriceDetail: cpd, contract, fieldValues: [{ value: taxTypes, fieldName: "taxTypes" }] };

    dispatch(persistProductContractPriceDetail(updateModel));
  };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    let taxTypes = [...cpd.taxTypes];
    taxTypes.splice(newIndex, 0, taxTypes.splice(oldIndex, 1)[0]);
    taxTypes.forEach((t, index) => (t.order = index));

    const updateModel = { contractPriceDetail: cpd, contract, fieldValues: [{ value: taxTypes, fieldName: "taxTypes" }] };

    dispatch(persistProductContractPriceDetail(updateModel));
  };

  let taxes = (cpd.taxTypes?.length > 0 && [...cpd.taxTypes]) || [];

  // Adds establishment valid taxes based on product types
  taxes.push(
    ...establishmentTaxes
      ?.filter((et) =>
        et.productType_Ids.some((etpt) =>
          establishmentProducts
            .find((ep) => Number(ep.id) === Number(contract.product_Id))
            ?.productType_Ids.some((t) => Number(t.id) === Number(etpt))
        )
      )
      .map((et) => ({
        ...et,
        disabled: true,
      }))
  );
  if (productTaxes) {
    taxes.push(
      ...productTaxes.map((et) => ({
        ...et,
        disabled: true,
      }))
    );
  }

  taxes = taxes.sort((a, b) => a.order - b.order);

  let taxTypeApplications = taxTypeApplicationsReducer.taxTypeApplications;
  let taxTypes = taxTypesReducer.taxTypes;
  let taxTypeTargets = taxTypeTargetsReducer.taxTypeTargets;

  return (
    <Row>
      <Col md="12">
        <div className="card mt-2 zIndex-3">
          <div className="card-body">
            <>
              <h5 className="card-title">
                {t("contract_taxes")}{" "}
                <button className="btn btn-success float-right" onClick={addTax} disabled={!accountReducer.isManager}>
                  <span className="icon icon-circle-with-plus" />
                </button>
              </h5>

              <hr className="clearfix" />
            </>
            <div className="card-text">
              <SortableContainer onSortEnd={handleSortEnd} useDragHandle>
                {taxes.map((tax, index) => (
                  <SortableItem
                    isManager={accountReducer.isManager}
                    key={`tax-${tax.uniqueIdentifier}`}
                    index={index}
                    itemIndex={index}
                    tax={tax}
                    taxTypes={taxTypes}
                    taxTypeTargets={taxTypeTargets}
                    taxTypeApplications={taxTypeApplications}
                    cpd={cpd}
                    contract={contract}
                  />
                ))}
              </SortableContainer>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
