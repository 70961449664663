import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useReactRouter from "use-react-router";

import { getProductEventsDetails } from "redux/actions/products";
import Spinner from "components/Spinner/Spinner";
import { Col, Row, Table } from "react-bootstrap";
import { Collapse } from "reactstrap";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import InputWithLabel from "components/FormControls/InputWithLabel";

function ProductEvents() {
  const productsReducer = useSelector((state) => state.productsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const { gettingProductEventsDetails, productEventsDetails } = productsReducer;

  const dispatch = useDispatch();
  const { match } = useReactRouter();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getProductEventsDetails(match.params.productId || match.params.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openIndex, setOpenIndex] = useState(0);
  const [searchString, setSearchString] = useState("");

  let report = { ...productEventsDetails };

  if (searchString) {
    report.years = report.years
      .filter(
        (y) =>
          y.year.toString().includes(searchString) ||
          y.eventDetails.some(
            (ed) =>
              ed.project.toLowerCase().includes(searchString.toLowerCase()) ||
              ed.departure.toLowerCase().includes(searchString.toLowerCase()) ||
              ed.project.toLowerCase().includes(searchString.toLowerCase()) ||
              ed.company.toLowerCase().includes(searchString.toLowerCase()) ||
              moment(ed.departureDate).format("YYYY-MM-DD").includes(searchString)
          )
      )
      .map((y) => {
        return {
          ...y,
          eventDetails: y.eventDetails.filter(
            (ed) =>
              ed.project.toLowerCase().includes(searchString.toLowerCase()) ||
              ed.departure.toLowerCase().includes(searchString.toLowerCase()) ||
              ed.project.toLowerCase().includes(searchString.toLowerCase()) ||
              ed.company.toLowerCase().includes(searchString.toLowerCase()) ||
              moment(ed.departureDate).format("YYYY-MM-DD").includes(searchString)
          ),
        };
      });
  } else {
    report = productEventsDetails;
  }

  return (
    <Row>
      <Col md={12}>
        <div className="dashhead mt-4 mb-3">
          <div className="dashhead-titles">
            <h6 className="dashhead-subtitle">{t("product_events")}</h6>
          </div>
        </div>
      </Col>

      {gettingProductEventsDetails ? (
        <Col md={12}>
          <Spinner />
        </Col>
      ) : (
        <Col md={12}>
          {productEventsDetails && (
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th colSpan={5}>
                    <InputWithLabel
                      labelText={t("search")}
                      inputType="text"
                      fieldToUpdate="weDontCate"
                      objectToUpdate={{}}
                      persistOnBlur
                      updateAction={(value) => setSearchString(value)}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {report &&
                  report.years.map((y, index) => (
                    <React.Fragment key={y.year}>
                      <tr>
                        <th colSpan={5} style={{ textAlign: "center", cursor: "pointer" }} onClick={() => setOpenIndex(index)}>
                          {`${y.year} (${t("projects")}: ${y.projectCount}, ${t("departures")}: ${y.departureCount})`}
                        </th>
                      </tr>
                      <Collapse tag="tr" isOpen={openIndex === index}>
                        <td colSpan={5}>
                          <Table key={index}>
                            <thead>
                              <tr>
                                <th td="true" colSpan={5}>
                                  {t("details")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {y.eventDetails &&
                                y.eventDetails.map((ed, index) => (
                                  <tr key={index}>
                                    <th>
                                      <ReactTooltip clickable id="tt-go-to-reservation" type="info" effect="solid" place="top" border />
                                      <ReactTooltip
                                        clickable
                                        id="tt-go-to-reservation-project"
                                        type="info"
                                        effect="solid"
                                        place="top"
                                        border
                                      />
                                      <a
                                        href={`${accountReducer.northWindUrl}/Projects#!/projects/${ed.departureId}/basicinfo`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {ed.project}
                                      </a>
                                    </th>
                                    <th>
                                      <a
                                        href={`${accountReducer.northWindUrl}/Projects#!/departures/${ed.departureId}/basicinfo`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {ed.departure}
                                      </a>
                                    </th>
                                    <td>{moment(ed.departureDate).format("YYYY-MM-DD")}</td>
                                    <td>{ed.company}</td>
                                    <td>
                                      <a
                                        href={`${accountReducer.northWindUrl}/Reservations#!/establishment/${ed.establishmentId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-for="tt-go-to-reservation"
                                        data-tip={t("go_to_reservation_nw")}
                                        className="float-left"
                                      >
                                        <i className="fas fa-2x fa-calendar-alt text-primary pointer" style={{ marginLeft: "10px" }}></i>
                                      </a>
                                      <a
                                        href={`${accountReducer.northWindUrl}/Reservations#!/project/${ed.projectId}/departure/${ed.departureId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-for="tt-go-to-reservation-project"
                                        data-tip={t("go_to_reservation_nw_project")}
                                        className="float-left"
                                      >
                                        <i className="fas fa-2x fa-calendar text-primary pointer" style={{ marginLeft: "10px" }}></i>
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </td>
                      </Collapse>
                    </React.Fragment>
                  ))}
              </tbody>
            </Table>
          )}
        </Col>
      )}
    </Row>
  );
}

export default ProductEvents;
