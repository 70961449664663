import React from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import LabelField from "components/FormControls/LabelField";

import moment from "moment";
import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";

export default function SummaryGeneralInformations({ contract, companies }) {
  const { t } = useTranslation();

  const categoriesReducer = useSelector((state) => state.categoriesReducer);

  const isEnglish = t("locale") === "en";
  const referenceLabel = isEnglish ? "labelEnglish" : "labelFrench";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  return (
    <div className={`card mt-3`}>
      <div className="card-body">
        <h5 className="card-title">{t("general_information")}</h5>
        <div className="card-text">
          <hr />
          <Row>
            <Col md="6">
              <LabelField labelText={t("nameFrench")} value={contract.finalContractNameFrench} />
            </Col>
            <Col md="6">
              <LabelField labelText={t("nameEnglish")} value={contract.finalContractNameEnglish} />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <LabelField labelText={t("from")} value={moment(contract.start).format("YYYY-MM-DD")} />
            </Col>
            <Col md="6">
              <LabelField labelText={t("to")} value={moment(contract.end).format("YYYY-MM-DD")} />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <LabelField labelText={t("isActive")} value={contract.isActive ? t("yes") : t("no")} />
            </Col>
            <Col md="6">
              <LabelField
                labelText={t("category_Id")}
                value={
                  categoriesReducer.categories &&
                  categoriesReducer.categories.length > 0 &&
                  contract.category_Id &&
                  categoriesReducer.categories.find((cat) => cat.id === contract.category_Id)[referenceName]
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              {companies.length > 0 ? (
                <MultiSelectWithLabel
                  labelText={t("companies")}
                  id="contracts-companies"
                  selectPlaceholder={t("companies_placeholder")}
                  objectToUpdate={contract}
                  updateAction={contract}
                  fieldToUpdate="company_Ids"
                  textField={referenceLabel}
                  valueField="id"
                  value={contract.company_Ids}
                  data={companies}
                  disabled={true}
                />
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
