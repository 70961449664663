export const paymentReducer = (
  state = {
    pushingForcePayment: false,
  },
  action
) => {
  switch (action.type) {
    case "PUSHING_PAYMENT": {
      return { ...state, pushingForcePayment: true };
    }
    case "PUSHED_PAYMENT": {
      return { ...state, pushingForcePayment: false };
    }
    default: {
    }
  }

  return state;
};

export default paymentReducer;
