import React, { useEffect } from "react";
import { isEmpty } from "utils/utils";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setActiveStep, showConfirmDeleteWithPriceDetailModal } from "redux/actions/contracts";
import { Row, Col, Alert } from "reactstrap";

import { addContractGroup, removeContractGroup, updateContractGroup } from "redux/actions/contracts";

import Group from "./Components/Group";

export default function Groups({ contract, validator, steps, isEstablishmentContract }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);
  const groupTargetsReducer = useSelector((state) => state.groupTargetsReducer);

  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  useEffect(() => {
    let currentStep = steps.findIndex((s) => s.name === "groups");
    if (contractsReducer.activeStepIndex !== currentStep) dispatch(setActiveStep(currentStep));
  }, [contractsReducer.activeStepIndex, contractsReducer.steps, dispatch, steps]);

  const addGroup = () => {
    dispatch(
      addContractGroup(isEstablishmentContract, groupTargetsReducer.groupTargets.find((gt) => gt.internalName === "APPLIED_BY_PERSON").id)
    );
  };

  const removeGroup = (group) => {
    if (contract.hasProductPrice && group.id > 0) {
      dispatch(
        showConfirmDeleteWithPriceDetailModal(`${group.nameFrench} / ${group.nameEnglish}`, (_) =>
          dispatch(removeContractGroup(group, isEstablishmentContract))
        )
      );
    } else {
      dispatch(removeContractGroup(group, isEstablishmentContract));
    }

    validator.purgeFields();
  };

  const updateGroup = (value, model, field, index) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;

    dispatch(updateContractGroup(model, index, isEstablishmentContract));
  };

  const updateGroupsTargetIds = (newGroupTargetId) => {
    for (let i = 0; i < contract.groups.length; i++) {
      const group = contract.groups[i];
      updateGroup(newGroupTargetId, group, "groupTarget_Id", i);
    }
  };

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h2 className="dashhead-title">{t("groups")}</h2>
          <Alert type="primary" className="mt-2">
            {t("group_header_information")}
          </Alert>
          <hr />
        </div>
      </div>

      <Row>
        <Col md="8">
          {contract.groups.length > 0 && (
            <div className="form-group">
              <label className="form-control-label">{t("payment_target")}</label>

              {groupTargetsReducer.groupTargets.map((gt) => (
                <div className="form-check" key={`${gt.id}`}>
                  <input
                    className="form-check-input"
                    type="radio"
                    id={`groupTarget_Id-${gt.id}`}
                    name={`groupTarget_Id-${gt.id}`}
                    value={gt.id}
                    checked={contract.groups[0]?.groupTarget_Id === gt.id}
                    onChange={(e) => updateGroupsTargetIds(Number(e.target.value))}
                    // disabled={tax.disabled || (taxTypes.find((tt) => tt.id === tax.taxType_Id) || {}).isFixed || !isManager}
                  />
                  <label className="form-check-label" htmlFor={`groupTarget_Id-${gt.id}`}>
                    {gt[referenceName]}
                  </label>
                </div>
              ))}
            </div>
          )}
        </Col>
        <Col md="4">
          <button className="btn btn-primary float-right" onClick={addGroup} disabled={!accountReducer.isManager}>
            {t("add_group")}
          </button>
        </Col>
      </Row>
      {contract &&
        contract.groups &&
        contract.groups.map((g, index) => (
          <Group
            key={g.uniqueIdentifier}
            removeGroup={removeGroup}
            contract={contract}
            group={g}
            index={index}
            isEstablishmentContract={isEstablishmentContract}
            validator={validator}
          />
        ))}

      {contract && contract.groups && contract.groups.length > 0 && (
        <Row className={"mt-2"}>
          <Col md="12">
            <button className="btn btn-primary float-right" onClick={addGroup} disabled={!accountReducer.isManager}>
              {t("add_group")}
            </button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}
