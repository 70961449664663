import axios from "axios";
import { HOST } from "config/config";
import tableDataQueryStringBuilder from "utils/tableData";

export function getProvinces(tableDataParam, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().provincesReducer.updating || forceRefresh) {
      if (tableDataParam === undefined) {
        return dispatch({
          type: "GET_PROVINCES_PENDING",
          payload: axios
            .get(`${HOST}/api/provinces`)
            .then(response => response.data)
            .then(json =>
              dispatch({
                type: "GET_PROVINCES_FULFILLED",
                payload: {
                  items: json
                }
              })
            )
            .catch(error =>
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.message
                }
              })
            )
        });
      }

      return dispatch({
        type: "GET_PROVINCES_PENDING",
        payload: axios
          .get(`${HOST}/api/provinces`, {
            paramsSerializer: params => tableDataQueryStringBuilder(params),
            params: tableDataParam
          })
          .then(response => response.data)
          .then(json => {
            if (tableDataParam) {
              dispatch({
                type: "UPDATE_PROVINCES_TABLEDATAPARAM",
                payload: tableDataParam
              });
            }

            dispatch({
              type: "GET_PROVINCES_FULFILLED",
              payload: json
            });
          })
          .catch(error =>
            dispatch({
              type: "GET_PROVINCES_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}

export function getProvincesForSelect() {
  return (dispatch, getState) => {
    if (!getState().provincesReducer.updating) {
      return dispatch({
        type: "GET_PROVINCES_PENDING",
        payload: axios
          .get(`${HOST}/api/filters/provinces`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_PROVINCES_SELECT_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message
              }
            })
          )
      });
    }
  };
}

export function getProvince(provinceId, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().provincesReducer.updating || forceRefresh) {
      if (provinceId === 0) {
        return dispatch({
          type: "GET_PROVINCE_FULFILLED",
          payload: {
            id: 0,
            internalName: "",
            nameFrench: "",
            nameEnglish: "",
            createdDate: new Date()
          }
        });
      }
      return dispatch({
        type: "GET_PROVINCE_PENDING",
        payload: axios
          .get(`${HOST}/api/provinces/${provinceId}`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_PROVINCE_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "GET_PROVINCE_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}

export function updateProvince(province) {
  return dispatch =>
    dispatch({
      type: "UPDATE_PROVINCE",
      payload: province
    });
}

export function persistProvince(province) {
  return (dispatch, getState) => {
    if (!getState().provincesReducer.updating) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "PERSISTING_PROVINCE",
          payload: (province.id === 0
            ? axios.post(`${HOST}/api/provinces`, province)
            : axios.put(`${HOST}/api/provinces/${province.id}`, province)
          )
            .then(response => response.data)
            .then(json => {
              dispatch({
                type: "PERSISTED_FEE_TYPE",
                payload: {
                  province: json,
                  isNew: province.id === 0
                }
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "saved_successful"
                }
              });
              return resolve();
            })
            .catch(error => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response
                }
              });
              return reject();
            })
        })
      );
    }
  };
}
