import React from "react";


import RestaurationPriceDetail from "./RestaurationPriceDetail";

export default function RestaurationPrice({ contract, contractPriceDetails }) {

  return contractPriceDetails.map(cpd => <RestaurationPriceDetail contract={contract} cpd={cpd} key={cpd.id} />);

  // <div className="card mt-3">
  //   <div className="card-body">
  //     <div className="card-text">
  //       <Row>
  //         <Col md="12">
  //           <table className="table table-striped">
  //             <thead>
  //               <tr>
  //                 <th>{t("contract_period")}</th>
  //                 <th>{t("age_categories")}</th>
  //                 <th>{t("groups")}</th>
  //                 <th>{t("value")}</th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {contractPriceDetails.map(cpd => (
  //                 <RestaurationPriceDetail contract={contract} cpd={cpd}  key={cpd.id} />
  //               ))}
  //             </tbody>
  //           </table>
  //         </Col>
  //       </Row>
  //     </div>
  //   </div>
  // </div>
}
