import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { hideProductModal, updateProduct, persistProduct, resetNewProductModel } from "redux/actions/products";

import TaxSection from "views/Products/Components/Components/TaxSection";
import RemarkSection from "views/Products/Components/Components/RemarkSection";
import GeneralInformationSection from "views/Products/Components/Components/GeneralInformationSection";
import ProductSpecificationSection from "./Components/ProductSpecificationSection";

function ProductModal({ remarkTypes = [], productTypes = [], taxTypes = [], selectedEstablishment }) {
  const productsReducer = useSelector(state => state.productsReducer);

  const saveBtn = () => {
    if (selectedEstablishment) {
      productsReducer.productModal.establishment_Id = selectedEstablishment;
    }
    dispatch(updateProduct(productsReducer.productModal));
    dispatch(persistProduct(productsReducer.productModal));
    dispatch(resetNewProductModel());

    closeModal();
  };

  const closeModal = () => {
    dispatch(hideProductModal());
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Modal className="mw-100 w-50" isOpen={productsReducer.showModal}>
      <ModalHeader>{t("edit_or_create_product")}</ModalHeader>
      <ModalBody>
        <GeneralInformationSection productTypes={productTypes} />

        <ProductSpecificationSection  />
        
        <TaxSection taxTypes={taxTypes} />

        <RemarkSection remarkTypes={remarkTypes} />
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={saveBtn}>
          {t("save")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ProductModal;
