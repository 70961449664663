import axios from "axios";
import { HOST } from "config/config";
import tableDataQueryStringBuilder from "utils/tableData";

export function hideProductContractTableView() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_PRODUCT_CONTRACT_TABLE_VIEW_MODAL",
      });
      return resolve();
    });
}

export function showProductContractTableView() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: "SHOW_PRODUCT_CONTRACT_TABLE_VIEW_MODAL",
      });
      return resolve();
    });
}

export function deleteEstablishmentContract(contract) {
  return (dispatch, getState) => {
    if (!getState().contractsReducer.deletingEstablishmentContract) {
      return new Promise((resolve) =>
        dispatch({
          type: "DELETING_ESTABLISHMENT_CONTRACT",
          payload: axios
            .delete(`${HOST}/api/establishment-contracts/${contract.id}`, contract)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "DELETED_ESTABLISHMENT_CONTRACT",
                payload: contract,
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "deleted_successfully",
                },
              });

              resolve(json);
            }),
        })
      );
    }
  };
}

export function hideDeleteContractModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_DELETE_CONTRACT_MODAL",
      });
      return resolve();
    });
}

export function showDeleteContractModal(contract) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SHOW_DELETE_CONTRACT_MODAL",
        payload: contract,
      });
      return resolve();
    });
}

export function clearProductContractFilters() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: "CLEAR_PRODUCT_CONTRACT_FILTERS",
      });
      return resolve();
    });
}

export function updateProductContractFilters(productContractFilters) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: "UPDATE_PRODUCT_CONTRACT_FILTERS",
        payload: {
          productContractFilters,
        },
      });
      return resolve();
    });
}

export function updateManyProductContracts(productContracts) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axios
        .put(`${HOST}/api/product-contracts/update-many`, productContracts)
        .then((response) => response.data)
        .then((json) => {
          dispatch({
            type: "SHOW_MESSAGE",
            payload: {
              type: "success",
              titleKey: "success",
              messageKey: "saved_successful",
            },
          });
          return resolve();
        })
        .catch((error) => {
          dispatch({
            type: "SHOW_MESSAGE",
            payload: {
              type: "error",
              titleKey: "error",
              message: error.response,
            },
          });
          return reject();
        })
    );
}

export function addContractPeriodDate(contractPeriod) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "ADD_CONTRACT_PERIOD_DATE",
        payload: { contractPeriod },
      });
      return resolve();
    });
}

export function removeContractPeriodDate(contractPeriod, contractPeriodDate) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "REMOVE_CONTRACT_PERIOD_DATE",
        payload: { contractPeriod, contractPeriodDate },
      });
      return resolve();
    });
}

export function addContractFile(contract, file, content) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "ADD_CONTRACT_FILE",
        payload: { contract, file, content },
      });
      return resolve();
    });
}

export function removeContractFile(contract, file) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "REMOVE_CONTRACT_FILE",
        payload: { contract, file },
      });
      return resolve();
    });
}

export function updateSteps(contract) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_STEPS",
        payload: { contract },
      });
      return resolve();
    });
}

export function updateCart(cart) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_CART",
        payload: { cart },
      });
      return resolve();
    });
}

export function setActiveTab(activeTab) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SET_ACTIVE_TAB",
        payload: { activeTab },
      });
      return resolve();
    });
}

export function hideFreebieEditModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_FREEBIE_EDIT_MODAL",
      });
      return resolve();
    });
}

export function showFreebieEditModal(freebie) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SHOW_FREEBIE_EDIT_MODAL",
        payload: { freebie },
      });
      return resolve();
    });
}

export function removeValidityDate(contract, validityDate) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "REMOVE_VALIDITY_DATE",
        payload: { contract, validityDate },
      });
      return resolve(contract);
    });
}

export function addValidityDate(contract) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "ADD_VALIDITY_DATE",
        payload: { contract },
      });
      return resolve();
    });
}

export function setProductTypeId(productType_Id) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SET_PRODUCT_TYPE_ID",
        payload: { productType_Id },
      });
      return resolve();
    });
}
export function removeContractProductFromContract(contractProduct, contract) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "REMOVE_CONTRACT_PRODUCT_FROM_CONTRACT",
        payload: { contractProduct, contract },
      });
      return resolve();
    });
}

export function addContractProductToContract(contractProduct, contract) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "ADD_CONTRACT_PRODUCT_TO_CONTRACT",
        payload: { contractProduct, contract },
      });
      return resolve();
    });
}
export function emptyCart() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "EMPTY_CART",
      });
      return resolve();
    });
}
export function addToCart(item, type) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "ADD_TO_CART",
        payload: {
          type,
          item,
        },
      });
      return resolve();
    });
}
export function setActiveStep(step) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({ type: "SET_ACTIVE_STEP", payload: step });
      return resolve();
    });
}

export function getContracts(tableDataParam, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().contractsReducer.updating || forceRefresh) {
      if (tableDataParam === undefined) {
        return new Promise((resolve) =>
          dispatch({
            type: "GET_CONTRACTS_PENDING",
            payload: axios
              .get(`${HOST}/api/contracts`)
              .then((response) => response.data)
              .then((json) => {
                dispatch({
                  type: "GET_CONTRACTS_FULFILLED",
                  payload: {
                    items: json,
                  },
                });

                resolve(json);
              })
              .catch((error) =>
                dispatch({
                  type: "SHOW_MESSAGE",
                  payload: {
                    type: "error",
                    titleKey: "error",
                    message: error.message,
                  },
                })
              ),
          })
        );
      }

      return dispatch({
        type: "GET_CONTRACTS_PENDING",
        payload: axios
          .get(`${HOST}/api/contracts`, {
            paramsSerializer: (params) => tableDataQueryStringBuilder(params),
            params: tableDataParam,
          })
          .then((response) => response.data)
          .then((json) => {
            if (tableDataParam) {
              dispatch({
                type: "UPDATE_CONTRACTS_TABLEDATAPARAM",
                payload: tableDataParam,
              });
            }

            dispatch({
              type: "GET_CONTRACTS_FULFILLED",
              payload: json,
            });
          })
          .catch((error) =>
            dispatch({
              type: "GET_CONTRACTS_REJECTED",
              payload: error,
            })
          ),
      });
    }
  };
}

export function getContractsForSelect() {
  return (dispatch, getState) => {
    if (!getState().contractsReducer.selectUpdating) {
      return dispatch({
        type: "GET_CONTRACTS_FOR_SELECT_PENDING",
        payload: axios
          .get(`${HOST}/api/contracts`)
          .then((response) => response.data)
          .then((json) =>
            dispatch({
              type: "GET_CONTRACTS_SELECT_FULFILLED",
              payload: json,
            })
          )
          .catch((error) =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message,
              },
            })
          ),
      });
    }
  };
}

export function getContractsForEstablishment(establishmentId) {
  return (dispatch, getState) => {
    if (!getState().contractsReducer.selectUpdating) {
      return dispatch({
        type: "GET_CONTRACTS_FOR_ESTABLISHMENT_PENDING",
        payload: axios
          .get(`${HOST}/api/establishment-contracts?establishment_Id=${establishmentId}`)
          .then((response) => response.data)
          .then((json) =>
            dispatch({
              type: "GET_CONTRACTS_ESTABLISHMENT_FULFILLED",
              payload: json,
              establishmentId: establishmentId,
            })
          )
          .catch((error) =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message,
              },
            })
          ),
      });
    }
  };
}

export function getEstablishmentContracts(tableDataParam, establishmentId, onlyActiveContract) {
  return (dispatch, getState) => {
    if (!getState().contractsReducer.updating) {
      return dispatch({
        type: "GET_ESTABLISHMENT_CONTRACTS_PENDING",
        payload: axios
          .get(`${HOST}/api/establishment-contracts`, {
            paramsSerializer: (params) => tableDataQueryStringBuilder(params),
            params: { ...tableDataParam, establishmentId, onlyActiveContract },
          })
          .then((response) => response.data)
          .then((json) =>
            dispatch({
              type: "GET_ESTABLISHMENT_CONTRACTS_FULFILLED",
              payload: json,
              establishmentId,
            })
          )
          .catch((error) =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message,
              },
            })
          ),
      });
    }
  };
}

export function getEstablishmentContract(contractId, id, forceRefresh = false) {
  return (dispatch, getState) =>
    new Promise((resolve) => {
      if (!getState().contractsReducer.updating || forceRefresh) {
        if (contractId === 0) {
          let currentDate = new Date();
          return dispatch({
            type: "GET_ESTABLISHMENT_CONTRACT_FULFILLED",
            payload: {
              id: 0,
              isActive: true,
              start: new Date(currentDate.getFullYear(), 0, 1),
              end: new Date(currentDate.getFullYear(), 11, 31),
              internalName: "",
              nameFrench: "",
              nameEnglish: "",
              contractType_Id: 0,
              product_Id: id,
              establishment_Id: id,
              ageCategories: [],
              lengthCategories: [],
              groups: [],
              contractPriceDetails: [],
              contractDayGroups: [],
              contractDepartureGroups: [],
              contractPeriods: [],
              freebies: [],
            },
          });
        }
        return dispatch({
          type: "GET_ESTABLISHMENT_CONTRACT_PENDING",
          payload: axios
            .get(`${HOST}/api/establishment-contracts/${contractId}`)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "GET_ESTABLISHMENT_CONTRACT_FULFILLED",
                payload: json,
              });
              return resolve(json);
            })
            .catch((error) =>
              dispatch({
                type: "GET_ESTABLISHMENT_CONTRACT_REJECTED",
                payload: error,
              })
            ),
        });
      }
    });
}

export function resetEstablishmentContract(id) {
  return (dispatch, getState) =>
    new Promise((resolve) => {
      let currentDate = new Date();
      const defaultEstablishmentContract = {
        id: 0,
        internalName: "",
        nameFrench: "",
        nameEnglish: "",
        startDate: new Date(currentDate.getFullYear(), 0, 1),
        endDate: new Date(currentDate.getFullYear(), 11, 31),
        contractType_Id: 0,
        product_Id: id,
        establishment_Id: id,
        ageCategories: [],
        lengthCategories: [],
        groups: [],
        contractPriceDetails: [],
        contractDayGroups: [],
        contractDepartureGroups: [],
        contractPeriods: [],
        freebies: [],
      };
      dispatch({
        type: "GET_ESTABLISHMENT_CONTRACT_FULFILLED",
        payload: defaultEstablishmentContract,
      });

      resolve(defaultEstablishmentContract);
    });
}

export function getProductContract(contractId, id, forceRefresh = false) {
  return (dispatch, getState) =>
    new Promise((resolve) => {
      if (!getState().contractsReducer.updating || forceRefresh) {
        if (contractId === 0) {
          let currentDate = new Date();
          return dispatch({
            type: "GET_PRODUCT_CONTRACT_FULFILLED",
            payload: {
              id: 0,
              internalName: "",
              nameFrench: "",
              nameEnglish: "",
              startDate: new Date(currentDate.getFullYear(), 0, 1),
              endDate: new Date(currentDate.getFullYear(), 11, 31),
              contractType_Id: 0,
              product_Id: id,
              establishment_Id: id,
              ageCategories: [],
              lengthCategories: [],
              groups: [],
              contractPriceDetails: [],
              contractDayGroups: [],
              contractDepartureGroups: [],
              contractPeriods: [],
              freebies: [],
            },
          });
        }
        return dispatch({
          type: "GET_PRODUCT_CONTRACT_PENDING",
          payload: axios
            .get(`${HOST}/api/product-contracts/${contractId}`)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "GET_PRODUCT_CONTRACT_FULFILLED",
                payload: json,
              });
              return resolve(json);
            })
            .catch((error) =>
              dispatch({
                type: "GET_PRODUCT_CONTRACT_REJECTED",
                payload: error,
              })
            ),
        });
      }
    });
}

export function updateContract(contract) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_CONTRACT",
        payload: contract,
      });
      return resolve(contract);
    });
}

export function persistProductContract(contract) {
  return (dispatch, getState) => {
    if (!getState().contractsReducer.updating) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "PERSISTING_CONTRACT",
          payload: (contract.id === 0
            ? axios.post(`${HOST}/api/product-contracts`, contract)
            : axios.put(`${HOST}/api/product-contracts/${contract.id}`, contract)
          )
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "PERSISTED_CONTRACT",
                payload: {
                  contract: json,
                  isNew: contract.id === 0,
                },
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "saved_successful",
                },
              });
              return resolve();
            })
            .catch((error) => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response,
                },
              });
              return reject();
            }),
        })
      );
    }
  };
}

export function persistEstablishmentContract(contract) {
  return (dispatch, getState) => {
    if (!getState().contractsReducer.updating) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "PERSISTING_ESTABLISHMENT_CONTRACT",
          payload: (contract.id === 0
            ? axios.post(`${HOST}/api/establishment-contracts`, contract)
            : axios.put(`${HOST}/api/establishment-contracts/${contract.id}`, contract)
          )
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "PERSISTED_ESTABLISHMENT_CONTRACT",
                payload: {
                  contract: json,
                  isNew: contract.id === 0,
                },
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "saved_successful",
                },
              });
              return resolve(json);
            })
            .catch((error) => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response,
                },
              });
              return reject();
            }),
        })
      );
    }
  };
}

export function assignContractEditModel(contract) {
  return (dispatch) =>
    new Promise((resolve) => {
      let c = contract || {
        id: 0,
        nameFrench: "",
        nameEnglish: "",
        remarks: [],
        productType_Ids: [],
      };
      dispatch({
        type: "ASSIGN_CONTRACT_EDIT_MODAL",
        payload: c,
      });
      return resolve(c);
    });
}

export function duplicateContract(contractId, nameFrench, nameEnglish) {
  return (dispatch, getState) => {
    if (!getState().contractsReducer.duplicating) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "DUPLICATING_ESTABLISHMENT_CONTRACT",
          payload: axios
            .post(`${HOST}/api/establishment-contracts/duplicate`, null, { params: { contractId, nameFrench, nameEnglish } })
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "DUPLICATED_ESTABLISHMENT_CONTRACT",
                payload: {
                  contract: json,
                },
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "duplicate_successful",
                },
              });

              return resolve(json);
            })
            .catch((error) => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response,
                },
              });

              return reject();
            }),
        })
      );
    }
  };
}

export function addContractDepartureGroup(isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "ADD_CONTRACT_DEPARTURE_GROUP",
      payload: { isEstablishmentContract },
    });
}

export function addContractDayGroup(isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "ADD_CONTRACT_DAY_GROUP",
      payload: { isEstablishmentContract },
    });
}

export function addContractAge(isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "ADD_CONTRACT_AGE",
      payload: { isEstablishmentContract },
    });
}

export function addContractLength(isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "ADD_CONTRACT_LENGTH",
      payload: { isEstablishmentContract },
    });
}

export function addContractFreebie(isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "ADD_CONTRACT_FREEBIE",
      payload: { isEstablishmentContract },
    });
}

export function addContractPeriod(isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "ADD_CONTRACT_PERIOD",
      payload: { isEstablishmentContract },
    });
}

export function removeContractDepartureGroup(contractDepartureGroup, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "REMOVE_CONTRACT_DEPARTURE_GROUP",
      payload: { contractDepartureGroup, isEstablishmentContract },
    });
}

export function removeContractDayGroup(contractDayGroup, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "REMOVE_CONTRACT_DAY_GROUP",
      payload: { contractDayGroup, isEstablishmentContract },
    });
}

export function removeContractAge(ageCategory, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "REMOVE_CONTRACT_AGE",
      payload: { ageCategory, isEstablishmentContract },
    });
}

export function removeContractFreebie(freebie, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "REMOVE_CONTRACT_FREEBIE",
      payload: { freebie, isEstablishmentContract },
    });
}

export function removeContractPeriod(contractPeriod, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "REMOVE_CONTRACT_PERIOD",
      payload: { contractPeriod, isEstablishmentContract },
    });
}

export function removeContractLength(lengthCategory, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "REMOVE_CONTRACT_LENGTH",
      payload: { lengthCategory, isEstablishmentContract },
    });
}

export function updateContractDepartureGroup(contractDepartureGroup, isEstablishmentContract) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_CONTRACT_DEPARTURE_GROUP",
        payload: { contractDepartureGroup, isEstablishmentContract },
      });
      return resolve();
    });
}

export function updateContractDayGroup(contractDayGroup, isEstablishmentContract) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_CONTRACT_DAY_GROUP",
        payload: { contractDayGroup, isEstablishmentContract },
      });
      return resolve();
    });
}

export function updateContractAge(ageCategory, index, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "UPDATE_CONTRACT_AGE_CATEGORY",
      payload: { ageCategory, index, isEstablishmentContract },
    });
}

export function updateContractFreebie(freebie, index, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "UPDATE_CONTRACT_FREEBIE",
      payload: { freebie, index, isEstablishmentContract },
    });
}

export function updateContractLength(lengthCategory, index, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "UPDATE_CONTRACT_LENGTH_CATEGORY",
      payload: { lengthCategory, index, isEstablishmentContract },
    });
}

export function addContractGroup(isEstablishmentContract, defaultGroupTargetId) {
  return (dispatch, getState) =>
    dispatch({
      type: "ADD_CONTRACT_GROUP",
      payload: { isEstablishmentContract, defaultGroupTargetId },
    });
}

export function removeContractGroup(group, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "REMOVE_CONTRACT_GROUP",
      payload: {
        group,
        isEstablishmentContract,
      },
    });
}

export function updateContractGroup(group, index, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "UPDATE_CONTRACT_GROUP",
      payload: { group, index, isEstablishmentContract },
    });
}

export function updateContractPeriod(contractPeriod, index, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "UPDATE_CONTRACT_PERIOD",
      payload: { contractPeriod, index, isEstablishmentContract },
    });
}

export function addAgeCategoryCriteria(ageCategory, index, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "ADD_CONTRACT_AGE_CATEGORY_CRITERIA",
      payload: { ageCategory, index, isEstablishmentContract },
    });
}

export function addFreebieCriteria(freebie, index, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "ADD_CONTRACT_FREEBIE_CRITERIA",
      payload: { freebie, index, isEstablishmentContract },
    });
}

export function addFreebiePeriodDate(freebie, index, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "ADD_CONTRACT_FREEBIE_PERIOD_DATE",
      payload: { freebie, index, isEstablishmentContract },
    });
}

export function addFreebieDetail(freebie, index, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "ADD_CONTRACT_FREEBIE_DETAIL",
      payload: { freebie, index, isEstablishmentContract },
    });
}

export function updateContractAgeCriteria(criteria, ageIndex, criteriaIndex, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "UPDATE_CONTRACT_AGE_CATEGORY_CRITERIA",
      payload: { criteria, ageIndex, criteriaIndex, isEstablishmentContract },
    });
}

export function updateContractFreebieCriteria(criteria, freebieIndex, criteriaIndex, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "UPDATE_CONTRACT_FREEBIE_CRITERIA",
      payload: { criteria, freebieIndex, criteriaIndex, isEstablishmentContract },
    });
}

export function updateContractFreebiePeriodDate(periodDate, freebieIndex, periodDateIndex, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "UPDATE_CONTRACT_FREEBIE_PERIOD_DATE",
      payload: { periodDate, freebieIndex, periodDateIndex, isEstablishmentContract },
    });
}

export function updateContractFreebieDetail(detail, freebieIndex, detailIndex, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "UPDATE_CONTRACT_FREEBIE_DETAIL",
      payload: { detail, freebieIndex, detailIndex, isEstablishmentContract },
    });
}

export function removeAgeCategoryCriteria(ageCategory, ageIndex, criteriaIndex, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "REMOVE_CONTRACT_AGE_CATEGORY_CRITERIA",
      payload: { ageCategory, ageIndex, criteriaIndex, isEstablishmentContract },
    });
}

export function removeFreebieCriteria(freebie, freebieIndex, criteriaIndex, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "REMOVE_CONTRACT_FREEBIE_CRITERIA",
      payload: { freebie, freebieIndex, criteriaIndex, isEstablishmentContract },
    });
}

export function removeFreebiePeriodDate(freebie, freebieIndex, periodDateIndex, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "REMOVE_CONTRACT_FREEBIE_PERIOD_DATE",
      payload: { freebie, freebieIndex, periodDateIndex, isEstablishmentContract },
    });
}

export function removeFreebieDetail(freebie, freebieIndex, detailIndex, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "REMOVE_CONTRACT_FREEBIE_DETAIL",
      payload: { freebie, freebieIndex, detailIndex, isEstablishmentContract },
    });
}

export function addGroupCriteria(group, index, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "ADD_CONTRACT_GROUP_CRITERIA",
      payload: { group, index, isEstablishmentContract },
    });
}

export function updateContractGroupCriteria(criteria, groupIndex, criteriaIndex, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "UPDATE_CONTRACT_GROUP_CRITERIA",
      payload: { criteria, groupIndex, criteriaIndex, isEstablishmentContract },
    });
}

export function removeGroupCriteria(group, groupIndex, criteriaIndex, isEstablishmentContract) {
  return (dispatch, getState) =>
    dispatch({
      type: "REMOVE_CONTRACT_GROUP_CRITERIA",
      payload: { group, groupIndex, criteriaIndex, isEstablishmentContract },
    });
}

export function showDuplicateModal(contract) {
  return (dispatch, getState) =>
    dispatch({
      type: "OPEN_DUPLICATE_CONTRACT_MODAL",
      payload: { contract },
    });
}

export function hideDuplicateModal() {
  return (dispatch, getState) =>
    dispatch({
      type: "HIDE_DUPLICATE_CONTRACT_MODAL",
    });
}

export function showSummaryModal(contract) {
  return (dispatch, getState) =>
    dispatch({
      type: "OPEN_SUMMARY_CONTRACT_MODAL",
      payload: { contract },
    });
}

export function hideSummaryModal() {
  return (dispatch, getState) =>
    dispatch({
      type: "HIDE_SUMMARY_CONTRACT_MODAL",
    });
}

export function updateDuplicateModel(value, fieldname) {
  return (dispatch, getState) =>
    dispatch({
      type: "UPDATE_DUPLICATE_CONTRACT_MODAL",
      payload: { value, fieldname },
    });
}

export function hideConfirmDeleteWithPriceDetailModal() {
  return (dispatch, getState) =>
    dispatch({
      type: "HIDE_CONFIRM_DELETE_WITH_PRICE_DETAILS_MODAL",
    });
}
export function showConfirmDeleteWithPriceDetailModal(name, saveAction) {
  return (dispatch, getState) =>
    dispatch({
      type: "SHOW_CONFIRM_DELETE_WITH_PRICE_DETAILS_MODAL",
      payload: { name, saveAction },
    });
}

export function getPricingProductsContracts(productIds) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.updating) {
      return new Promise((resolve) =>
        dispatch({
          type: "GET_PRICING_PRODUCTS_CONTRACTS_PENDING",
          payload: axios
            .get(`${HOST}/api/product-contracts/by-product-ids`, {
              params: { productIds },
              paramsSerializer: (params) =>
                params.productIds
                  .map((productId, index) => (index === 0 ? `[${index}]=${productId}` : `&[${index}]=${productId}`))
                  .join(""),
            })
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "GET_PRICING_PRODUCTS_CONTRACTS_FULFILLED",
                payload: json,
              });

              resolve(json);
            }),
        })
      );
    }
  };
}

export function resetProductPriceDetailProductIdsPricingProductsContrats() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "RESET_PRODUCT_PRICE_DETAIL_PRODUCT_IDS_PRICING_PRODUCTS_CONTRATS",
      });
      return resolve();
    });
}
