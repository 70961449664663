import React, { useEffect } from "react";

import useReactRouter from "use-react-router";
import { useDispatch } from "react-redux";

import { getPricingProductsContracts } from "redux/actions/contracts";
import PricingProductContractsContainer from "./PricingProductContractsContainer";
import { getProductsForEstablishment } from "redux/actions/products";
import { getProductTypes } from "redux/actions/product-types";

export default function ProductPricing() {
  const dispatch = useDispatch();
  const { history, match } = useReactRouter();

  useEffect(() => {
    dispatch(getPricingProductsContracts([Number(match.params.productId)]));
    dispatch(getProductsForEstablishment(Number(match.params.id)));
    dispatch(getProductTypes());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.productId]);

  const handleBack = () => {
    history.push(`/establishments/${match.params.id}/products`);
  };

  return <PricingProductContractsContainer handleBack={handleBack} showFilter />;
}
