import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import TableData from "components/TableData/TableData";
import Spinner from "components/Spinner/Spinner";

import ReactTooltip from "react-tooltip";

import { getProviders } from "redux/actions/providers";
import { Button } from "reactstrap";

function Providers() {
  const { t } = useTranslation();
  const providersReducer = useSelector((state) => state.providersReducer);
  const accountReducer = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProviders(providersReducer.tableDataParam));
  }, [dispatch, providersReducer.tableDataParam]);

  const renderRows = (value, field, isFilter) => {
    let tableDataParam = providersReducer.tableDataParam;
    tableDataParam[field] = value;

    if (isFilter) {
      tableDataParam.currentPage = 1;
    }

    dispatch(getProviders(tableDataParam));
  };

  const updatePage = (page) => {
    renderRows(page, "currentPage");
  };
  const updatePageSize = (pageSize) => {
    renderRows(pageSize, "pageSize");
  };

  const sortColumn = (column) => {
    let tableDataParam = providersReducer.tableDataParam;
    let existingSort = tableDataParam.sorts.find((s) => s.column === column.sortColumn);

    renderRows(
      [
        {
          column: column.sortColumn,
          asc: existingSort !== undefined ? !existingSort.asc : false,
        },
      ],
      "sorts"
    );
  };

  const filter = (filters) => {
    if (filters === undefined) {
      return renderRows([], "filters");
    }

    let formattedFilters = filters.map((f) => ({
      fieldName: f.selectedColumn.column.filterColumn,
      columnWrapper: f.selectedColumn.column.columnWrapper,
      fieldValue: f.value,
      fieldType: f.selectedColumn.column.type,
      operator: f.comparator,
    }));

    return renderRows(formattedFilters, "filters", true);
  };

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">{t("overview")}</h6>
          <h2 className="dashhead-title">{t("providers")}</h2>
        </div>
      </div>

      <TableData
        className="mb-4"
        numberOfItems={providersReducer.count}
        pageSize={providersReducer.tableDataParam.pageSize}
        updatePage={updatePage}
        updatePageSize={updatePageSize}
        pageSizes={[25, 50, 100, 200]}
        hideTitle
        reset={t("reset")}
        filter={t("filter")}
        sortMethod={sortColumn}
        filterMethod={filter}
        locale={t("locale")}
        hasFilter
        columns={[
          {
            title: t("id"),
            filterComparatorsName: "numberComparators",
            filterColumn: "Id",
            type: "int",
          },
          {
            title: t("name"),
            filterComparatorsName: "stringComparators",
            filterColumn: "ProviderName",
            type: "string",
          },
          {
            title: t("establishments"),
          },
          {
            title: t("actions"),
          },
        ]}
        quickLink={
          <a href={`${accountReducer.northWindUrl}/Providers`} target="_blank" rel="noopener noreferrer">
            <Button color="primary">
              <i className="fab  fa-neos"></i> {t("northwind_providers")}
            </Button>
          </a>
        }
      >
        <tbody>
          {providersReducer.updating ? (
            <tr>
              <td colSpan={5}>
                <Spinner />
              </td>
            </tr>
          ) : providersReducer.providers.length ? (
            providersReducer.providers.map((f) => (
              <tr key={f.id}>
                <td>{f.id}</td>
                <td>{f.providerName}</td>
                <td>
                  {f.establishments &&
                    f.establishments.map((e) => (
                      <ul key={`${f.id} - ${e.id}`}>
                        <li>{e.establishmentName}</li>
                      </ul>
                    ))}
                </td>
                <td>
                  <React.Fragment>
                    <ReactTooltip clickable id="tt-go-to-provider" type="info" effect="solid" place="left" border />
                    <a
                      href={`${accountReducer.northWindUrl}/Providers#!/providers/${f.id}/basicinfo`}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-for="tt-go-to-provider"
                      data-tip={t("go_to_provider")}
                      className="ml-2 text-success"
                    >
                      <i className="fab fa-2x fa-neos"></i>
                    </a>
                  </React.Fragment>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{t("no_data_found")}</td>
            </tr>
          )}
        </tbody>
      </TableData>
    </React.Fragment>
  );
}

export default Providers;
