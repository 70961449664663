export const departuresReducer = (
  state = {
    departures: [],
    departuresForSelect: [],
    departuresForSelectFiltered: [],
    updatingDepartureForSelect: false,

    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: [],
    },
  },
  action
) => {
  switch (action.type) {
    case "GET_DEPARTURES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_DEPARTURES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_DEPARTURES_FULFILLED": {
      state = { ...state, updating: false, updated: true, departures: action.payload.items };
      break;
    }
    case "GET_DEPARTURES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_DEPARTURE_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_DEPARTURE_FULFILLED": {
      let departures = state.departures;
      departures = departures.filter((s) => s.id !== action.payload.id);
      departures.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        departures,
      };
      break;
    }
    case "GET_DEPARTURES_SELECT_FULFILLED": {
      let departures = [];
      action.payload.forEach((departure) => {
        if (!departures.some((t) => t.id === departure.id)) {
          departures.push(departure);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        departuresForSelect: departures,
      };
      break;
    }
    case "GET_DEPARTURE_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    case "FILTER_DEPARTURE_SELECT": {
      let filterDepartures = [];

      state = { ...state, departuresForSelectFiltered: filterDepartures };

      break;
    }

    case "ADD_DEPARTURES_PENDING": {
      state = { ...state, updatingDepartureForSelect: true };
      break;
    }
    case "ADD_DEPARTURES_SELECT_FULFILLED": {
      let departures = state.departuresForSelect;
      action.payload.forEach((departure) => {
        if (!departures.some((t) => t.id === departure.id)) {
          departures.push(departure);
        }
      });

      state = {
        ...state,
        updatingDepartureForSelect: false,
        departuresForSelect: departures,
      };
      break;
    }

    default: {
    }
  }

  return state;
};

export default departuresReducer;
