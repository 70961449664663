export const optionsReducer = (
    state = {
      options: [],
      optionsForSelect: [],
      updating: false,
      updated: false,
      error: null,

      count: 0,
      tableDataParam: {
        pageSize: 25,
        currentPage: 1,
        sorts: [{ column: "id", asc: false }],
        filters: []
      }
    },
    action
  ) => {
    switch (action.type) {
      case "GET_OPTIONS_PENDING": {
        state = { ...state, updating: true };
        break;
      }
      case "GET_OPTIONS_FOR_SELECT_PENDING":{
        state = { ...state, selectUpdating: true };
        break;
      }

      case "UPDATE_OPTIONS_TABLEDATAPARAM": {
        state = { ...state, tableDataParam: action.payload };
  
        break;
      }
      case "GET_OPTIONS_FULFILLED": {        
        state = {
          ...state,
          updating: false,
          updated: true,
          options: action.payload.items,
          count: action.payload.count
        };
        break;
      }
      case "GET_OPTIONS_REJECTED": {
        state = { ...state, updating: false, error: action.payload };
        break;
      }
      case "GET_OPTION_PENDING": {
        state = { ...state, updating: true };
        break;
      }
      case "GET_OPTION_FULFILLED": {
        let options = state.options;
        options = options.filter(s => s.id !== action.payload.id);
        options.push(action.payload);
  
        state = {
          ...state,
          updating: false,
          updated: true,
          options
        };
        break;
      }
      case "GET_OPTIONS_SELECT_FULFILLED": {  
        state = {
          ...state,
          selectUpdating: false,
          updated: true,
          optionsForSelect: action.payload
        };
        break;
      }
      case "GET_OPTION_REJECTED": {
        state = { ...state, selectUpdating: false, error: action.payload };
        break;
      }
      case "PERSISTING_OPTION": {
        state = { ...state, updating: true, persisting: true };
  
        break;
      }
      case "PERSISTED_OPTION": {
        let options = state.options;
  
        if (action.payload.isNew) {
          options.forEach(d => {
            if (d.id === 0) {
              d.id = action.payload.option.id;
            }
          });
        }
        state = { ...state, options, updating: false, persisting: false };
  
        break;
      }
      case "UPDATE_OPTION": {
        let options = state.options;
        options.forEach(d => {
          if (d.id === action.payload.id) {
            d = Object.assign(action.payload, {});
          }
        });
        state = { ...state, options, updating: false };
  
        break;
      }
      default: {
      }
    }
  
    return state;
  };
  
  export default optionsReducer;
  