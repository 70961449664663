import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setActiveStep, addContractDepartureGroup } from "redux/actions/contracts";
import { Row, Col, Alert } from "reactstrap";

import Spinner from "components/Spinner/Spinner";
import ContractDepartureGroup from "./Components/ContractDepartureGroup";
import { addDeparturesForSelect } from "redux/actions/departures";

export default function ContractDepartureGroups({ contract, steps, validator }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const contractsReducer = useSelector((state) => state.contractsReducer);
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const departuresReducer = useSelector((state) => state.departuresReducer);
  const accountReducer = useSelector((state) => state.accountReducer);
  const isEstablishmentContract = true;

  useEffect(() => {
    let currentStep = steps.findIndex((s) => s.name === "specific-project-departures");
    if (contractsReducer.activeStepIndex !== currentStep) dispatch(setActiveStep(currentStep));
  }, [contractsReducer.activeStepIndex, contractsReducer.steps, dispatch, steps]);

  useEffect(() => {
    if (departuresReducer.departuresForSelect?.length > 0 && contract?.contractDepartureGroups?.length > 0) {
      const availableDepartures = departuresReducer.departuresForSelect.map((d) => d.id);
      let missingDepartureIds = [];
      contract.contractDepartureGroups.forEach((cdg) => {
        if (cdg.departure_Ids?.length > 0) {
          cdg.departure_Ids.forEach((dId) => {
            if (availableDepartures.every((mdId) => mdId !== dId) && missingDepartureIds.every((mdId) => mdId !== dId)) {
              missingDepartureIds.push(dId);
            }
          });
        }
      });

      if (missingDepartureIds.length > 0) {
        dispatch(addDeparturesForSelect(missingDepartureIds));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departuresReducer.departuresForSelect?.length, contract?.contractDepartureGroups?.length]);

  const handleAddContractDepartureGroup = () => {
    dispatch(addContractDepartureGroup(isEstablishmentContract));
  };

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h2 className="dashhead-title">{t("project_departures")}</h2>
          <hr />
          <Alert type="primary" className="mt-2">
            {t("project_departures_information")}
          </Alert>
        </div>
      </div>

      <Row>
        <Col md="12">
          <button className="btn btn-primary float-right" onClick={handleAddContractDepartureGroup} disabled={!accountReducer.isManager}>
            {t("add_contract_departure_group")}
          </button>
        </Col>
      </Row>

      {!departuresReducer.updatingDepartureForSelect && projectsReducer.projectsForSelect && departuresReducer.departuresForSelect ? (
        contract && contract.contractDepartureGroups && contract.contractDepartureGroups.length ? (
          contract.contractDepartureGroups.map((cdg, i) => (
            <ContractDepartureGroup
              contractDepartureGroup={cdg}
              contract={contract}
              index={i}
              key={cdg.uniqueIdentifier}
              isEstablishmentContract={isEstablishmentContract}
              validator={validator}
            />
          ))
        ) : null
      ) : (
        <Row>
          <Col md="12" className="text-center">
            <Spinner />
          </Col>
        </Row>
      )}

      {contract && contract.contractDepartureGroups && contract.contractDepartureGroups.length > 0 && (
        <Row className={"mt-2"}>
          <Col md="12">
            <button className="btn btn-primary float-right" onClick={handleAddContractDepartureGroup} disabled={!accountReducer.isManager}>
              {t("add_contract_departure_group")}
            </button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}
