import axios from "axios";
import { HOST } from "config/config";
import tableDataQueryStringBuilder from "utils/tableData";

export function getExtraProductInformation(products) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.updating) {
      return new Promise((resolve) =>
        dispatch({
          type: "GET_PRODUCTS_EXTRA_INFORMATION_PENDING",
          payload: axios
            .get(`${HOST}/api/products/get-extra-information`, {
              params: { productIds: products.map((p) => p.id) },
              paramsSerializer: (params) =>
                params.productIds
                  .map((productId, index) => (index === 0 ? `[${index}]=${productId}` : `&[${index}]=${productId}`))
                  .join(""),
            })
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "GET_PRODUCTS_EXTRA_INFORMATION_FULFILLED",
                payload: json,
              });

              resolve(json);
            }),
        })
      );
    }
  };
}

export function deleteProduct(product) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.updating) {
      return new Promise((resolve) =>
        dispatch({
          type: "DELETING_PRODUCT",
          payload: axios
            .delete(`${HOST}/api/products/${product.id}`, product)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "DELETED_PRODUCT",
                payload: product,
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "activated_successfully",
                },
              });

              resolve(json);
            }),
        })
      );
    }
  };
}

export function updateContractPriceDetail(contractPriceDetail) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.updating) {
      return new Promise((resolve) =>
        dispatch({
          type: "UPDATE_CONTRACT_PRICE_DETAIL",
          payload: contractPriceDetail,
        })
      );
    }
  };
}

export function addContract(product) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.updating) {
      return new Promise((resolve) =>
        dispatch({
          type: "ADD_CONTRACT_TO_PRODUCT",
          payload: { product, contract: { id: 0, product_Id: product.id, groups: [], ageCategories: [], contractPriceDetails: [] } },
        })
      );
    }
  };
}
export function activateProduct(product) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.updating) {
      return new Promise((resolve) =>
        dispatch({
          type: "ACTIVATE_PRODUCT_PENDING",
          payload: axios
            .post(`${HOST}/api/products/activate`, product)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "ACTIVATE_PRODUCT_FULFILLED",
                payload: json,
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "activated_successfully",
                },
              });

              resolve(json);
            })
            .catch((error) =>
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.message,
                },
              })
            ),
        })
      );
    }
  };
}

export function deactivateProduct(product, deactivationDate) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.updating) {
      return new Promise((resolve) =>
        dispatch({
          type: "DEACTIVATE_PRODUCT_PENDING",
          payload: axios
            .post(`${HOST}/api/products/deactivate`, product, { params: { deactivationDate } })
            .then((response) => response.data)
            .then((json) => {
              if (!json.deactivationValidation || json.deactivationValidation.canBeDeactivated) {
                dispatch({
                  type: "SHOW_MESSAGE",
                  payload: {
                    type: "success",
                    titleKey: "success",
                    messageKey: "deactivated_successfully",
                  },
                });

                dispatch({
                  type: "DEACTIVATE_PRODUCT_FULFILLED",
                  payload: json,
                });

                dispatch({
                  type: "HIDE_DEACTIVATE_PRODUCT_MODAL",
                });
              } else if (json.deactivationValidation) {
                dispatch({
                  type: "SHOW_DEACTIVATE_PRODUCT_MODAL",
                  payload: json,
                });
              }

              resolve(json);
            })
            .catch((error) =>
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.message,
                },
              })
            ),
        })
      );
    }
  };
}

export function hideDeactivationProductModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_DEACTIVATE_PRODUCT_MODAL",
      });
      return resolve();
    });
}

export function getProducts(tableDataParam, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.updating || forceRefresh) {
      if (tableDataParam === undefined) {
        return new Promise((resolve) =>
          dispatch({
            type: "GET_PRODUCTS_PENDING",
            payload: axios
              .get(`${HOST}/api/products`)
              .then((response) => response.data)
              .then((json) => {
                dispatch({
                  type: "GET_PRODUCTS_FULFILLED",
                  payload: {
                    items: json,
                  },
                });

                resolve(json);
              })
              .catch((error) =>
                dispatch({
                  type: "SHOW_MESSAGE",
                  payload: {
                    type: "error",
                    titleKey: "error",
                    message: error.message,
                  },
                })
              ),
          })
        );
      }

      return dispatch({
        type: "GET_PRODUCTS_PENDING",
        payload: axios
          .get(`${HOST}/api/products`, {
            paramsSerializer: (params) => tableDataQueryStringBuilder(params),
            params: tableDataParam,
          })
          .then((response) => response.data)
          .then((json) => {
            if (tableDataParam) {
              dispatch({
                type: "UPDATE_PRODUCTS_TABLEDATAPARAM",
                payload: tableDataParam,
              });
            }

            dispatch({
              type: "GET_PRODUCTS_FULFILLED",
              payload: json,
            });
          })
          .catch((error) =>
            dispatch({
              type: "GET_PRODUCTS_REJECTED",
              payload: error,
            })
          ),
      });
    }
  };
}

export function getProductsForSelect() {
  return (dispatch, getState) => {
    if (!getState().productsReducer.selectUpdating) {
      return dispatch({
        type: "GET_PRODUCTS_FOR_SELECT_PENDING",
        payload: axios
          .get(`${HOST}/api/products`)
          .then((response) => response.data)
          .then((json) =>
            dispatch({
              type: "GET_PRODUCTS_SELECT_FULFILLED",
              payload: json,
            })
          )
          .catch((error) =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message,
              },
            })
          ),
      });
    }
  };
}

export function getProductsForEstablishment(establishmentId) {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      if (!getState().productsReducer.selectUpdating) {
        dispatch({
          type: "GET_PRODUCTS_FOR_ESTABLISHMENT_PENDING",
          payload: axios
            .get(`${HOST}/api/products?establishment_Id=${establishmentId}`)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "GET_PRODUCTS_ESTABLISHMENT_FULFILLED",
                payload: json,
                establishmentId: establishmentId,
              });

              return resolve(json);
            })
            .catch((error) => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.message,
                },
              });

              return resolve();
            }),
        });
      } else {
        return resolve();
      }
    });
  };
}

export function getProduct(productId, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.updating || forceRefresh) {
      return new Promise((resolve, reject) => {
        if (Number(productId) === 0) {
          let product = {
            id: 0,
            internalName: "",
            nameFrench: "",
            nameEnglish: "",
            productType_Id: 0,
            isActive: true,
            deactivationDate: null,
            productType_Ids: [],
          };
          dispatch({
            type: "GET_PRODUCT_FULFILLED",
            payload: product,
          });

          return resolve(product);
        } else {
          dispatch({
            type: "GET_PRODUCT_PENDING",
            payload: axios
              .get(`${HOST}/api/products/${productId}`)
              .then((response) => response.data)
              .then((json) => {
                dispatch({
                  type: "GET_PRODUCT_FULFILLED",
                  payload: json,
                });

                return resolve(json);
              })
              .catch((error) => {
                dispatch({
                  type: "GET_PRODUCT_REJECTED",
                  payload: error,
                });
                return resolve();
              }),
          });
        }
      });
    }
  };
}

export function resetSelectedProduct() {
  return (dispatch) =>
    dispatch({
      type: "RESET_SELECTED_PRODUCT",
    });
}
export function updateProduct(product) {
  return (dispatch) =>
    dispatch({
      type: "UPDATE_PRODUCT",
      payload: product,
    });
}

export function updateSelectedEstablishmentProducts(selectedProducts) {
  return (dispatch) =>
    dispatch({
      type: "UPDATE_ESTABLISHMENT_SELECTED_PRODUCTS",
      payload: { selectedProducts },
    });
}

export function persistProduct(product) {
  let isNew = product.id === 0;
  return (dispatch, getState) => {
    if (!getState().productsReducer.updating) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "PERSISTING_PRODUCT",
          payload: (isNew ? axios.post(`${HOST}/api/products`, product) : axios.put(`${HOST}/api/products/${product.id}`, product))
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "PERSISTED_PRODUCT",
                payload: {
                  product: json,
                  isNew: isNew,
                },
              });

              // dispatch({
              //   type: "UPDATE_ESTABLISHMENT_PRODUCT",
              //   payload: {
              //     product: json,
              //     isNew: isNew
              //   }
              // });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "saved_successful",
                },
              });
              return resolve();
            })
            .catch((error) => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response,
                },
              });
              return reject();
            }),
        })
      );
    }
  };
}

export function assignProductModal(product) {
  return (dispatch) =>
    new Promise((resolve) => {
      let p = product || {
        id: 0,
        nameFrench: "",
        nameEnglish: "",
        remarks: [],
        isActive: true,
        deactivationDateTime: null,
        productType_Ids: [],
        category_Ids: [],
      };
      dispatch({
        type: "ASSIGN_PRODUCT_MODAL",
        payload: p,
      });
      return resolve(p);
    });
}

export function hideProductModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_PRODUCT_MODAL",
      });
      return resolve();
    });
}

export function hideMergeProductModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_MERGE_PRODUCT_MODAL",
      });
      return resolve();
    });
}

export function hideDeleteProductModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_DELETE_PRODUCT_MODAL",
      });
      return resolve();
    });
}

export function showDeleteProductModal(product) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SHOW_DELETE_PRODUCT_MODAL",
        payload: product,
      });
      return resolve();
    });
}

export function showMergeProductModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SHOW_MERGE_PRODUCT_MODAL",
      });
      return resolve();
    });
}

export function createPriceList(productPriceList) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "RESET_PRODUCT_PRICE_DETAIL_PRODUCT_IDS",
      });

      dispatch({
        type: "PERSISTING_PRICE_LIST",
        payload: axios
          .post(`${HOST}/api/product-contracts/create-price-list`, productPriceList)
          .then((response) => response.data)
          .then((json) => {
            dispatch({
              type: "PERSISTED_PRICE_LIST",
              payload: json,
            });

            const productIds = [...new Set(json.map((c) => c.product_Id))];

            dispatch({
              type: "UPDATE_PRODUCT_PRICE_DETAIL_PRODUCT_IDS",
              payload: {
                productIds,
              },
            });

            return resolve();
          }),
      });
    });
}

export function updateProductPriceDetailProductIds(productIds) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_PRODUCT_PRICE_DETAIL_PRODUCT_IDS",
        payload: {
          productIds,
        },
      });

      return resolve(productIds);
    });
}

export function regenerateProductContract(productPriceList) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "REGENERATING_PRODUCT_CONTRACT",
        payload: axios
          .post(`${HOST}/api/product-contracts/regenerate-product-contract`, productPriceList)
          .then((response) => response.data)
          .then((json) => {
            dispatch({
              type: "REGENERATED_PRODUCT_CONTRACT",
              payload: json,
            });

            return resolve();
          }),
      });
    });
}

export function updateRegenerateProductContractProduct(establishmentContract_Id) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_REGENERATE_PRODUCT_CONTRACT_PRODUCT",
        payload: establishmentContract_Id,
      });
      return resolve();
    });
}

export function updatePriceListProduct(productPriceList, establishmentContract_Id) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_CREATE_PRICE_LIST_PRODUCT",
        payload: { productPriceList, establishmentContract_Id },
      });
      return resolve();
    });
}

export function showRegenerateProductContractModal(product) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SHOW_REGENERATE_PRODUCT_CONTRACT_MODAL",
        payload: product,
      });
      return resolve();
    });
}

export function hideRegenerateProductContractModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_REGENERATE_PRODUCT_CONTRACT_MODAL",
      });
      return resolve();
    });
}

export function showCreatePriceListModal(products) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SHOW_CREATE_PRICE_LIST_MODAL",
        payload: products,
      });
      return resolve();
    });
}

export function hideCreatePriceListModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_CREATE_PRICE_LIST_MODAL",
      });
      return resolve();
    });
}

export function updateMergeMainProduct(productId) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_MERGE_MAIN_PRODUCT_MODAL",
        payload: { productId },
      });
      return resolve();
    });
}

export function mergePersistMergeProducts(productId, productIds) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.updating) {
      return new Promise((resolve) =>
        dispatch({
          type: "MERGE_PRODUCT_PENDING",
          payload: axios
            .post(`${HOST}/api/products/merge`, { productId, productIds })
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "MERGE_PRODUCT_FULFILLED",
                payload: json,
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "merge_successfully",
                },
              });

              resolve(json);
            })
            .catch((error) =>
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.message,
                },
              })
            ),
        })
      );
    }
  };
}

export function resetNewProductModel() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_PRODUCT_MODAL_MODEL",
        payload: {
          id: 0,
          nameFrench: "",
          nameEnglish: "",
          remarks: [],
          productType_Ids: [],
        },
      });
      return resolve();
    });
}

export function updateProductModal(product) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_PRODUCT_MODAL_MODEL",
        payload: product,
      });
      return resolve();
    });
}

export function updateContractTaxesOrder(taxes) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_PRODUCT_TAXES_CONTRACT_MODEL",
        payload: taxes,
      });
      return resolve();
    });
}

export function updateTaxesOrder(taxes) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_PRODUCT_TAXES_MODAL_MODEL",
        payload: taxes,
      });
      return resolve();
    });
}

export function resetProductEstablishment() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "RESET_PRODUCT_ESTABLISHMENT",
      });
      return resolve();
    });
}

export function getProductEventsDetails(productId) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.gettingProductEventsDetails) {
      return new Promise((resolve, reject) => {
        dispatch({
          type: "GET_PRODUCT_EVENTS_DETAILS_PENDING",
          payload: axios
            .get(`${HOST}/api/products/events-details/${productId}`)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "GET_PRODUCT_EVENTS_DETAILS_FULFILLED",
                payload: json,
              });

              return resolve(json);
            })
            .catch((error) => {
              dispatch({
                type: "GET_PRODUCT_EVENTS_DETAILS_REJECTED",
                payload: error,
              });
              return resolve();
            }),
        });
      });
    }
  };
}

export function getProductAssociatedContracts(productId) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.gettingProductAssociatedContracts) {
      return new Promise((resolve, reject) => {
        dispatch({
          type: "GET_PRODUCT_ASSOCIATED_CONTRACTS_PENDING",
          payload: axios
            .get(`${HOST}/api/products/associated-contracts/${productId}`)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "GET_PRODUCT_ASSOCIATED_CONTRACTS_FULFILLED",
                payload: json,
              });

              return resolve(json);
            })
            .catch((error) => {
              dispatch({
                type: "GET_PRODUCT_ASSOCIATED_CONTRACTS_REJECTED",
                payload: error,
              });
              return resolve();
            }),
        });
      });
    }
  };
}

export function hideDeleteAssociatedProductContractModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_DELETE_ASSOCIATED_PRODUCT_CONTRACT_MODAL",
      });

      return resolve();
    });
}

export function showDeleteAssociatedProductContractModal(product) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SHOW_DELETE_ASSOCIATED_PRODUCT_CONTRACT_MODAL",
        payload: product,
      });

      return resolve();
    });
}

export function deleteAssociatedProductContract(productContractId) {
  return (dispatch, getState) => {
    if (!getState().productsReducer.deletingProductAssociatedContracts) {
      return new Promise((resolve) => {
        dispatch({
          type: "DELETING_ASSOCIATED_PRODUCT_CONTRACT",
          payload: axios
            .delete(`${HOST}/api/product-contracts/${productContractId}`, productContractId)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "DELETED_ASSOCIATED_PRODUCT_CONTRACT",
                payload: productContractId,
              });

              return resolve();
            }),
        });
      });
    }
  };
}
