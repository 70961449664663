export const quickbooksAccountReducer = (
  state = {
    quickbooksAccount: [],
    gettingQuickbooksAccount: false,
  },
  action
) => {
  switch (action.type) {
    case "GET_QUICKBOOKS_ACCOUNTS_PENDING": {
      return { ...state, gettingQuickbooksAccount: true };
    }
    case "GET_QUICKBOOKS_ACCOUNTS_FULFILLED": {
      return { ...state, gettingQuickbooksAccount: false, quickbooksAccount: action.payload };
    }
    default: {
    }
  }

  return state;
};

export default quickbooksAccountReducer;
