export function resetMessageStore() {
  return (dispatch) => dispatch({ type: 'RESET_MESSAGE_STORE' });
}

export function showMessage(message) {
  return (dispatch) => dispatch({ type: 'SHOW_MESSAGE', payload: message });
};


export function showModalMessage(message) {
  return (dispatch) => dispatch({ type: 'SHOW_MODAL_MESSAGE', payload: message });
};