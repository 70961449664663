export const TaxTypeApplication = {
  APPLIED_PER_EVENT: "APPLIED_PER_EVENT",
  APPLIED_PER_DAY: "APPLIED_PER_DAY",
};

export const TaxTypeTarget = {
  APPLIED_BY_PRODUCT: "APPLIED_BY_PRODUCT",
  APPLIED_BY_PERSON: "APPLIED_BY_PERSON",
  APPLIED_BY_PERCENTAGE: "APPLIED_BY_PERCENTAGE",
};

export const QuickbooksState = {
  UNCONNECTED: "UNCONNECTED",
  CONNECTED: "CONNECTED",
};

export const TableDataComparatorGroupEnum = {
  OnlyContainsEquals: "ONLYCONTAINSEQUALS",
};
