export function product_type_name_enum() {
  return {
    CHARTER: "CHARTER",
    PUBLIC_BUS: "PUBLIC_BUS",
    FERRY: "FERRY",
    TRAIN: "TRAIN",
    AIRPLANE: "AIRPLANE",
    SERVICE: "SERVICE",
    INDIVIDUAL_CAMPING: "INDIVIDUAL_CAMPING",
    TRAILER: "TRAILER",
    DORM: "DORM",
    NON_STANDARD_ROOM: "NON_STANDARD_ROOM",
    SEDAN: "SEDAN",
    SUV_8_PASSENGERS: "SUV_8_PASSENGERS",
    STANDARD_CAR: "STANDARD_CAR",
    VEHICULE_RENTAL: "VEHICULE_RENTAL",
    COMPACT_SUV_5_PASSENGERS: "COMPACT_SUV_5_PASSENGERS",
    PRIVATE_TRANSFER: "PRIVATE_TRANSFER",
    COMPACT_CAR: "COMPACT_CAR",
    HALF_BOARD: "HALF_BOARD",
    HOTEL_ROOM: "HOTEL_ROOM",
    GROUP_CAMPING: "GROUP_CAMPING",
    MINIBUS_8_PASSENGERS: "MINIBUS_8_PASSENGERS",
    INDIVIDUAL_ACTIVITY: "INDIVIDUAL_ACTIVITY",
    LIMOUSINE: "LIMOUSINE",
    MINIBUS_15_PASSENGERS: "MINIBUS_15_PASSENGERS",
    BUS_56_SEATS: "BUS_56_SEATS",
    BUS_38_SEATS: "BUS_38_SEATS",
    MINIBUS_21_PASSENGERS: "MINIBUS_21_PASSENGERS",
    BUS_34_SEATS: "BUS_34_SEATS",
    BUS_32_SEATS: "BUS_32_SEATS",
    SHARED_SHUTTLE: "SHARED_SHUTTLE",
    MINIBUS_24_PASSENGERS: "MINIBUS_24_PASSENGERS",
    DINNER: "DINNER",
    MINIBUS_29_PASSENGERS: "MINIBUS_29_PASSENGERS",
    LUNCH: "LUNCH",
    BREAKFAST: "BREAKFAST",
    MINIVAN_7_PASSENGERS: "MINIVAN_7_PASSENGERS",
    GROUP_ACTIVITY: "GROUP_ACTIVITY",
    MINIBUS_11_PASSENGERS: "MINIBUS_11_PASSENGERS",
    BUS_28_SEATS: "BUS_28_SEATS",
    FULL_BOARD: "FULL_BOARD",
  };
}
export default product_type_name_enum;
