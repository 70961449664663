// Redux stuff:
import { applyMiddleware, createStore } from "redux";
import { Reducers } from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { IsProduction } from "config/config";

// middlewares
import thunk from "redux-thunk";
import { signalRMiddleware } from "middlewares/SignalR";

const middlewares = applyMiddleware(thunk, signalRMiddleware);
let compose = composeWithDevTools(middlewares);

if (IsProduction) {
  compose = middlewares;
}
export default createStore(Reducers, compose);
