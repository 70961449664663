import axios from "axios";
import { HOST } from "config/config";
import tableDataQueryStringBuilder from "utils/tableData";

export function getRemarkTypes(tableDataParam, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().remarkTypesReducer.updating || forceRefresh) {
      if (tableDataParam === undefined) {
        return new Promise(resolve =>
          dispatch({
            type: "GET_REMARK_TYPES_PENDING",
            payload: axios
              .get(`${HOST}/api/remark-types`)
              .then(response => response.data)
              .then(json => {
                dispatch({
                  type: "GET_REMARK_TYPES_FULFILLED",
                  payload: {
                    items: json
                  }
                });

                resolve(json);
              })
              .catch(error =>
                dispatch({
                  type: "SHOW_MESSAGE",
                  payload: {
                    type: "error",
                    titleKey: "error",
                    message: error.message
                  }
                })
              )
          })
        );
      }

      return dispatch({
        type: "GET_REMARK_TYPES_PENDING",
        payload: axios
          .get(`${HOST}/api/remark-types`, {
            paramsSerializer: params => tableDataQueryStringBuilder(params),
            params: tableDataParam
          })
          .then(response => response.data)
          .then(json => {
            if (tableDataParam) {
              dispatch({
                type: "UPDATE_REMARK_TYPES_TABLEDATAPARAM",
                payload: tableDataParam
              });
            }

            dispatch({
              type: "GET_REMARK_TYPES_FULFILLED",
              payload: json
            });
          })
          .catch(error =>
            dispatch({
              type: "GET_REMARK_TYPES_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}

export function getRemarkType(remarkTypeId, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().remarkTypesReducer.updating || forceRefresh) {
      if (remarkTypeId === 0) {
        return dispatch({
          type: "GET_REMARK_TYPE_FULFILLED",
          payload: {
            id: 0,
            internalName: "",
            nameFrench: "",
            nameEnglish: "",
            remarkTypeType_Id: 0
          }
        });
      }
      return dispatch({
        type: "GET_REMARK_TYPE_PENDING",
        payload: axios
          .get(`${HOST}/api/remark-types/${remarkTypeId}`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_REMARK_TYPE_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "GET_REMARK_TYPE_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}
