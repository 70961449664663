import axios from "axios";
import { HOST } from "config/config";
import tableDataQueryStringBuilder from "utils/tableData";

export function getDepartures(tableDataParam, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().departuresReducer.updating || forceRefresh) {
      if (tableDataParam === undefined) {
        return dispatch({
          type: "GET_DEPARTURES_PENDING",
          payload: axios
            .get(`${HOST}/api/departures`)
            .then((response) => response.data)
            .then((json) =>
              dispatch({
                type: "GET_DEPARTURES_FULFILLED",
                payload: {
                  items: json,
                },
              })
            )
            .catch((error) =>
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.message,
                },
              })
            ),
        });
      }

      return dispatch({
        type: "GET_DEPARTURES_PENDING",
        payload: axios
          .get(`${HOST}/api/departures`, {
            paramsSerializer: (params) => tableDataQueryStringBuilder(params),
            params: tableDataParam,
          })
          .then((response) => response.data)
          .then((json) => {
            if (tableDataParam) {
              dispatch({
                type: "UPDATE_DEPARTURES_TABLEDATAPARAM",
                payload: tableDataParam,
              });
            }

            dispatch({
              type: "GET_DEPARTURES_FULFILLED",
              payload: json,
            });
          })
          .catch((error) =>
            dispatch({
              type: "GET_DEPARTURES_REJECTED",
              payload: error,
            })
          ),
      });
    }
  };
}

export function getDeparturesForSelect(establishmentId, startDate = null, endDate = null) {
  return (dispatch, getState) => {
    if (!getState().departuresReducer.updating) {
      return dispatch({
        type: "GET_DEPARTURES_PENDING",
        payload: axios
          .get(
            establishmentId
              ? `${HOST}/api/filters/departures?establishment_Id=${establishmentId}
              ${startDate !== null ? "&startDate=" + startDate : ""}
              ${endDate !== null ? "&endDate=" + endDate : ""}`
              : `${HOST}/api/filters/departures`
          )
          .then((response) => response.data)
          .then((json) =>
            dispatch({
              type: "GET_DEPARTURES_SELECT_FULFILLED",
              payload: json,
            })
          )
          .catch((error) =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message,
              },
            })
          ),
      });
    }
  };
}

export function addDeparturesForSelect(departureIds) {
  return (dispatch, getState) => {
    if (!getState().departuresReducer.updatingDepartureForSelect) {
      const departureIdsQuery = departureIds.map((d, index) => `departure_Ids[${index}]=${d}`).join("&");
      return dispatch({
        type: "ADD_DEPARTURES_PENDING",
        payload: axios
          .get(`${HOST}/api/filters/missing-departures?${departureIdsQuery}`)
          .then((response) => response.data)
          .then((json) =>
            dispatch({
              type: "ADD_DEPARTURES_SELECT_FULFILLED",
              payload: json,
            })
          )
          .catch((error) =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message,
              },
            })
          ),
      });
    }
  };
}

export function getDeparture(departureId, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().departuresReducer.updating || forceRefresh) {
      if (departureId === 0) {
        return dispatch({
          type: "GET_DEPARTURE_FULFILLED",
          payload: {
            id: 0,
            internalName: "",
            nameFrench: "",
            nameEnglish: "",
            createdDate: new Date(),
          },
        });
      }
      return dispatch({
        type: "GET_DEPARTURE_PENDING",
        payload: axios
          .get(`${HOST}/api/departures/${departureId}`)
          .then((response) => response.data)
          .then((json) =>
            dispatch({
              type: "GET_DEPARTURE_FULFILLED",
              payload: json,
            })
          )
          .catch((error) =>
            dispatch({
              type: "GET_DEPARTURE_REJECTED",
              payload: error,
            })
          ),
      });
    }
  };
}

export function filterDepartureSelect(searchTerm) {
  return (dispatch, getState) => {
    if (!getState().departuresReducer.updating) {
      return dispatch({
        type: "FILTER_DEPARTURE_SELECT",
        searchTerm,
      });
    }
  };
}
