import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { getContractsForEstablishment } from "redux/actions/contracts";
import {
  regenerateProductContract,
  hideRegenerateProductContractModal,
  updateRegenerateProductContractProduct,
} from "redux/actions/products";
import SelectWithLabel from "components/FormControls/SelectWithLabel";
import InputWithLabel from "components/FormControls/InputWithLabel";

export default function RegenerateProductContractModal() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const productsReducer = useSelector((state) => state.productsReducer);
  const contractsReducer = useSelector((state) => state.contractsReducer);

  const { match, history } = useReactRouter();

  const [showActiveOnly, setShowActiveOnly] = useState(true);

  useEffect(() => {
    dispatch(getContractsForEstablishment(match.params.id));

    return () => {};
  }, [dispatch, match.params.id]);

  const handleCreatePriceList = () => {
    dispatch(regenerateProductContract(productsReducer.regenerateProductContractProduct)).then(() => {
      closeModal();
      history.push(`/establishments/${match.params.id}/products`);
    });
  };
  const handleContractChange = (establishmentContract_Id) => {
    dispatch(updateRegenerateProductContractProduct(establishmentContract_Id));
  };
  const closeModal = () => {
    dispatch(hideRegenerateProductContractModal());
  };

  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  let otherReference = isEnglish ? "nameFrench" : "nameEnglish";

  return (
    <Modal className="mw-100 w-50" isOpen={productsReducer.showRegenerateProductContractModal}>
      <ModalHeader>{t("create_price_list")}</ModalHeader>
      <ModalBody>
        <div className="form-group">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>{t("product_name")}</th>
                <th>
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <span style={{ marginRight: "1rem" }}>{t("contract")}</span>
                    <div style={{ marginBottom: "-1rem" }}>
                      <InputWithLabel
                        style={{ paddingBottom: "1rem!important" }}
                        labelText={t("show_active_only")}
                        inputType="checkbox"
                        fieldToUpdate="showActiveOnly"
                        objectToUpdate={{ showActiveOnly }}
                        persistOnBlur
                        updateAction={(value) => {
                          setShowActiveOnly(value);
                        }}
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                key={
                  productsReducer.regenerateProductContractProduct[referenceName] &&
                  productsReducer.regenerateProductContractProduct[referenceName].length
                    ? productsReducer.regenerateProductContractProduct[referenceName]
                    : productsReducer.regenerateProductContractProduct[otherReference]
                }
              >
                <td>
                  {productsReducer.regenerateProductContractProduct[referenceName] &&
                  productsReducer.regenerateProductContractProduct[referenceName].length
                    ? productsReducer.regenerateProductContractProduct[referenceName]
                    : productsReducer.regenerateProductContractProduct[otherReference]}
                </td>
                <td className="w-75">
                  {productsReducer.regenerateProductContractProduct &&
                  productsReducer.regenerateProductContractProduct.product &&
                  contractsReducer.establishmentContracts.length ? (
                    <SelectWithLabel
                      filterable
                      selectPlaceholder={t("contract_Id_placeholder")}
                      data={contractsReducer.establishmentContracts.filter(
                        (ec) =>
                          (!showActiveOnly || showActiveOnly === ec.isActive) &&
                          productsReducer.regenerateProductContractProduct.product.category_Ids.some((ci) => ci === ec.category_Id)
                      )}
                      textField={
                        productsReducer.regenerateProductContractProduct[referenceName] &&
                        productsReducer.regenerateProductContractProduct[referenceName].length
                          ? referenceName
                          : otherReference
                      }
                      valueField="id"
                      objectToUpdate={productsReducer.regenerateProductContractProduct}
                      updateAction={(establishmentContract_Id) => handleContractChange(establishmentContract_Id)}
                      fieldToUpdate="establishmentContract_Id"
                    />
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          disabled={productsReducer.priceListProducts.some((plp) => !plp.establishmentContract_Id)}
          onClick={handleCreatePriceList}
        >
          {t("regenerate")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
