import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Currency from "react-currency-formatter";

import { getQuickbooksAccount } from "redux/quickbooks/quickbooks-account/quickbooks-account-action";

function QuickbooksAccounts() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const quickbooksAccountReducer = useSelector((state) => state.quickbooksAccountReducer);

  const { quickbooksAccount } = quickbooksAccountReducer;

  useEffect(() => {
    dispatch(getQuickbooksAccount());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">{t("overview")}</h6>
          <h2 className="dashhead-title">{t("quickbooks_account")}</h2>
        </div>
      </div>

      <div className="card mt-2">
        <div className="card-body">
          <div className="card-text">
            <table className={`table`}>
              <thead>
                <tr>
                  <td>Id</td>
                  <td>Name</td>
                  <td>Current Balance</td>
                </tr>
              </thead>
              <tbody>
                {quickbooksAccount?.response?.entities?.map((qba) => (
                  <tr>
                    <td>{qba.id}</td>
                    <td>{qba.name}</td>
                    <td>
                      <Currency
                        quantity={qba.currentBalance} // Required
                        currency="CAD" // Optional (USD by default)
                        locale="fr_CA" // Optional
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default QuickbooksAccounts;
