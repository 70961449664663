import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { isEmpty } from "utils/utils";
import { Row, Col } from "reactstrap";

import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";
import { updateProductContractFilters, clearProductContractFilters } from "redux/actions/contracts";

export default function ProductPricingFilters() {
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearProductContractFilters());
    };
  }, [dispatch]);

  const updateFilter = (value, filter, field, isBlurring) => {
    if ((isEmpty(filter[field]) && isEmpty(value)) || filter[field] === value) return;

    filter[field] = value;
    dispatch(updateProductContractFilters(filter));
  };

  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  const { pricingProductContractFilters, pricingProductContracts } = contractsReducer;

  let contracts = pricingProductContracts;

  if (!contracts || contracts.length === 0) return null;

  let contractPeriods = pricingProductContracts.map((c) => c.contractPeriods).flat();
  let ageCategories = pricingProductContracts.map((c) => c.ageCategories).flat();
  let groups = pricingProductContracts.map((c) => c.groups).flat();
  let contractDepartureGroups = pricingProductContracts.map((c) => c.contractDepartureGroups).flat();
  let lengthCategories = pricingProductContracts.map((c) => c.lengthCategories).flat();
  let contractDayGroups = pricingProductContracts.map((c) => c.contractDayGroups).flat();

  let filter = pricingProductContractFilters;

  return (
    <div className="card mt-2 zIndex-1030">
      <div className="card-body">
        <h5 className="card-title">{t("filters")}</h5>

        <div className="card-text">
          <hr />
          <Row>
            {contracts.length && contracts.length > 1 ? (
              <Col md="3">
                <MultiSelectWithLabel
                  labelText={t("contracts")}
                  id="contracts-filter"
                  objectToUpdate={filter}
                  updateAction={updateFilter}
                  fieldToUpdate="contract_Ids"
                  textField={referenceName}
                  valueField="id"
                  value={filter.contract_Ids}
                  data={contracts}
                />
              </Col>
            ) : null}

            {contractPeriods.length ? (
              <Col md="3">
                <MultiSelectWithLabel
                  labelText={t("contract_periods")}
                  id="contract-periods-filter"
                  objectToUpdate={filter}
                  updateAction={updateFilter}
                  fieldToUpdate="contractPeriod_Ids"
                  textField={referenceName}
                  valueField="id"
                  value={filter.contractPeriod_Ids}
                  data={contractPeriods}
                />
              </Col>
            ) : null}

            {ageCategories.length ? (
              <Col md="3">
                <MultiSelectWithLabel
                  labelText={t("age_categories")}
                  id="age-categories-filter"
                  objectToUpdate={filter}
                  updateAction={updateFilter}
                  fieldToUpdate="ageCategory_Ids"
                  textField={referenceName}
                  valueField="id"
                  value={filter.ageCategory_Ids}
                  data={ageCategories}
                />
              </Col>
            ) : null}

            {groups.length ? (
              <Col md="3">
                <MultiSelectWithLabel
                  labelText={t("groups")}
                  id="groups-filter"
                  objectToUpdate={filter}
                  updateAction={updateFilter}
                  fieldToUpdate="group_Ids"
                  textField={referenceName}
                  valueField="id"
                  value={filter.group_Ids}
                  data={groups}
                />
              </Col>
            ) : null}

            {contractDepartureGroups.length ? (
              <Col md="3">
                <MultiSelectWithLabel
                  labelText={t("contract_departure_group")}
                  id="departures-filter"
                  objectToUpdate={filter}
                  updateAction={updateFilter}
                  fieldToUpdate="contractDepartureGroup_Ids"
                  textField={referenceName}
                  valueField="id"
                  value={filter.contractDepartureGroup_Ids}
                  data={contractDepartureGroups}
                />
              </Col>
            ) : null}

            {lengthCategories.length ? (
              <Col md="3">
                <MultiSelectWithLabel
                  labelText={t("length_categories")}
                  id="length-categories-filter"
                  objectToUpdate={filter}
                  updateAction={updateFilter}
                  fieldToUpdate="lengthCategory_Ids"
                  textField={referenceName}
                  valueField="id"
                  value={filter.lengthCategory_Ids}
                  data={lengthCategories}
                />
              </Col>
            ) : null}

            {contractDayGroups.length ? (
              <Col md="3">
                <MultiSelectWithLabel
                  labelText={t("contract_day_group")}
                  id="contract-day-group-filter"
                  objectToUpdate={filter}
                  updateAction={updateFilter}
                  fieldToUpdate="contractDayGroup_Ids"
                  textField={referenceName}
                  valueField="id"
                  value={filter.contractDayGroup_Ids}
                  data={contractDayGroups}
                />
              </Col>
            ) : null}
          </Row>
        </div>
      </div>
    </div>
  );
}
