export const quickbooksCompanyReducer = (
  state = {
    quickbooksCompanies: [],
    gettingQuickbooksCompany: false,
  },
  action
) => {
  switch (action.type) {
    case "GET_QUICKBOOKS_COMPANIES_PENDING": {
      return { ...state, gettingQuickbooksCompany: true };
    }
    case "GET_QUICKBOOKS_COMPANIES_FULFILLED": {
      return { ...state, gettingQuickbooksCompany: false, quickbooksCompanies: action.payload };
    }
    default: {
    }
  }

  return state;
};

export default quickbooksCompanyReducer;
