import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { isEmpty } from "utils/utils";

import { Col } from "reactstrap";
import { getTaxTypes } from "redux/actions/tax-types";
import { getSalesTaxes } from "redux/actions/sales-taxes";
import { getTaxTypeApplicationsForSelect } from "redux/actions/tax-type-applications";
import { getTaxTypeTargetsForSelect } from "redux/actions/tax-type-targets";

import PaymentInformation from "./Components/PaymentInformation";
import ContractPriceDetailHeader from "./Components/ContractPriceDetailHeader";
import TaxSection from "./Components/TaxSection";
import { persistProductContractPriceDetail } from "redux/actions/product-price-details";
import GuideDriverPriceDetail from "./Components/GuideDriverPriceDetail";
import product_type_name_enum from "Enums/productType";
import BaseTransportPriceDetail from "./Components/BaseTransportPriceDetail";
import FerryTransportPriceDetail from "./Components/FerryTransportPriceDetail";

export default function TransportPriceDetail({ contract, cpd }) {
  const dispatch = useDispatch();
  const salesTaxesReducer = useSelector((state) => state.salesTaxesReducer);
  const taxTypeApplicationsReducer = useSelector((state) => state.taxTypeApplicationsReducer);
  const taxTypeTargetsReducer = useSelector((state) => state.taxTypeTargetsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  useEffect(() => {
    dispatch(getTaxTypes());
    dispatch(getSalesTaxes());
    dispatch(getTaxTypeApplicationsForSelect());
    dispatch(getTaxTypeTargetsForSelect());
  }, [dispatch]);

  const handleUpdateContractPriceDetail = (e, contractPriceDetail, field, isNumber) => {
    let value = e.target ? e.target.value : e;

    if (isNumber) {
      value = value.replace(/,(?=[\d,]*\.\d{2}\b)/g, "");
      value = Number(value);
    }

    if ((isEmpty(contractPriceDetail[field]) && isEmpty(value)) || contractPriceDetail[field] === value) return;

    const updateModel = { contractPriceDetail, contract, fieldValues: [{ value, fieldName: field }] };

    dispatch(persistProductContractPriceDetail(updateModel));
  };

  if (!cpd.id) return null;

  return (
    <>
      <Col md="12">
        <div className="card mt-3">
          <div className="card-body">
            <div className="card-text">
              <ContractPriceDetailHeader
                contractPriceDetail={cpd}
                contractPeriods={contract.contractPeriods}
                ageCategories={contract.ageCategories}
                groups={contract.groups}
                hasGroups={contract.hasGroups}
                contractDepartureGroups={contract.contractDepartureGroups}
                contractDayGroups={contract.contractDayGroups}
                contract={contract}
              />

              {cpd.productTypes &&
                cpd.productTypes.map((pt) => {
                  switch (pt) {
                    case product_type_name_enum().FERRY:
                      return (
                        <FerryTransportPriceDetail
                          key={product_type_name_enum().FERRY}
                          cpd={cpd}
                          handleUpdateContractPriceDetail={handleUpdateContractPriceDetail}
                          isManager={accountReducer.isManager}
                        />
                      );

                    default:
                      return (
                        <BaseTransportPriceDetail
                          key={"DEFAULT"}
                          cpd={cpd}
                          handleUpdateContractPriceDetail={handleUpdateContractPriceDetail}
                          isManager={accountReducer.isManager}
                        />
                      );
                  }
                })}

              {(contract.hasGuidePrice || contract.hasDriverPrice) && (
                <>
                  <hr />
                  <GuideDriverPriceDetail
                    key={`${cpd.id}-GuideDriver-Prices`}
                    contract={contract}
                    cpd={cpd}
                    handleUpdateContractPriceDetail={handleUpdateContractPriceDetail}
                    isManager={accountReducer.isManager}
                  />
                  <hr />
                </>
              )}

              <PaymentInformation
                contractPriceDetail={cpd}
                taxTypeTargets={taxTypeTargetsReducer.taxTypeTargets}
                taxTypeApplications={taxTypeApplicationsReducer.taxTypeApplications}
                updateAction={handleUpdateContractPriceDetail}
                salesTaxes={salesTaxesReducer.salesTaxes}
                isManager={accountReducer.isManager}
              />

              <TaxSection cpd={cpd} contract={contract} />
            </div>
          </div>
        </div>
      </Col>
    </>
  );
}
