import React from "react";
import uuidv4 from "uuid/v4";

import { useTranslation } from "react-i18next";

import CurrencyInput from "react-currency-input";

import { Row, Col } from "reactstrap";
import InputWithLabel from "components/FormControls/InputWithLabel";

export default function GroupCampingAccomodationPriceDetail({ cpd, handleUpdateContractPriceDetail, isManager }) {
  const { t } = useTranslation();

  let guid1 = uuidv4();

  return (
    <Row>
      <Col md="12">
        <h5>{t("group_camping")}</h5>
        <div className="d-flex flex-nowrap">
          <div className="form-group pt-2 pb-2 pr-2">
            <label className="form-label" htmlFor={`value-${guid1}`}>
              {t("price")}
            </label>
            <div className="input-group mt-1">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <CurrencyInput
                value={cpd.campingGroup_Price}
                id={`value-${guid1}`}
                onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "campingGroup_Price", true)}
                className="form-control text-right"
                disabled={!isManager}
              />
            </div>
          </div>
          <div className="form-group p-2">
            <InputWithLabel
              className={"mt-1"}
              labelText={t("capacity")}
              inputType="number"
              fieldToUpdate="campingIndividual_CampingCapacity"
              disabled
              objectToUpdate={cpd}
              persistOnBlur
              updateAction={handleUpdateContractPriceDetail}
            />
          </div>
          <div className="form-group p-2">
            <InputWithLabel
              className={"mt-1"}
              labelText={t("adult_number")}
              inputType="number"
              fieldToUpdate="campingIndividual_NumberOfAdultsPerSite"
              disabled
              objectToUpdate={cpd}
              persistOnBlur
              updateAction={handleUpdateContractPriceDetail}
            />
          </div>

          <div className="form-group p-2">
            <InputWithLabel
              className={"mt-1"}
              labelText={t("child_number")}
              inputType="number"
              fieldToUpdate="campingIndividual_NumberOfChildrenPerSite"
              disabled
              objectToUpdate={cpd}
              persistOnBlur
              updateAction={handleUpdateContractPriceDetail}
            />
          </div>
          <div className="form-group p-2">
            <InputWithLabel
              className={"mt-1"}
              labelText={t("minimum_person_number")}
              inputType="number"
              fieldToUpdate="campingGroup_MinimumNumberOfPerson"
              objectToUpdate={cpd}
              persistOnBlur
              updateAction={handleUpdateContractPriceDetail}
              disabled={!isManager}
            />
          </div>
          <div className="form-group p-2">
            <InputWithLabel
              className={"mt-1"}
              labelText={t("maximum_person_number")}
              inputType="number"
              fieldToUpdate="campingGroup_MaximumNumberOfPerson"
              objectToUpdate={cpd}
              persistOnBlur
              updateAction={handleUpdateContractPriceDetail}
              disabled={!isManager}
            />
          </div>

          <div className="form-group p-2">
            <label className="form-label" htmlFor={`additionnal-price-person-${guid1}`}>
              {t("additionnal_price_person")}
            </label>
            <div className="input-group mt-1">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <CurrencyInput
                id={`additionnal-price-person-${guid1}`}
                value={cpd.campingGroup_PricePerAdditionalPerson}
                onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "campingGroup_PricePerAdditionalPerson", true)}
                className="form-control text-right"
                disabled={!isManager}
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
