import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";

import { Row, Col, Button } from "reactstrap";

import Spinner from "components/Spinner/Spinner";
import ValidationSummary from "components/ValidationSummary/ValidationSummary";

import InputWithLabel from "components/FormControls/InputWithLabel";
import SelectWithLabel from "components/FormControls/SelectWithLabel";
import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";

import { isEmpty } from "utils/utils";

import { getSalesTax, updateEditSalesTax, persistSalesTax } from "redux/actions/sales-taxes";
import { getCountriesForSelect } from "redux/actions/countries";
import { getProvincesForSelect } from "redux/actions/provinces";

function SalesTax() {
  const { t } = useTranslation();

  const salesTaxesReducer = useSelector((state) => state.salesTaxesReducer);
  const countriesReducer = useSelector((state) => state.countriesReducer);
  const provincesReducer = useSelector((state) => state.provincesReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const dispatch = useDispatch();
  const { history, match } = useReactRouter();

  useEffect(() => {
    dispatch(getSalesTax(parseInt(match.params.id, 10)));
    dispatch(getCountriesForSelect());
    dispatch(getProvincesForSelect());
  }, [match, dispatch, t]);

  const redirectsToSalestaxes = () => {
    history.push("/sales-taxes");
  };

  const update = (value, salesTax, field) => {
    if ((isEmpty(salesTax[field]) && isEmpty(value)) || salesTax[field] === value) return;

    salesTax[field] = value;
    dispatch(updateEditSalesTax(salesTax));
  };

  const handlePersistSalesTax = (salesTax) => {
    dispatch(persistSalesTax(salesTax)).then(() => redirectsToSalestaxes());
  };

  if (!salesTaxesReducer.editSalesTax || salesTaxesReducer.isUpdating || salesTaxesReducer.updating) return <Spinner />;

  let errorMessage = "";

  if (
    salesTaxesReducer.error &&
    salesTaxesReducer.error.response &&
    salesTaxesReducer.error.response.data &&
    salesTaxesReducer.error.response.data.message
  ) {
    errorMessage = t(salesTaxesReducer.error.response.data.message);
  } else if (salesTaxesReducer.error && salesTaxesReducer.error.response === undefined && salesTaxesReducer.error.message !== undefined) {
    errorMessage = salesTaxesReducer.error.message;
  }

  let salesTax = salesTaxesReducer.editSalesTax;

  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "labelEnglish" : "labelFrench";

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">{salesTax.id === 0 ? t("create") : t("edit")}</h6>
          <h2 className="dashhead-title">{t("sales_taxes")}</h2>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="card mt-3">
        <div className="card-body">
          <div className="card-text">
            <React.Fragment>
              <Row>
                <Col md="12">{errorMessage !== "" && errorMessage ? <ValidationSummary messages={[errorMessage]} /> : null}</Col>
              </Row>

              <Row>
                <Col md="6">
                  <SelectWithLabel
                    filterable
                    clearable
                    labelText={t("country")}
                    selectPlaceholder={t("country_placeholder")}
                    data={countriesReducer.countriesForSelect}
                    textField={referenceName}
                    valueField="id"
                    value={salesTax.country_Id}
                    objectToUpdate={salesTax}
                    updateAction={update}
                    fieldToUpdate="country_Id"
                  />
                </Col>
                <Col md="6">
                  <MultiSelectWithLabel
                    labelText={t("provinces")}
                    id="sales-taxes-provinces"
                    selectPlaceholder={t("provinces_placeholder")}
                    objectToUpdate={salesTax}
                    updateAction={update}
                    fieldToUpdate="province_Ids"
                    textField={referenceName}
                    valueField="id"
                    value={salesTax.province_Ids}
                    data={provincesReducer.provincesForSelect.filter((p) => p.country_Id === salesTax.country_Id)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <InputWithLabel
                    labelText={t("unique_name")}
                    inputType="text"
                    fieldToUpdate="internalName"
                    persistOnBlur
                    disabled={salesTax.id !== 0}
                    objectToUpdate={salesTax}
                    updateAction={update}
                  />
                  <InputWithLabel
                    labelText={t("nameFrench")}
                    inputType="text"
                    fieldToUpdate="nameFrench"
                    objectToUpdate={salesTax}
                    persistOnBlur
                    updateAction={update}
                  />
                  <InputWithLabel
                    labelText={t("nameEnglish")}
                    inputType="text"
                    fieldToUpdate="nameEnglish"
                    objectToUpdate={salesTax}
                    persistOnBlur
                    updateAction={update}
                  />
                  <InputWithLabel
                    labelText={t("value")}
                    isDecimal
                    inputType="number"
                    fieldToUpdate="value"
                    objectToUpdate={salesTax}
                    persistOnBlur
                    updateAction={update}
                  />
                  <InputWithLabel
                    labelText={t("windigocan_id")}
                    isDecimal
                    inputType="number"
                    fieldToUpdate="windigoCan_Id"
                    objectToUpdate={salesTax}
                    persistOnBlur
                    updateAction={update}
                  />
                  <InputWithLabel
                    labelText={t("windigousa_id")}
                    isDecimal
                    inputType="number"
                    fieldToUpdate="windigoUsa_Id"
                    objectToUpdate={salesTax}
                    persistOnBlur
                    updateAction={update}
                  />
                  <InputWithLabel
                    labelText={t("receptour_id")}
                    isDecimal
                    inputType="number"
                    fieldToUpdate="receptour_Id"
                    objectToUpdate={salesTax}
                    persistOnBlur
                    updateAction={update}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12" className="text-right">
                  <Button color="success" onClick={() => handlePersistSalesTax(salesTax)} disabled={!accountReducer.isManager}>
                    {t("save")}
                  </Button>{" "}
                  <Button color="info" onClick={redirectsToSalestaxes}>
                    {t("cancel")}
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SalesTax;
