import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useReactRouter from "use-react-router";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { hideSummaryModal } from "redux/actions/contracts";
import { getCompaniesForSelect } from "redux/actions/companies";
import SummaryBody from "./Steps/Components/SummaryBody";
import Spinner from "components/Spinner/Spinner";
import { getProductsForEstablishment } from "redux/actions/products";
import { getDeparturesForSelect } from "redux/actions/departures";

function ContractSummaryModal() {
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const companiesReducer = useSelector((state) => state.companiesReducer);

  const { match } = useReactRouter();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { summaryContractModel: contract, showSummaryModal } = contractsReducer;
  const { selectUpdating } = companiesReducer;

  useEffect(() => {
    dispatch(getCompaniesForSelect());
    dispatch(getProductsForEstablishment(Number(match.params.id)));
    dispatch(getDeparturesForSelect(Number(match.params.id)));
  }, [dispatch, match.params.id]);

  const closeModal = () => {
    dispatch(hideSummaryModal());
  };

  if (!contract) return null;

  return (
    <Modal className="mw-100 w-50" isOpen={showSummaryModal}>
      <ModalHeader>{t("summary_contract")}</ModalHeader>
      <ModalBody>{selectUpdating ? <Spinner /> : <SummaryBody contract={contract} />}</ModalBody>
      <ModalFooter>
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ContractSummaryModal;
