import React from "react";
import { useTranslation } from "react-i18next";

import Currency from "react-currency-formatter";
import { useSelector } from "react-redux";

export default function TransportTableViewBody({ contract }) {
  const { t } = useTranslation();

  const salesTaxesReducer = useSelector((state) => state.salesTaxesReducer);
  const taxTypeApplicationsReducer = useSelector((state) => state.taxTypeApplicationsReducer);
  const taxTypeTargetsReducer = useSelector((state) => state.taxTypeTargetsReducer);
  const taxTypesReducer = useSelector((state) => state.taxTypesReducer);

  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  return (
    <tbody>
      {contract.contractPriceDetails.map((cpd) => (
        <tr key={cpd.id}>
          <td>{`${contract.productName}-${contract[referenceName]}`}</td>
          {contract.hasContractPeriods ? (
            <td>{cpd.contractPeriod_Id && contract.contractPeriods.find((cp) => cp.id === cpd.contractPeriod_Id)[referenceName]}</td>
          ) : null}
          {contract.hasAgeCategories ? (
            <td>{cpd.ageCategory_Id && contract.ageCategories.find((ac) => ac.id === cpd.ageCategory_Id)[referenceName]}</td>
          ) : null}
          {contract.hasGroups ? <td>{cpd.group_Id && contract.groups.find((g) => g.id === cpd.group_Id)[referenceName]}</td> : null}
          {contract.hasPriceByDays ? (
            <td>{cpd.contractDayGroup_Id && contract.contractDayGroups.find((g) => g.id === cpd.contractDayGroup_Id)[referenceName]}</td>
          ) : null}
          {contract.projects && contract.projects.length ? (
            <td>{cpd.project_Id && contract.projects.find((cp) => cp.id === cpd.project_Id)["label"]}</td>
          ) : null}
          {contract.departures && contract.departures.length ? (
            <td>{cpd.departure_Id && contract.departures.find((cp) => cp.id === cpd.departure_Id)["name"]}</td>
          ) : null}
          <td>{taxTypeTargetsReducer.taxTypeTargets.find((ttt) => cpd.taxTypeTarget_Id === ttt.id)[referenceName]}</td>
          <td>{taxTypeApplicationsReducer.taxTypeApplications.find((tta) => cpd.taxTypeApplication_Id === tta.id)[referenceName]}</td>
          <td>
            {salesTaxesReducer.salesTaxes
              .filter((st) => cpd.salesTax_Ids.includes(st.id))
              .map((st) => st[referenceName])
              .join(", ")}
          </td>
          <td>{taxTypesReducer.taxTypes
              .filter((st) => cpd.taxTypes.some(ctt => ctt.taxType_Id ===  st.id))
              .map((st) => st[referenceName])
              .join(", ")}</td>
          <td>
            <Currency quantity={cpd.value || 0} currency="CAD" />
          </td>
        </tr>
      ))}
      {/* <tr>
        <th>{t("payment_target")}</th>
        <th>{t("payment_application")}</th>
        <th>{t("sales_taxes")}</th>
        <th>{t("taxes")}</th>
        <th>{t("single")}</th>
        <th>{t("double")}</th>
        <th>{t("twin")}</th>
        <th>{t("triple")}</th>
        <th>{t("quad")}</th>
      </tr> */}
    </tbody>
  );
}
