/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

class Pager extends Component {
  constructor(props) {
    super(props);
    let numberOfPagesToShow = 2;
    this.state = {
      currentPage: 1,
      lastPage: Math.ceil(props.numberOfItems / props.pageSize) || 1,
      firstPage: 1,
      numberOfPagesToShow: numberOfPagesToShow,
      pageNumbers: [...Array(numberOfPagesToShow).keys()].map(i => i + 1),
      showFL: true,
      showPN: true,
      showHasMore: true
    };

    this.getPagesToShow = this.getPagesToShow.bind(this);
    this.updateFirstAndLastPageToShow = this.updateFirstAndLastPageToShow.bind(
      this
    );
    this.goToPage = this.goToPage.bind(this);
    this.goToNext = this.goToNext.bind(this);
    this.goToPrev = this.goToPrev.bind(this);
    this.goToFirst = this.goToFirst.bind(this);
    this.goToLast = this.goToLast.bind(this);
    this.showMore = this.showMore.bind(this);
  }

  componentDidUpdate(prevProps) {
    let mustSetState = false;
    let newState = this.state;

    if (this.props.numberOfItems !== prevProps.numberOfItems) {
      mustSetState = true;
      newState["numberOfItems"] = this.props.numberOfItems;
    }

    if (this.props.pageSize !== prevProps.pageSize) {
      mustSetState = true;
      newState["pageSize"] = this.props.pageSize;
    }

    if (mustSetState) {
      let lastPage =
        Math.ceil(this.props.numberOfItems / this.props.pageSize) || 1;
      let pagesToShow = this.getPagesToShow(1, lastPage);
      newState.lastPage = lastPage;
      newState.pageNumbers = [
        ...Array(
          this.state.numberOfPagesToShow < lastPage
            ? this.state.numberOfPagesToShow
            : lastPage
        ).keys()
      ].map(i => i + pagesToShow.firstPageToShow);
      this.setState({ state: newState });
    }
  }

  getPagesToShow(firstPageToShow, lastPageToShow) {
    const lastPage = this.state.lastPage;

    // Shifts the pages to show if the firstPage is under the minimum
    while (firstPageToShow < 1) {
      firstPageToShow++;
      if (lastPageToShow < lastPage) {
        lastPageToShow++;
      }
    }

    // Shifts the pages to show if the lastPage is over the maximum
    while (lastPageToShow > lastPage) {
      lastPageToShow--;
      if (firstPageToShow > 1) {
        firstPageToShow--;
      }
    }

    return { firstPageToShow, lastPageToShow };
  }

  updateFirstAndLastPageToShow() {
    let currentPage = this.state.currentPage;
    let nbPagesWithoutCurrent = this.state.numberOfPagesToShow - 1;

    let nbPagesBefore = parseInt(nbPagesWithoutCurrent / 2);
    let nbPagesAfter = nbPagesWithoutCurrent - nbPagesBefore;

    let firstPageToShow = currentPage - nbPagesBefore;
    let lastPageToShow = currentPage + nbPagesAfter;

    let firstAndLastPage = this.getPagesToShow(firstPageToShow, lastPageToShow);

    return this.setState({
      pageNumbers: [
        ...Array(
          this.state.numberOfPagesToShow < lastPageToShow
            ? this.state.numberOfPagesToShow
            : lastPageToShow > firstAndLastPage.lastPageToShow
            ? firstAndLastPage.lastPageToShow
            : lastPageToShow
        ).keys()
      ].map(i => i + firstAndLastPage.firstPageToShow)
    });
  }

  goToPage(page) {
    this.props.updatePage(page);

    this.setState(
      {
        currentPage: page
      },
      () => this.updateFirstAndLastPageToShow()
    );
  }

  goToNext() {
    let page = this.state.currentPage + 1;
    this.props.updatePage(page);

    this.setState(
      {
        currentPage: page
      },
      () => this.updateFirstAndLastPageToShow()
    );
  }

  goToPrev() {
    let page = this.state.currentPage - 1;
    this.props.updatePage(page);

    this.setState(
      {
        currentPage: page
      },
      () => this.updateFirstAndLastPageToShow()
    );
  }

  goToFirst() {
    let page = 1;
    this.props.updatePage(page);

    this.setState(
      {
        currentPage: page
      },
      () => this.updateFirstAndLastPageToShow()
    );
  }

  goToLast() {
    let page = this.state.lastPage;
    this.props.updatePage(page);

    this.setState(
      {
        currentPage: page
      },
      () => this.updateFirstAndLastPageToShow()
    );
  }

  showMore(before) {
    let nbOfPagesShown = this.state.numberOfPagesToShow;
    let currentPage = this.state.pageNumbers[0];

    let newPage = currentPage + nbOfPagesShown;
    if (before) {
      newPage = currentPage - nbOfPagesShown;
    }

    this.setState({
      pageNumbers: [...Array(this.state.numberOfPagesToShow).keys()].map(
        i => i + newPage
      )
    });
  }

  render() {
    return (
      <nav aria-label="Page navigation">
        <ul className="pagination">
          {this.state.showFL ? (
            <li className="page-item">
              <a
                role="button"
                className={`page-link p-2 ${this.state.currentPage === 1 ? "disabled" : ""}`}
                onClick={() => this.state.currentPage === 1 ? null : this.goToFirst()}
              >
                <span className="fa fa-angle-double-left" />
              </a>
            </li>
          ) : null}

          {this.state.showPN ? (
            <li className="page-item">
              <a
                role="button"
                className={`page-link p-2 ${this.state.currentPage === 1 ? "disabled" : ""}`}
                disabled={this.state.currentPage === 1}
                onClick={() => this.state.currentPage === 1 ? null : this.goToPrev()}
              >
                <span className="fa fa-angle-left" />
              </a>
            </li>
          ) : null}

          {this.state.pageNumbers[0] > 1 ? (
            <React.Fragment>
              <li className="page-item">
                <a
                  role="button"
                  className="page-link p-2"
                  disabled={`page-link p-2`}
                  onClick={() => this.state.currentPage === 1 ? null : this.goToPage(1)}
                >
                  1
                </a>
              </li>
              <li className="page-item">
                <a
                  role="button"
                  className={`page-link p-2`}
                  disabled={this.state.currentPage === 1}
                  onClick={ this.showMore}
                >
                  <span className="fa fa-ellipsis-h" />
                </a>
              </li>
            </React.Fragment>
          ) : null}
          {this.state.pageNumbers.map(page => (
            <li
              key={page}
              className={`page-item ${
                page === this.state.currentPage ? "active" : ""
              }`}
            >
              <a
                role="button"
                className={`page-link p-2 ${page === this.state.currentPage ? "disabled" : ""}`}
                disabled={page === this.state.currentPage}
                onClick={() => this.goToPage(page)}
              >
                {page}
              </a>
            </li>
          ))}

          {this.state.pageNumbers[this.state.pageNumbers.length - 1] <
          this.state.lastPage ? (
            <React.Fragment>
              <li className={`page-item`}>
                <a
                  role="button"
                  className="page-link p-2"
                  onClick={() => this.showMore(false)}
                >
                  <span className="fa fa-ellipsis-h" />
                </a>
              </li>
              <li className={`page-item`}>
                <a
                  role="button"
                  className="page-link p-2"
                  onClick={this.goToLast}
                >
                  {this.state.lastPage}
                </a>
              </li>
            </React.Fragment>
          ) : null}

          {this.state.showPN ? (
            <li className={`page-item`}>
              <a
                role="button"
                className={`page-link p-2 ${this.state.currentPage === this.state.lastPage ? "disabled" : ""}`}
                disabled={this.state.currentPage === this.state.lastPage}
                onClick={() =>  this.state.currentPage === this.state.lastPage ? null : this.goToNext()}
              >
                <span className="fa fa-angle-right" />
              </a>
            </li>
          ) : null}

          {this.state.showFL ? (
            <li className={`page-item`}>
              <a
                role="button"
                className={`page-link p-2 ${this.state.currentPage === this.state.lastPage ? "disabled" : ""}`}
                disabled={this.state.currentPage === this.state.lastPage}
                onClick={() => this.state.currentPage === this.state.lastPage ? null : this.goToLast()}
              >
                <span className="fa fa-angle-double-right" />
              </a>
            </li>
          ) : null}
        </ul>
      </nav>
    );
  }
}

export default Pager;
