import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "reactstrap";

import { isEmpty } from "utils/utils";

import { updateProductModal } from "redux/actions/products";

import InputWithLabel from "components/FormControls/InputWithLabel";
import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";

function RemarkSection({ remarkTypes = [] }) {
  const productsReducer = useSelector((state) => state.productsReducer);

  const updateModelRemark = (value, model, field) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;

    const product = productsReducer.productModal;

    dispatch(updateProductModal(product));
  };

  const removeRemark = (index) => {
    const product = productsReducer.productModal;
    product.remarks.splice(index, 1);

    dispatch(updateProductModal(product));
  };

  const addRemark = () => {
    const product = productsReducer.productModal;
    product.remarks = product.remarks || [];

    product.remarks.push({ product_Id: product.id });

    dispatch(updateProductModal(product));
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  return (
    <div className="card mt-2 zIndex-2">
      <div className="card-body">
        <h5 className="card-title">
          {t("remarks")}{" "}
          <button className="btn btn-success float-right" onClick={addRemark}>
            <span className="icon icon-circle-with-plus" />
          </button>
        </h5>

        <hr className="clearfix" />
        {productsReducer.productModal.remarks &&
          productsReducer.productModal.remarks.map((r, index) => (
            <React.Fragment key={index}>
              <Row>
                <Col md="7">
                  <InputWithLabel
                    labelText={t("description")}
                    inputType="text"
                    fieldToUpdate="description"
                    objectToUpdate={r}
                    persistOnBlur
                    updateAction={updateModelRemark}
                  />
                </Col>
                <Col md="3">
                  <MultiSelectWithLabel
                    filterable
                    labelText={t("remarkType_Id")}
                    selectPlaceholder={t("remarkType_Id_placeholder")}
                    data={remarkTypes}
                    textField={referenceName}
                    valueField="id"
                    value={r.remarkType_Ids}
                    objectToUpdate={r}
                    updateAction={updateModelRemark}
                    fieldToUpdate="remarkType_Ids"
                  />
                </Col>
                <Col md="2">
                  <button className="mt-125 btn btn-danger float-right" onClick={(_) => removeRemark(index)}>
                    <span className="icon icon-circle-with-minus" />
                  </button>
                </Col>
              </Row>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
}

export default RemarkSection;
