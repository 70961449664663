export const provincesReducer = (
  state = {
    provinces: [],
    provincesForSelect: [],

    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_PROVINCES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_PROVINCES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_PROVINCES_FULFILLED": {
      state = { ...state, updating: false, updated: true, provinces: action.payload.items };
      break;
    }
    case "GET_PROVINCES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_PROVINCE_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_PROVINCE_FULFILLED": {
      let provinces = state.provinces;
      provinces = provinces.filter(s => s.id !== action.payload.id);
      provinces.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        provinces
      };
      break;
    }
    case "GET_PROVINCES_SELECT_FULFILLED": {
      let provinces = state.provinces;
      action.payload.forEach(province => {
        if (!provinces.some(t => t.id === province.id)) {
          provinces.push(province);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        provincesForSelect: provinces
      };
      break;
    }
    case "GET_PROVINCE_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default provincesReducer;
