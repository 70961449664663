import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import uuidv4 from "uuid/v4";

import { Row, Col } from "reactstrap";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";

import { isEmpty } from "utils/utils";
import { TaxTypeTarget } from "utils/enums";

import { updateEstablishment, updateTaxesOrder } from "redux/actions/establishments";

import SelectWithLabel from "components/FormControls/SelectWithLabel";
import InputWithLabel from "components/FormControls/InputWithLabel";
import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";
import ReactTooltip from "react-tooltip";

const DragHandle = sortableHandle(() => <i className="fas fa-2x fa-bars text-default mt-4 grabbable"></i>);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const SortableItem = sortableElement(
  ({ tax, taxTypes = [], taxTypeApplications = [], taxTypeTargets = [], productTypes = [], itemIndex, isManager }) => {
    const establishmentsReducer = useSelector((state) => state.establishmentsReducer);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [forceRefresh, setForceRefresh] = useState(0);

    const removeTax = () => {
      const establishment = establishmentsReducer.establishment;
      establishment.taxes.splice(itemIndex, 1);

      dispatch(updateEstablishment(establishment));
    };

    const updateModelTax = (value, model, field) => {
      if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

      if (
        field === "taxTypeTarget_Id" &&
        taxTypeTargets.find((ttt) => ttt.id === Number(value)).internalName === TaxTypeTarget.APPLIED_BY_PERCENTAGE
      ) {
        model.taxTypeApplication_Id = null;
      }

      model[field] = value;

      const establishment = establishmentsReducer.establishment;

      if (field === "taxType_Id") {
        let taxType = taxTypes.find((t) => t.id === value);
        model.value = taxType.defaultValue;
        model.taxTypeApplication_Id = taxType.taxTypeApplication_Id;
        model.taxTypeTarget_Id = taxType.taxTypeTarget_Id;

        setForceRefresh(forceRefresh + 1);
      }

      dispatch(updateEstablishment(establishment));
    };

    const isEnglish = t("locale") === "en";
    const referenceName = isEnglish ? "nameEnglish" : "nameFrench";
    const hasProductTypes = productTypes.length > 0;

    return (
      <Col md="12" className="">
        <Row>
          <Col md="1"> {isManager && <DragHandle />}</Col>

          {hasProductTypes && (
            <Col md="2">
              <MultiSelectWithLabel
                labelText={t("productType_Ids")}
                id="establishment-product-types"
                objectToUpdate={tax}
                updateAction={updateModelTax}
                disabled={!isManager}
                fieldToUpdate="productType_Ids"
                textField={referenceName}
                valueField="id"
                value={tax.productType_Ids}
                data={productTypes}
              />
              <InputWithLabel
                labelText={t("is_taxable")}
                inputType="checkbox"
                fieldToUpdate="isTaxable"
                objectToUpdate={tax}
                disabled={!isManager}
                inputNamePrefix={tax.uniqueIdentifier || tax.id}
                persistOnBlur
                updateAction={updateModelTax}
              />
            </Col>
          )}

          <Col md={hasProductTypes ? "2" : "3"}>
            <SelectWithLabel
              filterable
              labelText={t("taxType_Id")}
              selectPlaceholder={t("taxType_Id_placeholder")}
              data={taxTypes}
              textField={referenceName}
              disabled={!isManager}
              valueField="id"
              value={tax.taxType_Id}
              objectToUpdate={tax}
              updateAction={updateModelTax}
              fieldToUpdate="taxType_Id"
            />
            {!hasProductTypes && (
              <InputWithLabel
                labelText={t("is_taxable")}
                inputType="checkbox"
                fieldToUpdate="isTaxable"
                disabled={!isManager}
                objectToUpdate={tax}
                inputNamePrefix={tax.uniqueIdentifier || tax.id}
                persistOnBlur
                updateAction={updateModelTax}
              />
            )}
          </Col>

          <Col md={hasProductTypes ? "2" : "3"}>
            {/* {tax.isPercentage ? ( */}
            <InputWithLabel
              key={forceRefresh}
              labelText={t("value")}
              groupAppend={
                tax.taxTypeTarget_Id &&
                taxTypeTargets.length &&
                taxTypeTargets.find((ttt) => tax.taxTypeTarget_Id === ttt.id).internalName === TaxTypeTarget.APPLIED_BY_PERCENTAGE
                  ? "%"
                  : "$"
              }
              isDecimal
              inputType="number"
              fieldToUpdate="value"
              disabled={!isManager || (taxTypes.find((tt) => tt.id === tax.taxType_Id) || {}).isFixed}
              objectToUpdate={tax}
              persistOnBlur
              updateAction={updateModelTax}
            />
            {/* ) : (
             <CurrencyInput value={tax.value} onBlur={e => updateModelTax(e, tax, "value")} className="form-control text-right" />
           )}*/}
          </Col>
          <Col md={hasProductTypes ? "4" : "5"}>
            <Row>
              <Col md="6">
                <div className="form-group">
                  <label className="form-control-label">{t("payment_target")}</label>

                  {taxTypeTargets.map((ttt) => (
                    <div className="form-check" key={`${ttt.id}-${tax.id || tax.uniqueIdentifier}`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`taxTypeTarget_Id-${ttt.id}-${tax.id || tax.uniqueIdentifier}`}
                        name={`taxTypeTarget_Id-${tax.id || tax.uniqueIdentifier}`}
                        value={ttt.id}
                        disabled={!isManager || (taxTypes.find((tt) => tt.id === tax.taxType_Id) || {}).isFixed}
                        checked={tax.taxTypeTarget_Id === ttt.id}
                        onChange={(e) => updateModelTax(Number(e.target.value), tax, "taxTypeTarget_Id")}
                      />
                      <label className="form-check-label" htmlFor={`taxTypeTarget_Id-${ttt.id}-${tax.id || tax.uniqueIdentifier}`}>
                        {ttt[referenceName]}
                      </label>
                    </div>
                  ))}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-control-label">{t("payment_calculation")}</label>

                  {taxTypeApplications.map((tta) => (
                    <div className="form-check" key={`${tta.id}-${tax.uniqueIdentifier}`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`taxTypeApplication_Id-${tta.id}-${tax.uniqueIdentifier}`}
                        name={`taxTypeApplication_Id-${tax.uniqueIdentifier}`}
                        value={tta.id}
                        checked={tax.taxTypeApplication_Id === tta.id}
                        onClick={(e) => updateModelTax(Number(e.target.value), tax, "taxTypeApplication_Id")}
                        disabled={
                          !isManager ||
                          (taxTypes.find((tt) => tt.id === tax.taxType_Id) || {}).isFixed ||
                          !tax.taxTypeTarget_Id ||
                          (tax.taxTypeTarget_Id &&
                            taxTypeTargets.length &&
                            taxTypeTargets.find((ttt) => ttt.id === tax.taxTypeTarget_Id).internalName ===
                              TaxTypeTarget.APPLIED_BY_PERCENTAGE)
                        }
                      />
                      <label className="form-check-label" htmlFor={`taxTypeApplication_Id-${tta.id}-${tax.uniqueIdentifier}`}>
                        {tta[referenceName]}
                        <ReactTooltip
                          id={`taxTypeApplication_Id-tooltip-${tta.id}-${tax.uniqueIdentifier}`}
                          type="info"
                          effect="solid"
                          place="top"
                          border
                        />
                        <i
                          className="fas fa-info-circle text-info ml-1"
                          data-tip={isEnglish ? tta.extraInformationEnglish : tta.extraInformationFrench}
                          data-for={`taxTypeApplication_Id-tooltip-${tta.id}-${tax.uniqueIdentifier}`}
                        ></i>
                      </label>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="1">
            <button className="mt-125 btn btn-danger float-right" disabled={!isManager} onClick={(_) => removeTax()}>
              <span className="icon icon-circle-with-minus" />
            </button>
          </Col>
        </Row>
      </Col>
    );
  }
);

function TaxSection({ taxTypes = [], taxTypeApplications = [], taxTypeTargets = [], productTypes = [] }) {
  const establishmentsReducer = useSelector((state) => state.establishmentsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const addTax = () => {
    const establishment = establishmentsReducer.establishment;
    establishment.taxes = establishment.taxes || [];

    var newTaxType = taxTypes[0];

    establishment.taxes.push({
      establishment_Id: establishment.id,
      taxType_Id: newTaxType.id,
      uniqueIdentifier: uuidv4(),
      taxTypeApplication_Id: null,
      order: establishment.taxes.length > 0 ? Math.max(...establishment.taxes.map((tt) => tt.order || 0)) + 1 : 0,
      value: newTaxType.defaultValue,
    });

    dispatch(updateEstablishment(establishment));
  };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const establishment = establishmentsReducer.establishment;
    establishment.taxes.splice(newIndex, 0, establishment.taxes.splice(oldIndex, 1)[0]);
    establishment.taxes.forEach((t, index) => (t.order = index));
    dispatch(updateTaxesOrder(establishment.taxes));
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  let taxes = establishmentsReducer.establishment.taxes || [];
  taxes = taxes.sort((a, b) => a.order - b.order);

  return (
    <div className="card mt-2 zIndex-1030">
      <div className="card-body">
        <h5 className="card-title">
          {t("taxes")}{" "}
          <button className="btn btn-success float-right" onClick={addTax} disabled={!accountReducer.isManager}>
            <span className="icon icon-circle-with-plus" />
          </button>
        </h5>

        <hr className="clearfix" />

        <div className="card-text">
          <SortableContainer onSortEnd={handleSortEnd} useDragHandle>
            {taxes.map((tax, index) => (
              <SortableItem
                isManager={accountReducer.isManager}
                key={`tax-${tax.uniqueIdentifier}`}
                index={index}
                itemIndex={index}
                tax={tax}
                taxTypes={taxTypes}
                taxTypeApplications={taxTypeApplications}
                taxTypeTargets={taxTypeTargets}
                productTypes={productTypes}
              />
            ))}
          </SortableContainer>
        </div>
      </div>
    </div>
  );
}

export default TaxSection;
