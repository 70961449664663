import React from "react";
import uuidv4 from "uuid/v4";

import { useTranslation } from "react-i18next";

import CurrencyInput from "react-currency-input";

import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

export default function BaseAccomodationPriceDetail({ cpd, handleUpdateContractPriceDetail }) {
  const { t } = useTranslation();
  const accountReducer = useSelector((state) => state.accountReducer);

  let guid1 = uuidv4();
  let guid2 = uuidv4();
  let guid3 = uuidv4();
  let guid4 = uuidv4();
  let guid5 = uuidv4();

  return (
    <Row>
      <Col md="12">
        <h5>{t("standard_room")}</h5>
        <div className="d-flex flex-nowrap">
          <div className="form-group pt-2 pb-2 pr-2">
            <label className="form-label" htmlFor={`single-${guid1}`}>
              {t("single")}
            </label>
            <div className="input-group mt-1">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <CurrencyInput
                value={cpd.single}
                id={`single-${guid1}`}
                disabled={!accountReducer.isManager}
                onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "single", true)}
                className="form-control text-right"
              />
            </div>
          </div>
          <div className="form-group p-2">
            <label className="form-label" htmlFor={`double-${guid2}`}>
              {t("double")}
            </label>
            <div className="input-group mt-1">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <CurrencyInput
                value={cpd.double}
                id={`double-${guid2}`}
                disabled={!accountReducer.isManager}
                onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "double", true)}
                className="form-control text-right"
              />
            </div>
          </div>

          <div className="form-group p-2">
            <label className="form-label" htmlFor={`twin-${guid5}`}>
              {t("twin")}
            </label>
            <div className="input-group mt-1">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <CurrencyInput
                id={`twin-${guid5}`}
                value={cpd.twin}
                disabled={!accountReducer.isManager}
                onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "twin", true)}
                className="form-control text-right"
              />
            </div>
          </div>

          <div className="form-group p-2">
            <label className="form-label" htmlFor={`triple-${guid3}`}>
              {t("triple")}
            </label>
            <div className="input-group mt-1">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <CurrencyInput
                id={`triple-${guid3}`}
                value={cpd.triple}
                disabled={!accountReducer.isManager}
                onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "triple", true)}
                className="form-control text-right"
              />
            </div>
          </div>

          <div className="form-group p-2">
            <label className="form-label" htmlFor={`quad-${guid4}`}>
              {t("quad")}
            </label>
            <div className="input-group mt-1">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <CurrencyInput
                id={`quad-${guid4}`}
                value={cpd.quad}
                disabled={!accountReducer.isManager}
                onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "quad", true)}
                className="form-control text-right"
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
