export const invoiceReducer = (
  state = {
    invoicesToSynchronize: [],
    gettingInvoiceToSynchronize: false,
    pushingInvoice: false,
    updatingInvoice: false,
    showDeleteInvoiceModal: false,
    showSyncInvoiceModal: false,
    showInvoiceChangeDateModal: false,
    deleteInvoiceModalEntity: {},
    syncInvoiceModalEntity: {},
    invoiceChangeDateModalEntity: {},
  },
  action
) => {
  switch (action.type) {
    case "GET_INVOICES_TO_SYNC_PENDING": {
      return { ...state, gettingInvoiceToSynchronize: true };
    }
    case "GET_INVOICES_TO_SYNC_FULFILLED": {
      return { ...state, gettingInvoiceToSynchronize: false, invoicesToSynchronize: action.payload };
    }

    case "PERSISTED_INVOICE": {
      let revision = action.payload.invoiceRevision;
      if (revision.quickbooksSynchronizationProhibited) {
        let filteredInvoices = state.invoicesToSynchronize.filter((x) => x.id !== revision.id);
        return { ...state, updatingInvoice: false, invoicesToSynchronize: filteredInvoices };
      }

      let invoiceToUpdate = state.invoicesToSynchronize.find((x) => x.id === revision.id);
      invoiceToUpdate.isOnHold = revision.isOnHold;

      return { ...state, updatingInvoice: false };
    }
    case "PUSHING_INVOICE": {
      return { ...state, pushingInvoice: true };
    }
    case "SHOW_DELETE_INVOICE_MODAL": {
      state = { ...state, showDeleteInvoiceModal: true, deleteInvoiceModalEntity: action.payload };
      break;
    }
    case "HIDE_DELETE_INVOICE_MODAL": {
      state = { ...state, showDeleteInvoiceModal: false, deleteInvoiceModalEntity: {} };
      break;
    }
    case "SHOW_INVOICE_CHANGE_DATE_MODAL": {
      state = { ...state, showInvoiceChangeDateModal: true, invoiceChangeDateModalEntity: action.payload };
      break;
    }
    case "HIDE_INVOICE_CHANGE_DATE_MODAL": {
      state = { ...state, showInvoiceChangeDateModal: false, invoiceChangeDateModalEntity: {} };
      break;
    }
    case "SHOW_SYNC_INVOICE_MODAL": {
      state = { ...state, showSyncInvoiceModal: true, syncInvoiceModalEntity: action.payload };
      break;
    }
    case "HIDE_SYNC_INVOICE_MODAL": {
      state = { ...state, showSyncInvoiceModal: false, syncInvoiceModalEntity: {} };
      break;
    }
    case "PUSHED_INVOICE": {
      let invoicesToSynchronize = state.invoicesToSynchronize;

      if (action.payload.status === "success") {
        invoicesToSynchronize = invoicesToSynchronize.filter((s) => s.id !== action.payload.invoiceRevisionId);
      }

      return { ...state, pushingInvoice: false, invoicesToSynchronize };
    }
    default: {
    }
  }

  return state;
};

export default invoiceReducer;
