import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { hideDeletePurchaseOrderConfirmationModal, persistPurchaseOrder } from "redux/quickbooks/purchase-order/purchase-order-action";

export default function DeletePurchaseOrderModal() {
  const purchaseOrderReducer = useSelector((state) => state.purchaseOrderReducer);

  const { showDeletePurchaseOrderModal, deletePurchaseOrderModalEntity } = purchaseOrderReducer;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeletePurchaseOrder = () => {
    deletePurchaseOrderModalEntity["quickbooksSynchronizationProhibited"] = true;

    dispatch(persistPurchaseOrder(deletePurchaseOrderModalEntity)).then(() => {
      closeModal();
    });
  };

  const closeModal = () => {
    dispatch(hideDeletePurchaseOrderConfirmationModal());
  };

  if (!showDeletePurchaseOrderModal) return null;

  return (
    <Modal className="mw-100 w-50" isOpen={showDeletePurchaseOrderModal}>
      <ModalHeader>{t("delete_po_modal_title")}</ModalHeader>
      <ModalBody>
        <h6>
          <strong>{t("delete_po_impact")}</strong>
        </h6>
        <div className={"mt-2"}>{t("delete_po_warning")}</div>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleDeletePurchaseOrder}>
          {t("confirmer")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
