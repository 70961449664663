import axios from "axios";
import { HOST } from "config/config";
import tableDataQueryStringBuilder from "utils/tableData";

export function getEstablishments(tableDataParam, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().establishmentsReducer.updating || forceRefresh) {
      if (tableDataParam === undefined) {
        return dispatch({
          type: "GET_ESTABLISHMENTS_PENDING",
          payload: axios
            .get(`${HOST}/api/establishments`)
            .then(response => response.data)
            .then(json =>
              dispatch({
                type: "GET_ESTABLISHMENTS_FULFILLED",
                payload: {
                  items: json
                }
              })
            )
            .catch(error =>
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.message
                }
              })
            )
        });
      }

      return dispatch({
        type: "GET_ESTABLISHMENTS_PENDING",
        payload: axios
          .get(`${HOST}/api/establishments`, {
            paramsSerializer: params => tableDataQueryStringBuilder(params),
            params: tableDataParam
          })
          .then(response => response.data)
          .then(json => {
            if (tableDataParam) {
              dispatch({
                type: "UPDATE_ESTABLISHMENTS_TABLEDATAPARAM",
                payload: tableDataParam
              });
            }

            dispatch({
              type: "GET_ESTABLISHMENTS_FULFILLED",
              payload: json
            });
          })
          .catch(error =>
            dispatch({
              type: "GET_ESTABLISHMENTS_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}

export function getEstablishmentsForSelect() {
  return (dispatch, getState) => {
    if (!getState().establishmentsReducer.updating) {
      return dispatch({
        type: "GET_ESTABLISHMENTS_PENDING",
        payload: axios
          .get(`${HOST}/api/filters/establishments`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_ESTABLISHMENTS_SELECT_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message
              }
            })
          )
      });
    }
  };
}

export function getEstablishment(establishmentId, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().establishmentsReducer.updating || forceRefresh) {
      if (establishmentId === 0) {
        return dispatch({
          type: "GET_ESTABLISHMENT_FULFILLED",
          payload: {
            id: 0,
            internalName: "",
            nameFrench: "",
            nameEnglish: "",
            createdDate: new Date()
          }
        });
      }
      return dispatch({
        type: "GET_ESTABLISHMENT_PENDING",
        payload: axios
          .get(`${HOST}/api/establishments/${establishmentId}`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_ESTABLISHMENT_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "GET_ESTABLISHMENT_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}

export function getEstablishmentsByProvider(providerId, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().establishmentsReducer.updating || forceRefresh) {
      return dispatch({
        type: "GET_ESTABLISHMENTS_BY_PROVIDER_PENDING",
        payload: axios
          .get(`${HOST}/api/establishments?provider_Id=${providerId}`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_ESTABLISHMENTS_BY_PROVIDER_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message
              }
            })
          )
      });
    }
  };
}

export function updateEstablishment(establishment) {
  return dispatch =>
    dispatch({
      type: "UPDATE_ESTABLISHMENT",
      payload: establishment
    });
}

export function updateTaxesOrder(taxes) {
  return dispatch =>
    new Promise(resolve => {
      dispatch({
        type: "UPDATE_ESTABLISHMENT_TAXES_MODEL",
        payload: taxes
      });
      return resolve();
    });
}

export function persistEstablishment(establishment) {
  return (dispatch, getState) => {
    if (!getState().establishmentsReducer.updating) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "PERSISTING_ESTABLISHMENT",
          payload: (establishment.id === 0
            ? axios.post(`${HOST}/api/establishments`, establishment)
            : axios.put(`${HOST}/api/establishments/${establishment.id}`, establishment)
          )
            .then(response => response.data)
            .then(json => {
              dispatch({
                type: "PERSISTED_ESTABLISHMENT",
                payload: {
                  establishment: json,
                  isNew: establishment.id === 0
                }
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "saved_successful"
                }
              });
              return resolve();
            })
            .catch(error => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response
                }
              });
              return reject();
            })
        })
      );
    }
  };
}
