import axios from "axios";
import { HOST } from "config/config";
import tableDataQueryStringBuilder from "utils/tableData";

export function getCountries(tableDataParam, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().countriesReducer.updating || forceRefresh) {
      if (tableDataParam === undefined) {
        return dispatch({
          type: "GET_COUNTRIES_PENDING",
          payload: axios
            .get(`${HOST}/api/countries`)
            .then(response => response.data)
            .then(json =>
              dispatch({
                type: "GET_COUNTRIES_FULFILLED",
                payload: {
                  items: json
                }
              })
            )
            .catch(error =>
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.message
                }
              })
            )
        });
      }

      return dispatch({
        type: "GET_COUNTRIES_PENDING",
        payload: axios
          .get(`${HOST}/api/countries`, {
            paramsSerializer: params => tableDataQueryStringBuilder(params),
            params: tableDataParam
          })
          .then(response => response.data)
          .then(json => {
            if (tableDataParam) {
              dispatch({
                type: "UPDATE_COUNTRIES_TABLEDATAPARAM",
                payload: tableDataParam
              });
            }

            dispatch({
              type: "GET_COUNTRIES_FULFILLED",
              payload: json
            });
          })
          .catch(error =>
            dispatch({
              type: "GET_COUNTRIES_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}

export function getCountriesForSelect() {
  return (dispatch, getState) => {
    if (!getState().countriesReducer.updating) {
      return dispatch({
        type: "GET_COUNTRIES_SELECT_PENDING",
        payload: axios
          .get(`${HOST}/api/filters/countries`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_COUNTRIES_SELECT_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message
              }
            })
          )
      });
    }
  };
}

export function getCountry(countryId, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().countriesReducer.updating || forceRefresh) {
      if (countryId === 0) {
        return dispatch({
          type: "GET_COUNTRY_FULFILLED",
          payload: {
            id: 0,
            internalName: "",
            nameFrench: "",
            nameEnglish: "",
            createdDate: new Date()
          }
        });
      }
      return dispatch({
        type: "GET_COUNTRY_PENDING",
        payload: axios
          .get(`${HOST}/api/countries/${countryId}`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_COUNTRY_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "GET_COUNTRY_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}
