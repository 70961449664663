import React, { useState } from "react";
import ReactTooltip from "react-tooltip";

export default function InputNumber({ props }) {
  const [isValid, setValid] = useState(true);

  const floatRegExp = new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$");
  const intRegExp = new RegExp("^[+-]?([0-9])+$");

  const handleValidation = (e, onBlur) => {
    const { value } = e.target;
    if (value === "" || (props.isDecimal && floatRegExp.test(value)) || (!props.isDecimal && intRegExp.test(value))) {
      setValid(true);
      onBlur(Number(value), props.objectToUpdate, props.fieldToUpdate, true);
    } else {
      setValid(false);
    }
  };

  return (
    <div className={`form-group ${props.groupClassName}`}>
      {props.labelText && (
        <label
          className={
            props.errorMessage || props.forceShowErrorState || !isValid
              ? `form-control-label text-danger ${props.labelClassName}`
              : `form-control-label ${props.labelClassName}`
          }
          htmlFor={props.fieldToUpdate}
        >
          {props.labelText}
        </label>
      )}

      <div className={(props.icon ? "input-with-icon" : "") + (props.groupPrepend || props.groupAppend ? " input-group" : "")}>
        {props.groupPrepend ? (
          <div className="input-group-prepend">
            <span className="input-group-text">{props.groupPrepend}</span>
          </div>
        ) : null}
        <input
          placeholder={props.placeholder}
          className={
            props.errorMessage || props.forceShowErrorState || !isValid
              ? `form-control is-invalid ${props.className}`
              : `form-control ${props.className}`
          }
          id={props.fieldToUpdate}
          name={props.fieldToUpdate}
          type="number"
          disabled={props.disabled}
          defaultValue={
            props.objectToUpdate[props.fieldToUpdate] === null || props.objectToUpdate[props.fieldToUpdate] === undefined
              ? ""
              : props.objectToUpdate[props.fieldToUpdate]
          }
          onBlur={(e) => props.persistOnBlur && handleValidation(e, props.updateAction)} // onChange={e => updateAction(e.target.value, objectToUpdate, fieldToUpdate, false)}
        />
        {props.groupAppend ? (
          <div className="input-group-append">
            <span className="input-group-text">{props.groupAppend}</span>
          </div>
        ) : null}
        {props.icon ? (
          <span className="icon-number" data-tip={props.iconTooltip} data-html={props.iconHtml} data-for={`tt-${props.fieldToUpdate}`}>
            {props.icon}
          </span>
        ) : null}
        {props.iconTooltip ? (
          <ReactTooltip clickable id={`tt-${props.fieldToUpdate}`} type="info" effect="solid" place="right" border />
        ) : null}
        {props.hasValidator && props.errorMessage}
      </div>
    </div>
  );
}
