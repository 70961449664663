/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import uuidv4 from "uuid/v4";
import FilterRow from "./components/FilterRow";
import { comparators } from "./components/components/comparators";

class Filter extends Component {
  constructor(props) {
    super(props);
    let initialId = uuidv4();
    if (props.columns.some((c) => c.selected)) {
      let selectedColumn = props.columns.find((c) => c.selected);
      this.state = {
        filters: [
          {
            id: initialId,
            selectedColumn: { comparators: comparators[selectedColumn.filterComparatorsName], column: selectedColumn },
            value: null,
            comparator: comparators[selectedColumn.filterComparatorsName].find((c) => c.internalName === selectedColumn.defaultComparator)
              .valueForQuery,
          },
        ],
      };
    } else {
      this.state = {
        filters: [
          {
            id: initialId,
            selectedColumn: null,
            value: null,
            comparator: null,
          },
        ],
      };
    }

    this.resetFilter = this.resetFilter.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.removeFilterRow = this.removeFilterRow.bind(this);
  }

  resetFilter() {
    this.setState(
      {
        filters: [
          {
            id: uuidv4(),
            selectedColumn: null,
            value: null,
            comparator: null,
          },
        ],
      },
      () => this.props.filterMethod()
    );
  }

  addFilter() {
    let filters = this.state.filters;
    filters.push({
      id: uuidv4(),
      selectedColumn: null,
      value: null,
      comparator: null,
    });

    this.setState({ filters });
  }

  updateFilter(filter) {
    let filters = this.state.filters;
    filters.forEach((f) => {
      if (f.id === filter.id) {
        f = Object.assign({}, filter);
      }
    });

    this.setState({ filters });
  }

  removeFilterRow(filter) {
    let filters = this.state.filters;
    filters = filters.filter((f) => f.id !== filter.id);

    this.setState({ filters });
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          {this.state.filters.map((f, i) => (
            <FilterRow
              id={f.id}
              key={f.id}
              updateFilter={this.updateFilter}
              columns={this.props.columns}
              filter={f}
              canDelete={i !== 0}
              locale={this.props.locale}
              removeFilterRow={() => this.removeFilterRow(f)}
            />
          ))}
        </div>
        <div className="row">
          <div className="col-md-12 mt-2">
            <div className="float-right">
              {this.state.filters.some((f) => f.selectedColumn !== null && f.selectedColumn.column) &&
              this.state.filters.some((f) => f.comparator !== null) ? (
                <React.Fragment>
                  <button className="btn btn-primary mr-2" onClick={() => this.props.filterMethod(this.state.filters)}>
                    <span className="icon icon-funnel" />
                    {"  "}
                    {this.props.filter}
                  </button>

                  <button className="btn btn-primary mr-2" onClick={this.resetFilter}>
                    <span className="icon icon-cycle" />
                    {"  "}
                    {this.props.reset}
                  </button>

                  <button className="btn btn-success " onClick={this.addFilter}>
                    <span className="icon icon-circle-with-plus" />
                  </button>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Filter;
