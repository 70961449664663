import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";

import TableData from "components/TableData/TableData";
import Spinner from "components/Spinner/Spinner";
import { TaxTypeTarget } from "utils/enums";
import { getTaxTypeTargetsForSelect } from "redux/actions/tax-type-targets";

import ReactTooltip from "react-tooltip";

import { getTaxTypes, deleteTaxType } from "redux/actions/tax-types";
import ConfirmDeleteTaxTypeModal from "./Components/ConfirmDeleteTaxTypeModal";

function TaxTypes() {
  const { t } = useTranslation();
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [taxTypeToDelete, setTaxTypeToDelete] = useState(false);

  const taxTypesReducer = useSelector((state) => state.taxTypesReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const taxTypeTargetsReducer = useSelector((state) => state.taxTypeTargetsReducer);

  const { taxTypeTargets } = taxTypeTargetsReducer;

  const dispatch = useDispatch();
  const { history } = useReactRouter();

  useEffect(() => {
    dispatch(getTaxTypeTargetsForSelect());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTaxTypes());
  }, [dispatch, taxTypesReducer.tableDataParam]);

  const renderRows = (value, field, isFilter) => {
    let tableDataParam = taxTypesReducer.tableDataParam;
    tableDataParam[field] = value;

    if (isFilter) {
      tableDataParam.currentPage = 1;
    }

    dispatch(getTaxTypes(tableDataParam));
  };

  const updatePage = (page) => {
    renderRows(page, "currentPage");
  };
  const updatePageSize = (pageSize) => {
    renderRows(pageSize, "pageSize");
  };

  const sortColumn = (column) => {
    let tableDataParam = getTaxTypes.tableDataParam;
    let existingSort = tableDataParam.sorts.find((s) => s.column === column.sortColumn);

    renderRows(
      [
        {
          column: column.sortColumn,
          asc: existingSort !== undefined ? !existingSort.asc : false,
        },
      ],
      "sorts"
    );
  };

  const filter = (filters) => {
    if (filters === undefined) {
      return renderRows([], "filters");
    }

    let formattedFilters = filters.map((f) => ({
      fieldName: f.selectedColumn.column.filterColumn,
      columnWrapper: f.selectedColumn.column.columnWrapper,
      fieldValue: f.value,
      fieldType: f.selectedColumn.column.type,
      operator: f.comparator,
    }));

    return renderRows(formattedFilters, "filters", true);
  };

  const handleEdit = (taxeTypeId) => {
    history.push(`/tax-types/${taxeTypeId}`);
  };

  const handleShowDeleteModal = (taxType) => {
    setTaxTypeToDelete(taxType);
    setConfirmDeleteModalOpen(true);
  };

  const handleDelete = (taxeTypeId) => {
    dispatch(deleteTaxType(taxeTypeId)).then((_) => setConfirmDeleteModalOpen(false));
  };

  const addButton = {};

  if (accountReducer.isManager) {
    addButton.addButtonText = t("add_tax");
    addButton.addAction = () => handleEdit(0);
  }

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">{t("overview")}</h6>
          <h2 className="dashhead-title">{t("taxes")}</h2>
        </div>
      </div>

      <ConfirmDeleteTaxTypeModal
        isOpen={confirmDeleteModalOpen}
        handleCloseModal={() => setConfirmDeleteModalOpen(false)}
        taxType={taxTypeToDelete}
        handleDelete={handleDelete}
      />

      <TableData
        className="mb-4"
        numberOfItems={taxTypesReducer.count}
        pageSize={taxTypesReducer.tableDataParam.pageSize}
        updatePage={updatePage}
        updatePageSize={updatePageSize}
        pageSizes={[25, 50, 100, 200]}
        hideTitle
        reset={t("reset")}
        filter={t("filter")}
        sortMethod={sortColumn}
        filterMethod={filter}
        locale={t("locale")}
        {...addButton}
        hasFilter
        columns={[
          {
            title: t("nameFrench"),
            filterComparatorsName: "stringComparators",
            filterColumn: "nameFrench",
            type: "string",
          },
          {
            title: t("nameEnglish"),
            filterComparatorsName: "stringComparators",
            filterColumn: "nameEnglish",
            type: "string",
          },
          {
            title: t("defaultValue"),
          },
          {
            title: t("type"),
          },
          {
            title: t("actions"),
          },
        ]}
      >
        <tbody>
          {taxTypesReducer.updating || taxTypeTargetsReducer.updating || !(taxTypesReducer.taxTypes && taxTypesReducer.taxTypes.length) ? (
            <tr>
              <td colSpan={4}>
                <Spinner />
              </td>
            </tr>
          ) : taxTypesReducer.taxTypes.length ? (
            taxTypesReducer.taxTypes.map((p) => (
              <tr key={p.id}>
                <td>{p.nameFrench}</td>
                <td>{p.nameEnglish}</td>
                <td>{`${p.defaultValue} ${
                  taxTypeTargets?.length > 0
                    ? (taxTypeTargets.find((ttt) => Number(p.taxTypeTarget_Id) === Number(ttt.id)) || {}).internalName ===
                      TaxTypeTarget.APPLIED_BY_PERCENTAGE
                      ? "%"
                      : "$"
                    : ""
                }`}</td>
                <td>{p.isFixed ? t("taxTypeFixed") : t("taxTypeVariable")}</td>
                <td>
                  <React.Fragment>
                    <ReactTooltip clickable id="tt-edit-tax" type="info" effect="solid" place="left" border />

                    <i
                      data-tip={t("edit_tax")}
                      data-for="tt-edit-tax"
                      onClick={() => handleEdit(p.id)}
                      style={{ marginLeft: "10px" }}
                      className="fas fa-2x fa-pencil-alt text-primary pointer"
                    />

                    {p.productCount === 0 && accountReducer.isManager && (
                      <React.Fragment>
                        <ReactTooltip clickable id="tt-delete-tax" type="error" effect="solid" place="right" border />

                        <i
                          data-tip={t("delete_tax")}
                          data-for="tt-delete-tax"
                          onClick={() => handleShowDeleteModal(p)}
                          style={{ marginLeft: "10px" }}
                          className="fas fa-2x fa-trash text-danger pointer"
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{t("no_data_found")}</td>
            </tr>
          )}
        </tbody>
      </TableData>
    </React.Fragment>
  );
}

export default TaxTypes;
