import React from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import LabelField from "components/FormControls/LabelField";

import moment from "moment";
import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";
import InputWithLabel from "components/FormControls/InputWithLabel";

export default function SummaryFreebie({ contract }) {
  const { t } = useTranslation();

  const comparatorsReducer = useSelector((state) => state.comparatorsReducer);
  const productsReducer = useSelector((state) => state.productsReducer);

  const isEnglish = t("locale") === "en";
  const referenceLabel = isEnglish ? "labelEnglish" : "labelFrench";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  let validOperators = comparatorsReducer.comparatorsForSelect.filter((c) => c.comparatorTypeName === "int");
  let sortedProducts = (productsReducer.establishmentProducts || []).sort((a, b) =>
    a[referenceName] ? a[referenceName].localeCompare(b[referenceName]) : 0
  );

  return (
    <div className={`card mt-3`}>
      <div className="card-body">
        <h5 className="card-title">{t("freebies_title")}</h5>
        <div className="card-text">
          {contract.freebies &&
            contract.freebies.map((a) => (
              <React.Fragment key={a.id || a.uniqueIdentifier}>
                <hr />
                <Row>
                  <Col md="6">
                    <LabelField labelText={t("nameFrench")} value={a.nameFrench} />
                  </Col>
                  <Col md="6">
                    <LabelField labelText={t("nameEnglish")} value={a.nameEnglish} />
                  </Col>
                </Row>

                {a.periodDates && a.periodDates.length > 0 && (
                  <>
                    <h5 className="mb-1">{t("date_periods")}</h5>

                    {a.periodDates.map((d) => (
                      <Row key={d.id || d.uniqueIdentifier}>
                        <Col md="6">
                          <LabelField labelText={t("from")} value={moment(d.start).format("YYYY-MM-DD")} />
                        </Col>
                        <Col md="6">
                          <LabelField labelText={t("to")} value={moment(d.end).format("YYYY-MM-DD")} />
                        </Col>
                      </Row>
                    ))}
                  </>
                )}

                <h5 className="mb-1">{t("criterias")}</h5>
                {sortedProducts.length > 0 && (
                  <MultiSelectWithLabel
                    labelText={t("products")}
                    selectPlaceholder={t("freebie_products_placeholder")}
                    objectToUpdate={a}
                    updateAction={() => {}}
                    fieldToUpdate="product_Ids"
                    textField={referenceName}
                    valueField="id"
                    defaultValue={a.product_Ids}
                    data={sortedProducts}
                    disabled={true}
                  />
                )}

                {a.criterias &&
                  a.criterias.map((c) => (
                    <Row key={c.id || c.uniqueIdentifier}>
                      <Col md="6">
                        {c.comparator_Id && (
                          <LabelField
                            labelText={t("age_criteria_operators")}
                            value={validOperators.find((o) => o.id === c.comparator_Id)[referenceLabel]}
                          />
                        )}
                      </Col>
                      <Col md="6">
                        <LabelField labelText={t("value")} value={c.value} />
                      </Col>
                    </Row>
                  ))}

                <h5 className="mb-1">{t("freebies")}</h5>
                {a.details &&
                  a.details.map((c) => (
                    <Row key={c.id || c.uniqueIdentifier}>
                      <Col md="5">
                        {sortedProducts.length > 0 && (
                          <MultiSelectWithLabel
                            labelText={t("products")}
                            selectPlaceholder={t("detail_products_placeholder")}
                            objectToUpdate={c}
                            updateAction={() => {}}
                            fieldToUpdate="product_Ids"
                            textField={referenceName}
                            valueField="id"
                            defaultValue={c.product_Ids}
                            data={sortedProducts}
                            disabled={true}
                          />
                        )}
                      </Col>
                      <Col md="2" className={"detail-application-checkbox"}>
                        <label>{t("application")}</label>
                        <InputWithLabel
                          inputNamePrefix={c.uniqueIdentifier}
                          labelText={t("guide")}
                          inputType="checkbox"
                          fieldToUpdate="isAppliedForGuide"
                          objectToUpdate={c}
                          persistOnBlur
                          updateAction={() => {}}
                          disabled={true}
                        />
                        <InputWithLabel
                          inputNamePrefix={c.uniqueIdentifier}
                          labelText={t("driver")}
                          inputType="checkbox"
                          fieldToUpdate="isAppliedForDriver"
                          objectToUpdate={c}
                          persistOnBlur
                          updateAction={() => {}}
                          disabled={true}
                        />
                        <InputWithLabel
                          inputNamePrefix={c.uniqueIdentifier}
                          labelText={t("traveler")}
                          inputType="checkbox"
                          fieldToUpdate="isAppliedForTraveler"
                          objectToUpdate={c}
                          persistOnBlur
                          updateAction={() => {}}
                          disabled={true}
                        />
                      </Col>
                      <Col md="2">
                        <InputWithLabel
                          labelText={t("reduction_percentage")}
                          inputType="text"
                          fieldToUpdate="percentage"
                          objectToUpdate={c}
                          persistOnBlur
                          updateAction={() => {}}
                          disabled={true}
                        />
                      </Col>

                      <Col md="2">
                        <InputWithLabel
                          labelText={t("quantity")}
                          inputType="text"
                          fieldToUpdate="quantity"
                          objectToUpdate={c}
                          persistOnBlur
                          updateAction={() => {}}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  ))}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
}
