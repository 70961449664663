import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { addContractLength, removeContractLength } from "redux/actions/contracts";
import { setActiveStep } from "redux/actions/contracts";
import { Row, Col, Alert } from "reactstrap";
import LengthCategory from "./Components/LengthCategory";

export default function LengthCategories({ contract, steps, isEstablishmentContract, validator }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const durationsReducer = useSelector((state) => state.durationsReducer);

  useEffect(() => {
    let currentStep = steps.findIndex((s) => s.name === "length-categories");
    if (contractsReducer.activeStepIndex !== currentStep) dispatch(setActiveStep(currentStep));
  }, [contractsReducer.activeStepIndex, contractsReducer.steps, dispatch, steps]);

  const addLength = () => {
    dispatch(addContractLength(isEstablishmentContract));
  };

  const removeLength = (lengthCategory) => {
    dispatch(removeContractLength(lengthCategory, isEstablishmentContract));
  };

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h4 className="dashhead-title">{t("length_category")}</h4>
          <Alert type="primary" className="mt-2">
            {t("length_category_information")}
          </Alert>
          <hr />
        </div>
      </div>

      <Row>
        <Col md="12">
          <button className="btn btn-primary float-right" onClick={addLength}>
            {t("add_length")}
          </button>
        </Col>
      </Row>
      {contract &&
        contract.lengthCategories &&
        contract.lengthCategories.map((l, index) => (
          <LengthCategory
            key={l.uniqueIdentifier}
            removeLength={removeLength}
            contract={contract}
            lengthCategory={l}
            index={index}
            durations={durationsReducer.durationsForSelect}
            isEstablishmentContract={isEstablishmentContract}
            validator={validator}
          />
        ))}
    </React.Fragment>
  );
}
