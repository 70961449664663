import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/utils";

import { updateContractGroup, addGroupCriteria, removeGroupCriteria, updateContractGroupCriteria } from "redux/actions/contracts";

import { Row, Col } from "reactstrap";
import InputWithLabel from "components/FormControls/InputWithLabel";
import Criteria from "./Criteria";

function Group({ group, index, removeGroup, isEstablishmentContract, contract, validator }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accountReducer = useSelector((state) => state.accountReducer);

  const updateGroup = (value, model, field, isBlurring) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;

    dispatch(updateContractGroup(model, index, isEstablishmentContract));
  };

  const addCriteria = () => {
    dispatch(addGroupCriteria(group, index, isEstablishmentContract));
  };

  const removeCriteria = (currentCriteriaIndex) => {
    dispatch(removeGroupCriteria(group, index, currentCriteriaIndex, isEstablishmentContract));
  };

  const updateGroupCriteria = (groupCriteria, groupIndex, currentCriteriaIndex) => {
    dispatch(updateContractGroupCriteria(groupCriteria, groupIndex, currentCriteriaIndex, isEstablishmentContract));
  };

  let referenceName = t("locale") === "en" ? "nameEnglish" : "nameFrench";
  if (!group.criterias || group.criterias.length === 0) addCriteria();

  return (
    <React.Fragment>
      <div className={`card mt-3 zIndex-${10 - index}`}>
        <div className="card-body">
          <h5 className="card-title">
            <button className="btn btn-danger float-right" onClick={() => removeGroup(group)} disabled={!accountReducer.isManager}>
              <span className="fa fa-trash" />
            </button>
            {group[referenceName] || `${t("group")} ${index + 1}`}
          </h5>
          <div className="card-text">
            <hr />
            <Row>
              <Col md="12">
                <InputWithLabel
                  labelText={t("name")}
                  placeholder={t("group_namefrench_placeholder")}
                  inputType="text"
                  fieldToUpdate="nameFrench"
                  objectToUpdate={group}
                  persistOnBlur
                  updateAction={(value, group, fieldName, isBlurring) => {
                    updateGroup(value, group, fieldName, isBlurring);
                    updateGroup(value, group, "nameEnglish", isBlurring);
                  }}
                  disabled={!accountReducer.isManager || (contract.hasProductPrice && group.id > 0)}
                  hasValidator
                  errorMessage={validator.message(t("nameFrench"), group.nameFrench, `required`)}
                />
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <hr />
                <button className="btn btn-primary float-right" onClick={addCriteria} disabled={!accountReducer.isManager}>
                  {t("add_criteria")}
                </button>
                <h5>{t("criterias")}</h5>
              </Col>
            </Row>
            {group.criterias &&
              group.criterias.map((r, criteriaIndex) => (
                <Criteria
                  hasProductPrice={contract.hasProductPrice && r.id > 0}
                  key={`${criteriaIndex}-${r.uniqueIdentifier}`}
                  criteria={r}
                  criteriaIndex={criteriaIndex}
                  index={index}
                  showDelete={group.criterias.length > 1}
                  removeCriteria={removeCriteria}
                  updateAction={updateGroupCriteria}
                  labelKey={"group_criteria_operators"}
                  placeholderKey={"group_criteria_operators_placeholder"}
                  isEstablishmentContract={isEstablishmentContract}
                  validator={validator}
                />
              ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Group;
