import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getQuickbooksVendor } from "redux/quickbooks/quickbooks-vendor/quickbooks-vendor-action";
import InputWithLabel from "components/FormControls/InputWithLabel";
import SelectWithLabel from "components/FormControls/SelectWithLabel";
import Spinner from "components/Spinner/Spinner";

function QuickbooksVendors() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const quickbooksVendorReducer = useSelector((state) => state.quickbooksVendorReducer);

  const { quickbooksVendor, gettingQuickbooksVendor } = quickbooksVendorReducer;
  const accountReducer = useSelector((state) => state.accountReducer);
  const { isManager, availableGroups } = accountReducer;
  const [selectedGroupId, setSelectedGroupId] = useState(accountReducer.userGroupId);

  useEffect(() => {
    dispatch(getQuickbooksVendor(selectedGroupId));
  }, [dispatch, selectedGroupId]);

  return (
    isManager && (
      <React.Fragment>
        <div className="dashhead mt-4">
          <div className="dashhead-titles">
            <h6 className="dashhead-subtitle">{t("overview")}</h6>
            <h2 className="dashhead-title">{t("quickbooks_vendor")}</h2>
            <SelectWithLabel
              filterable
              clearable
              labelText={t("usergroup")}
              selectPlaceholder={t("usergroup")}
              data={availableGroups}
              textField={"name"}
              valueField="userGroup_Id"
              value={selectedGroupId}
              objectToUpdate={selectedGroupId}
              updateAction={setSelectedGroupId}
              fieldToUpdate="userGroup_Id"
            />
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="card-text">
              <table className={`table`}>
                <thead>
                  <tr>
                    <td>Id</td>
                    <td>Active</td>
                    <td>Company Name</td>
                    <td>Display Name</td>
                    <td>Billing Address</td>
                  </tr>
                </thead>
                <tbody>
                  {gettingQuickbooksVendor ? (
                    <tr>
                      <td colspan="10" style={{ textAlign: "center" }}>
                        <Spinner />
                      </td>
                    </tr>
                  ) : !quickbooksVendor || quickbooksVendor.length === 0 ? (
                    <tr>
                      <td colspan="10" style={{ textAlign: "center" }}>
                        {t("no_records")}
                      </td>
                    </tr>
                  ) : (
                    quickbooksVendor?.map((vendor) => (
                      <tr>
                        <td>{vendor.id}</td>
                        <td>
                          <InputWithLabel
                            labelText={""}
                            inputType="checkbox"
                            fieldToUpdate="active"
                            objectToUpdate={vendor}
                            persistOnBlur
                            disabled={true}
                          />
                          {vendor.active}
                        </td>
                        <td>{vendor.companyName}</td>
                        <td>{vendor.displayName}</td>
                        <td>
                          {vendor.billAddr?.line1}
                          <br />
                          {vendor.billAddr?.line2} {vendor.billAddr?.city} {vendor.billAddr?.countrySubDivisionCode}{" "}
                          {vendor.billAddr?.postalCode}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  );
}

export default QuickbooksVendors;
