import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { resetMessageStore } from "redux/actions/message";

import { toastr } from "react-redux-toastr";
import ReduxToastr from "react-redux-toastr";

class Toastr extends Component {
  componentDidUpdate() {
    let t = this.props.t;
    let messageReducer = this.props.messageReducer;
    let title = messageReducer.titleKey
      ? t(messageReducer.titleKey)
      : messageReducer.title;
    let message = "";
    if (messageReducer.messageKeys.length) {
      message = messageReducer.messageKeys
        .map(mk => t(mk))
        .reduce((a, b) => a.concat(b) + ", ", "");
    } else if (messageReducer.messages.length) {
      message = messageReducer.messageKeys.reduce(
        (a, b) => a.concat(b) + " ",
        ""
      );
    } else if (messageReducer.messageKey) {
      message = t(messageReducer.messageKey);
    } else {
      message = messageReducer.message;
    }

    if (message && message.length )
      if (messageReducer.showMessage) {
        switch (messageReducer.type) {
          case "success": {
            toastr.success(title, message);
            break;
          }
          case "info": {
            toastr.info(title, message);
            break;
          }
          case "warning": {
            toastr.warning(title, message);
            break;
          }
          case "error": {
            toastr.error(title, message);
            break;
          }
          default:
        }

        this.props.dispatch(resetMessageStore());
      }
  }

  render() {
    return (
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    );
  }
}

const mapStateToProps = store => {
  return {
    messageReducer: store.messageReducer
  };
};

export default withTranslation()(connect(mapStateToProps)(Toastr));
