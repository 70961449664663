/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

class StringInput extends Component {
  render() {
    return (
      <input
        key={this.props.filter.id}
        type="text"
        className="form-control"
        onChange={e => this.props.updateValue(e.target.value)}
      />
    );
  }
}

export default StringInput;
