import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import uuidv4 from "uuid/v4";

import { Row, Col } from "reactstrap";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";

import { isEmpty } from "utils/utils";

import { updateProductModal, updateTaxesOrder } from "redux/actions/products";
import { TaxTypeTarget } from "utils/enums";

import SelectWithLabel from "components/FormControls/SelectWithLabel";
import InputWithLabel from "components/FormControls/InputWithLabel";
import LabelField from "components/FormControls/LabelField";
import ReactTooltip from "react-tooltip";

const DragHandle = sortableHandle(() => <i className="fas fa-2x fa-bars text-default mt-4 grabbable"></i>);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const SortableItem = sortableElement(
  ({ tax, taxTypes, establishmentTaxTypes = [], taxTypeApplications = [], taxTypeTargets = [], itemIndex, isManager }) => {
    const productsReducer = useSelector((state) => state.productsReducer);

    const [forceRefresh, setForceRefresh] = useState(0);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const removeTax = () => {
      const product = productsReducer.productModal;
      product.taxes.splice(itemIndex, 1);

      dispatch(updateProductModal(product));
    };

    const updateModelTax = (value, model, field) => {
      if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

      if (
        field === "taxTypeTarget_Id" &&
        taxTypeTargets.find((ttt) => ttt.id === Number(value)).internalName === TaxTypeTarget.APPLIED_BY_PERCENTAGE
      ) {
        model.taxTypeApplication_Id = null;
      }
      model[field] = value;

      if (field === "taxType_Id") {
        let taxType = taxTypes.find((t) => t.id === value);
        model.value = taxType.defaultValue;
        model.taxTypeApplication_Id = taxType.taxTypeApplication_Id;
        model.taxTypeTarget_Id = taxType.taxTypeTarget_Id;

        setForceRefresh(forceRefresh + 1);
      }

      const product = productsReducer.productModal;

      dispatch(updateProductModal(product));
    };

    const isEnglish = t("locale") === "en";
    const referenceName = isEnglish ? "nameEnglish" : "nameFrench";

    return (
      <Col md="12" className="">
        <Row>
          <Col md="1">{isManager && <DragHandle />}</Col>

          <Col md={"3"}>
            <SelectWithLabel
              filterable
              labelText={t("taxType_Id")}
              selectPlaceholder={t("taxType_Id_placeholder")}
              data={taxTypes}
              textField={referenceName}
              disabled={!isManager}
              valueField="id"
              value={tax.taxType_Id}
              objectToUpdate={tax}
              updateAction={updateModelTax}
              fieldToUpdate="taxType_Id"
            />
            <InputWithLabel
              labelText={t("is_taxable")}
              inputType="checkbox"
              fieldToUpdate="isTaxable"
              disabled={!isManager}
              objectToUpdate={tax}
              inputNamePrefix={tax.uniqueIdentifier || tax.id}
              persistOnBlur
              updateAction={updateModelTax}
            />
          </Col>

          <Col md={"3"}>
            {/* {tax.isPercentage ? ( */}
            <InputWithLabel
              key={forceRefresh}
              labelText={t("value")}
              groupAppend={
                tax.taxTypeTarget_Id &&
                taxTypeTargets.length > 0 &&
                taxTypeTargets.find((ttt) => tax.taxTypeTarget_Id === ttt.id).internalName === TaxTypeTarget.APPLIED_BY_PERCENTAGE
                  ? "%"
                  : "$"
              }
              isDecimal
              inputType="number"
              fieldToUpdate="value"
              objectToUpdate={tax}
              disabled={!isManager || (taxTypes.find((tt) => tt.id === tax.taxType_Id) || {}).isFixed}
              persistOnBlur
              updateAction={updateModelTax}
            />
            {/* ) : (
             <CurrencyInput value={tax.value} onBlur={e => updateModelTax(e, tax, "value")} className="form-control text-right" />
           )}*/}
          </Col>
          <Col md={"4"}>
            <Row>
              <Col md="6">
                <div className="form-group">
                  <label className="form-control-label">{t("payment_target")}</label>

                  {taxTypeTargets.map((ttt) => (
                    <div className="form-check" key={`${ttt.id}-${tax.id || tax.uniqueIdentifier}`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`taxTypeTarget_Id-${ttt.id}-${tax.id || tax.uniqueIdentifier}`}
                        name={`taxTypeTarget_Id-${tax.id || tax.uniqueIdentifier}`}
                        value={ttt.id}
                        checked={tax.taxTypeTarget_Id === ttt.id}
                        disabled={!isManager || (taxTypes.find((tt) => tt.id === tax.taxType_Id) || {}).isFixed}
                        onChange={(e) => updateModelTax(Number(e.target.value), tax, "taxTypeTarget_Id")}
                      />
                      <label className="form-check-label" htmlFor={`taxTypeTarget_Id-${ttt.id}-${tax.id || tax.uniqueIdentifier}`}>
                        {ttt[referenceName]}
                      </label>
                    </div>
                  ))}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-control-label">{t("payment_calculation")}</label>

                  {taxTypeApplications.map((tta) => (
                    <div className="form-check" key={`${tta.id}-${tax.uniqueIdentifier}`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`taxTypeApplication_Id-${tta.id}-${tax.uniqueIdentifier}`}
                        name={`taxTypeApplication_Id-${tax.uniqueIdentifier}`}
                        value={tta.id}
                        checked={tax.taxTypeApplication_Id === tta.id}
                        onChange={(e) => updateModelTax(Number(e.target.value), tax, "taxTypeApplication_Id")}
                        disabled={
                          !isManager ||
                          (taxTypes.find((tt) => tt.id === tax.taxType_Id) || {}).isFixed ||
                          !tax.taxTypeTarget_Id ||
                          (tax.taxTypeTarget_Id &&
                            taxTypeTargets.find((ttt) => ttt.id === tax.taxTypeTarget_Id).internalName ===
                              TaxTypeTarget.APPLIED_BY_PERCENTAGE)
                        }
                      />
                      <label className="form-check-label" htmlFor={`taxTypeApplication_Id-${tta.id}-${tax.uniqueIdentifier}`}>
                        <ReactTooltip id={`tt_has_taxTypeApplication-tooltip-${tta.id}`} type="info" effect="solid" place="top" border />
                        {tta[referenceName]}
                        <i
                          className="fas fa-info-circle text-info ml-1"
                          data-tip={isEnglish ? tta.extraInformationEnglish : tta.extraInformationFrench}
                          data-for={`tt_has_taxTypeApplication-tooltip-${tta.id}`}
                        ></i>
                      </label>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="1">
            <button className="mt-125 btn btn-danger float-right" onClick={(_) => removeTax()} disabled={!isManager}>
              <span className="icon icon-circle-with-minus" />
            </button>
          </Col>
        </Row>
      </Col>
    );
  }
);

export default function TaxSection({ taxTypes = [], taxTypeApplications = [], taxTypeTargets = [], establishmentTaxTypes = [] }) {
  const productsReducer = useSelector((state) => state.productsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const addTax = () => {
    const product = productsReducer.productModal;
    product.taxes = product.taxes || [];

    var newTaxType = taxTypes[0];

    product.taxes.push({
      product_Id: product.id,
      taxType_Id: newTaxType.id,
      uniqueIdentifier: uuidv4(),
      order: product.taxes.length > 0 ? Math.max(...product.taxes.map((tt) => tt.order || 0)) + 1 : 0,
      value: newTaxType.defaultValue,
    });

    dispatch(updateProductModal(product));
  };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    productsReducer.productModal.taxes.splice(newIndex, 0, productsReducer.productModal.taxes.splice(oldIndex, 1)[0]);
    productsReducer.productModal.taxes.forEach((t, index) => (t.order = index));
    dispatch(updateTaxesOrder(productsReducer.productModal.taxes));
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  let taxes = productsReducer.productModal.taxes || [];
  taxes = taxes.sort((a, b) => a.order - b.order);

  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  let validEstablishmentTaxTypes = establishmentTaxTypes.filter((ett) =>
    ett.productType_Ids.some((cid) => productsReducer.productModal.productType_Ids.some((pmpt) => Number(pmpt.id) === Number(cid)))
  );

  return (
    <div className="card mt-2 zIndex-3">
      <div className="card-body">
        {validEstablishmentTaxTypes.length === 0 && (
          <>
            <div className="float-right">
              <button className="btn btn-success " onClick={addTax} disabled={!accountReducer.isManager}>
                <span className="icon icon-circle-with-plus" />
              </button>
            </div>
            <h5 className="card-title">{t("taxes")} </h5>

            <hr className="clearfix" />
          </>
        )}
        <div className="card-text">
          {validEstablishmentTaxTypes.length > 0 && (
            <>
              <h5 className="card-title">{t("establishment_taxes")}</h5>
              <hr />
            </>
          )}
          {validEstablishmentTaxTypes.map((ett) => (
            <Col md="12" className="" key={ett.id}>
              <Row>
                <Col md="1"></Col>

                <Col md={"3"}>
                  <LabelField labelText={t("taxType_Id")} value={taxTypes.find((tt) => tt.id === ett.taxType_Id)[referenceName]} />
                </Col>

                <Col md={"3"}>
                  <LabelField
                    labelText={t("value")}
                    value={`${ett.value} ${
                      taxTypeTargets.find((tta) => tta.id === ett.taxTypeTarget_Id).internalName === TaxTypeTarget.APPLIED_BY_PERCENTAGE
                        ? "%"
                        : "$"
                    }`}
                  />
                </Col>
                <Col md={"4"}>
                  <Row>
                    <Col md="6">
                      <LabelField
                        labelText={t("payment_target")}
                        value={taxTypeTargets.find((ttt) => ttt.id === ett.taxTypeTarget_Id)[referenceName]}
                      />
                    </Col>

                    {ett.taxTypeApplication_Id ? (
                      <Col md="6">
                        <LabelField
                          labelText={t("payment_calculation")}
                          value={taxTypeApplications.find((tta) => tta.id === ett.taxTypeApplication_Id)[referenceName]}
                        />
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Col>
          ))}
          {validEstablishmentTaxTypes.length > 0 && (
            <>
              <hr />
              <div className="float-right">
                <button className="btn btn-success " onClick={addTax} disabled={!accountReducer.isManager}>
                  <span className="icon icon-circle-with-plus" />
                </button>
              </div>
              <h5 className="card-title">{t("product_taxes")}</h5>

              <hr />
            </>
          )}
          {taxes && taxes.length > 0 && taxTypes.length > 0 && taxTypeTargets.length > 0 && taxTypeApplications.length > 0 && (
            <SortableContainer onSortEnd={handleSortEnd} useDragHandle>
              {taxes.map((tax, index) => (
                <SortableItem
                  key={`tax-${tax.uniqueIdentifier}`}
                  index={index}
                  itemIndex={index}
                  isManager={accountReducer.isManager}
                  tax={tax}
                  taxTypes={taxTypes}
                  establishmentTaxTypes={validEstablishmentTaxTypes}
                  taxTypeTargets={taxTypeTargets}
                  taxTypeApplications={taxTypeApplications}
                />
              ))}
            </SortableContainer>
          )}
        </div>
      </div>
    </div>
  );
}
