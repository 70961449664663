export const remarksReducer = (
  state = {
    remarks: [],
    remarksForSelect: [],
    productRemarks: [],

    updating: false,
    updated: false,
    error: null,

    showModal: false,

    count: 0,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_REMARKS_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_REMARKS_FOR_SELECT_PENDING": {
      state = { ...state, selectUpdating: true };
      break;
    }

    case "UPDATE_REMARKS_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_REMARKS_FULFILLED": {
      state = {
        ...state,
        updating: false,
        updated: true,
        remarks: action.payload.items,
        count: action.payload.count
      };
      break;
    }
    case "GET_REMARKS_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_REMARK_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_REMARK_FULFILLED": {
      let remarks = state.remarks;
      remarks = remarks.filter(s => s.id !== action.payload.id);
      remarks.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        remarks
      };
      break;
    }
    case "GET_REMARKS_SELECT_FULFILLED": {
      state = {
        ...state,
        selectUpdating: false,
        updated: true,
        remarksForSelect: action.payload
      };
      break;
    }
    case "GET_REMARK_REJECTED": {
      state = { ...state, selectUpdating: false, error: action.payload };
      break;
    }
    case "PERSISTING_REMARK": {
      state = { ...state, updating: true, persisting: true };

      break;
    }
    case "PERSISTED_REMARK": {
      let remarks = state.remarks;

      if (action.payload.isNew) {
        remarks.forEach(d => {
          if (d.id === 0) {
            d.id = action.payload.remark.id;
          }
        });
      }
      state = { ...state, remarks, updating: false, persisting: false };

      break;
    }
    case "UPDATE_REMARK": {
      let remarks = state.remarks;
      remarks.forEach(d => {
        if (d.id === action.payload.id) {
          d = Object.assign(action.payload, {});
        }
      });
      state = { ...state, remarks, updating: false };

      break;
    }
    case "GET_REMARKS_FOR_PRODUCT_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_REMARKS_PRODUCT_FULFILLED": {
      state = { ...state, updating: false, productRemarks: action.payload };
      break;
    }

    case "SHOW_REMARK_MODAL": {
      state = { ...state, showModal: true };
      break;
    }

    case "HIDE_REMARK_MODAL": {
      state = { ...state, showModal: false };
      break;
    }

    default: {
    }
  }

  return state;
};

export default remarksReducer;
