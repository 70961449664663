import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Currency from "react-currency-formatter";

// actions

// components

// others
import {
  getInvoicesToSync,
  persistInvoiceRevision,
  showDeleteInvoiceConfirmationModal,
  showInvoiceChangeDateModal,
  showSyncInvoiceConfirmationModal,
} from "redux/quickbooks/invoice/invoice-action";
import ReactTooltip from "react-tooltip";
import DeleteInvoiceRevisionModal from "./DeleteInvoiceRevisionModal";
import SyncInvoiceRevisionModal from "./SyncInvoiceRevisionModal";
import InvoiceChangeDateModal from "./InvoiceChangeDateModal";
import { useState } from "react";
import SelectWithLabel from "components/FormControls/SelectWithLabel";
import Spinner from "components/Spinner/Spinner";

function QuickbooksInvoiceSynchronizations() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const invoiceReducer = useSelector((state) => state.invoiceReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const { invoicesToSynchronize, pushingInvoice, gettingInvoiceToSynchronize } = invoiceReducer;
  const { isManager, availableGroups } = accountReducer;
  const [selectedGroupId, setSelectedGroupId] = useState(accountReducer.userGroupId);

  useEffect(() => {
    dispatch(getInvoicesToSync(selectedGroupId));
  }, [selectedGroupId, dispatch]);

  const handleUpdateInvoiceRevision = (invoiceRevision, value, name) => {
    invoiceRevision[name] = value;

    dispatch(persistInvoiceRevision(invoiceRevision));
  };

  const handleOpenDeleteConfirmationModal = (e, invoice) => {
    e.stopPropagation();
    dispatch(showDeleteInvoiceConfirmationModal(invoice));
  };

  const handleOpenSyncConfirmationModal = (e, invoice) => {
    e.stopPropagation();
    dispatch(showSyncInvoiceConfirmationModal(invoice));
  };

  const handleOpenInvoiceChangeDateModal = (e, invoice) => {
    e.stopPropagation();
    dispatch(showInvoiceChangeDateModal(invoice));
  };

  return (
    isManager && (
      <React.Fragment>
        <DeleteInvoiceRevisionModal />
        <SyncInvoiceRevisionModal />
        <InvoiceChangeDateModal />
        <div className="dashhead mt-4">
          <div className="dashhead-titles">
            <h6 className="dashhead-subtitle">{t("overview")}</h6>
            <h2 className="dashhead-title">{t("invoices_to_sync")}</h2>
            <SelectWithLabel
              filterable
              clearable
              labelText={t("usergroup")}
              selectPlaceholder={t("usergroup")}
              data={availableGroups}
              textField={"name"}
              valueField="userGroup_Id"
              value={selectedGroupId}
              objectToUpdate={selectedGroupId}
              updateAction={setSelectedGroupId}
              fieldToUpdate="userGroup_Id"
            />
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="card-text">
              <table className={`table`}>
                <thead>
                  <tr>
                    <td>{t("number")}</td>
                    <td>{t("agency_name")}</td>
                    <td>{t("invoice_amount")}</td>
                    <td>{t("departure")}</td>
                    <td>{t("departure_date")}</td>
                    <td>{t("sync_date_asked")}</td>
                    <td>{t("transport_percentage")}</td>
                    <td>{t("retries")}</td>
                    <td>{t("valid")}</td>
                    <td>{t("invoice_actions")}</td>
                  </tr>
                </thead>
                <tbody>
                  {gettingInvoiceToSynchronize ? (
                    <tr>
                      <td colspan="10" style={{ textAlign: "center" }}>
                        <Spinner />
                      </td>
                    </tr>
                  ) : !invoicesToSynchronize || invoicesToSynchronize.length === 0 ? (
                    <tr>
                      <td colspan="10" style={{ textAlign: "center" }}>
                        {t("no_records")}
                      </td>
                    </tr>
                  ) : (
                    invoicesToSynchronize.map((invoice) => (
                      <tr
                        key={invoice.formattedInvoiceNumber}
                        className={
                          invoice.isOnHold
                            ? "grayBackground"
                            : invoice.synchronizationRetryCount > 0 || moment(invoice.toBeSynchronizedByDateTime) < moment()
                            ? "error-background"
                            : ""
                        }
                      >
                        <td>
                          <b>
                            {invoice.formattedInvoiceNumber}
                            {invoice.isSupplement ? " (S)" : " (R)"}
                          </b>
                        </td>
                        <td>{invoice.agencyName}</td>
                        <td>
                          <Currency quantity={invoice.balance || 0} currency={invoice.currencyCode} />
                        </td>
                        <td>{invoice.departureName}</td>
                        <td>{moment(invoice.departureDate).format("LL")}</td>
                        <td>{moment(invoice.toBeSynchronizedByDateTime).format("LL")}</td>
                        <td>{invoice.transportPercentage ?? "N/A"}</td>
                        <td>{invoice.synchronizationRetryCount}</td>
                        <td>
                          {invoice.isQuickbooksValid && !invoice.quickbooksSynchronizationProhibited ? (
                            <span className="fas fa-check-circle" style={{ color: "green" }} />
                          ) : invoice.quickbooksSynchronizationProhibited ? (
                            <React.Fragment>
                              <ReactTooltip id="tt-invoice-already-synched" type="info" effect="solid" place="top" border />
                              <span
                                data-tip={t("invoice_already_synched")}
                                data-for="tt-invoice-already-synched"
                                className="fas fa-exclamation-circle"
                                style={{ color: "red" }}
                              />
                            </React.Fragment>
                          ) : !invoice.transportPercentage && invoice.transportPercentage !== 0 ? (
                            <React.Fragment>
                              <ReactTooltip id="tt-invoice-transport-invalid" type="info" effect="solid" place="top" border />
                              <span
                                data-tip={t("invoice_is_invalid_transport")}
                                data-for="tt-invoice-transport-invalid"
                                className="fas fa-exclamation-circle"
                                style={{ color: "red" }}
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <ReactTooltip id="tt-invoice-invalid" type="info" effect="solid" place="top" border />
                              <span
                                data-tip={t("invoice_is_invalid")}
                                data-for="tt-invoice-invalid"
                                className="fas fa-exclamation-circle"
                                style={{ color: "red" }}
                              />
                            </React.Fragment>
                          )}
                        </td>
                        <td>
                          <button
                            title="Synchronize"
                            onClick={(e) => handleOpenSyncConfirmationModal(e, invoice)} //onClick={() => handleForcePushQuickbooks(invoice.id)}
                            disabled={
                              !invoice.isQuickbooksValid ||
                              invoice.isOnHold ||
                              pushingInvoice ||
                              invoice.quickbooksSynchronizationProhibited
                            }
                          >
                            <span className="fas fa-sync" style={{ fontSize: "22px" }} />
                          </button>
                          <button
                            title={invoice.isOnHold ? "Resume" : "Hold"}
                            onClick={() => handleUpdateInvoiceRevision(invoice, !invoice.isOnHold, "isOnHold")}
                            disabled={!invoice.isQuickbooksValid || pushingInvoice || invoice.quickbooksSynchronizationProhibited}
                          >
                            {invoice.isOnHold ? (
                              <span className="fas fa-play-circle" style={{ fontSize: "22px", marginLeft: "5px" }} />
                            ) : (
                              <span className="fas fa-pause-circle" style={{ fontSize: "22px", marginLeft: "5px" }} />
                            )}
                          </button>
                          <button
                            title="Date"
                            onClick={(e) => handleOpenInvoiceChangeDateModal(e, invoice)}
                            disabled={
                              !invoice.isQuickbooksValid ||
                              pushingInvoice ||
                              invoice.quickbooksSynchronizationProhibited ||
                              !invoice.isSupplement
                            }
                          >
                            <span style={{ fontSize: "22px", marginLeft: "5px" }} className="fas fa-calendar-alt" />
                          </button>
                          <button title="Delete" onClick={(e) => handleOpenDeleteConfirmationModal(e, invoice)} disabled={pushingInvoice}>
                            <span style={{ fontSize: "22px", marginLeft: "5px" }} className="fas fa-trash-alt" />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  );
}

export default QuickbooksInvoiceSynchronizations;
