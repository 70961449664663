import axios from "axios";
import { HOST } from "config/config";

export function getComparators() {
  return (dispatch, getState) => {
    if (!getState().comparatorsReducer.pending) {
      return dispatch({
        type: "GET_COMPARATORS_PENDING",
        payload: axios
          .get(`${HOST}/api/filters/comparators`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_COMPARATOR_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message
              }
            })
          )
      });
    }
  };
}
