import React, { useEffect, useCallback } from "react";
import { HOST } from "config/config";

import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";

import { Row, Col } from "reactstrap";
import { setActiveStep } from "redux/actions/contracts";
import { updateSteps, addContractFile, removeContractFile } from "redux/actions/contracts";
import { isEmpty } from "utils/utils";

import ReactTooltip from "react-tooltip";
import InputWithLabel from "components/FormControls/InputWithLabel";
import SelectWithLabel from "components/FormControls/SelectWithLabel";
import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";
import getClassNameForExtension from "font-awesome-filetypes";

import DatePickerWithLabel from "components/FormControls/DatePickerWithLabel";

export default function GeneralInformation({ update, validator, t, steps, contract, isEstablishmentContract, companies = [] }) {
  const dispatch = useDispatch();
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const categoriesReducer = useSelector((state) => state.categoriesReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = function (ev) {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        var uint8View = new Uint8Array(binaryStr);
        dispatch(addContractFile(contract, file, uint8View));
      };
      reader.readAsArrayBuffer(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    let currentStep = steps.findIndex((s) => s.name === "general-information");
    if (contractsReducer.activeStepIndex !== currentStep) dispatch(setActiveStep(currentStep));
  }, [contractsReducer.activeStepIndex, contractsReducer.steps, dispatch, steps]);

  const handleUpdateSteps = (value, contract, field, isBlurring) => {
    if ((isEmpty(contract[field]) && isEmpty(value)) || contract[field] === value) return;

    contract[field] = value;

    dispatch(updateSteps(contract));

    update(value, contract, field, isBlurring);
  };

  const getIconClassName = (file) => {
    let extension = file.fileName.split(".").pop();
    return getClassNameForExtension(extension);
  };

  const handleDeleteFile = (e, contract, file) => {
    e.stopPropagation();
    dispatch(removeContractFile(contract, file));
  };

  const referenceName = t("locale") === "en" ? "nameEnglish" : "nameFrench";
  const labelName = t("locale") === "en" ? "labelEnglish" : "labelFrench";
  const isAccommodationCategory =
    contract.category_Id &&
    (categoriesReducer.categories.find((cat) => cat.id === contract.category_Id) || {}).internalName === "ACCOMMODATION";

  const isTransportCategory =
    contract.category_Id &&
    (categoriesReducer.categories.find((cat) => cat.id === contract.category_Id) || {}).internalName === "TRANSPORT";

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h2 className="dashhead-title">{t("general_information")}</h2>
        </div>
      </div>

      <Row className="mt-2">
        <Col md="12">
          <h4>
            <i>{contract.finalContractNameFrench}</i>
          </h4>
        </Col>

        <Col md="6" className="mt-2">
          <DatePickerWithLabel
            labelText={t("dateStart")}
            objectToUpdate={contract}
            fieldToUpdate={`start`}
            updateAction={update}
            dateFormat={t("date_format")}
            locale={t("locale")}
            disabled={!accountReducer.isManager}
            persistOnBlur
            hasValidator
            errorMessage={validator.message(t("dateStart"), contract.start, "required|date_format")}
          />
        </Col>
        <Col md="6" className="mt-2">
          <DatePickerWithLabel
            labelText={t("dateEnd")}
            objectToUpdate={contract}
            fieldToUpdate={`end`}
            updateAction={update}
            dateFormat={t("date_format")}
            locale={t("locale")}
            disabled={!accountReducer.isManager}
            persistOnBlur
            hasValidator
            errorMessage={validator.message(t("dateEnd"), contract.end, "required|date_format")}
          />
        </Col>
        <Col md="12">
          <InputWithLabel
            labelText={t("name")}
            inputType="text"
            fieldToUpdate="nameFrench"
            objectToUpdate={contract}
            persistOnBlur
            updateAction={(value, contract, field, isBlurring) => {
              update(value, contract, field, isBlurring);
              update(value, contract, "nameEnglish", isBlurring);
            }}
            disabled={!accountReducer.isManager}
            hasValidator
            errorMessage={validator.message(t("nameFrench"), contract.nameFrench, `required`)}
          />
        </Col>
        {isEstablishmentContract && (
          <React.Fragment>
            <Col md="6">
              <SelectWithLabel
                filterable
                labelText={t("category_Id")}
                selectPlaceholder={t("category_Id_placeholder")}
                data={categoriesReducer.categories}
                textField={referenceName}
                valueField="id"
                value={contract.category_Id}
                objectToUpdate={contract}
                updateAction={update}
                disabled={!accountReducer.isManager || contract.hasProductPrice}
                fieldToUpdate="category_Id"
                hasValidator
                errorMessage={validator.message(t("category_Id"), contract.category_Id, `required`)}
              />
            </Col>
            <Col md="6">
              {companies.length ? (
                <MultiSelectWithLabel
                  labelText={t("companies")}
                  id="contracts-companies"
                  selectPlaceholder={t("companies_placeholder")}
                  objectToUpdate={contract}
                  updateAction={update}
                  fieldToUpdate="company_Ids"
                  textField={labelName}
                  disabled={!accountReducer.isManager}
                  valueField="id"
                  value={contract.company_Ids}
                  data={companies}
                  hasValidator
                  errorMessage={validator.message(t("companies"), contract.company_Ids, `required`)}
                />
              ) : null}
            </Col>

            {(isAccommodationCategory || isTransportCategory) && (
              <Col md={"6"}>
                <InputWithLabel
                  labelText={t("child_age")}
                  inputType="text"
                  fieldToUpdate="childAge"
                  disabled={!accountReducer.isManager}
                  objectToUpdate={contract}
                  persistOnBlur
                  updateAction={update}
                  hasValidator
                  errorMessage={validator.message(t("child_age"), contract.childAge, `required`)}
                />
              </Col>
            )}
          </React.Fragment>
        )}

        <Col md="12" className="mt-3">
          <InputWithLabel
            labelText={t("isActive")}
            inputType="checkbox"
            fieldToUpdate="isActive"
            objectToUpdate={contract}
            disabled={!accountReducer.isManager}
            persistOnBlur
            updateAction={update}
          />
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <hr />
          <h5 className="mb-4">{t("contract_type")}</h5>
          {contract.hasProductPrice && (
            <div className={"alert alert-warning"}>
              <i
                className="fas fa-info-circle text-warning mr-1"
                data-tip={t("has_age_categories_tooltip")}
                data-for="tt_has_age_categories"
              ></i>
              {t("contract_type_disabled_info")}
            </div>
          )}

          <Row>
            <Col md="6">
              <InputWithLabel
                labelText={t("has_contract_periods")}
                inputType="checkbox"
                fieldToUpdate="hasContractPeriods"
                objectToUpdate={contract}
                persistOnBlur
                updateAction={handleUpdateSteps}
                disabled={!accountReducer.isManager || contract.hasSpecificProjectDepartures === true || contract.hasProductPrice}
                appendtooltip={
                  <>
                    <ReactTooltip id="tt_has_contract_periods" type="info" effect="solid" place="top" border />
                    <i
                      className="fas fa-info-circle text-info ml-1"
                      data-tip={t("has_contract_periods_tooltip")}
                      data-for="tt_has_contract_periods"
                    ></i>
                  </>
                }
              />
            </Col>
            <Col md="6">
              <InputWithLabel
                labelText={t("has_pricing_by_days")}
                inputType="checkbox"
                fieldToUpdate="hasPriceByDays"
                objectToUpdate={contract}
                persistOnBlur
                updateAction={handleUpdateSteps}
                disabled={!accountReducer.isManager || contract.hasProductPrice}
                appendtooltip={
                  <>
                    <ReactTooltip id="tt_has_price_by_days" type="info" effect="solid" place="top" border />
                    <i
                      className="fas fa-info-circle text-info ml-1"
                      data-tip={t("has_price_by_days_tooltip")}
                      data-for="tt_has_price_by_days"
                    ></i>
                  </>
                }
              />
            </Col>

            <Col md="6">
              <InputWithLabel
                labelText={t("has_pricing_by_group")}
                inputType="checkbox"
                fieldToUpdate="hasGroups"
                objectToUpdate={contract}
                persistOnBlur
                updateAction={handleUpdateSteps}
                disabled={!accountReducer.isManager || contract.hasProductPrice}
                appendtooltip={
                  <>
                    <ReactTooltip id="tt_has_pricing_by_group" type="info" effect="solid" place="top" border />
                    <i
                      className="fas fa-info-circle text-info ml-1"
                      data-tip={t("has_pricing_by_group_tooltip")}
                      data-for="tt_has_pricing_by_group"
                    ></i>
                  </>
                }
              />
            </Col>
            <Col md="6">
              <InputWithLabel
                labelText={t("has_age_categories")}
                inputType="checkbox"
                fieldToUpdate="hasAgeCategories"
                objectToUpdate={contract}
                persistOnBlur
                updateAction={handleUpdateSteps}
                disabled={!accountReducer.isManager || contract.hasProductPrice}
                appendtooltip={
                  <>
                    <ReactTooltip id="tt_has_age_categories" type="info" effect="solid" place="top" border />
                    <i
                      className="fas fa-info-circle text-info ml-1"
                      data-tip={t("has_age_categories_tooltip")}
                      data-for="tt_has_age_categories"
                    ></i>
                  </>
                }
              />
            </Col>

            {/* <Col md="6">
              <InputWithLabel
                labelText={t("has_pricing_by_length")}
                inputType="checkbox"
                fieldToUpdate="hasLengths"
                objectToUpdate={contract}
                persistOnBlur
                updateAction={handleUpdateSteps}
                disabled={contract.hasProductPrice}
                appendtooltip={
                  <>
                    <ReactTooltip id="tt_has_pricing_by_length" type="info" effect="solid" place="top" border />
                    <i
                      className="fas fa-info-circle text-info ml-1"
                      data-tip={t("has_pricing_by_length_tooltip")}
                      data-for="tt_has_pricing_by_length"
                    ></i>
                  </>
                }
              />
            </Col> */}
            <Col md="6">
              <InputWithLabel
                labelText={t("has_freebies")}
                inputType="checkbox"
                fieldToUpdate="hasFreebies"
                objectToUpdate={contract}
                persistOnBlur
                updateAction={handleUpdateSteps}
                disabled={!accountReducer.isManager || contract.hasProductPrice}
                appendtooltip={
                  <>
                    <ReactTooltip id="tt_has_freebies" type="info" effect="solid" place="top" border />
                    <i className="fas fa-info-circle text-info ml-1" data-tip={t("has_freebies_tooltip")} data-for="tt_has_freebies"></i>
                  </>
                }
              />
            </Col>

            <Col md="6">
              <InputWithLabel
                labelText={t("has_specificProjectDeparture")}
                inputType="checkbox"
                fieldToUpdate="hasSpecificProjectDepartures"
                objectToUpdate={contract}
                persistOnBlur
                disabled={!accountReducer.isManager || contract.hasContractPeriods === true || contract.hasProductPrice}
                updateAction={handleUpdateSteps}
                appendtooltip={
                  <>
                    <ReactTooltip id="tt_has_specificProjectDeparture" type="info" effect="solid" place="top" border />
                    <i
                      className="fas fa-info-circle text-info ml-1"
                      data-tip={t("has_specificProjectDeparture_tooltip")}
                      data-for="tt_has_specificProjectDeparture"
                    ></i>
                  </>
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <hr />
          <h5 className="mb-4">{t("contract_additional_configurations")}</h5>

          <Row>
            <Col md="6">
              <InputWithLabel
                labelText={t("has_guide_price")}
                inputType="checkbox"
                fieldToUpdate="hasGuidePrice"
                objectToUpdate={contract}
                persistOnBlur
                updateAction={handleUpdateSteps}
                disabled={!accountReducer.isManager}
                appendtooltip={
                  <>
                    <ReactTooltip id="tt_has_guide_price" type="info" effect="solid" place="top" border />
                    <i
                      className="fas fa-info-circle text-info ml-1"
                      data-tip={t("has_guide_price_tooltip")}
                      data-for="tt_has_guide_price"
                    ></i>
                  </>
                }
              />
            </Col>
            <Col md="6">
              <InputWithLabel
                labelText={t("has_driver_price")}
                inputType="checkbox"
                fieldToUpdate="hasDriverPrice"
                objectToUpdate={contract}
                persistOnBlur
                updateAction={handleUpdateSteps}
                disabled={!accountReducer.isManager}
                appendtooltip={
                  <>
                    <ReactTooltip id="tt_has_driver_price" type="info" effect="solid" place="top" border />
                    <i
                      className="fas fa-info-circle text-info ml-1"
                      data-tip={t("has_driver_price_tooltip")}
                      data-for="tt_has_driver_price"
                    ></i>
                  </>
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <hr />
          <h5 className="mb-4">{t("contract_files")}</h5>
          <div {...getRootProps()} className={`fileuploader mt-2 mb-2 ${isDragActive ? "active" : ""}`}>
            <div id="zdrop" className="fileuploader ">
              <div id="upload-label" style={{ width: "200px" }}>
                <i className="fas fa-file-upload"></i>
                <span className="title">{isDragActive ? t("drop_files_here_active") : t("drop_files_here")}</span>
                <input {...getInputProps()} />
              </div>
            </div>
          </div>
        </Col>
        <Col md="12">
          <div className="filecontainer mt-2">
            <Row>
              {contract.contractFiles &&
                contract.contractFiles.map((contractFile) => (
                  <Col md="3" key={contractFile.fileName} className="text-center mt-2 mb-2">
                    <span className="delete-file-button text-danger pointer" onClick={(e) => handleDeleteFile(e, contract, contractFile)}>
                      <i className="fas fa-trash-alt"></i>
                    </span>
                    {contractFile.id ? (
                      <>
                        <a href={`${HOST}/api/files/download/contract-files/${contractFile.id}`} disabled={!accountReducer.isManager}>
                          <i className={`fas ${getIconClassName(contractFile)} download-icon mt-2`}></i>

                          {contractFile.fileName}
                        </a>
                      </>
                    ) : (
                      <>
                        <i className={`fas ${getIconClassName(contractFile)} download-icon mt-2`}></i>
                        <span>{contractFile.fileName}</span>
                      </>
                    )}
                  </Col>
                ))}
            </Row>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
