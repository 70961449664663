import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { hideDeleteProductModal, deleteProduct } from "redux/actions/products";

export default function DeleteProductModal() {
  const productsReducer = useSelector(state => state.productsReducer);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  

  
  const handleDeleteProduct = () => {
    dispatch(deleteProduct(productsReducer.deleteProduct)).then(() => closeModal());
  };

  const closeModal = () => {
    dispatch(hideDeleteProductModal());
  };


  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  let otherReference = isEnglish ? "nameFrench" : "nameEnglish";
  let product = productsReducer.deleteProduct;

  return (
    <Modal className="mw-100 w-50" isOpen={productsReducer.showDeleteProductModal}>
      <ModalHeader>{t("delete_product_modal_title")}</ModalHeader>
      <ModalBody>
        {t("delete_product_warning")}
        <h6 className="mt-2">
          {product && product[referenceName] && product[referenceName].length
            ? product[referenceName]
            : product
            ? product[otherReference]
            : null}
        </h6>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleDeleteProduct}>
          {t("delete")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
