export const productPriceDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    default: {
    }
  }

  return state;
};

export default productPriceDetailsReducer;
