export const providersReducer = (
  state = {
    providers: [],
    providersForSelect: [],
    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_PROVIDERS_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_PROVIDERS_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_PROVIDERS_FULFILLED": {
      state = { ...state, updating: false, updated: true, providers: action.payload.items, count: action.payload.count };
      break;
    }
    case "GET_PROVIDERS_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_PROVIDER_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_PROVIDER_FULFILLED": {
      let providers = state.providers;
      providers = providers.filter(s => s.id !== action.payload.id);
      providers.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        providers
      };
      break;
    }
    case "GET_PROVIDERS_SELECT_FULFILLED": {
      state = {
        ...state,
        updating: false,
        updated: true,
        providersForSelect: action.payload
      };
      break;
    }
    case "GET_PROVIDER_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default providersReducer;
