export default function tableDataQueryStringBuilder(tableDataParam) {
  let queryString = `currentPage=${tableDataParam.currentPage}&pageSize=${tableDataParam.pageSize}`;

  if (tableDataParam.sorts.length) {
    queryString += tableDataParam.sorts.map((s, i) => `&sorts[${i}].asc=${s.asc ? "true" : "false"}&sorts[${i}].column=${s.column}`).join();
  }

  if (tableDataParam.filters.length) {
    queryString += tableDataParam.filters
      .map((f, i) =>
        f.columnWrapper
          ? `&filters[${i}].fieldName=${f.fieldName}&filters[${i}].operator=${f.operator}&filters[${i}].fieldValue=${f.fieldValue}&filters[${i}].fieldType=${f.fieldType}&filters[${i}].columnWrapper=${f.columnWrapper}`
          : `&filters[${i}].fieldName=${f.fieldName}&filters[${i}].operator=${f.operator}&filters[${i}].fieldValue=${f.fieldValue}&filters[${i}].fieldType=${f.fieldType}`
      )
      .join("");
  }

  if (tableDataParam.establishmentId) {
    queryString += `&filters[${tableDataParam.filters.length}].fieldName=Establishment_Id&filters[${tableDataParam.filters.length}].operator={0} == {1}&filters[${tableDataParam.filters.length}].fieldValue=${tableDataParam.establishmentId}&filters[${tableDataParam.filters.length}].fieldType=string`;
  }

  if (tableDataParam.onlyActiveContract) {
    queryString += `&filters[${tableDataParam.filters.length + 1}].fieldName=IsActive&filters[${
      tableDataParam.filters.length + 1
    }].operator={0} == {1}&filters[${tableDataParam.filters.length + 1}].fieldValue=${
      tableDataParam.onlyActiveContract ? "true" : "false"
    }&filters[${tableDataParam.filters.length + 1}].fieldType=bool`;
  }

  return queryString;
}
