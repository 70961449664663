import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";

import LabelField from "components/FormControls/LabelField";

import { Button } from "reactstrap";
import { Row, Col } from "reactstrap";

import TaxSection from "views/Establishments/Components/Components/TaxSection";

import Spinner from "components/Spinner/Spinner";
import ReactTooltip from "react-tooltip";
import EstablishmentProviderCompanyInformation from "./Components/EstablishmentProviderCompanyInformation";
import InputWithLabel from "components/FormControls/InputWithLabel";
import { updateEstablishment } from "redux/actions/establishments";
import { isEmpty } from "utils/utils";

function EstablishmentInformation({ taxTypes, taxTypeApplications, taxTypeTargets, productTypes, rollback, saveBtn }) {
  const { t } = useTranslation();

  const establishmentsReducer = useSelector((state) => state.establishmentsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);
  const productsReducer = useSelector((state) => state.productsReducer);
  const remarkTypesReducer = useSelector((state) => state.remarkTypesReducer);

  const dispatch = useDispatch();
  const { match } = useReactRouter();

  useEffect(() => {}, [establishmentsReducer]);

  useEffect(() => {}, [match, dispatch]);

  if (establishmentsReducer.isUpdating || remarkTypesReducer.isUpdating || productsReducer.isUpdating) return <Spinner />;

  const establishment = establishmentsReducer.establishment;

  const handleUpdateEstablishment = (value, model, field) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;

    dispatch(updateEstablishment(model));
  };

  return (
    <React.Fragment>
      <div className="card mt-3">
        <div className="card-body">
          <div className="mt-3 text-right"></div>
          <div className="card-text">
            <ReactTooltip clickable id="tt-go-to-establishment" type="info" effect="solid" place="top" border />
            <ReactTooltip clickable id="tt-go-to-reservation" type="info" effect="solid" place="top" border />

            <div className="float-right">
              <Button color="success" onClick={saveBtn} className="mr-2">
                {t("save")}
              </Button>
              <Button color="info" onClick={rollback}>
                {t("cancel")}
              </Button>
            </div>

            <div className="float-right" style={{ marginRight: "10px" }}>
              <a
                href={`${accountReducer.northWindUrl}/Reservations#!/establishment/${establishment.id}`}
                target="_blank"
                rel="noopener noreferrer"
                data-for="tt-go-to-reservation"
                data-tip={t("go_to_reservation_nw")}
                className="float-left"
              >
                <i className="fas fa-2x fa-calendar-alt text-primary pointer" style={{ marginLeft: "10px" }}></i>
              </a>
              <a
                href={`${accountReducer.northWindUrl}/Providers#!/establishments/${establishment.id}/basicinfo`}
                target="_blank"
                rel="noopener noreferrer"
                data-for="tt-go-to-establishment"
                data-tip={t("go_to_establishment_nw")}
                className="ml-2 text-success"
              >
                <i className="fab fa-2x fa-neos"></i>
              </a>
            </div>

            <h5 className="card-title">{establishment.establishmentName}</h5>

            <hr />
            <Row>
              <Col md="12">
                <h6>{t("coordinates")}</h6>
              </Col>
              <Col md="12">
                <LabelField labelText={t("provider")} value={establishment.providerName} />
              </Col>
              <Col md="6">
                <LabelField labelText={t("address")} value={establishment.address} />
              </Col>
              <Col md="6">
                <LabelField labelText={t("postalCode")} value={establishment.postalCode} />
              </Col>
              <Col md="12">
                <InputWithLabel
                  labelText={t("isTaxExemption")}
                  inputType="checkbox"
                  disabled={!accountReducer.isManager}
                  fieldToUpdate="isTaxExemption"
                  objectToUpdate={establishment}
                  persistOnBlur
                  updateAction={handleUpdateEstablishment}
                />
              </Col>
            </Row>
            <TaxSection
              taxTypes={taxTypes}
              productTypes={productTypes}
              taxTypeApplications={taxTypeApplications}
              taxTypeTargets={taxTypeTargets}
            />
            <Row>
              {establishment.providerCompagnies &&
                establishment.providerCompagnies.length > 0 &&
                establishment.providerCompagnies
                  .sort((a, b) => (a.company_Id === accountReducer.userCompanyId ? -1 : 0))
                  .map((epc) => <EstablishmentProviderCompanyInformation key={epc.id} establishmentProviderCompany={epc} />)}
            </Row>
            <div className="mt-3 text-right">
              <Button color="success" onClick={saveBtn} className="mr-2">
                {t("save")}
              </Button>
              <Button color="info" onClick={rollback}>
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EstablishmentInformation;
