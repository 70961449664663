import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useReactRouter from "use-react-router";

import { Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import { updateProduct, persistProduct, getProduct, assignProductModal, updateProductModal } from "redux/actions/products";
import { getRemarkTypes } from "redux/actions/remark-types";
import { getProductTypes } from "redux/actions/product-types";
import { getTaxTypes } from "redux/actions/tax-types";
import { getTaxTypeApplications } from "redux/actions/tax-type-applications";

import TaxSection from "views/Products/Components/Components/TaxSection";
import RemarkSection from "views/Products/Components/Components/RemarkSection";
import GeneralInformationSection from "views/Products/Components/Components/GeneralInformationSection";
import ProductSpecificationSection from "views/Products/Components/Components/ProductSpecificationSection";
import { getTaxTypeTargets } from "redux/actions/tax-type-targets";
import ProductEvents from "./Components/ProductEvents";
import product_type_name_enum from "Enums/productType";
import { TaxTypeTarget } from "utils/enums";
import NonStandardAccomationAdditionalSection from "./Components/Components/NonStandardAccomationAdditionalSection";
import CampingAdditionalSection from "./Components/Components/CampingAdditionalSection";
import ProductAssociatedContracts from "./Components/ProductAssociatedContracts";
import Spinner from "components/Spinner/Spinner";

function Product() {
  const productsReducer = useSelector((state) => state.productsReducer);
  const establishmentsReducer = useSelector((state) => state.establishmentsReducer);
  const remarkTypesReducer = useSelector((state) => state.remarkTypesReducer);
  const taxTypesReducer = useSelector((state) => state.taxTypesReducer);
  const productTypesReducer = useSelector((state) => state.productTypesReducer);
  const taxTypeApplicationsReducer = useSelector((state) => state.taxTypeApplicationsReducer);
  const taxTypeTargetsReducer = useSelector((state) => state.taxTypeTargetsReducer);

  const dispatch = useDispatch();
  const { history, match } = useReactRouter();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const { productModal = [] } = productsReducer;
  const { productTypes = [] } = productTypesReducer;

  useEffect(() => {
    if (!productsReducer.productModal.id) {
      dispatch(getProduct(match.params.productId || match.params.id)).then((product) => {
        dispatch(assignProductModal(product));
      });
    }
    dispatch(getRemarkTypes());
    dispatch(getProductTypes());
    dispatch(getTaxTypes());
    dispatch(getTaxTypeApplications());
    dispatch(getTaxTypeTargets());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsReducer.productModal.id]);

  if (
    !productsReducer.productModal?.id === null ||
    remarkTypesReducer.updating ||
    productTypesReducer.updating ||
    taxTypesReducer.updating ||
    taxTypeApplicationsReducer.updating ||
    taxTypeTargetsReducer.updating
  )
    return <Spinner />;

  const hasSpecialAccomodationType = productTypes.some(
    (pt) =>
      productModal?.productType_Ids.map((pmpt) => Number(pmpt.id)).includes(Number(pt.id)) &&
      pt.internalName === product_type_name_enum().NON_STANDARD_ROOM
  );

  const hasCampingType = productTypes.some(
    (pt) =>
      productModal?.productType_Ids.map((pmpt) => Number(pmpt.id)).includes(Number(pt.id)) &&
      (pt.internalName === product_type_name_enum().INDIVIDUAL_CAMPING || pt.internalName === product_type_name_enum().GROUP_CAMPING)
  );

  const saveBtn = () => {
    if (!productsReducer.productModal.establishment_Id) {
      productsReducer.productModal.establishment_Id = productsReducer.establishmentId || match.params.id;
    }
    if (validate()) {
      dispatch(updateProduct(productsReducer.productModal));
      dispatch(persistProduct(productsReducer.productModal)).then(() => {
        redirectToList();
      });
    }
  };

  const validate = () => {
    const taxes = productModal.taxes;

    if (taxes && taxes.some((t) => !t.taxTypeTarget_Id)) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("tax_type_target_required"),
        },
      });
      return false;
    }

    var listTaxTypeTarget = taxTypeTargetsReducer.taxTypeTargets
      .filter((ttt) => ttt.internalName === TaxTypeTarget.APPLIED_BY_PRODUCT || ttt.internalName === TaxTypeTarget.APPLIED_BY_PERSON)
      .map((ttt) => ttt.id);

    if (taxes && taxes.filter((t) => listTaxTypeTarget.includes(t.taxTypeTarget_Id)).some((t) => !t.taxTypeApplication_Id)) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("some_tax_type_application_required"),
        },
      });

      return false;
    }

    if (hasSpecialAccomodationType) {
      let hasError = false;

      productModal.specialAccommodationQtyRoomError = false;
      productModal.specialAccommodationQtyBedError = false;
      productModal.specialAccommodationCapacityError = false;

      if (!productModal.specialAccommodationQtyRoom || productModal.specialAccommodationQtyRoom <= 0) {
        dispatch({
          type: "SHOW_MESSAGE",
          payload: {
            type: "error",
            titleKey: "error",
            message: t("special_accommodation_qty_room_required"),
          },
        });

        productModal.specialAccommodationQtyRoomError = true;
        hasError = true;
      }

      if (!productModal.specialAccommodationQtyBed || productModal.specialAccommodationQtyBed <= 0) {
        dispatch({
          type: "SHOW_MESSAGE",
          payload: {
            type: "error",
            titleKey: "error",
            message: t("special_accommodation_qty_bed_required"),
          },
        });

        productModal.specialAccommodationQtyBedError = true;
        hasError = true;
      }

      if (!productModal.specialAccommodationCapacity || productModal.specialAccommodationCapacity <= 0) {
        dispatch({
          type: "SHOW_MESSAGE",
          payload: {
            type: "error",
            titleKey: "error",
            message: t("product_capacity_required"),
          },
        });

        productModal.specialAccommodationCapacityError = true;
        hasError = true;
      }

      dispatch(updateProductModal(productModal));

      if (hasError) {
        return false;
      }
    }

    if (hasCampingType) {
      let hasError = false;

      productModal.campingQtyTentBySiteError = false;
      productModal.campingCapacityError = false;
      productModal.campingQtyAdultError = false;
      productModal.campingQtyChildrenError = false;

      if (!Number.isInteger(productModal.campingQtyTentBySite) || productModal.campingQtyTentBySite < 0) {
        dispatch({
          type: "SHOW_MESSAGE",
          payload: {
            type: "error",
            titleKey: "error",
            message: t("camping_qty_tent_by_site_required"),
          },
        });

        productModal.campingQtyTentBySiteError = true;
        hasError = true;
      }

      if (!Number.isInteger(productModal.campingCapacity) || productModal.campingCapacity < 0) {
        dispatch({
          type: "SHOW_MESSAGE",
          payload: {
            type: "error",
            titleKey: "error",
            message: t("product_capacity_required"),
          },
        });

        productModal.campingCapacityError = true;
        hasError = true;
      }

      if (!Number.isInteger(productModal.campingQtyAdult) || productModal.campingQtyAdult < 0) {
        debugger;
        dispatch({
          type: "SHOW_MESSAGE",
          payload: {
            type: "error",
            titleKey: "error",
            message: t("camping_qty_adult_required"),
          },
        });

        productModal.campingQtyAdultError = true;
        hasError = true;
      }

      if (!Number.isInteger(productModal.campingQtyChildren) || productModal.campingQtyChildren < 0) {
        debugger;
        dispatch({
          type: "SHOW_MESSAGE",
          payload: {
            type: "error",
            titleKey: "error",
            message: t("camping_qty_children_required"),
          },
        });

        productModal.campingQtyChildrenError = true;
        hasError = true;
      }

      dispatch(updateProductModal(productModal));

      if (hasError) {
        return false;
      }
    }

    return true;
  };

  const redirectToList = () =>
    history.location.pathname.includes("establishments/")
      ? history.push(`/establishments/${match.params.id}/products`)
      : history.push(`/products`);
  let establishment = establishmentsReducer.establishments.find((e) => e.id === productsReducer.productModal.establishment_Id) || {
    taxes: [],
  };

  return (
    <React.Fragment>
      <div className="dashhead mt-4 mb-3">
        <div className="dashhead-titles">
          <h2 className="dashhead-title">{t("products")}</h2>
        </div>
      </div>

      <Nav tabs>
        <NavItem>
          <NavLink style={{ cursor: "pointer" }} className={activeTab === 0 ? "active" : ""} onClick={() => setActiveTab(0)}>
            {t("product_info_tab")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink style={{ cursor: "pointer" }} className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>
            {t("product_events_tab")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink style={{ cursor: "pointer" }} className={activeTab === 2 ? "active" : ""} onClick={() => setActiveTab(2)}>
            {t("product_contracts_tab")}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="pt-2">
        <TabPane tabId={0}>
          {activeTab === 0 && (
            <>
              <div className="dashhead mt-4 mb-3">
                <div className="dashhead-titles">
                  <h6 className="dashhead-subtitle">{t("edit_or_create_product")}</h6>
                </div>
              </div>
              <GeneralInformationSection productTypes={productTypesReducer.productTypes} />

              {hasSpecialAccomodationType && <NonStandardAccomationAdditionalSection />}

              {hasCampingType && <CampingAdditionalSection />}

              <ProductSpecificationSection />
              <TaxSection
                taxTypes={taxTypesReducer.taxTypes}
                establishmentTaxTypes={establishment.taxes}
                taxTypeApplications={taxTypeApplicationsReducer.taxTypeApplications}
                taxTypeTargets={taxTypeTargetsReducer.taxTypeTargets}
              />
              {remarkTypesReducer.remarkTypes.length > 0 && <RemarkSection remarkTypes={remarkTypesReducer.remarkTypes} />}
              <div className="mt-3 text-right">
                <Button color="success" onClick={saveBtn} className="mr-2">
                  {t("save")}
                </Button>
                <Button color="info" onClick={redirectToList}>
                  {t("close")}
                </Button>
              </div>
            </>
          )}
        </TabPane>
        <TabPane tabId={1}>
          {activeTab === 1 && (
            <>
              <ProductEvents />
            </>
          )}
        </TabPane>

        <TabPane tabId={2}>
          {activeTab === 2 && (
            <>
              <ProductAssociatedContracts />
            </>
          )}
        </TabPane>
      </TabContent>
    </React.Fragment>
  );
}

export default Product;
