// First, checks if it isn't implemented yet.
if (!String.prototype.format) {
  // eslint-disable-next-line no-extend-native
  String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != "undefined" ? args[number] : match;
    });
  };
}
