/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import DropdownList from "react-widgets/lib/DropdownList";

class DropdownInput extends Component {
  render() {
    let options = this.props.options.map(item => ({
      value: item.value,
      label:
        item.labelEnglish || item.labelFrench
          ? this.props.locale === "en"
            ? item.labelEnglish
            : item.labelFrench
          : item.label
    }));

    return (
      <DropdownList
        filter="contains"
        key={this.props.filter.id}
        placeholder=""
        onChange={e => this.props.updateValue(e.value)}
        value={options.find(j => j.value === this.props.filter.value)}
        textField="label"
        valueField="value"
        data={options}
      />
    );
  }
}

export default DropdownInput;
