import React from "react";

import { useSelector, useDispatch } from "react-redux";
import useReactRouter from "use-react-router";

import { getPricingProductsContracts } from "redux/actions/contracts";
import { useEffect } from "react";
import PricingProductContractsContainer from "views/Products/Components/PricingProductContractsContainer";

export default function Pricing() {
  const dispatch = useDispatch();
  const { history, match } = useReactRouter();

  const contractsReducer = useSelector((state) => state.contractsReducer);

  useEffect(() => {
    if (contractsReducer.pricingProductIds.length > 0) dispatch(getPricingProductsContracts(contractsReducer.pricingProductIds));
  }, [dispatch, contractsReducer.pricingProductIds, contractsReducer.pricingProductIds.length]);

  const handleBack = () => {
    history.push(`/establishments/${match.params.id}/products`);
  };

  return <PricingProductContractsContainer handleBack={handleBack} showFilter/>;
}
