import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/utils";

import { updateContractLength } from "redux/actions/contracts";

import { Row, Col } from "reactstrap";
import InputWithLabel from "components/FormControls/InputWithLabel";
import SelectWithLabel from "components/FormControls/SelectWithLabel";

export default function LengthCategory({ lengthCategory, validator, index, isEstablishmentContract, removeLength, durations = [] }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateLengthCategory = (value, model, field, isBlurring) => {
    setTimeout(function () {
      if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

      model[field] = value;

      dispatch(updateContractLength(model, index, isEstablishmentContract));
    }, 0);
  };

  let referenceName = t("locale") === "en" ? "nameEnglish" : "nameFrench";
  let referenceLabelName = t("locale") === "en" ? "labelEnglish" : "labelFrench";
  return (
    <React.Fragment>
      <div className={`card mt-3 zIndex-${10 - index}`}>
        <div className="card-body">
          <h5 className="card-title">
            <button className="btn btn-danger float-right" onClick={() => removeLength(lengthCategory)}>
              <span className="fa fa-trash" />
            </button>
            {lengthCategory.value && lengthCategory.duration_Id && durations.length > 0
              ? `${lengthCategory.value} ${durations.find((d) => d.id === lengthCategory.duration_Id)[referenceLabelName]}`
              : lengthCategory[referenceName] || `${t("duration")} ${index + 1}`}
          </h5>
          <div className="card-text">
            <hr />
            <Row>
              <Col md="6">
                <InputWithLabel
                  labelText={t("duration")}
                  inputType="number"
                  fieldToUpdate="value"
                  objectToUpdate={lengthCategory}
                  persistOnBlur
                  updateAction={updateLengthCategory}
                  hasValidator
                  errorMessage={validator.message(t("duration"), lengthCategory.value, `required`)}
                />
              </Col>
              <Col md="6">
                <SelectWithLabel
                  filterable
                  labelText={t("durations")}
                  selectPlaceholder={t("durations_placeholder")}
                  data={durations}
                  textField={referenceLabelName}
                  valueField="id"
                  value={lengthCategory.duration_Id}
                  objectToUpdate={lengthCategory}
                  updateAction={updateLengthCategory}
                  fieldToUpdate="duration_Id"
                  hasValidator
                  errorMessage={validator.message(t("durations"), lengthCategory.duration_Id, `required`)}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
