import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { resetMessageStore } from "redux/actions/message";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Alert } from "reactstrap";

export default function MessageModal() {
  const messageReducer = useSelector(state => state.messageReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeModal = () => {
    dispatch(resetMessageStore());
  };

  return (
    <Modal className="mw-100 w-50" isOpen={messageReducer.showModal}>
      <ModalHeader toggle={closeModal}>{t(messageReducer.titleKey)}</ModalHeader>
      <ModalBody>
        <Alert color={messageReducer.type === "error" ? "danger" : "info"}>{t(messageReducer.message)}</Alert>
        {messageReducer.modalAdditionnalMessage ? (
          typeof messageReducer.modalAdditionnalMessage === "string" ? (
            <div dangerouslySetInnerHTML={{ __html: messageReducer.modalAdditionnalMessage }} />
          ) : (
            <ul>
              {messageReducer.modalAdditionnalMessage.map(mam => (
                <li key={mam}>{mam}</li>
              ))}
            </ul>
          )
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
