import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Alert } from "reactstrap";
import moment from "moment";
import { addDeparturesForSelect, getDeparturesForSelect } from "redux/actions/departures";

export default function ContractPriceDetailHeader({
  contractPriceDetail = {},
  contractPeriods = [],
  ageCategories = [],
  groups = [],
  hasGroups = false,
  projects,
  contractDepartureGroups = [],
  contractDayGroups = [],
  contract,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const departuresReducer = useSelector((state) => state.departuresReducer);
  const { match } = useReactRouter();

  useEffect(() => {
    if (!departuresReducer.departuresForSelect || departuresReducer.departuresForSelect.length === 0)
      dispatch(getDeparturesForSelect(Number(match.params.id)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  useEffect(() => {
    if (departuresReducer.departuresForSelect?.length > 0 && contract?.contractDepartureGroups?.length > 0) {
      const availableDepartures = departuresReducer.departuresForSelect.map((d) => d.id);
      let missingDepartureIds = [];
      contract.contractDepartureGroups.forEach((cdg) => {
        if (cdg.departure_Ids.length > 0) {
          cdg.departure_Ids.forEach((dId) => {
            if (availableDepartures.every((mdId) => mdId !== dId) && missingDepartureIds.every((mdId) => mdId !== dId)) {
              missingDepartureIds.push(dId);
            }
          });
        }
      });

      if (missingDepartureIds.length > 0) {
        dispatch(addDeparturesForSelect(missingDepartureIds));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departuresReducer.departuresForSelect?.length, contract?.contractDepartureGroups?.length]);

  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  let referenceNameContract = isEnglish ? "finalContractNameEnglish" : "finalContractNameFrench";
  let otherReferenceContract = isEnglish ? "finalContractNameFrench" : "finalContractNameEnglish";
  let otherReference = isEnglish ? "nameFrench" : "nameEnglish";
  let labelReferenceName = isEnglish ? "labelEnglish" : "labelFrench";

  let contractDepartureGroup = contractDepartureGroups.find((ac) => ac.id === contractPriceDetail.contractDepartureGroup_Id);
  let contractDayGroup = contractDayGroups.find((ac) => ac.id === contractPriceDetail.contractDayGroup_Id);

  return (
    <>
      <h5 className="card-title">
        {contract.productName} -{" "}
        {contract[referenceNameContract] && contract[referenceNameContract].length
          ? contract[referenceNameContract]
          : contract[otherReferenceContract]}
      </h5>
      <hr />
      <Row>
        {contractPeriods && contractPeriods.length > 0 && (
          <Col md="3">
            <div className="form-group ">
              <label>{t("contract_period")}</label>
              <label className="form-text mb-2">
                <h5>
                  <strong>
                    {contractPeriods.find((ac) => ac.id === contractPriceDetail.contractPeriod_Id)[referenceName] &&
                    contractPeriods.find((ac) => ac.id === contractPriceDetail.contractPeriod_Id)[referenceName].length
                      ? contractPeriods.find((ac) => ac.id === contractPriceDetail.contractPeriod_Id)[referenceName]
                      : contractPeriods.find((ac) => ac.id === contractPriceDetail.contractPeriod_Id)[otherReference]}
                  </strong>
                </h5>
              </label>
              {contractPeriods
                .find((ac) => ac.id === contractPriceDetail.contractPeriod_Id)
                .contractPeriodDates.map((cpd) => (
                  <div key={cpd.id}>
                    {`${moment(cpd.start).format(t("date_small_text_no_year"))} - ${moment(cpd.end).format(t("date_small_text_no_year"))}`}
                  </div>
                ))}
            </div>
          </Col>
        )}

        {ageCategories && ageCategories.length > 0 && (
          <Col md="3">
            <div className="form-group ">
              <label>{t("age_categories")}</label>
              <label className="form-text ">
                <h5>
                  <strong>
                    {ageCategories.find((ac) => ac.id === contractPriceDetail.ageCategory_Id)[referenceName] &&
                    ageCategories.find((ac) => ac.id === contractPriceDetail.ageCategory_Id)[referenceName].length
                      ? ageCategories.find((ac) => ac.id === contractPriceDetail.ageCategory_Id)[referenceName]
                      : ageCategories.find((ac) => ac.id === contractPriceDetail.ageCategory_Id)[otherReference]}
                  </strong>
                </h5>
              </label>
            </div>
          </Col>
        )}

        {groups && groups.length > 0 ? (
          <Col md="3">
            <div className="form-group ">
              <label>{t("groups")}</label>
              <label className={`form-control-label`}>
                <h5>
                  <strong>
                    {groups.find((ac) => ac.id === contractPriceDetail.group_Id)[referenceName] &&
                    groups.find((ac) => ac.id === contractPriceDetail.group_Id)[referenceName].length
                      ? groups.find((ac) => ac.id === contractPriceDetail.group_Id)[referenceName]
                      : groups.find((ac) => ac.id === contractPriceDetail.group_Id)[otherReference]}
                  </strong>
                </h5>
              </label>{" "}
               
            </div>
          </Col>
        ) : hasGroups && contractPriceDetail.group_Id ? (
          <Col md="3">
            <h5 className="form-group ">
              <label>{t("groups")}</label>
              <label className={`form-control-label`}>
                <h5>
                  <strong>{t("fit")}</strong>
                </h5>
              </label>
            </h5>
          </Col>
        ) : null}

        {contractDepartureGroups.length > 0 && contractDepartureGroup && (
          <Col md="3">
            <div className="form-group ">
              <label>{t("contract_departure_group")}</label>
              <label className="form-text ">
                <h5>
                  <strong>
                    {contractDepartureGroup[referenceName] && contractDepartureGroup[referenceName].length
                      ? contractDepartureGroup[referenceName]
                      : contractDepartureGroup[otherReference]}
                  </strong>
                </h5>
              </label>
            </div>
          </Col>
        )}

        {contractDayGroups.length > 0 && contractDayGroup && (
          <Col md="3">
            <div className="form-group ">
              <label>{t("contract_day_group")}</label>
              <label className="form-text ">
                <h5>
                  <strong>
                    {contractDayGroup[referenceName] && contractDayGroup[referenceName].length
                      ? contractDayGroup[referenceName]
                      : contractDayGroup[otherReference]}
                  </strong>
                </h5>
              </label>
            </div>
          </Col>
        )}
      </Row>

      {!departuresReducer.updatingDepartureForSelect &&
      contractDepartureGroups.length > 0 &&
      contractDepartureGroup &&
      departuresReducer.departuresForSelect.length ? (
        <Row>
          <Col md="12">
            <Alert type="info">
              <p>
                {departuresReducer.departuresForSelect?.length > 0 &&
                  contractDepartureGroup.departure_Ids
                    .map(
                      (departureId) =>
                        (departuresReducer.departuresForSelect.find((dfs) => dfs.id === departureId) || {})[labelReferenceName]
                    )
                    .sort(function (a, b) {
                      if (a < b) {
                        return -1;
                      }
                      if (a > b) {
                        return 1;
                      }
                      return 0;
                    })
                    .join(", ")}
              </p>
            </Alert>
          </Col>
        </Row>
      ) : null}
    </>
  );
}
