export const companiesReducer = (
  state = {
    companies: [],
    companiesForSelect: [],    

    updating: false,
    updated: false,

    selectUpdating: false,

    error: null,

    count: 0,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_COMPANIES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_COMPANIES_FOR_SELECT_PENDING": {
      state = { ...state, selectUpdating: true };
      break;
    }

    case "UPDATE_COMPANIES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_COMPANIES_FULFILLED": {
      state = {
        ...state,
        updating: false,
        updated: true,
        companies: action.payload.items,
        count: action.payload.count
      };
      break;
    }
    case "GET_COMPANIES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_COMPANY_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_COMPANY_FULFILLED": {
      let companies = state.companies;
      companies = companies.filter(s => s.id !== action.payload.id);
      companies.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        companies
      };
      break;
    }
    case "GET_COMPANIES_SELECT_FULFILLED": {
      state = {
        ...state,
        selectUpdating: false,
        updated: true,
        companiesForSelect: action.payload
      };
      break;
    }
    case "GET_COMPANY_REJECTED": {
      state = { ...state, selectUpdating: false, error: action.payload };
      break;
    }
    case "PERSISTING_COMPANY": {
      state = { ...state, updating: true, persisting: true };

      break;
    }
    case "PERSISTED_COMPANY": {
      let compagnies = state.compagnies;

      if (action.payload.isNew) {
        compagnies.forEach(d => {
          if (d.id === 0) {
            d.id = action.payload.remarkType.id;
          }
        });
      }
      state = { ...state, compagnies, updating: false, persisting: false };

      break;
    }
    case "UPDATE_COMPANY": {
      let companies = state.companies;
      companies.forEach(d => {
        if (d.id === action.payload.id) {
          d = Object.assign(action.payload, {});
        }
      });
      state = { ...state, companies, updating: false };

      break;
    }

    default: {
    }
  }

  return state;
};

export default companiesReducer;
