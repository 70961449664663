export const establishmentsReducer = (
  state = {
    establishments: [],
    establishment: {},
    establishmentsForSelect: [],
    providerEstablishments: [],

    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: [],
    },
  },
  action
) => {
  switch (action.type) {
    case "GET_ESTABLISHMENTS_SELECT_FULFILLED": {
      state = {
        ...state,
        updating: false,
        updated: true,
        establishmentsForSelect: action.payload,
      };
      break;
    }
    case "UPDATE_ESTABLISHMENT_PRODUCT": {
      let product = action.payload.product;
      let establishment = state.establishments.find((e) => e.id === product.establishment_Id);
      if (establishment) {
        if (!action.payload.isNew) {
          establishment.products.forEach((p) => {
            if (p.id === product.id) {
              p = Object.assign({}, product);
            }
          });
        } else {
          establishment.products.push(product);
        }
      }

      state = { ...state, establishments: state.establishments, establishment, updating: true };
      break;
    }
    case "GET_ESTABLISHMENTS_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_ESTABLISHMENTS_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_ESTABLISHMENTS_FULFILLED": {
      state = { ...state, updating: false, updated: true, establishments: action.payload.items, count: action.payload.count };
      break;
    }
    case "GET_ESTABLISHMENTS_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_ESTABLISHMENT_PENDING": {
      state = { ...state, updating: true, establishment: {} };
      break;
    }
    case "GET_ESTABLISHMENT_FULFILLED": {
      let establishments = state.establishments;
      establishments = establishments.filter((s) => s.id !== action.payload.id);
      establishments.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        establishments,
        establishment: action.payload,
      };
      break;
    }

    case "GET_ESTABLISHMENT_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    case "GET_ESTABLISHMENTS_BY_PROVIDER_PENDING": {
      state = { ...state, updating: true };
      break;
    }

    case "GET_ESTABLISHMENTS_BY_PROVIDER_FULFILLED": {
      state = { ...state, updating: false, providerEstablishments: action.payload };
      break;
    }

    case "UPDATE_ESTABLISHMENT_TAXES_MODEL": {
      let establishment = state.establishment;
      establishment.taxes = Object.assign(action.payload, {});

      state = {
        ...state,
        establishment,
      };
      break;
    }

    case "UPDATE_ESTABLISHMENT": {
      state = {
        ...state,
        establishment: action.payload,
      };
      break;
    }

    default: {
    }
  }

  return state;
};

export default establishmentsReducer;
