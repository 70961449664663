import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import cookie from "react-cookies";
import axios from "axios";

import { logout } from "redux/actions/account";
import { NavDropdown, Navbar, Nav } from "react-bootstrap";
import { IsProduction, IsStaging } from "config/config";

function TopNavBar() {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const accountReducer = useSelector((state) => state.accountReducer);

  const handleLogout = () => {
    dispatch(logout());
  };
  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    axios.defaults.headers.common["Accept-Language"] = language;
    cookie.save("northwind_language", language);
  };

  return (
    <Navbar className="navbar navbar-expand-sm fixed-top navbar-dark app-navbar zIndex-1050">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav style={{ marginLeft: "4rem" }} className="mr-auto">
          <NavDropdown
            title={
              <div style={{ display: "inline-block" }}>
                <span className="fas fa-home" /> {t("homeTitle")}
              </div>
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item href={`${accountReducer.northWindUrl}`}>{t("home")}</NavDropdown.Item>
            <NavDropdown.Item href={`${accountReducer.northWindUrl}/DepartureProcessing`}>{t("listCurrentFolder")}</NavDropdown.Item>
            <NavDropdown.Item href={`${accountReducer.northWindUrl}/Calendar`}>{t("calendar")}</NavDropdown.Item>
          </NavDropdown>
          <a href={`${accountReducer.northWindUrl}/Projects`} className="nav-link">
            <span className="fas fa-folder" /> {t("project")}
          </a>
          <NavDropdown
            title={
              <div style={{ display: "inline-block" }}>
                <span className="fas fa-user-clock" /> {t("reservations")}
              </div>
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item href={`${accountReducer.northWindUrl}/Reservations#!/establishment`}>{t("reservations")}</NavDropdown.Item>
            <NavDropdown.Item href={`${accountReducer.northWindUrl}/Reservations/ReservationList`}>
              {t("reservations_by_status")}
            </NavDropdown.Item>
          </NavDropdown>
          <a href={`${accountReducer.northWindUrl}/Invoicing`} className="nav-link">
            <span className="fas fa-coins" /> {t("billing")}
          </a>
          <NavDropdown
            title={
              <div style={{ display: "inline-block" }}>
                <span className="fas fa-cog" /> {t("manage")}
              </div>
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item href={`${accountReducer.northWindUrl}/Providers`}>{t("suppliers")}</NavDropdown.Item>
            <NavDropdown.Item href={`${accountReducer.northWindUrl}/Agencies`}>{t("agencies")}</NavDropdown.Item>
            <NavDropdown.Item href={`${accountReducer.northWindUrl}/Users`}>{t("user")}</NavDropdown.Item>
            <NavDropdown.Item href={`${accountReducer.northWindUrl}/ManageGuides`}>{t("guides")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title={
              <div style={{ display: "inline-block" }}>
                <span className="fas fa-chart-line" /> {t("reports")}
              </div>
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item href={`${accountReducer.northWindUrl}/Reporting/BigList`}>{t("biglist")}</NavDropdown.Item>
            <NavDropdown.Item href={`${accountReducer.northWindUrl}/Reporting/FidTransfer`}>{t("fid_transfer")}</NavDropdown.Item>
            <NavDropdown.Item href={`${accountReducer.northWindUrl}/Reporting/Operations`}>{t("operations")}</NavDropdown.Item>
            <NavDropdown.Item href={`${accountReducer.northWindUrl}/Reporting/Current`}>{t("current")}</NavDropdown.Item>
          </NavDropdown>
          <a href={`${accountReducer.northWindUrl}/PurchaseOrder`} className="nav-link">
            <span className="fas fa-file-invoice" /> {t("purchase_order")}
          </a>
        </Nav>
        <Nav className="justify-content-end">
          <ul className="nav navbar-nav float-right">
            {!IsProduction && !IsStaging && (
              <li className=" mr-2">
                <h1 className="color-pure-red ">Dev</h1>
              </li>
            )}
            {IsStaging && (
              <li className=" mr-2">
                <h1 className="color-pure-red ">Staging</h1>
              </li>
            )}
            <li className=" mr-2" style={{ marginTop: "14px" }}>
              <span className="color-white ">{accountReducer.profile.fullName}</span>
            </li>
            <li className="pointer" style={{ padding: "0.85rem" }}>
              <span
                className="color-white"
                data-toggle="tooltip"
                data-tip={t("change_language")}
                data-for="tt-language"
                onClick={() => handleChangeLanguage(t("other_language_two_letter"))}
              >
                {t("other_language")}
              </span>
            </li>
            <li className="nav-item  pointer" onClick={handleLogout}>
              <span className="nav-link active mt-1">
                <span className="color-white fas fa-2x fa-sign-out-alt" />
              </span>
            </li>
          </ul>
        </Nav>
      </Navbar.Collapse>
    </Navbar>

    /* 
   
      </div>
   

      <div className="collapse navbar-collapse" style={{ marginLeft: "4rem" }} id="navbarResponsive">
        <ul className="nav navbar-nav mr-auto">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdownHome"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                v-pre
              >
                <span className="fas fa-home" /> {t("homeTitle")}
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownHome">
                <a className="dropdown-item" href="www.google.ca" activeStyle={activeStyle}>
                  {t("home")}
                </a>
                <a className="dropdown-item" href="www.google.ca" activeStyle={activeStyle}>
                  {t("listCurrentFolder")}
                </a>
                <a className="dropdown-item" href="www.google.ca" activeStyle={activeStyle}>
                  {t("calendar")}
                </a>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={`${accountReducer.northWindUrl}/Projects`} title={t("project")}>
                <span className="fas fa-folder" /> {t("project")}
              </a>
            </li>
          <React.Fragment>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/roles"
                title=""
                data-toggle="tooltip"
                data-placement="right"
                data-container="body"
                data-original-title="Fluid layout"
              >
                <span className="fa fa-user-clock" /> {t("reservations")}
                <small className="iconav-nav-label d-md-none">{t("reservations")}</small>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/Users"
                title=""
                data-toggle="tooltip"
                data-placement="right"
                data-container="body"
                data-original-title="Fluid layout"
              >
                <span className="fas fa-coins" /> {t("billing")}
                <small className="iconav-nav-label d-md-none">{t("billing")}</small>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/priorities"
                title=""
                data-toggle="tooltip"
                data-placement="right"
                data-container="body"
                data-original-title="Fluid layout"
              >
                <span className="fas fa-cog" /> {t("manage")}
                <small className="iconav-nav-label d-md-none">{t("manage")}</small>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/territories"
                title=""
                data-toggle="tooltip"
                data-placement="right"
                data-container="body"
                data-original-title="Fluid layout"
              >
                <span className="fas fa-chart-line" /> {t("reports")}
                <small className="iconav-nav-label d-md-none">{t("reports")}</small>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/territories"
                title=""
                data-toggle="tooltip"
                data-placement="right"
                data-container="body"
                data-original-title="Fluid layout"
              >
                <span className="fas fa-file-invoice" /> {t("purchase_order")}
                <small className="iconav-nav-label d-md-none">{t("purchase_order")}</small>
              </NavLink>
            </li>
          </React.Fragment>
        </ul> 

        
    </nav>*/
  );
}

export default TopNavBar;
