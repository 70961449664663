import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useReactRouter from "use-react-router";
import { Row, Col } from "reactstrap";
import ReactTooltip from "react-tooltip";

import {
  assignContractEditModel,
  showDuplicateModal,
  showDeleteContractModal,
  resetEstablishmentContract,
  showSummaryModal,
  getEstablishmentContracts,
} from "redux/actions/contracts";
import { getCategories } from "redux/actions/categories";
import { getRemarkTypes } from "redux/actions/remark-types";
import Spinner from "components/Spinner/Spinner";
import DeleteContractModal from "views/Contracts/Components/DeleteContractModal";
import ContractDuplicateModal from "./ContractDuplicateModal";
import ContractSummaryModal from "./ContractSummaryModal";
import { getCompaniesForSelect } from "redux/actions/companies";
import TableData from "components/TableData/TableData";
import InputWithLabel from "components/FormControls/InputWithLabel";
import { HOST } from "config/config";
import { TableDataComparatorGroupEnum } from "utils/enums";

function ContractList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history, match } = useReactRouter();

  const contractsReducer = useSelector((state) => state.contractsReducer);
  const categoriesReducer = useSelector((state) => state.categoriesReducer);
  const remarkTypesReducer = useSelector((state) => state.remarkTypesReducer);
  const companiesReducer = useSelector((state) => state.companiesReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const [showActiveOnly, setShowActiveOnly] = useState(true);

  useEffect(() => {
    if (!companiesReducer.companiesForSelect || companiesReducer.companiesForSelect.length === 0) dispatch(getCompaniesForSelect());
    dispatch(getRemarkTypes());
    dispatch(getCategories());
    dispatch(getEstablishmentContracts(contractsReducer.tableDataParam, match.params.id, showActiveOnly));

    return () => {
      //console.log();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getEstablishmentContracts(contractsReducer.tableDataParam, match.params.id, showActiveOnly));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActiveOnly]);

  const addContracts = () => {
    dispatch(resetEstablishmentContract(parseInt(match.params.id, 10))).then((_) =>
      history.push(`/establishments/${match.params.id}/contracts/0`)
    );
  };

  const redirectToContractEdit = (contract) => {
    const cloneContract = JSON.parse(JSON.stringify(contract));
    dispatch(assignContractEditModel(cloneContract));
    history.push(`/establishments/${match.params.id}/contracts/${contract.id}`);
  };

  const handleDuplicateContract = (contract) => {
    dispatch(
      showDuplicateModal({
        id: contract.id,
        currentContractNameFrench: contract.nameFrench,
        currentContractNameEnglish: contract.nameEnglish,
      })
    );
  };

  const handleSummaryContract = (contract) => {
    dispatch(showSummaryModal(contract));
  };

  const handleShowDeleteContractModal = (e, contract) => {
    dispatch(showDeleteContractModal(contract));
  };

  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  let labelName = isEnglish ? "labelEnglish" : "labelFrench";
  let remarkTypes = remarkTypesReducer.remarkTypes;

  const renderRows = (value, field, isFilter) => {
    let tableDataParam = contractsReducer.tableDataParam;
    tableDataParam[field] = value;

    if (isFilter) {
      tableDataParam.currentPage = 1;
    }

    dispatch(getEstablishmentContracts(tableDataParam, match.params.id));
  };

  const updatePage = (page) => {
    renderRows(page, "currentPage");
  };
  const updatePageSize = (pageSize) => {
    renderRows(pageSize, "pageSize");
  };

  const sortColumn = (column) => {
    let tableDataParam = contractsReducer.tableDataParam;
    let existingSort = tableDataParam.sorts.find((s) => s.column === column.sortColumn);

    renderRows(
      [
        {
          column: column.sortColumn,
          asc: existingSort !== undefined ? !existingSort.asc : false,
        },
      ],
      "sorts"
    );
  };

  const filter = (filters) => {
    if (filters === undefined) {
      return renderRows([], "filters");
    }

    let formattedFilters = filters.map((f) => ({
      fieldName: f.selectedColumn.column.filterColumn,
      columnWrapper: f.selectedColumn.column.columnWrapper,
      fieldValue: f.value,
      fieldType: f.selectedColumn.column.type,
      operator: f.comparator,
    }));

    return renderRows(formattedFilters, "filters", true);
  };

  const addButton = {};
  if (accountReducer.isManager) {
    addButton.addButtonText = t("create_contract");
    addButton.addAction = () => addContracts();
  }

  return (
    <React.Fragment>
      <ContractDuplicateModal />
      <ContractSummaryModal />
      <DeleteContractModal />

      <Col style={{ display: "flex", justifyContent: "space-between", alignItems: "center", alignContent: "center" }}>
        <Col xs="6" style={{ paddingTop: "1.5rem" }}>
          <InputWithLabel
            labelText={t("show_active_only")}
            inputType="checkbox"
            fieldToUpdate="showActiveOnly"
            objectToUpdate={{ showActiveOnly }}
            persistOnBlur
            updateAction={(value) => {
              setShowActiveOnly(value);
            }}
            disabled={!accountReducer.isManager || contractsReducer.updating}
          />
        </Col>
        <Col xs="6" style={{ textAlign: "right" }}>
          <ReactTooltip id="tt-create-contract" type="info" effect="solid" place="top" border />
          <button
            style={{ margin: "auto" }}
            className="btn btn-primary button-field-equal"
            onClick={addContracts}
            data-tip={t("create_contract_tt")}
            data-for="tt-create-contract"
            disabled={!accountReducer.isManager}
          >
            <span className="icon icon-circle-with-plus" /> {t("create_contract")}
          </button>
        </Col>
      </Col>

      <TableData
        className="mb-4"
        numberOfItems={contractsReducer.count}
        pageSize={contractsReducer.tableDataParam.pageSize}
        updatePage={updatePage}
        updatePageSize={updatePageSize}
        pageSizes={[25, 50, 100, 200]}
        hideTitle
        reset={t("reset")}
        filter={t("filter")}
        sortMethod={sortColumn}
        filterMethod={filter}
        locale={t("locale")}
        hasFilter
        columns={[
          {
            title: t("name"),
            filterComparatorsName: "stringComparators",
            filterColumn: "Text",
            sortColumn: 'Label.LabelTranslations.First(Language.CultureString == "fr").Text',
            columnWrapper: `Label.{0} OR Label.LabelTranslations.First(Language.CultureString == "fr").{0}`,
            type: "string",
            comparatorGroup: TableDataComparatorGroupEnum.OnlyContainsEquals,
          },
          {
            title: t("companies"),
            filterComparatorsName: "dropdownComparators",
            columnWrapper: "Companies.Any({0})",
            filterColumn: "Company_Id",
            sortColumn: "ProductTypes.Select(ProductType.UniqueName)",
            url: `${HOST}/api/filters/companies`,
            type: "dropdown int",
            optionLabelFrench: "labelFrench",
            optionLabelEnglish: "labelEnglish",
            optionValue: "id",
          },
          {
            title: t("category_Id2"),
            filterComparatorsName: "dropdownComparators",
            filterColumn: "Category_Id",
            sortColumn: "ProductTypes.Select(ProductType.UniqueName)",
            url: `${HOST}/api/filters/categories`,
            type: "dropdown int",
            optionLabelFrench: "labelFrench",
            optionLabelEnglish: "labelEnglish",
            optionValue: "id",
          },
          {
            title: t("isActive"),
          },
          {
            title: t("actions"),
          },
        ]}
      >
        <tbody>
          {contractsReducer.updating ? (
            <tr>
              <td colSpan={7}>
                <Spinner />
              </td>
            </tr>
          ) : contractsReducer.establishmentContracts.length ? (
            contractsReducer.establishmentContracts.map((contract) => (
              <tr key={contract.id}>
                <td className="vertical-align-middle">
                  {isEnglish ? contract.finalContractNameEnglish : contract.finalContractNameFrench}
                </td>
                <td className="vertical-align-middle">
                  {companiesReducer.companiesForSelect &&
                    contract.company_Ids &&
                    contract.company_Ids.length > 0 &&
                    companiesReducer.companiesForSelect
                      .filter((c) => contract.company_Ids.includes(c.id))
                      .map((c) => c[labelName])
                      .join(", ")}
                </td>
                <td className="vertical-align-middle">
                  {(categoriesReducer.categories.length > 0 &&
                    contract.category_Id &&
                    categoriesReducer.categories
                      .filter((c) => contract.category_Id === c.id)
                      .map((c) => c[referenceName])
                      .join(", ")) || <label className="form-text">{t("noResult")}</label>}
                </td>
                <td>{contract.isActive ? <span className="icon icon-check" /> : <span className="icon icon-circle-with-cross" />}</td>

                <td>
                  <ReactTooltip id="tt-edit-contract" type="info" effect="solid" place="top" border />
                  <ReactTooltip id="tt-duplicate-contract" type="info" effect="solid" place="top" border />
                  <ReactTooltip id="tt-summary-contract" type="info" effect="solid" place="top" border />
                  <ReactTooltip id="tt-delete-contract" type="info" effect="solid" place="top" border />
                  <button
                    className="btn btn-primary"
                    onClick={() => redirectToContractEdit(contract)}
                    data-tip={t("edit_this_contract")}
                    data-for="tt-edit-contract"
                  >
                    <span className="icon icon-edit" />
                  </button>

                  <button
                    className="btn btn-primary ml-2"
                    onClick={() => handleDuplicateContract(contract)}
                    data-tip={t("duplicate_contract")}
                    data-for="tt-duplicate-contract"
                    disabled={!accountReducer.isManager}
                  >
                    <span className="icon icon-copy" />
                  </button>

                  <button
                    className="btn btn-primary ml-2"
                    onClick={() => handleSummaryContract(contract)}
                    data-tip={t("view_summary_contract")}
                    data-for="tt-summary-contract"
                  >
                    <span className="icon icon-map" />
                  </button>

                  <button
                    className="btn btn-danger ml-2"
                    onClick={(e) => handleShowDeleteContractModal(e, contract)}
                    data-tip={t("delete_contract")}
                    data-for="tt-delete-contract"
                    disabled={!accountReducer.isManager || contract.hasProductPrice}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{t("no_data_found")}</td>
            </tr>
          )}
        </tbody>
      </TableData>
    </React.Fragment>
  );
}

export default ContractList;
