import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import TableData from "components/TableData/TableData";
import Spinner from "components/Spinner/Spinner";

import ReactTooltip from "react-tooltip";

import { getEstablishments } from "redux/actions/establishments";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { TableDataComparatorGroupEnum } from "utils/enums";

function Establishments() {
  const { t } = useTranslation();
  const establishmentsReducer = useSelector((state) => state.establishmentsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEstablishments(establishmentsReducer.tableDataParam));
  }, [dispatch, establishmentsReducer.tableDataParam]);

  const renderRows = (value, field, isFilter) => {
    let tableDataParam = establishmentsReducer.tableDataParam;
    tableDataParam[field] = value;

    if (isFilter) {
      tableDataParam.currentPage = 1;
    }

    dispatch(getEstablishments(tableDataParam));
  };

  const updatePage = (page) => {
    renderRows(page, "currentPage");
  };
  const updatePageSize = (pageSize) => {
    renderRows(pageSize, "pageSize");
  };

  const sortColumn = (column) => {
    let tableDataParam = establishmentsReducer.tableDataParam;
    let existingSort = tableDataParam.sorts.find((s) => s.column === column.sortColumn);

    renderRows(
      [
        {
          column: column.sortColumn,
          asc: existingSort !== undefined ? !existingSort.asc : false,
        },
      ],
      "sorts"
    );
  };

  const filter = (filters) => {
    if (filters === undefined) {
      return renderRows([], "filters");
    }

    let formattedFilters = filters.map((f) => ({
      fieldName: f.selectedColumn.column.filterColumn,
      columnWrapper: f.selectedColumn.column.columnWrapper,
      fieldValue: f.value,
      fieldType: f.selectedColumn.column.type,
      operator: f.comparator,
    }));

    return renderRows(formattedFilters, "filters", true);
  };

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">{t("overview")}</h6>
          <h2 className="dashhead-title">{t("establishments")}</h2>
        </div>
      </div>
      <TableData
        className="mb-4"
        numberOfItems={establishmentsReducer.count}
        pageSize={establishmentsReducer.tableDataParam.pageSize}
        updatePage={updatePage}
        updatePageSize={updatePageSize}
        pageSizes={[25, 50, 100, 200]}
        hideTitle
        reset={t("reset")}
        filter={t("filter")}
        sortMethod={sortColumn}
        filterMethod={filter}
        locale={t("locale")}
        hasFilter
        quickLink={
          <a href={`${accountReducer.northWindUrl}/Providers`} target="_blank" rel="noopener noreferrer">
            <Button color="primary">
              <i className="fab  fa-neos"></i> {t("northwind_establishments")}
            </Button>
          </a>
        }
        columns={[
          {
            title: t("id"),
            filterComparatorsName: "numberComparators",
            filterColumn: "Id",
            type: "int",
          },
          {
            title: t("establishment"),
            filterComparatorsName: "stringComparators",
            filterColumn: "EstablishmentName",
            type: "string",
            selected: true,
            defaultComparator: "contains",
            comparatorGroup: TableDataComparatorGroupEnum.OnlyContainsEquals,
          },
          {
            title: t("address"),
            filterComparatorsName: "stringComparators",
            filterColumn: "Address",
            type: "string",
            comparatorGroup: TableDataComparatorGroupEnum.OnlyContainsEquals,
          },
          {
            title: t("postalCode"),
            filterComparatorsName: "stringComparators",
            filterColumn: "PostalCode",
            type: "string",
            comparatorGroup: TableDataComparatorGroupEnum.OnlyContainsEquals,
          },
          {
            title: t("provider"),
            filterComparatorsName: "stringComparators",
            filterColumn: "ProviderCompany.Provider.ProviderName",
            columnWrapper: "EstablishmentProviderCompanies.Any({0})",
            type: "string",
            comparatorGroup: TableDataComparatorGroupEnum.OnlyContainsEquals,
          },
          {
            title: t("phones"),
            filterComparatorsName: "stringComparators",
            columnWrapper: "EstablishmentProviderCompanies.Any({0})",
            filterColumn: "PhoneNumber",
            type: "string",
            comparatorGroup: TableDataComparatorGroupEnum.OnlyContainsEquals,
          },
          {
            title: t("actions"),
            className: "text-center",
          },
        ]}
      >
        <tbody>
          {establishmentsReducer.updating ? (
            <tr>
              <td colSpan={7}>
                <Spinner />
              </td>
            </tr>
          ) : establishmentsReducer.establishments.length ? (
            establishmentsReducer.establishments.map((f) => (
              <tr key={f.id}>
                <td>{f.id}</td>
                <td>{f.establishmentName}</td>
                <td>{f.address}</td>
                <td>{f.postalCode}</td>
                <td>{f.providerName}</td>
                <td>
                  {f.establishmentPhones &&
                    f.establishmentPhones.map((ep, i) => (
                      <ul key={`${ep.companyName}-${ep.phone}-${i}`}>
                        <li className="no-wrap">
                          {ep.companyName} - {ep.phone}
                        </li>
                      </ul>
                    ))}
                </td>
                <td className="text-center no-wrap" style={{ width: "140px" }}>
                  <ReactTooltip clickable id="tt-go-to-establishment" type="info" effect="solid" place="right" border />
                  <ReactTooltip clickable id="tt-edit-form" type="info" effect="solid" place="top" border />
                  <ReactTooltip clickable id="tt-go-to-reservation" type="info" effect="solid" place="top" border />
                  <div className="no-wrap">
                    <Link
                      to={`/establishments/${f.id}`}
                      data-tip={t("edit_establishment_products")}
                      data-for="tt-edit-form"
                      className="float-left"
                    >
                      <i className="fas fa-2x fa-pencil-alt text-primary pointer" style={{ marginLeft: "10px" }} />
                    </Link>
                    <a
                      href={`${accountReducer.northWindUrl}/Reservations#!/establishment/${f.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-for="tt-go-to-reservation"
                      data-tip={t("go_to_reservation_nw")}
                      className="float-left"
                    >
                      <i className="fas fa-2x fa-calendar-alt text-primary pointer" style={{ marginLeft: "10px" }}></i>
                    </a>
                    <a
                      href={`${accountReducer.northWindUrl}/Providers#!/establishments/${f.id}/basicinfo`}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-for="tt-go-to-establishment"
                      data-tip={t("go_to_establishment_nw")}
                      className="ml-2 text-success float-left"
                    >
                      <i className="fab fa-2x fa-neos"></i>
                    </a>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>Pas de données disponibles</td>
            </tr>
          )}
        </tbody>
      </TableData>
    </React.Fragment>
  );
}

export default Establishments;
