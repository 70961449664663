import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

// Actions
import { getProductTypes, getProductTypesForSelect } from "redux/actions/product-types";
import { getEstablishment, updateEstablishment, persistEstablishment } from "redux/actions/establishments";
import { getTaxTypes } from "redux/actions/tax-types";
import { getTaxTypeApplications } from "redux/actions/tax-type-applications";
import { getComparators } from "redux/actions/comparators";
import { getTaxTypeTargets } from "redux/actions/tax-type-targets";
import { getGroupTargets } from "redux/actions/group-targets";

// Components
import ProductList from "../Products/Components/ProductList";
import Product from "views/Products/Product";
import EstablishmentInformation from "views/Establishments/Components/EstablishmentInformation";
import EstablishmentContract from "views/Contracts/EstablishmentContract";
import ContractList from "views/Contracts/Components/ContractList";
import Spinner from "components/Spinner/Spinner";
import Pricing from "views/Contracts/Pricing";
import ProductPricing from "views/Products/Components/ProductPricing";
import { TaxTypeTarget } from "utils/enums";

function Establishment() {
  const { t } = useTranslation();

  const establishmentsReducer = useSelector((state) => state.establishmentsReducer);
  const productsReducer = useSelector((state) => state.productsReducer);
  const productTypesReducer = useSelector((state) => state.productTypesReducer);
  const remarkTypesReducer = useSelector((state) => state.remarkTypesReducer);
  const taxTypesReducer = useSelector((state) => state.taxTypesReducer);
  const taxTypeApplicationsReducer = useSelector((state) => state.taxTypeApplicationsReducer);
  const taxTypeTargetsReducer = useSelector((state) => state.taxTypeTargetsReducer);
  const prohibitedTerms = [
    "BUS_28_SEATS",
    "BUS_32_SEATS",
    "BUS_34_SEATS",
    "BUS_38_SEATS",
    "BUS_56_SEATS",
    "PUBLIC_BUS",
    "LIMOUSINE",
    "VEHICULE_RENTAL",
    "MINIBUS_11_PASSENGERS",
    "MINIBUS_15_PASSENGERS",
    "MINIBUS_21_PASSENGERS",
    "MINIBUS_24_PASSENGERS",
    "MINIBUS_29_PASSENGERS",
    "MINIBUS_8_PASSENGERS",
    "MINIVAN_7_PASSENGERS",
    "SEDAN",
    "STANDARD_CAR",
    "COMPACT_CAR",
    "SHARED_SHUTTLE",
    "TRAILER",
    "SUV_8_PASSENGERS",
    "COMPACT_SUV_5_PASSENGERS",
  ];

  const filteredProductTypes = productTypesReducer.productTypes.filter((x) => !prohibitedTerms.includes(x.internalName));
  const dispatch = useDispatch();
  const { match, history } = useReactRouter();
  const establishment = establishmentsReducer.establishment;

  useEffect(() => {
    dispatch(getTaxTypes());
    dispatch(getProductTypes());
    dispatch(getComparators());
    dispatch(getProductTypesForSelect());
    dispatch(getTaxTypeApplications());
    dispatch(getTaxTypeTargets());
    dispatch(getGroupTargets());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEstablishment(Number(match.params.id)));
  }, [match, dispatch]);

  const changeTab = (tab) => {
    history.push(`/establishments/${match.params.id}/${tab.toLowerCase()}`);
  };

  const saveBtn = () => {
    if (validate()) {
      dispatch(updateEstablishment(establishmentsReducer.establishment));
      dispatch(persistEstablishment(establishmentsReducer.establishment));
    }
  };

  const validate = () => {
    const taxes = establishment.taxes;

    if (taxes && taxes.some((t) => !t.taxTypeTarget_Id)) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("tax_type_target_required"),
        },
      });
      return false;
    }

    var listTaxTypeTarget = taxTypeTargetsReducer.taxTypeTargets
      .filter((ttt) => ttt.internalName === TaxTypeTarget.APPLIED_BY_PRODUCT || ttt.internalName === TaxTypeTarget.APPLIED_BY_PERSON)
      .map((ttt) => ttt.id);

    if (taxes && taxes.filter((t) => listTaxTypeTarget.includes(t.taxTypeTarget_Id)).some((t) => !t.taxTypeApplication_Id)) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("some_tax_type_application_required"),
        },
      });
      return false;
    }
    return true;
  };

  const rollback = () => {
    dispatch(getEstablishment(Number(match.params.id)));
  };

  let activeTab =
    history.location.pathname.includes("establishments/") &&
    (history.location.pathname.includes("edit") ||
      (!history.location.pathname.includes("products") && !history.location.pathname.includes("contracts")))
      ? "Establishment"
      : history.location.pathname.includes("contracts") || !history.location.pathname.includes("products")
      ? "Contracts"
      : history.location.pathname.includes("establishments/") && history.location.pathname.includes("/products")
      ? "Products"
      : // Default page
        "Products";
  if (establishmentsReducer.isUpdating || remarkTypesReducer.isUpdating || productsReducer.isUpdating) return <Spinner />;

  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">{t("edition")}</h6>
          <h2 className="dashhead-title">{t("establishment_configuration")}</h2>
          <h5 className="mt-2">{`${t("establishment")}: ${establishmentsReducer.establishment.establishmentName}`}</h5>
        </div>
      </div>
      <hr />

      <Nav tabs>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={activeTab === "Establishment" ? "active" : ""}
            onClick={() => changeTab("edit")}
          >
            {t("establishment_tab")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={activeTab === "Contracts" ? "active" : ""}
            onClick={() => changeTab("Contracts")}
          >
            {t("contracts_tab")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink style={{ cursor: "pointer" }} className={activeTab === "Products" ? "active" : ""} onClick={() => changeTab("Products")}>
            {t("products_tab")}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="pt-2">
        <TabPane tabId="Establishment">
          {activeTab === "Establishment" && (
            <Switch>
              <Route
                path="/establishments/:id"
                render={() => (
                  <EstablishmentInformation
                    taxTypes={taxTypesReducer.taxTypes}
                    taxTypeTargets={taxTypeTargetsReducer.taxTypeTargets}
                    taxTypeApplications={taxTypeApplicationsReducer.taxTypeApplications}
                    productTypes={filteredProductTypes.sort((a, b) => (a[referenceName] || "").localeCompare(b[referenceName] || ""))}
                    saveBtn={saveBtn}
                    rollback={rollback}
                  />
                )}
              />
            </Switch>
          )}
        </TabPane>
        <TabPane tabId="Contracts">
          {activeTab === "Contracts" && (
            <Switch>
              <Route path="/establishments/:id/contracts/:contractId" render={() => <EstablishmentContract />} />
              <Route path="/establishments/:id/contracts" render={() => <ContractList />} />
            </Switch>
          )}
        </TabPane>
        <TabPane tabId="Products">
          {activeTab === "Products" && (
            <Switch>
              <Route path="/establishments/:id/products/:productId/pricing" render={() => <ProductPricing />} />
              <Route path="/establishments/:id/products/price-list" render={() => <Pricing />} />
              <Route path="/establishments/:id/products/:productId" render={() => <Product />} />
              <Route
                path="/establishments/:id/products"
                render={() => (
                  <ProductList
                    products={productsReducer.establishmentProducts}
                    remarkTypes={remarkTypesReducer.remarkTypes}
                    productTypes={productTypesReducer.productTypes}
                    selectedProducts={productsReducer.establishmentSelectedProducts}
                  />
                )}
              />
            </Switch>
          )}
        </TabPane>
      </TabContent>
    </React.Fragment>
  );
}

export default Establishment;
