export const quickbooksCustomerReducer = (
  state = {
    quickbooksCustomer: [],
    gettingQuickbooksCustomer: false,
  },
  action
) => {
  switch (action.type) {
    case "GET_QUICKBOOKS_CUSTOMERS_PENDING": {
      return { ...state, gettingQuickbooksCustomer: true };
    }
    case "GET_QUICKBOOKS_CUSTOMERS_FULFILLED": {
      return { ...state, gettingQuickbooksCustomer: false, quickbooksCustomer: action.payload };
    }
    default: {
    }
  }

  return state;
};

export default quickbooksCustomerReducer;
