import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "reactstrap";

import { clearProductContractFilters, showProductContractTableView } from "redux/actions/contracts";

import { ContractPriceType } from "Enums/contractPriceType";
import AccomodationPrice from "views/Contracts/Components/ContractPriceDetails/AccomodationPrice";
import ActivityPrice from "views/Contracts/Components/ContractPriceDetails/ActivityPrice";
import RestaurationPrice from "views/Contracts/Components/ContractPriceDetails/RestaurationPrice";
import ServicePrice from "views/Contracts/Components/ContractPriceDetails/ServicePrice";
import TransportPrice from "views/Contracts/Components/ContractPriceDetails/TransportPrice";
import Spinner from "components/Spinner/Spinner";

import ProductPricingFilters from "./Components/ProductPricingFilters";
import ProductContractTableViewModal from "./Components/ProductContractTableViewModal";

export default function PricingProductContractsContainer({ handleBack, showFilter }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const contractsReducer = useSelector((state) => state.contractsReducer);
  const productsReducer = useSelector((state) => state.productsReducer);
  const establishmentsReducer = useSelector((state) => state.establishmentsReducer);

  const { updating: gettingEstablishment } = establishmentsReducer;

  const renderPriceDetail = (contract, contractPriceDetails) => {
    if (contractPriceDetails.length) {
      switch (contractPriceDetails[0].contractPriceType) {
        case ContractPriceType.ACCOMODATION:
          return <AccomodationPrice contract={contract} contractPriceDetails={contractPriceDetails} />;
        case ContractPriceType.ACTIVITY:
          return <ActivityPrice contract={contract} contractPriceDetails={contractPriceDetails} />;
        case ContractPriceType.RESTAURATION:
          return <RestaurationPrice contract={contract} contractPriceDetails={contractPriceDetails} />;
        case ContractPriceType.SERVICE:
          return <ServicePrice contract={contract} contractPriceDetails={contractPriceDetails} />;
        case ContractPriceType.TRANSPORT:
          return <TransportPrice contract={contract} contractPriceDetails={contractPriceDetails} />;
        default:
          break;
      }
    }
    return;
  };

  const clearFilter = () => {
    dispatch(clearProductContractFilters());
  };

  const handleShowProductContractTableView = () => {
    dispatch(showProductContractTableView());
  };

  const { pricingProductContracts, gettingPricingProductContracts, pricingProductContractFilters } = contractsReducer;
  const { selectUpdating } = productsReducer;

  if (!pricingProductContracts || gettingEstablishment || gettingPricingProductContracts || selectUpdating) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <ProductContractTableViewModal />
      <Row>
        <Col md="12">
          <div className="float-right mt-2">
            <button className="btn btn-primary mr-2" onClick={handleShowProductContractTableView}>
              <i className="far fa-eye"></i> {t("table_view")}
            </button>
            {showFilter && (
              <button className="btn btn-primary" onClick={clearFilter}>
                <i className="fas fa-ban"></i> {t("clear_filters")}
              </button>
            )}
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      {showFilter && <ProductPricingFilters />}

      {pricingProductContracts
        .sort((a, b) => new Date(b.end) - new Date(a.end))
        .map((contract) => {
          let filteredContractPriceDetails = contract.contractPriceDetails;

          if (showFilter) {
            let filters = pricingProductContractFilters;
            // prettier-ignore
            filteredContractPriceDetails = filteredContractPriceDetails.filter(
          filteredContractPriceDetail =>
            (!filters.contract_Ids || !filters.contract_Ids.length || filters.contract_Ids.includes(filteredContractPriceDetail.contract_Id)) &&
            (!filters.contractPeriod_Ids || !filters.contractPeriod_Ids.length || filters.contractPeriod_Ids.includes(filteredContractPriceDetail.contractPeriod_Id)) &&
            (!filters.ageCategory_Ids || !filters.ageCategory_Ids.length || filters.ageCategory_Ids.includes(filteredContractPriceDetail.ageCategory_Id)) &&
            (!filters.group_Ids || !filters.group_Ids.length || filters.group_Ids.includes(filteredContractPriceDetail.group_Id)) &&
            (!filters.contractDepartureGroup_Ids || !filters.contractDepartureGroup_Ids.length || filters.contractDepartureGroup_Ids.includes(filteredContractPriceDetail.contractDepartureGroup_Id)) &&
            (!filters.lengthCategory_Ids || !filters.lengthCategory_Ids.length || filters.lengthCategory_Ids.includes(filteredContractPriceDetail.lengthCategory_Id)) &&
            (!filters.contractDayGroup_Ids || !filters.contractDayGroup_Ids.length || filters.contractDayGroup_Ids.includes(filteredContractPriceDetail.contractDayGroup_Id))
        );
          }
          return <Row key={contract.id}>{renderPriceDetail(contract, filteredContractPriceDetails)}</Row>;
        })}

      <div className="card mt-3" style={{ "z-index": "-1" }}>
        <div className="card-body">
          <div className="card-text">
            <Row>
              <Col md="12" className="text-right mt-2">
                <Button color="danger" className="ml-2" onClick={handleBack}>
                  {t("back")}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
