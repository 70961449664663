import React from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/utils";

import ContractPeriodDate from "./ContractPeriodDate";

import { Col } from "reactstrap";
import InputWithLabel from "components/FormControls/InputWithLabel";

import { removeContractPeriodDate } from "redux/actions/contracts";
import { useSelector } from "react-redux";

function ContractPeriod({ contractPeriod, validator, index, removeContractPeriod, updateAction, contract }) {
  const { t } = useTranslation();
  const accountReducer = useSelector((state) => state.accountReducer);

  const updateContractPeriod = (value, model, field, isBlurring) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;

    updateAction(model);
  };

  const handleRemoveContractPeriodDate = (date) => {
    removeContractPeriodDate(contractPeriod, date);
  };

  return (
    <React.Fragment>
      <Col md="12">{index > 0 && <hr />}</Col>
      <Col md="10">
        <InputWithLabel
          labelText={t("name")}
          inputType="text"
          fieldToUpdate="nameFrench"
          objectToUpdate={contractPeriod}
          persistOnBlur
          updateAction={(value, period, fieldName, isBlurring) => {
            updateContractPeriod(value, period, fieldName, isBlurring);
            updateContractPeriod(value, period, "nameEnglish", isBlurring);
          }}
          disabled={!accountReducer.isManager || (contract.hasProductPrice && contractPeriod.id > 0)}
          hasValidator
          errorMessage={validator.message(t("nameFrench"), contractPeriod.nameFrench, `required`)}
        />
      </Col>
      <Col md="1" className="text-right">
        <button
          className="btn btn-danger "
          style={{ marginTop: "1.3rem" }}
          onClick={() => removeContractPeriod(contractPeriod)}
          disabled={!accountReducer.isManager}
        >
          <span className="fa fa-trash" />
        </button>
      </Col>
      {contractPeriod.contractPeriodDates &&
        contractPeriod.contractPeriodDates.map((cpd, i) => (
          <ContractPeriodDate
            key={cpd.uniqueIdentifier}
            disabled={contract.hasProductPrice && cpd.id > 0}
            contractPeriod={contractPeriod}
            date={cpd}
            isLast={i === contractPeriod.contractPeriodDates.length - 1}
            updateAction={updateAction}
            removeContractPeriodDate={handleRemoveContractPeriodDate}
            validator={validator}
          />
        ))}
    </React.Fragment>
  );
}

export default ContractPeriod;
