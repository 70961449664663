export const productTypesReducer = (
  state = {
    productTypes: [],
    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_PRODUCT_TYPES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_PRODUCT_TYPES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_PRODUCT_TYPES_FULFILLED": {
      state = { ...state, updating: false, updated: true, productTypes: action.payload.items };
      break;
    }
    case "GET_PRODUCT_TYPES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_PRODUCT_TYPE_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_PRODUCT_TYPE_FULFILLED": {
      let productTypes = state.productTypes;
      productTypes = productTypes.filter(s => s.id !== action.payload.id);
      productTypes.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        productTypes
      };
      break;
    }
    case "GET_PRODUCT_TYPES_SELECT_FULFILLED": {
      let productTypes = state.productTypes;
      action.payload.forEach(productType => {
        if (!productTypes.some(t => t.id === productType.id)) {
          productTypes.push(productType);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        productTypes: productTypes
      };
      break;
    }
    case "GET_PRODUCT_TYPE_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default productTypesReducer;
