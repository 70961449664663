import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import i18n from "i18n";
import { connect } from "react-redux";



import { Alert } from "reactstrap";

class Home extends Component {
  state = {
    showTour: false,
    steps: [
      {
        selector: "#assessment-table-todo",
        content: i18n.t("help_assessment_table_todo")
      },
      {
        selector: "#assessment-table-done",
        content: i18n.t("help_assessment_table_done")
      },
      {
        selector: ".complete-survey",
        content: i18n.t("help_complete_survey_button")
      },
      {
        selector: "#add-registering-code",
        content: i18n.t("registering_code_button_help")
      }
    ]
  };

  redirectToAssessment = assessmentCode => {
    this.props.history.push(`/assessment-completion/${assessmentCode}`);
  };

  showTour = () => {
    this.setState({
      showTour: true
    });
  };

  closeTour = () => {
    this.setState({
      showTour: false
    });
  };

  render() {
    const { t } = this.props;


    return (
      <React.Fragment>

        <Alert color="info" className="align-middle mt-2">
          <h2>{t("help_section_title")}</h2>
          <p className="mt-2">{t("use_this_section_for_help")}</p>
          <button className="btn btn-border btn-primary mt-2" onClick={() => this.showTour()}>
            {t("help")}
          </button>
        </Alert>


      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    appReducer: store.appReducer,
    accountReducer: store.accountReducer
  };
};

export default withTranslation()(connect(mapStateToProps)(Home));
