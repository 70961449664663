export const remarkTypesReducer = (
  state = {
    remarkTypes: [],
    remarkTypesForSelect: [],
    productRemarkTypes: [],

    updating: false,
    updated: false,
    error: null,

    showModal: false,

    count: 0,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_REMARK_TYPES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_REMARK_TYPES_FOR_SELECT_PENDING": {
      state = { ...state, selectUpdating: true };
      break;
    }

    case "UPDATE_REMARK_TYPES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_REMARK_TYPES_FULFILLED": {
      state = {
        ...state,
        updating: false,
        updated: true,
        remarkTypes: action.payload.items,
        count: action.payload.count
      };
      break;
    }
    case "GET_REMARK_TYPES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_REMARK_TYPE_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_REMARK_TYPE_FULFILLED": {
      let remarkTypes = state.remarkTypes;
      remarkTypes = remarkTypes.filter(s => s.id !== action.payload.id);
      remarkTypes.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        remarkTypes
      };
      break;
    }
    case "GET_REMARK_TYPES_SELECT_FULFILLED": {
      state = {
        ...state,
        selectUpdating: false,
        updated: true,
        remarkTypesForSelect: action.payload
      };
      break;
    }
    case "GET_REMARK_TYPE_REJECTED": {
      state = { ...state, selectUpdating: false, error: action.payload };
      break;
    }
    case "PERSISTING_REMARK_TYPE": {
      state = { ...state, updating: true, persisting: true };

      break;
    }
    case "PERSISTED_REMARK_TYPE": {
      let remarkTypes = state.remarkTypes;

      if (action.payload.isNew) {
        remarkTypes.forEach(d => {
          if (d.id === 0) {
            d.id = action.payload.remarkType.id;
          }
        });
      }
      state = { ...state, remarkTypes, updating: false, persisting: false };

      break;
    }
    case "UPDATE_REMARK_TYPE": {
      let remarkTypes = state.remarkTypes;
      remarkTypes.forEach(d => {
        if (d.id === action.payload.id) {
          d = Object.assign(action.payload, {});
        }
      });
      state = { ...state, remarkTypes, updating: false };

      break;
    }
    case "GET_REMARK_TYPES_FOR_PRODUCT_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_REMARK_TYPES_PRODUCT_FULFILLED": {
      state = { ...state, updating: false, productRemarkTypes: action.payload };
      break;
    }

    case "SHOW_REMARK_TYPE_MODAL": {
      state = { ...state, showModal: true };
      break;
    }

    case "HIDE_REMARK_TYPE_MODAL": {
      state = { ...state, showModal: false };
      break;
    }

    default: {
    }
  }

  return state;
};

export default remarkTypesReducer;
