import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { hideSyncInvoiceConfirmationModal, forcePushInvoice } from "redux/quickbooks/invoice/invoice-action";

export default function SyncInvoiceRevisionModal() {
  const invoiceReducer = useSelector((state) => state.invoiceReducer);

  const { showSyncInvoiceModal, syncInvoiceModalEntity } = invoiceReducer;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSyncInvoice = () => {

    dispatch(forcePushInvoice(syncInvoiceModalEntity.id)).then(() => {
      closeModal();
    });
  };

  const closeModal = () => {
    dispatch(hideSyncInvoiceConfirmationModal());
  };

  if (!showSyncInvoiceModal) return null;

  return (
    <Modal className="mw-100 w-50" isOpen={showSyncInvoiceModal}>
      <ModalHeader>{t("sync_invoice_modal_title")}</ModalHeader>
      <ModalBody>
        <h6>
          <strong>{t("sync_invoice_impact")}</strong>
        </h6>
        <div className={"mt-2"}>{t("sync_invoice_warning")}</div>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleSyncInvoice}>
          {t("confirmer")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
