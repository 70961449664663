import { combineReducers } from "redux";

import accountReducer from "redux/reducers/account";
import appReducer from "redux/reducers/app";
import daysReducer from "redux/reducers/days";
import categoriesReducer from "redux/reducers/categories";
import comparatorsReducer from "redux/reducers/comparators";
import companiesReducer from "redux/reducers/companies";
import contractsReducer from "redux/reducers/contracts";
import countriesReducer from "redux/reducers/countries";
import datesReducer from "redux/reducers/dates";
import departuresReducer from "redux/reducers/departures";
import durationsReducer from "redux/reducers/durations";
import establishmentsReducer from "redux/reducers/establishments";
import establishmentReservationTypesReducer from "redux/reducers/establishment-reservation-types";
import feeTypesReducer from "redux/reducers/fee-types";
import freebieTypeOperatorsReducer from "redux/reducers/freebie-type-operators";
import freebieTypesReducer from "redux/reducers/freebie-types";
import optionsReducer from "redux/reducers/options";
import productsReducer from "redux/reducers/products";
import remarksReducer from "redux/reducers/remarks";
import remarkTypesReducer from "redux/reducers/remark-types";
import productTypesReducer from "redux/reducers/product-types";
import projectsReducer from "redux/reducers/projects";
import providersReducer from "redux/reducers/providers";
import provincesReducer from "redux/reducers/provinces";
import salesTaxesReducer from "redux/reducers/sales-taxes";
import productPriceDetailsReducer from "redux/reducers/product-price-details";
import purchaseOrderReducer from "redux/quickbooks/purchase-order/purchase-order-reducer";
import invoiceReducer from "redux/quickbooks/invoice/invoice-reducer";
import taxesReducer from "redux/reducers/taxes";
import taxTypeApplicationsReducer from "redux/reducers/tax-type-applications";
import taxTypeTargetsReducer from "redux/reducers/tax-type-targets";
import taxTypesReducer from "redux/reducers/tax-types";
import quickbooksAccountReducer from "redux/quickbooks/quickbooks-account/quickbooks-account-reducer";
import quickbooksCustomerReducer from "redux/quickbooks/quickbooks-customer/quickbooks-customer-reducer";
import quickbooksVendorReducer from "redux/quickbooks/quickbooks-vendor/quickbooks-vendor-reducer";
import quickbooksCompanyReducer from "redux/quickbooks/quickbooks-company/quickbooks-company-reducer";
import quickbooksPaymentReducer from "redux/quickbooks/payment/payment-reducer";

import messageReducer from "redux/reducers/message";
import groupTargetsReducer from "redux/reducers/group-targets";

import { reducer as toastrReducer } from "react-redux-toastr";

export const Reducers = combineReducers({
  accountReducer,
  appReducer,
  categoriesReducer,
  companiesReducer,
  comparatorsReducer,
  contractsReducer,
  countriesReducer,
  daysReducer,
  datesReducer,
  departuresReducer,
  durationsReducer,
  establishmentsReducer,
  establishmentReservationTypesReducer,
  feeTypesReducer,
  freebieTypeOperatorsReducer,
  freebieTypesReducer,
  groupTargetsReducer,
  invoiceReducer,
  messageReducer,
  optionsReducer,
  productsReducer,
  productPriceDetailsReducer,
  productTypesReducer,
  projectsReducer,
  provincesReducer,
  providersReducer,
  purchaseOrderReducer,
  remarksReducer,
  remarkTypesReducer,
  salesTaxesReducer,
  taxesReducer,
  taxTypeApplicationsReducer,
  taxTypeTargetsReducer,
  taxTypesReducer,

  quickbooksAccountReducer,
  quickbooksCustomerReducer,
  quickbooksVendorReducer,
  quickbooksCompanyReducer,
  quickbooksPaymentReducer,

  toastr: toastrReducer,
});
