export const categoriesReducer = (
  state = {
    categories: [],
    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_CATEGORIES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_CATEGORIES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_CATEGORIES_FULFILLED": {
      state = { ...state, updating: false, updated: true, categories: action.payload.items };
      break;
    }
    case "GET_CATEGORIES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_CATEGORY_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_CATEGORY_FULFILLED": {
      let categories = state.categories;
      categories = categories.filter(s => s.id !== action.payload.id);
      categories.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        categories
      };
      break;
    }
    case "GET_CATEGORIES_SELECT_FULFILLED": {
      let categories = state.categories;
      action.payload.forEach(category => {
        if (!categories.some(t => t.id === category.id)) {
          categories.push(category);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        categories: categories
      };
      break;
    }
    case "GET_CATEGORY_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default categoriesReducer;
