import axios from "axios";
import { HOST } from "config/config";

export function getDays() {
  return (dispatch, getState) => {
    if (!getState().daysReducer.pending) {
      return dispatch({
        type: "GET_DAYS_PENDING",
        payload: axios
          .get(`${HOST}/api/filters/days`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_DAY_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message
              }
            })
          )
      });
    }
  };
}
