export const durationsReducer = (
  state = {
    error: null,
    pending: false,
    durationsForSelect: []
  },
  action
) => {
  switch (action.type) {
    case "GET_DURATIONS_PENDING": {
      state = { ...state, pending: true };

      break;
    }
    case "GET_DURATION_FULFILLED": {
      state = { ...state, durationsForSelect: action.payload, pending: false };

      break;
    }

    default: {
    }
  }

  return state;
};

export default durationsReducer;
