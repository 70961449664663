import axios from "axios";
import { HOST } from "config/config";

export function getInvoicesToSync(groupId) {
  return (dispatch) => {
    return dispatch({
      type: "GET_INVOICES_TO_SYNC_PENDING",
      payload: axios
        .get(`${HOST}/api/invoices/get-to-sync?groupId=${groupId}`)
        .then((response) => response.data)
        .then((json) =>
          dispatch({
            type: "GET_INVOICES_TO_SYNC_FULFILLED",
            payload: json,
          })
        ),
    });
  };
}

export function showDeleteInvoiceConfirmationModal(invoice) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SHOW_DELETE_INVOICE_MODAL",
        payload: invoice,
      });

      return resolve();
    });
}

export function hideDeleteInvoiceConfirmationModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_DELETE_INVOICE_MODAL",
      });

      return resolve();
    });
}

export function showInvoiceChangeDateModal(invoice) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SHOW_INVOICE_CHANGE_DATE_MODAL",
        payload: invoice,
      });

      return resolve();
    });
}

export function hideInvoiceChangeDateModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_INVOICE_CHANGE_DATE_MODAL",
      });

      return resolve();
    });
}

export function persistInvoiceRevision(invoiceRevision) {
  return (dispatch, getState) => {
    if (!getState().invoiceReducer.updatingInvoice) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "PERSISTING_INVOICE",
          payload: axios
            .put(`${HOST}/api/invoices/${invoiceRevision.id}`, invoiceRevision)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "PERSISTED_INVOICE",
                payload: {
                  invoiceRevision: json,
                },
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "saved_successful",
                },
              });
              return resolve();
            })
            .catch((error) => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response,
                },
              });
              return reject();
            }),
        })
      );
    }
  };
}

export function showSyncInvoiceConfirmationModal(invoice) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SHOW_SYNC_INVOICE_MODAL",
        payload: invoice,
      });

      return resolve();
    });
}

export function hideSyncInvoiceConfirmationModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_SYNC_INVOICE_MODAL",
      });

      return resolve();
    });
}

export function forcePushInvoice(invoiceRevisionId) {
  return (dispatch, getState) => {
    if (!getState().invoiceReducer.pushingInvoice) {
      return new Promise((resolve) =>
        dispatch({
          type: "PUSHING_INVOICE",
          payload: axios
            .post(`${HOST}/api/invoices/force-synchronize/${invoiceRevisionId}`)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "PUSHED_INVOICE",
                payload: { invoiceRevisionId: invoiceRevisionId, status: json.status },
              });

              if (json.status === "success") {
                dispatch({
                  type: "SHOW_MESSAGE",
                  payload: {
                    type: "success",
                    titleKey: "success",
                    messageKey: "activated_successfully",
                  },
                });
              } else {
                dispatch({
                  type: "SHOW_MESSAGE",
                  payload: {
                    type: "error",
                    titleKey: "error",
                    messageKey: json.message,
                  },
                });
              }

              resolve(json);
            }),
        })
      );
    }
  };
}
