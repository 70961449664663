import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "reactstrap";

import { isEmpty } from "utils/utils";

import { updateProductModal } from "redux/actions/products";
import InputWithLabel from "components/FormControls/InputWithLabel";

function NonStandardAccomationAdditionalSection(props) {
  const productsReducer = useSelector((state) => state.productsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const updateModel = (value, model, field, isBlurring) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    if (field === "deactivationDateTime") {
      if (value && value.getTime() < new Date().getTime()) {
        model.isActive = false;
      } else {
        model.isActive = true;
      }
    }

    model[field] = value;

    dispatch(updateProductModal(model));
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="card mt-2">
      <div className="card-body">
        <h5 className="card-title">{t("additional_non_standard_information")}</h5>
        <div className="card-text">
          <hr />
          <Row>
            <Col md="4">
              <InputWithLabel
                labelText={t("qty_room")}
                inputType="number"
                fieldToUpdate="specialAccommodationQtyRoom"
                objectToUpdate={productsReducer.productModal}
                persistOnBlur
                disabled={!accountReducer.isManager}
                updateAction={updateModel}
                errorMessage={productsReducer.productModal.specialAccommodationQtyRoomError ? "error" : ""}
              />
            </Col>
            <Col md="4">
              <InputWithLabel
                labelText={t("qty_bed")}
                inputType="number"
                fieldToUpdate="specialAccommodationQtyBed"
                objectToUpdate={productsReducer.productModal}
                persistOnBlur
                disabled={!accountReducer.isManager}
                updateAction={updateModel}
                errorMessage={productsReducer.productModal.specialAccommodationQtyBedError ? "error" : ""}
              />
            </Col>
            <Col md="4">
              <InputWithLabel
                labelText={t("capacity")}
                inputType="number"
                fieldToUpdate="specialAccommodationCapacity"
                objectToUpdate={productsReducer.productModal}
                persistOnBlur
                disabled={!accountReducer.isManager}
                updateAction={updateModel}
                errorMessage={productsReducer.productModal.specialAccommodationCapacityError ? "error" : ""}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default NonStandardAccomationAdditionalSection;
