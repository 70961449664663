export const taxTypesReducer = (
  state = {
    taxTypes: [],
    taxTypesForSelect: [],
    productTaxTypes: [],

    editTaxType: {},

    updating: false,
    updated: false,
    error: null,

    showModal: false,

    count: 0,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_TAX_TYPES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_TAX_TYPES_FOR_SELECT_PENDING": {
      state = { ...state, selectUpdating: true };
      break;
    }

    case "UPDATE_TAX_TYPES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_TAX_TYPES_FULFILLED": {
      state = {
        ...state,
        updating: false,
        updated: true,
        taxTypes: action.payload.items,
        count: action.payload.count
      };
      break;
    }
    case "GET_TAX_TYPES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_TAX_TYPE_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_TAX_TYPE_FULFILLED": {
      let taxTypes = state.taxTypes;
      taxTypes = taxTypes.filter(s => s.id !== action.payload.id);
      taxTypes.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        taxTypes,
        editTaxType: action.payload
      };
      break;
    }
    case "GET_TAX_TYPES_SELECT_FULFILLED": {
      state = {
        ...state,
        selectUpdating: false,
        updated: true,
        taxTypesForSelect: action.payload
      };
      break;
    }
    case "GET_TAX_TYPE_REJECTED": {
      state = { ...state, selectUpdating: false, error: action.payload };
      break;
    }
    case "PERSISTING_TAX_TYPE": {
      state = { ...state, updating: true, persisting: true };

      break;
    }
    case "PERSISTED_TAX_TYPE": {
      let taxTypes = state.taxTypes;

      if (action.payload.isNew) {
        taxTypes.forEach(d => {
          if (d.id === 0) {
            d.id = action.payload.taxType.id;
          }
        });
      }
      state = { ...state, taxTypes, updating: false, persisting: false };

      break;
    }
    case "DELETED_TAX_TYPE" :{
      let taxTypes = state.taxTypes;
      taxTypes = taxTypes.filter(s => s.id !== action.payload);

      state = {
        ...state,
        taxTypes
      };

      break;
    }
    case "UPDATE_TAX_TYPE": {
      let taxTypes = state.taxTypes;
      taxTypes.forEach(d => {
        if (d.id === action.payload.id) {
          d = Object.assign(action.payload, {});
        }
      });
      state = { ...state, taxTypes, updating: false };

      break;
    }
    case "UPDATE_EDIT_TAX_TYPE": {
      state = { ...state, editTaxType: action.payload };

      break;
    }
    case "GET_TAX_TYPES_FOR_PRODUCT_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_TAX_TYPES_PRODUCT_FULFILLED": {
      state = { ...state, updating: false, productTaxTypes: action.payload };
      break;
    }

    case "SHOW_TAX_TYPE_MODAL": {
      state = { ...state, showModal: true };
      break;
    }

    case "HIDE_TAX_TYPE_MODAL": {
      state = { ...state, showModal: false };
      break;
    }

    default: {
    }
  }

  return state;
};

export default taxTypesReducer;
