import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "reactstrap";

import { isEmpty } from "utils/utils";

import { updateProductModal } from "redux/actions/products";

import InputWithLabel from "components/FormControls/InputWithLabel";

function ProductSpecificationSection() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const productsReducer = useSelector((state) => state.productsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const updateModelSpecification = (value, model, field) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;

    const product = productsReducer.productModal;

    dispatch(updateProductModal(product));
  };

  const addSpecifications = () => {
    const product = productsReducer.productModal;
    product.productSpecifications = product.productSpecifications || [];

    product.productSpecifications.push({ product_Id: product.id, guideImpactsCapacity: true });

    dispatch(updateProductModal(product));
  };

  const removeSpecification = (index) => {
    const product = productsReducer.productModal;
    product.productSpecifications.splice(index, 1);

    dispatch(updateProductModal(product));
  };

  return (
    <div className="card mt-2 zIndex-4">
      <div className="card-body">
        <h5 className="card-title">
          {t("specifications")}
          <button className="btn btn-success float-right" onClick={addSpecifications} disabled={!accountReducer.isManager}>
            <span className="icon icon-circle-with-plus" />
          </button>
        </h5>

        <hr className="clearfix" />

        <div className="card-text">
          <Row>
            {productsReducer.productModal.productSpecifications &&
              productsReducer.productModal.productSpecifications.map((ps, index) => (
                <Col md="12" key={index}>
                  <Row>
                    <Col md="3">
                      <InputWithLabel
                        labelText={t("minimum_capacity")}
                        disabled={!accountReducer.isManager}
                        inputType="number"
                        fieldToUpdate="minimumCapacity"
                        objectToUpdate={ps}
                        persistOnBlur
                        updateAction={updateModelSpecification}
                      />
                    </Col>
                    <Col md="3">
                      <InputWithLabel
                        labelText={t("maximum_capacity")}
                        disabled={!accountReducer.isManager}
                        inputType="number"
                        fieldToUpdate="maximumCapacity"
                        objectToUpdate={ps}
                        persistOnBlur
                        updateAction={updateModelSpecification}
                      />
                    </Col>
                    <Col md="3">
                      <InputWithLabel
                        labelText={t("minimum_age")}
                        disabled={!accountReducer.isManager}
                        inputType="number"
                        fieldToUpdate="minimumAge"
                        objectToUpdate={ps}
                        persistOnBlur
                        updateAction={updateModelSpecification}
                      />
                    </Col>
                    <Col md="2" className="mt-4">
                      <InputWithLabel
                        labelText={t("guide_impacts_capacity")}
                        disabled={!accountReducer.isManager}
                        inputType="checkbox"
                        fieldToUpdate="guideImpactsCapacity"
                        objectToUpdate={ps}
                        persistOnBlur
                        updateAction={updateModelSpecification}
                      />
                    </Col>
                    <Col md="1">
                      <button
                        className="mt-125 btn btn-danger float-right"
                        onClick={(_) => removeSpecification(index)}
                        disabled={!accountReducer.isManager}
                      >
                        <span className="icon icon-circle-with-minus" />
                      </button>
                    </Col>
                  </Row>
                </Col>
              ))}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ProductSpecificationSection;
