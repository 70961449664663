import React, { Component } from "react";


import ReactTooltip from "react-tooltip";

class MenuTooltips extends Component {

  render() {
    return (
      <React.Fragment>
       
          <ReactTooltip id="tt-providers" type="info" effect="solid" place="right" border />
          <ReactTooltip id="tt-products" type="info" effect="solid" place="right" border />
          <ReactTooltip id="tt-establishments" type="info" effect="solid" place="right" border />
          <ReactTooltip id="tt-taxes" type="info" effect="solid" place="right" border />
          <ReactTooltip id="tt-sales-taxes" type="info" effect="solid" place="right" border />
         
      </React.Fragment>
    );
  }
}


export default MenuTooltips;
