import axios from "axios";
import { HOST } from "config/config";
import tableDataQueryStringBuilder from "utils/tableData";

export function getProductTypes(tableDataParam, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().productTypesReducer.updating || forceRefresh) {
      if (tableDataParam === undefined) {
        return dispatch({
          type: "GET_PRODUCT_TYPES_PENDING",
          payload: axios
            .get(`${HOST}/api/product-types`)
            .then(response => response.data)
            .then(json =>
              dispatch({
                type: "GET_PRODUCT_TYPES_FULFILLED",
                payload: {
                  items: json
                }
              })
            )
            .catch(error =>
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.message
                }
              })
            )
        });
      }

      return dispatch({
        type: "GET_PRODUCT_TYPES_PENDING",
        payload: axios
          .get(`${HOST}/api/product-types`, {
            paramsSerializer: params => tableDataQueryStringBuilder(params),
            params: tableDataParam
          })
          .then(response => response.data)
          .then(json => {
            if (tableDataParam) {
              dispatch({
                type: "UPDATE_PRODUCT_TYPES_TABLEDATAPARAM",
                payload: tableDataParam
              });
            }

            dispatch({
              type: "GET_PRODUCT_TYPES_FULFILLED",
              payload: json
            });
          })
          .catch(error =>
            dispatch({
              type: "GET_PRODUCT_TYPES_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}

export function getProductTypesForSelect() {
  return (dispatch, getState) => {
    if (!getState().productTypesReducer.updating) {
      return dispatch({
        type: "GET_PRODUCT_TYPES_PENDING",
        payload: axios
          .get(`${HOST}/api/product-types`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_PRODUCT_TYPES_SELECT_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message
              }
            })
          )
      });
    }
  };
}

export function getProductType(productTypeId, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().productTypesReducer.updating || forceRefresh) {
      if (productTypeId === 0) {
        return dispatch({
          type: "GET_PRODUCT_TYPE_FULFILLED",
          payload: {
            id: 0,
            internalName: "",
            nameFrench: "",
            nameEnglish: "",
            createdDate: new Date()
          }
        });
      }
      return dispatch({
        type: "GET_PRODUCT_TYPE_PENDING",
        payload: axios
          .get(`${HOST}/api/product-types/${productTypeId}`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_PRODUCT_TYPE_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "GET_PRODUCT_TYPE_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}

export function updateProductType(productType) {
  return dispatch =>
    dispatch({
      type: "UPDATE_PRODUCT_TYPE",
      payload: productType
    });
}

export function persistProductType(productType) {
  return (dispatch, getState) => {
    if (!getState().productTypesReducer.updating) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "PERSISTING_PRODUCT_TYPE",
          payload: (productType.id === 0
            ? axios.post(`${HOST}/api/product-types`, productType)
            : axios.put(`${HOST}/api/product-types/${productType.id}`, productType)
          )
            .then(response => response.data)
            .then(json => {
              dispatch({
                type: "PERSISTED_PRODUCT_TYPE",
                payload: {
                  productType: json,
                  isNew: productType.id === 0
                }
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "saved_successful"
                }
              });
              return resolve();
            })
            .catch(error => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response
                }
              });
              return reject();
            })
        })
      );
    }
  };
}
