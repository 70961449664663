import React from "react";

import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import LabelField from "components/FormControls/LabelField";

import moment from "moment";

export default function SummaryContractPeriods({ contract }) {
  const { t } = useTranslation();

  return (
    <div className={`card mt-3`}>
      <div className="card-body">
        <h5 className="card-title">{t("contract_periods")}</h5>
        <div className="card-text">
          {contract.contractPeriods &&
            contract.contractPeriods.map((a) => (
              <React.Fragment key={a.id || a.uniqueIdentifier}>
                <hr />
                <Row>
                  <Col md="6">
                    <LabelField labelText={t("nameFrench")} value={a.nameFrench} />
                  </Col>
                  <Col md="6">
                    <LabelField labelText={t("nameEnglish")} value={a.nameEnglish} />
                  </Col>
                </Row>

                <h5 className="mb-1">{t("dates")}</h5>

                {a.contractPeriodDates &&
                  a.contractPeriodDates.map((d) => (
                    <Row key={d.id || d.uniqueIdentifier}>
                      <Col md="6">
                        <LabelField labelText={t("from")} value={moment(d.start).format("YYYY-MM-DD")} />
                      </Col>
                      <Col md="6">
                        <LabelField labelText={t("to")} value={moment(d.end).format("YYYY-MM-DD")} />
                      </Col>
                    </Row>
                  ))}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
}
