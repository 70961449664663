import React from "react";
import useReactRouter from "use-react-router";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
  hideMergeProductModal,
  updateMergeMainProduct,
  mergePersistMergeProducts,
  getProductsForEstablishment
} from "redux/actions/products";

function MergeProductsModal({ selectedProducts = [] }) {
  const productsReducer = useSelector(state => state.productsReducer);
  const { match } = useReactRouter();

  const handleUpdateSelectedMainProduct = ({ target: { value: productId } }) => {
    productId = Number(productId);

    dispatch(updateMergeMainProduct(productId));
  };

  const saveBtn = () => {
    dispatch(
      mergePersistMergeProducts(
        productsReducer.mergeMainProductIdModal,
        productsReducer.establishmentSelectedProducts.filter(p => p.id !== productsReducer.mergeMainProductIdModal).map(p => p.id)
      )
    ).then(_ => {
      dispatch(getProductsForEstablishment(Number(match.params.id)));

      closeModal();
    });
  };

  const closeModal = () => {
    dispatch(hideMergeProductModal());
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  let otherReference = isEnglish ? "nameFrench" : "nameEnglish";

  return (
    <Modal className="mw-100 w-50" isOpen={productsReducer.showMergeProductModal}>
      <ModalHeader>{t("merge_products")}</ModalHeader>
      <ModalBody>
        <div className="alert  alert-warning">{t("merge_warning_placeholder")}</div>
        <div className="form-group">
          <label className="form-control-label">{t("select_main_product")}</label>
          {selectedProducts.map(p => (
            <div className="form-check" key={`${p.id}-${p.labelInternalName}`}>
              <input
                className="form-check-input"
                type="radio"
                id={`${p.id}-${p.labelInternalName}`}
                name={`merge-products-list`}
                value={p.id}
                defaultChecked={false}
                onClick={handleUpdateSelectedMainProduct}
              />
              <label className="form-check-label" htmlFor={`${p.id}-${p.labelInternalName}`}>
                {p[referenceName] && p[referenceName].length ? p[referenceName] : p[otherReference]}
              </label>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="success" disabled={!productsReducer.mergeMainProductIdModal} onClick={saveBtn}>
          {t("save")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default MergeProductsModal;
