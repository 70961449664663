import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";

import TableData from "components/TableData/TableData";
import Spinner from "components/Spinner/Spinner";

import ReactTooltip from "react-tooltip";

import { HOST } from "config/config";
import { getProducts } from "redux/actions/products";
import { getRemarkTypes } from "redux/actions/remark-types";
import { getProductTypes } from "redux/actions/product-types";
import { getTaxTypes } from "redux/actions/tax-types";
import { assignProductModal, activateProduct, deactivateProduct } from "redux/actions/products";

function Products() {
  const { t } = useTranslation();

  const productsReducer = useSelector((state) => state.productsReducer);
  const productTypesReducer = useSelector((state) => state.productTypesReducer);

  const dispatch = useDispatch();
  const { history } = useReactRouter();

  useEffect(() => {
    dispatch(getProducts(productsReducer.tableDataParam));
    dispatch(getProductTypes());
    dispatch(getRemarkTypes());
    dispatch(getTaxTypes());
  }, [dispatch, productsReducer.tableDataParam]);

  const redirectToProduct = (product) => {
    const cloneProduct = JSON.parse(JSON.stringify(product));
    dispatch(assignProductModal(cloneProduct));
    history.push(`/products/${product.id}`);
  };

  const renderRows = (value, field, isFilter) => {
    let tableDataParam = productsReducer.tableDataParam;
    tableDataParam[field] = value;

    if (isFilter) {
      tableDataParam.currentPage = 1;
    }

    dispatch(getProducts(tableDataParam));
  };

  const updatePage = (page) => {
    renderRows(page, "currentPage");
  };
  const updatePageSize = (pageSize) => {
    renderRows(pageSize, "pageSize");
  };

  const sortColumn = (column) => {
    let tableDataParam = productsReducer.tableDataParam;
    let existingSort = tableDataParam.sorts.find((s) => s.column === column.sortColumn);

    renderRows(
      [
        {
          column: column.sortColumn,
          asc: existingSort !== undefined ? !existingSort.asc : false,
        },
      ],
      "sorts"
    );
  };

  const filter = (filters) => {
    if (filters === undefined) {
      return renderRows([], "filters");
    }

    let formattedFilters = filters.map((f) => ({
      fieldName: f.selectedColumn.column.filterColumn,
      columnWrapper: f.selectedColumn.column.columnWrapper,
      fieldValue: f.value,
      fieldType: f.selectedColumn.column.type,
      operator: f.comparator,
    }));

    return renderRows(formattedFilters, "filters", true);
  };

  const redirectToEstablishment = (establishmentId) => {
    history.push(`/establishments/${establishmentId}`);
  };

  const handleActivate = (product) => {
    dispatch(activateProduct(product));
  };

  const handleDeactivate = (product) => {
    dispatch(deactivateProduct(product));
  };

  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">{t("overview")}</h6>
          <h2 className="dashhead-title">{t("products")}</h2>
        </div>
      </div>

      <TableData
        className="mb-4"
        numberOfItems={productsReducer.count}
        pageSize={productsReducer.tableDataParam.pageSize}
        updatePage={updatePage}
        updatePageSize={updatePageSize}
        pageSizes={[25, 50, 100, 200]}
        hideTitle
        reset={t("reset")}
        filter={t("filter")}
        sortMethod={sortColumn}
        filterMethod={filter}
        locale={t("locale")}
        hasFilter
        columns={[
          {
            title: t("status"),
            className: "text-center",
            filterComparatorsName: "booleanComparators",
            filterColumn: "isActive",
            type: "bool",
          },
          {
            title: t("nameFrench"),
          },
          {
            title: t("nameEnglish"),
          },
          {
            title: t("name"),
            filterComparatorsName: "stringComparators",
            filterColumn: "Text",
            type: "string",
            columnWrapper: `Label.LabelTranslations.Any(Language.CultureString == "en" AND {0})`,
            hidden: true,
          },
          {
            title: t("city"),
            filterComparatorsName: "stringComparators",
            filterColumn: "Establishment.City.Name",
            type: "string",
            hidden: true,
          },
          {
            title: t("product_type"),
            filterComparatorsName: "dropdownComparators",
            columnWrapper: "ProductTypes.Any({0})",
            filterColumn: "ProductType_Id",
            sortColumn: "ProductTypes.Select(ProductType.UniqueName)",
            url: `${HOST}/api/product-types`,
            type: "dropdown int",
            optionLabelFrench: "nameFrench",
            optionLabelEnglish: "nameEnglish",
            optionValue: "id",
          },
          {
            title: t("establishment"),
            filterComparatorsName: "stringComparators",
            filterColumn: "Establishment.EstablishmentName",
            type: "string",
          },
          {
            title: t("actions"),
          },
        ]}
      >
        <tbody>
          {productsReducer.updating || !(productTypesReducer.productTypes && productTypesReducer.productTypes.length) ? (
            <tr>
              <td colSpan={6}>
                <Spinner />
              </td>
            </tr>
          ) : productsReducer.products.length ? (
            productsReducer.products.map((p) => (
              <tr key={p.id}>
                <td className="text-center">
                  <ReactTooltip clickable id="tt-deactive" type="info" effect="solid" place="left" border />
                  <ReactTooltip clickable id="tt-active" type="info" effect="solid" place="left" border />
                  {p.isActive ? (
                    <i
                      className="fas fa-2x fa-power-off text-success pointer"
                      data-tip={t("deactivate")}
                      data-for="tt-deactive"
                      onClick={() => handleDeactivate(p)}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-2x fa-power-off text-danger pointer"
                      data-tip={t("activate")}
                      data-for="tt-active"
                      onClick={() => handleActivate(p)}
                    ></i>
                  )}
                </td>
                <td>{p.nameFrench}</td>
                <td>{p.nameEnglish}</td>
                <td>
                  {productTypesReducer.productTypes
                    .filter((pt) => p.productType_Ids && p.productType_Ids.some((pti) => pti.id === pt.id))
                    .map((pt) => pt[referenceName])
                    .join(", ")}
                </td>
                <td>{p.establishmentName}</td>
                <td className="nowrap">
                  <React.Fragment>
                    <ReactTooltip clickable id="tt-edit-form" type="info" effect="solid" place="left" border />

                    <i
                      data-tip={t("edit_product")}
                      data-for="tt-edit-form"
                      onClick={() => redirectToProduct(p)}
                      style={{ marginLeft: "10px" }}
                      className="fas fa-2x fa-pencil-alt text-primary pointer"
                    />
                    <ReactTooltip clickable id="tt-go-to-establishment" type="info" effect="solid" place="top" border />
                    <i
                      className="fas fa-2x fa-building text-primary pointer ml-2"
                      onClick={() => redirectToEstablishment(p.establishment_Id)}
                      data-tip={t("go_to_establishment")}
                      data-for="tt-go-to-establishment"
                    ></i>

                    {/* <ReactTooltip clickable id="tt-delete-form" type="info" effect="solid" place="right" border />
                      <i
                        data-tip={t("delete_form")}
                        data-for="tt-delete-form"
                        onClick={() => redirectToProducts(p.id)}
                        style={{ marginLeft: "10px" }}
                        className="far fa-2x fa-trash-alt text-danger pointer"
                      /> */}
                  </React.Fragment>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{t("no_data_found")}</td>
            </tr>
          )}
        </tbody>
      </TableData>
    </React.Fragment>
  );
}

export default Products;
