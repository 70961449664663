export const salesTaxesReducer = (
  state = {
    salesTaxes: [],
    salesTaxesForSelect: [],
    productsalesTaxes: [],

    editSalesTax: {},

    updating: false,
    updated: false,
    error: null,

    showModal: false,

    count: 0,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_SALES_TAXES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_SALES_TAXES_FOR_SELECT_PENDING": {
      state = { ...state, selectUpdating: true };
      break;
    }

    case "UPDATE_SALES_TAXES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_SALES_TAXES_FULFILLED": {
      state = {
        ...state,
        updating: false,
        updated: true,
        salesTaxes: action.payload.items,
        count: action.payload.count
      };
      break;
    }
    case "GET_SALES_TAXES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_SALES_TAX_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_SALES_TAX_FULFILLED": {
      let salesTaxes = state.salesTaxes;
      if (action.payload.id !== 0) {
        salesTaxes = salesTaxes.filter(s => s.id !== action.payload.id);
        salesTaxes.push(action.payload);
      }
      state = {
        ...state,
        updating: false,
        updated: true,
        salesTaxes,
        editSalesTax: action.payload
      };
      break;
    }
    case "GET_SALES_TAXES_SELECT_FULFILLED": {
      state = {
        ...state,
        selectUpdating: false,
        updated: true,
        salesTaxesForSelect: action.payload
      };
      break;
    }
    case "GET_SALES_TAX_REJECTED": {
      state = { ...state, selectUpdating: false, error: action.payload };
      break;
    }
    case "PERSISTING_SALES_TAX_PENDING": {
      state = { ...state, updating: true, persisting: true };

      break;
    }
    case "PERSISTED_SALES_TAX_FULFILLED": {
      let salesTaxes = state.salesTaxes;

      if (action.payload.isNew) {
        salesTaxes.forEach(d => {
          if (d.id === 0) {
            d.id = action.payload.salesTax.id;
          }
        });
      }
      state = { ...state, salesTaxes, updating: false, persisting: false };

      break;
    }
    case "DELETED_SALES_TAX": {
      let salesTaxes = state.salesTaxes;
      salesTaxes = salesTaxes.filter(s => s.id !== action.payload);

      state = {
        ...state,
        salesTaxes
      };

      break;
    }
    case "UPDATE_SALES_TAX": {
      let salesTaxes = state.salesTaxes;
      salesTaxes.forEach(d => {
        if (d.id === action.payload.id) {
          d = Object.assign(action.payload, {});
        }
      });
      state = { ...state, salesTaxes, updating: false };

      break;
    }
    case "UPDATE_EDIT_SALES_TAX": {
      state = { ...state, editSalesTax: action.payload };

      break;
    }
    case "GET_SALES_TAXES_FOR_PRODUCT_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_SALES_TAXES_PRODUCT_FULFILLED": {
      state = { ...state, updating: false, productsalesTaxes: action.payload };
      break;
    }

    case "SHOW_SALES_TAX_MODAL": {
      state = { ...state, showModal: true };
      break;
    }

    case "HIDE_SALES_TAX_MODAL": {
      state = { ...state, showModal: false };
      break;
    }

    case "ACTIVATE_SALES_TAX_FULFILLED": {
      let salesTax = action.payload;
      let salesTaxFromReducer = state.salesTaxes.find(p => p.id === salesTax.id);
      salesTaxFromReducer.isActive = salesTax.isActive;

      state = { ...state };
      break;
    }

    case "DEACTIVATE_SALES_TAX_FULFILLED": {
      let salesTax = action.payload;
      let salesTaxFromReducer = state.salesTaxes.find(p => p.id === salesTax.id);
      salesTaxFromReducer.isActive = salesTax.isActive;

      state = { ...state };
      break;
    }

    default: {
    }
  }

  return state;
};

export default salesTaxesReducer;
