import React from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Row, Col } from "reactstrap";
import LabelField from "components/FormControls/LabelField";

export default function SummaryAgeCategories({ contract }) {
  const { t } = useTranslation();

  const comparatorsReducer = useSelector((state) => state.comparatorsReducer);

  const isEnglish = t("locale") === "en";
  const referenceLabel = isEnglish ? "labelEnglish" : "labelFrench";

  let validOperators = comparatorsReducer.comparatorsForSelect.filter((c) => c.comparatorTypeName === "int");

  return (
    <div className={`card mt-3`}>
      <div className="card-body">
        <h5 className="card-title">{t("age_category")}</h5>
        <div className="card-text">
          {contract.ageCategories &&
            contract.ageCategories.map((a) => (
              <React.Fragment key={a.id}>
                <hr />
                <Row>
                  <Col md="6">
                    <LabelField labelText={t("nameFrench")} value={a.nameFrench} />
                  </Col>
                  <Col md="6">
                    <LabelField labelText={t("nameEnglish")} value={a.nameEnglish} />
                  </Col>
                </Row>

                <h5 className="mb-1">{t("criterias")}</h5>

                {a.criterias &&
                  a.criterias.map((c) => (
                    <Row key={c.id}>
                      <Col md="6">
                        {c.comparator_Id && (
                          <LabelField
                            labelText={t("age_criteria_operators")}
                            value={validOperators.find((o) => o.id === c.comparator_Id)[referenceLabel]}
                          />
                        )}
                      </Col>
                      <Col md="6">
                        <LabelField labelText={t("value")} value={c.value} />
                      </Col>
                    </Row>
                  ))}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
}
