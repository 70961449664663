import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { deleteAssociatedProductContract, hideDeleteAssociatedProductContractModal } from "redux/actions/products";

export default function DeleteAssociatedProductContractModal() {
  const productsReducer = useSelector((state) => state.productsReducer);

  const { showDeleteAssociatedProductContractModal, deleteAssociatedProductContractModalEntity } = productsReducer;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteProduct = () => {
    dispatch(deleteAssociatedProductContract(deleteAssociatedProductContractModalEntity.id)).then(() => {
      closeModal();
    });
  };

  const closeModal = () => {
    dispatch(hideDeleteAssociatedProductContractModal());
  };

  const isEnglish = t("locale") === "en";
  const namePropertyName = isEnglish ? "finalContractNameEnglish" : "finalContractNameFrench";

  if (!showDeleteAssociatedProductContractModal) return null;

  return (
    <Modal className="mw-100 w-50" isOpen={showDeleteAssociatedProductContractModal}>
      <ModalHeader>{t("delete_associated_product_contract_modal_title")}</ModalHeader>
      <ModalBody>
        <h6>
          <strong>{t("delete_associated_product_contract_impact")}</strong>
        </h6>
        <div className={"mt-2"}>{t("delete_associated_product_contract_warning")}</div>
        <h6 className="mt-2">
          {deleteAssociatedProductContractModalEntity && deleteAssociatedProductContractModalEntity[namePropertyName]}
        </h6>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleDeleteProduct}>
          {t("delete")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
