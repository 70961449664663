import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LeftNavBar = () => {
  const { t } = useTranslation();

  return (
    <nav className="iconav" id="nav">
      <NavLink className="iconav-brand" to="/" style={{ height: "55px", backgroundColor: "#00386c !important" }}></NavLink>
      <div className="iconav-slider">
        <ul className="nav nav-pills iconav-nav flex-md-column">
          <li className="nav-item">
            <NavLink className="nav-link white-color" data-tip={t("providers")} data-for="tt-providers" to="/providers">
              <i className="fab fa-wpforms"></i>
              <small className="iconav-nav-label d-md-none">{t("form")}</small>
            </NavLink>

            <NavLink className="nav-link white-color" data-tip={t("establishments")} data-for="tt-establishments" to="/establishments">
              <i className="fas fa-building"></i>
              <small className="iconav-nav-label d-md-none">{t("establishments")}</small>
            </NavLink>

            <NavLink exact className="nav-link" data-tip={t("products")} data-for="tt-products" to="/products">
              <i className="fab fa-product-hunt"></i>
              <small className="iconav-nav-label d-md-none">{t("product")}</small>
            </NavLink>

            <NavLink exact className="nav-link" data-tip={t("sales_taxes")} data-for="tt-sales-taxes" to="/sales-taxes">
              <i className="fas fa-money-check-alt"></i>
              <small className="iconav-nav-label d-md-none">{t("sales_taxes")}</small>
            </NavLink>

            <NavLink exact className="nav-link" data-tip={t("taxes")} data-for="tt-taxes" to="/tax-types">
              <i className="fas fa-money-bill"></i>
              <small className="iconav-nav-label d-md-none">{t("taxes")}</small>
            </NavLink>

            <NavLink exact className="nav-link" data-tip={t("quickbooks_companies")} data-for="tt-taxes" to="/quickbooks-companies">
              <i className="fas fa-file-invoice"></i>
              <small className="iconav-nav-label d-md-none">{t("quickbooks_companies")}</small>
            </NavLink>

            <NavLink
              exact
              className="nav-link"
              data-tip={t("purchase_orders_to_sync")}
              data-for="tt-taxes"
              to="/quickbooks-sync-purchase-orders"
            >
              <i className="fas fa-sync"></i>
              <small className="iconav-nav-label d-md-none">{t("purchase_orders_to_sync")}</small>
            </NavLink>

            <NavLink exact className="nav-link" data-tip={t("invoices_to_sync")} data-for="tt-taxes" to="/quickbooks-sync-invoices">
              <i className="fas fa-file-export"></i>
              <small className="iconav-nav-label d-md-none">{t("invoices_to_sync")}</small>
            </NavLink>

            <NavLink exact className="nav-link" data-tip={t("quickbooks_vendors")} data-for="tt-taxes" to="/quickbooks-vendors">
              <i className="fas fa-building"></i>
              <small className="iconav-nav-label d-md-none">{t("quickbooks_vendors")}</small>
            </NavLink>

            <NavLink exact className="nav-link" data-tip={t("quickbooks_customers")} data-for="tt-taxes" to="/quickbooks-customers">
              <i className="fas fa-building"></i>
              <small className="iconav-nav-label d-md-none">{t("quickbooks_customers")}</small>
            </NavLink>

            {/* 
            {quickbooksState === QuickbooksState.CONNECTED && (
              <>
                <NavLink exact className="nav-link" data-tip={t("quickbooks_accounts")} data-for="tt-taxes" to="/quickbooks-accounts">
                  <i className="fas fa-file-invoice"></i>
                  <small className="iconav-nav-label d-md-none">{t("quickbooks_accounts")}</small>
                </NavLink>
                <NavLink exact className="nav-link" data-tip={t("quickbooks_customers")} data-for="tt-taxes" to="/quickbooks-customers">
                  <i className="fas fa-building"></i>
                  <small className="iconav-nav-label d-md-none">{t("quickbooks_customers")}</small>
                </NavLink>
               
              </>
            )} */}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default LeftNavBar;
