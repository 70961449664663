import React from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Row, Col } from "reactstrap";
import LabelField from "components/FormControls/LabelField";

import { Multiselect } from "react-widgets";

export default function SummaryPriceByDays({ contract }) {
  const { t } = useTranslation();

  const daysReducer = useSelector((state) => state.daysReducer);

  const isEnglish = t("locale") === "en";
  const referenceLabel = isEnglish ? "labelEnglish" : "labelFrench";

  return (
    <div className={`card mt-3`}>
      <div className="card-body">
        <h5 className="card-title">{t("specific_days")}</h5>
        <div className="card-text">
          {contract.contractDayGroups &&
            contract.contractDayGroups.map((a) => (
              <React.Fragment key={`${a.uniqueIdentifier || a.id}-${contract.id}`}>
                <hr />
                <Row>
                  <Col md="6">
                    <LabelField labelText={t("nameFrench")} value={a.nameFrench} />
                  </Col>
                  <Col md="6">
                    <LabelField labelText={t("nameEnglish")} value={a.nameEnglish} />
                  </Col>
                </Row>

                <h5 className="mb-1">{t("criterias")}</h5>

                {a.day_Ids && a.day_Ids.length > 0 && (
                  <Row>
                    <Col md="12">
                      <Multiselect
                        id="days"
                        name="days"
                        placeholder={t("days_placeholder")}
                        onChange={(e) => {}}
                        value={a.day_Ids}
                        valueField="id"
                        textField={referenceLabel}
                        data={daysReducer.daysForSelect}
                        filter="contains"
                        disabled={true}
                      />
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
}
