import i18n from "i18n";
import { TableDataComparatorGroupEnum } from "utils/enums";

export const comparators = {
  stringComparators: [
    {
      propertyType: "string",
      label: i18n.t("contains"),
      internalName: "contains",
      valueForQuery: '{0}.Contains("{1}")',
      group: [TableDataComparatorGroupEnum.OnlyContainsEquals],
    },
    {
      propertyType: "string",
      label: i18n.t("ends_with"),
      internalName: "endsWith",
      valueForQuery: '{0}.EndsWith("{1}")',
    },
    {
      propertyType: "string",
      label: i18n.t("equal"),
      internalName: "equal",
      valueForQuery: '{0} == "{1}"',
      group: [TableDataComparatorGroupEnum.OnlyContainsEquals],
    },
    {
      propertyType: "string",
      label: i18n.t("not_contains"),
      internalName: "notContains",
      valueForQuery: '!({0}.Contains("{1}"))',
    },
    {
      propertyType: "string",
      label: i18n.t("not_ends_with"),
      internalName: "notEndsWith",
      valueForQuery: '!({0}.EndsWith("{1}"))',
    },
    {
      propertyType: "string",
      label: i18n.t("not_equal"),
      internalName: "notEqual",
      valueForQuery: '{0} != "{1}"',
    },
    {
      propertyType: "string",
      label: i18n.t("not_starts_with"),
      internalName: "notStartsWith",
      valueForQuery: '!({0}.StartsWith("{1}"))',
    },
    {
      propertyType: "string",
      label: i18n.t("starts_with"),
      internalName: "startsWith",
      valueForQuery: '{0}.StartsWith("{1}")',
    },
    {
      propertyType: "string",
      label: i18n.t("is_empty"),
      internalName: "isEmpty",
      valueForQuery: '{0} == {1} || {0} == ""',
      showInput: false,
    },
  ],
  numberComparators: [
    {
      propertyType: "number",
      label: "<>",
      internalName: "notEqual",
      valueForQuery: "{0} != {1}",
    },
    {
      propertyType: "number",
      label: "<",
      internalName: "lessThan",
      valueForQuery: "{0} < {1}",
    },
    {
      propertyType: "number",
      label: "<=",
      internalName: "lessThanOrEqual",
      valueForQuery: "{0} <= {1}",
    },
    {
      propertyType: "number",
      label: "=",
      internalName: "equal",
      valueForQuery: "{0} == {1}",
    },
    {
      propertyType: "number",
      label: ">",
      internalName: "greaterThan",
      valueForQuery: "{0} > {1}",
    },
    {
      propertyType: "number",
      label: ">=",
      internalName: "greaterThanOrEqual",
      valueForQuery: "{0} >= {1}",
    },
    {
      propertyType: "number",
      label: "Is Empty",
      internalName: "isEmpty",
      valueForQuery: "{0} == {1}",
      showInput: false,
    },
  ],
  dateComparators: [
    {
      propertyType: "date",
      label: "<",
      internalName: "lessThan",
      valueForQuery: "{0} < {1}",
    },
    {
      propertyType: "date",
      label: "<>",
      internalName: "notEqual",
      valueForQuery: "{0} != {1}",
    },
    {
      propertyType: "date",
      label: "<=",
      internalName: "lessThanOrEqual",
      valueForQuery: "{0} <= {1}",
    },
    {
      propertyType: "date",
      label: "=",
      internalName: "equal",
      valueForQuery: "{0} == {1}",
    },
    {
      propertyType: "date",
      label: ">",
      internalName: "greaterThan",
      valueForQuery: "{0} > {1}",
    },
    {
      propertyType: "date",
      label: ">=",
      internalName: "greaterThanOrEqual",
      valueForQuery: "{0} >= {1}",
    },
    {
      propertyType: "date",
      label: "Is Empty",
      internalName: "isEmpty",
      valueForQuery: "{0} == {1}",
      showInput: false,
    },
  ],
  booleanComparators: [
    {
      propertyType: "boolean",
      label: "<>",
      internalName: "notEqual",
      valueForQuery: "{0} != {1}",
    },
    {
      propertyType: "boolean",
      label: "=",
      internalName: "equal",
      valueForQuery: "{0} == {1}",
    },
  ],
  dropdownComparators: [
    {
      propertyType: "dropdown",
      label: i18n.t("equal"),
      internalName: "equal",
      valueForQuery: "{0} == {1}",
    },
    {
      propertyType: "dropdown",
      label: i18n.t("except"),
      internalName: "notEqual",
      valueForQuery: "{0} != {1}",
    },
  ],
};
