import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import useReactRouter from "use-react-router";

import { Row, Col } from "reactstrap";

import Spinner from "components/Spinner/Spinner";
import ProductContract from "views/Contracts/ProductContract";
import DropdownList from "react-widgets/lib/DropdownList";

import { getProduct, addContract } from "redux/actions/products";
import { getProductTypes } from "redux/actions/product-types";
import { getRemarkTypes } from "redux/actions/remark-types";

function ProductContracts() {
  const { t } = useTranslation();
  const productsReducer = useSelector((state) => state.productsReducer);
  const productTypesReducer = useSelector((state) => state.productTypesReducer);
  const remarkTypesReducer = useSelector((state) => state.remarkTypesReducer);
  const contractsReducer = useSelector((state) => state.contractsReducer);

  const dispatch = useDispatch();
  const { history, match } = useReactRouter();

  useEffect(() => {
    dispatch(getProduct(parseInt(match.params.id, 10)));
    dispatch(getProductTypes());
    dispatch(getRemarkTypes());
  }, [match, dispatch, t]);

  const redirectToContract = (contractId) => {
    history.push(`/products/${match.params.id}/contracts/${contractId}`);
  };

  const handleAddContract = (product) => {
    dispatch(addContract(product));
    redirectToContract(0);
  };

  if (
    !(
      productsReducer.products &&
      productsReducer.products.length &&
      productsReducer.products.find((p) => p.id === parseInt(match.params.id)) !== undefined &&
      productTypesReducer.productTypes &&
      productTypesReducer.productTypes.length &&
      remarkTypesReducer.remarkTypes &&
      remarkTypesReducer.remarkTypes.length
    )
  )
    return <Spinner />;

  let product = productsReducer.products.find((p) => p.id === parseInt(match.params.id));
  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  let otherReference = isEnglish ? "nameFrench" : "nameEnglish";

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">{t("create_or_edit")}</h6>
          <h2 className="dashhead-title">{t("contract")}</h2>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <h5 className="card-title">
            {product[referenceName] && product[referenceName].length ? product[referenceName] : product[otherReference]}
          </h5>
          <hr />
          <div className="card-text">
            <div className="clearfix"></div>
            <Row>
              <Col md="11">
                <div className="form-group">
                  <label className="form-control-label" htmlFor="contracts">
                    {t("contracts")}
                  </label>
                  <DropdownList
                    placeholder={t("contracts_placeholder")}
                    onChange={(e) => {
                      return redirectToContract(e["id"]);
                    }}
                    name="contracts"
                    value={contractsReducer.contract ? contractsReducer.contract : undefined}
                    valueField="id"
                    textField={
                      contractsReducer.contract[referenceName] && contractsReducer.contract[referenceName].length
                        ? referenceName
                        : otherReference
                    }
                    data={product.contracts}
                    // open={this.state.open}
                    // onToggle={() => this.toggleDropDown(true)}
                  />
                </div>
              </Col>
              <Col md="1">
                <button className="btn btn-success button-field-equal" onClick={() => handleAddContract(product)}>
                  <i className="far fa-plus-square"></i>
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <React.Fragment>
        <Route path="/products/:id/contracts/:contractId" component={ProductContract} />
      </React.Fragment>
    </React.Fragment>
  );
}

export default ProductContracts;
