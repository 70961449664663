import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setActiveStep, addContractDayGroup } from "redux/actions/contracts";
import { Row, Col, Alert } from "reactstrap";

import DayGroup from "./Components/DayGroup";

export default function ByDays({ contract, steps, validator }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const contractsReducer = useSelector((state) => state.contractsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);
  const isEstablishmentContract = true;

  useEffect(() => {
    let currentStep = steps.findIndex((s) => s.name === "price-by-days");
    if (contractsReducer.activeStepIndex !== currentStep) dispatch(setActiveStep(currentStep));
  }, [contractsReducer.activeStepIndex, contractsReducer.steps, dispatch, steps]);

  const handleAddDayGroup = () => {
    dispatch(addContractDayGroup(isEstablishmentContract));
  };

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h2 className="dashhead-title">{t("specific_days")}</h2>
          <hr />
          <Alert type="primary" className="mt-2">
            {t("specific_days_information")}
          </Alert>
        </div>
      </div>

      <Row>
        <Col md="12">
          <button className="btn btn-primary float-right" onClick={handleAddDayGroup} disabled={!accountReducer.isManager}>
            {t("add_specific_days")}
          </button>
        </Col>
      </Row>

      {contract && contract.contractDayGroups && contract.contractDayGroups.length
        ? contract.contractDayGroups.map((cd, i) => (
            <DayGroup
              contractDay={cd}
              contract={contract}
              index={i}
              key={cd.uniqueIdentifier}
              isEstablishmentContract={isEstablishmentContract}
              validator={validator}
            />
          ))
        : null}

      {contract && contract.contractDayGroups && contract.contractDayGroups.length > 0 && (
        <Row className={"mt-2"}>
          <Col md="12">
            <button className="btn btn-primary float-right" onClick={handleAddDayGroup} disabled={!accountReducer.isManager}>
              {t("add_specific_days")}
            </button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}
