import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

import DropdownList from "react-widgets/lib/DropdownList";
import { Badge } from "reactstrap";

let ValueInput = ({ item, textField, clearable, toggleDropDown, clearAction }) => (
  <span>
    <strong>{item[textField]}</strong>

    {clearable && (
      <Badge
        className="float-right"
        color="light"
        pill
        style={{ cursor: "pointer", paddingTop: "3px", marginTop: "3px" }}
        onClick={(event) => {
          event.stopPropagation();
          toggleDropDown(false);
          return clearAction();
        }}
      >
        X
      </Badge>
    )}
  </span>
);

class SelectWithLabel extends Component {
  state = {
    open: false,
  };

  toggleDropDown = (isOpening) => {
    let { open } = this.state;
    if (isOpening) {
      this.setState({ open: !open });
    }
  };

  render() {
    const {
      data,
      textField,
      valueField,
      defaultValue,
      icon,
      iconHtml,
      iconTooltip,
      labelText,
      selectPlaceholder,
      fieldToUpdate,
      objectToUpdate,
      updateAction,
      hasValidator,
      errorMessage,
      forceShowErrorState = false,
      value,
      disabled,
      clearable,
      filterable,
      groupBy,
      groupComponent,
      itemComponent,
      appendtooltip
    } = this.props;

    if (objectToUpdate === undefined) return null;

    return (
      <React.Fragment>
        <div className="form-group">
          <label
            className={errorMessage || forceShowErrorState ? "form-control-label text-danger" : "form-control-label"}
            htmlFor={fieldToUpdate}
          >
            {labelText}
            {appendtooltip ? appendtooltip : null}
            {icon ? (
              <span className="ml-2 icon text-primary" data-tip={iconTooltip} data-html={iconHtml} data-for={`tt-${fieldToUpdate}`}>
                {icon}
              </span>
            ) : null}
            {iconTooltip ? <ReactTooltip clickable id={`tt-${fieldToUpdate}`} type="info" effect="solid" place="right" border /> : null}
          </label>
          <DropdownList
            busy={data === undefined}
            filter={filterable ? "contains" : false}
            disabled={disabled}
            containerClassName={errorMessage || forceShowErrorState ? "error" : ""}
            id={fieldToUpdate}
            name={fieldToUpdate}
            placeholder={selectPlaceholder}
            onChange={(e) => {
              return updateAction(e[valueField], objectToUpdate, fieldToUpdate, true);
            }}
            value={value}
            defaultValue={defaultValue}
            valueField={valueField}
            textField={textField}
            data={data}
            groupBy={groupBy}
            groupComponent={groupComponent}
            itemComponent={itemComponent}
            open={this.state.open}
            onToggle={() => this.toggleDropDown(true)}
            valueComponent={(item) => (
              <ValueInput
                item={item.item}
                textField={textField}
                clearable={disabled ? false : clearable}
                toggleDropDown={this.toggleDropDown}
                clearAction={() => {
                  return updateAction(undefined, objectToUpdate, fieldToUpdate, true);
                }}
              />
            )}
          />
          {hasValidator && errorMessage}
        </div>
      </React.Fragment>
    );
  }
}

export default SelectWithLabel;
