import React, { Component } from "react";

export default class ValidationSummary extends Component {
  render() {
    const { messages } = this.props;

    return (
      <div className="row">
        <div className="col-md-12 ">
          <div className="alert alert-danger">
            <ul>
              {messages.map((m) => (
                <li key={m} dangerouslySetInnerHTML={{ __html: m }} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}