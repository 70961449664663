import axios from "axios";
import { HOST } from "config/config";

export function getEstablishmentReservationTypesForSelect() {
  return (dispatch, getState) => {
    if (!getState().establishmentReservationTypesReducer.selectUpdating) {
      return dispatch({
        type: "GET_ESTABLISHMENT_RESERVATION_TYPES_FOR_SELECT_PENDING",
        payload: axios
          .get(`${HOST}/api/filters/establishment-reservation-types`)
          .then((response) => response.data)
          .then((json) =>
            dispatch({
              type: "GET_ESTABLISHMENT_RESERVATION_TYPES_FOR_SELECT_FULFILLED",
              payload: { establishmentReservationTypes: json },
            })
          )
          .catch((error) =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message,
              },
            })
          ),
      });
    }
  };
}
