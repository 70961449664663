import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { isEmpty } from "utils/utils";

import { Col } from "reactstrap";
import TaxSection from "./Components/TaxSection";
import { getTaxTypes } from "redux/actions/tax-types";
import { getTaxTypeApplicationsForSelect } from "redux/actions/tax-type-applications";
import { getTaxTypeTargetsForSelect } from "redux/actions/tax-type-targets";
import { getSalesTaxes } from "redux/actions/sales-taxes";
import PaymentInformation from "./Components/PaymentInformation";
import ContractPriceDetailHeader from "./Components/ContractPriceDetailHeader";
import { persistProductContractPriceDetail } from "redux/actions/product-price-details";
import BaseAccomodationPriceDetail from "./Components/BaseAccomodationPriceDetail";
import CampingAccomodationPriceDetail from "./Components/CampingAccomodationPriceDetail";
import GroupCampingAccomodationPriceDetail from "./Components/GroupCampingAccomodationPriceDetail";
import NonStandardAccomodationPriceDetail from "./Components/NonStandardAccomodationPriceDetail";
import product_type_name_enum from "Enums/productType";
import GuideDriverPriceDetail from "./Components/GuideDriverPriceDetail";

export default function AccomodationPriceDetail({ contract, cpd, index }) {
  const dispatch = useDispatch();
  const salesTaxesReducer = useSelector((state) => state.salesTaxesReducer);
  const taxTypeApplicationsReducer = useSelector((state) => state.taxTypeApplicationsReducer);
  const taxTypeTargetsReducer = useSelector((state) => state.taxTypeTargetsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  useEffect(() => {
    dispatch(getTaxTypes());
    dispatch(getSalesTaxes());
    dispatch(getTaxTypeApplicationsForSelect());
    dispatch(getTaxTypeTargetsForSelect());
  }, [dispatch]);

  const handleUpdateContractPriceDetail = (e, contractPriceDetail, field, isNumber) => {
    let value = e.target ? e.target.value : e;

    if (isNumber) {
      value = value.toString().replace(/,(?=[\d,]*\.\d{2}\b)/g, "");
      value = Number(value);
    }

    if ((isEmpty(contractPriceDetail[field]) && isEmpty(value)) || contractPriceDetail[field] === value) return;

    const updateModel = { contractPriceDetail, contract, fieldValues: [{ value, fieldName: field }] };

    dispatch(persistProductContractPriceDetail(updateModel));
  };

  if (!cpd.id) return null;

  return (
    <Col md="12" style={{ zIndex: 1029 - index /* 1030 is the top navbar */ }}>
      <div className="card mt-3">
        <div className="card-body">
          <div className="card-text">
            <ContractPriceDetailHeader
              contractPriceDetail={cpd}
              contractPeriods={contract.contractPeriods}
              ageCategories={contract.ageCategories}
              groups={contract.groups}
              hasGroups={contract.hasGroups}
              contractDepartureGroups={contract.contractDepartureGroups}
              contractDayGroups={contract.contractDayGroups}
              contract={contract}
            />
            {cpd.productTypes &&
              cpd.productTypes.map((pt) => {
                switch (pt) {
                  case product_type_name_enum().INDIVIDUAL_CAMPING:
                    return (
                      <CampingAccomodationPriceDetail
                        key={product_type_name_enum().INDIVIDUAL_CAMPING}
                        cpd={cpd}
                        handleUpdateContractPriceDetail={handleUpdateContractPriceDetail}
                        isManager={accountReducer.isManager}
                      />
                    );

                  case product_type_name_enum().GROUP_CAMPING:
                    return (
                      <GroupCampingAccomodationPriceDetail
                        key={product_type_name_enum().GROUP_CAMPING}
                        cpd={cpd}
                        handleUpdateContractPriceDetail={handleUpdateContractPriceDetail}
                        isManager={accountReducer.isManager}
                      />
                    );

                  case product_type_name_enum().NON_STANDARD_ROOM:
                    return (
                      <NonStandardAccomodationPriceDetail
                        key={product_type_name_enum().NON_STANDARD_ROOM}
                        cpd={cpd}
                        handleUpdateContractPriceDetail={handleUpdateContractPriceDetail}
                        isManager={accountReducer.isManager}
                      />
                    );

                  default:
                    return (
                      <BaseAccomodationPriceDetail
                        key={"DEFAULT"}
                        cpd={cpd}
                        handleUpdateContractPriceDetail={handleUpdateContractPriceDetail}
                      />
                    );
                }
              })}
            {(contract.hasGuidePrice || contract.hasDriverPrice) && (
              <>
                <hr />
                <GuideDriverPriceDetail
                  key={`${cpd.id}-GuideDriver-Prices`}
                  contract={contract}
                  cpd={cpd}
                  handleUpdateContractPriceDetail={handleUpdateContractPriceDetail}
                  isManager={accountReducer.isManager}
                />
                <hr />
              </>
            )}
            <PaymentInformation
              contractPriceDetail={cpd}
              taxTypeTargets={taxTypeTargetsReducer.taxTypeTargets}
              taxTypeApplications={taxTypeApplicationsReducer.taxTypeApplications}
              updateAction={handleUpdateContractPriceDetail}
              salesTaxes={salesTaxesReducer.salesTaxes}
              isManager={accountReducer.isManager}
            />
            <TaxSection cpd={cpd} contract={contract} />
          </div>
        </div>
      </div>
    </Col>
  );
}
