import React from "react";
import { useTranslation } from "react-i18next";

import { Row, Col } from "reactstrap";
import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";
import ReactTooltip from "react-tooltip";

export default function PaymentInformation({
  contractPriceDetail = {},
  taxTypeApplications = [],
  taxTypeTargets = [],
  salesTaxes = [],
  updateAction,
  isManager = false,
}) {
  const { t } = useTranslation();

  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  let otherReference = isEnglish ? "nameFrench" : "nameEnglish";

  return (
    <Row>
      <Col md="8">
        <Row>
          <Col md="6">
            <div className="form-group">
              <label className="form-control-label">{t("payment_target")}</label>

              {taxTypeTargets
                .filter((ttt) => !ttt.isForTaxOnly)
                .map((ttt) => (
                  <div className="form-check" key={`${ttt.id}-${contractPriceDetail.id}`}>
                    <input
                      className="form-check-input"
                      type="radio"
                      id={`taxTypeTarget_Id-${ttt.id}-${contractPriceDetail.id}`}
                      name={`taxTypeTarget_Id-${contractPriceDetail.id}`}
                      value={ttt.id}
                      disabled={!isManager}
                      defaultChecked={contractPriceDetail.taxTypeTarget_Id === ttt.id}
                      onClick={(e) => updateAction(Number(e.target.value), contractPriceDetail, "taxTypeTarget_Id")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`taxTypeTarget_Id-${ttt.id}-${contractPriceDetail.id}`}
                      style={{ display: "inline-block!important" }}
                    >
                      {ttt[referenceName] && ttt[referenceName].length ? ttt[referenceName] : ttt[otherReference]}
                    </label>
                  </div>
                ))}
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-control-label">{t("payment_calculation")}</label>

              {taxTypeApplications
                .filter((tta) => !tta.isForTaxOnly)
                .map((tta) => (
                  <div className="form-check" key={`${tta.id}-${contractPriceDetail.id}`}>
                    <input
                      className="form-check-input"
                      type="radio"
                      id={`taxTypeApplication_Id-${tta.id}-${contractPriceDetail.id}`}
                      name={`taxTypeApplication_Id-${contractPriceDetail.id}`}
                      value={tta.id}
                      disabled={!isManager}
                      defaultChecked={contractPriceDetail.taxTypeApplication_Id === tta.id}
                      onClick={(e) => updateAction(Number(e.target.value), contractPriceDetail, "taxTypeApplication_Id")}
                    />
                    <label className="form-check-label" htmlFor={`taxTypeApplication_Id-${tta.id}-${contractPriceDetail.id}`}>
                      {tta[referenceName] && tta[referenceName].length ? tta[referenceName] : tta[otherReference]}
                      <ReactTooltip id={`taxTypeApplication_Id-tooltip-${tta.id}`} type="info" effect="solid" place="top" border />
                      <i
                        className="fas fa-info-circle text-info ml-1"
                        data-tip={isEnglish ? tta.extraInformationEnglish : tta.extraInformationFrench}
                        data-for={`taxTypeApplication_Id-tooltip-${tta.id}`}
                      ></i>
                    </label>
                  </div>
                ))}
            </div>
          </Col>
        </Row>
      </Col>
      <Col md="4">
        {salesTaxes.length ? (
          <MultiSelectWithLabel
            labelText={t("applicable_sales_taxes")}
            id={`sales_taxes_${contractPriceDetail.id}`}
            objectToUpdate={contractPriceDetail}
            updateAction={updateAction}
            fieldToUpdate="salesTax_Ids"
            textField={referenceName}
            valueField="id"
            disabled
            defaultValue={contractPriceDetail.salesTax_Ids}
            data={salesTaxes}
          />
        ) : null}
      </Col>
    </Row>
  );
}
