import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { getContractsForEstablishment } from "redux/actions/contracts";
import { createPriceList, hideCreatePriceListModal, updatePriceListProduct } from "redux/actions/products";
import SelectWithLabel from "components/FormControls/SelectWithLabel";
import { getCompaniesForSelect } from "redux/actions/companies";
import InputWithLabel from "components/FormControls/InputWithLabel";

function CreatePriceListModal() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const productsReducer = useSelector((state) => state.productsReducer);
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const companiesReducer = useSelector((state) => state.companiesReducer);

  const { match, history } = useReactRouter();

  const [showActiveOnly, setShowActiveOnly] = useState(true);

  useEffect(() => {
    dispatch(getContractsForEstablishment(match.params.id));
    if (!companiesReducer.companiesForSelect || companiesReducer.companiesForSelect.length === 0) dispatch(getCompaniesForSelect());

    return () => {
      console.log();
    };
  }, [companiesReducer.companiesForSelect, dispatch, match.params.id]);

  const handleCreatePriceList = () => {
    dispatch(createPriceList(productsReducer.priceListProducts)).then(async () => {
      closeModal().then((_) => {
        // Pour aucune raison, la modal n'as pas le temps de completer le "close" avant que le url change
        // Ce qui cause que la classe modal-open est encore sur le body quand on arrive dans la price-list
        // Le timeout donne le temp a la modal de se fermer avant de changer de page
        setTimeout(() => {
          history.push(`/establishments/${match.params.id}/products/price-list`);
        }, 500);
      });
    });
  };
  const handleContractChange = (product, value) => {
    dispatch(updatePriceListProduct(product, value));
  };
  const closeModal = () => {
    return dispatch(hideCreatePriceListModal());
  };

  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  const referenceNameContract = isEnglish ? "finalContractNameEnglish" : "finalContractNameFrench";
  const labelName = isEnglish ? "labelEnglish" : "labelFrench";
  let otherReference = isEnglish ? "finalContractNameEnglish" : "finalContractNameFrench";

  return (
    <Modal className="mw-100 w-50" isOpen={productsReducer.showCreatePriceListModal}>
      <ModalHeader>{t("create_price_list")}</ModalHeader>
      <ModalBody>
        <div className="form-group">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>{t("product_name")}</th>
                <th>
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <span style={{ marginRight: "1rem" }}>{t("contract")}</span>
                    <div style={{ marginBottom: "-1rem" }}>
                      <InputWithLabel
                        style={{ paddingBottom: "1rem!important" }}
                        labelText={t("show_active_only")}
                        inputType="checkbox"
                        fieldToUpdate="showActiveOnly"
                        objectToUpdate={{ showActiveOnly }}
                        persistOnBlur
                        updateAction={(value) => {
                          setShowActiveOnly(value);
                        }}
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {productsReducer.priceListProducts.map((p) => (
                <tr key={p[referenceName] && p[referenceName].length ? p[referenceName] : p[otherReference]}>
                  <td>{p[referenceName] && p[referenceName].length ? p[referenceName] : p[otherReference]}</td>
                  <td className="w-75">
                    <SelectWithLabel
                      filterable
                      selectPlaceholder={t("contract_Id_placeholder")}
                      data={contractsReducer.establishmentContracts
                        .sort((a, b) => new Date(b.end) - new Date(a.end))
                        .filter(
                          (ec) =>
                            (!showActiveOnly || showActiveOnly === ec.isActive) &&
                            p.product.category_Ids.some((ci) => ci === ec.category_Id)
                        )}
                      textField={p[referenceNameContract] && p[referenceNameContract].length ? referenceNameContract : otherReference}
                      itemComponent={({ item }) => {
                        return (
                          <span>{`${
                            item[p[referenceNameContract] && p[referenceNameContract].length ? referenceNameContract : otherReference]
                          } (${
                            companiesReducer.companiesForSelect &&
                            item.company_Ids &&
                            item.company_Ids.length > 0 &&
                            companiesReducer.companiesForSelect
                              .filter((c) => item.company_Ids.includes(c.id))
                              .map((c) => c[labelName])
                              .join(", ")
                          })`}</span>
                        );
                      }}
                      valueField="id"
                      objectToUpdate={p}
                      updateAction={(value) => handleContractChange(p, value)}
                      fieldToUpdate="establishmentContract_Id"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          disabled={productsReducer.priceListProducts.some((plp) => !plp.establishmentContract_Id)}
          onClick={handleCreatePriceList}
        >
          {t("create")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default CreatePriceListModal;
