import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import cookie from "react-cookies";
import axios from "axios";

import ValidationSummary from "components/ValidationSummary/ValidationSummary";

import { login } from "redux/actions/account";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = { user: {}, api: {} };
  }

  componentDidUpdate() {
    if (this.props.accountReducer.isLoggedIn) {
      if (this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.pathname) {
        this.props.history.push(this.props.location.state.from.pathname);
      } else {
        this.props.history.push("/");
      }
    }
  }

  changeLanguage = language => {
    this.props.i18n.changeLanguage(language);
    axios.defaults.headers.common["Accept-Language"] = language;
    cookie.save("lm_language", language);
  };

  login = e => {
    e.preventDefault();

    this.props.dispatch(login(this.refs.username.value, this.refs.password.value, this.refs.rememberme.value));
  };
  logout = () => {};

  renewToken = () => {};

  render() {
    const { t, accountReducer } = this.props;
    let errorMessage = "";
    if (accountReducer.error && accountReducer.error.success === false) {
      errorMessage = t(accountReducer.error.message);
    } else if (accountReducer.error && accountReducer.error.response) {
      if (accountReducer.error.response.data && accountReducer.error.response.data.message)
        errorMessage = t(accountReducer.error.response.data.message);
      else if (accountReducer.error.response.statusText) errorMessage = t(accountReducer.error.response.statusText);
    } else if (accountReducer.error && accountReducer.error.response === undefined && accountReducer.error.message !== undefined) {
      errorMessage = accountReducer.error.message;
    }

    return (
      <React.Fragment>
        <form className="login100-form " onSubmit={this.login}>
          {errorMessage !== "" && errorMessage ? <ValidationSummary messages={[errorMessage]} /> : null}
          <div className="row">
            <div className="col-md-12">
              <h2>{t("login")}</h2>
              <small>
                <span className=" btn-link pointer" onClick={() => this.changeLanguage(t("other_language_two_letter"))}>
                  {t("other_language")}
                </span>
              </small>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="form-group">
                <input type="text" className="form-control" id="username" placeholder={t("email_placeholder")} ref="username" />
              </div>
              <div className="form-group">
                <input type="password" className="form-control" id="password" placeholder={t("password_placeholder")} ref="password" />
              </div>
              <div className="form-check">
                <input id="rememberme" type="checkbox" name="rememberme" className="form-check-input" ref="rememberme" />
                <label htmlFor="rememberme" className="form-check-label">
                  {t("remember_me")}
                </label>
              </div>

              <div className="float-right">
                <input type="submit" className="btn btn-primary block m-b" onClick={this.login} value={t("login")} />
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    accountReducer: store.accountReducer
  };
};

export default withTranslation()(connect(mapStateToProps)(Login));
