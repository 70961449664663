import React, { useEffect } from "react";
import uuidv4 from "uuid/v4";

import { useDispatch, useSelector } from "react-redux";

import CurrencyInput from "react-currency-input";
import { isEmpty } from "utils/utils";

import { Row, Col } from "reactstrap";
import { getTaxTypes } from "redux/actions/tax-types";
import { getSalesTaxes } from "redux/actions/sales-taxes";
import { getTaxTypeApplicationsForSelect } from "redux/actions/tax-type-applications";
import { getTaxTypeTargetsForSelect } from "redux/actions/tax-type-targets";
import product_type_name_enum from "Enums/productType";

import PaymentInformation from "./Components/PaymentInformation";
import TaxSection from "./Components/TaxSection";
import ContractPriceDetailHeader from "./Components/ContractPriceDetailHeader";
import { persistProductContractPriceDetail } from "redux/actions/product-price-details";
import { useTranslation } from "react-i18next";
import GuideDriverPriceDetail from "./Components/GuideDriverPriceDetail";

export default function RestaurationPriceDetail({ contract, cpd }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const salesTaxesReducer = useSelector((state) => state.salesTaxesReducer);
  const taxTypeApplicationsReducer = useSelector((state) => state.taxTypeApplicationsReducer);
  const taxTypeTargetsReducer = useSelector((state) => state.taxTypeTargetsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);
  const productTypesReducer = useSelector((state) => state.productTypesReducer);

  const { productTypes } = productTypesReducer;

  useEffect(() => {
    dispatch(getTaxTypes());
    dispatch(getSalesTaxes());
    dispatch(getTaxTypeApplicationsForSelect());
    dispatch(getTaxTypeTargetsForSelect());
  }, [dispatch]);

  const handleUpdateContractPriceDetail = (e, contractPriceDetail, field, isNumber) => {
    let value = e.target ? e.target.value : e;

    if (isNumber) {
      value = value.replace(/,(?=[\d,]*\.\d{2}\b)/g, "");
      value = Number(value);
    }

    if ((isEmpty(contractPriceDetail[field]) && isEmpty(value)) || contractPriceDetail[field] === value) return;

    const updateModel = { contractPriceDetail, contract, fieldValues: [{ value, fieldName: field }] };

    dispatch(persistProductContractPriceDetail(updateModel));
  };

  let guid1 = uuidv4();
  let guid2 = uuidv4();
  let guid3 = uuidv4();

  if (!cpd.id) return null;

  const validOnDemandeTypes = [
    productTypes.find((pt) => pt.internalName === product_type_name_enum().DINNER).id,
    productTypes.find((pt) => pt.internalName === product_type_name_enum().LUNCH).id,
    productTypes.find((pt) => pt.internalName === product_type_name_enum().BREAKFAST).id,
  ];
  var isOnDemandProductType = contract.productTypes.some((ppt) => validOnDemandeTypes.some((vpt) => Number(vpt) === Number(ppt)));

  // Product that are
  const validFullBoardTypes = [
    ...validOnDemandeTypes,
    productTypes.find((pt) => pt.internalName === product_type_name_enum().FULL_BOARD).id,
  ];
  var isFullBoardProductType = contract.productTypes.some((ppt) => validFullBoardTypes.some((vpt) => Number(vpt) === Number(ppt)));

  const validHalfBoardTypes = [
    ...validOnDemandeTypes,
    productTypes.find((pt) => pt.internalName === product_type_name_enum().HALF_BOARD).id,
  ];
  var isHalfBoardProductType = contract.productTypes.some((ppt) => validHalfBoardTypes.some((vpt) => Number(vpt) === Number(ppt)));

  return (
    <>
      <Col md="12">
        <div className="card mt-3">
          <div className="card-body">
            <div className="card-text">
              <ContractPriceDetailHeader
                contractPriceDetail={cpd}
                contractPeriods={contract.contractPeriods}
                ageCategories={contract.ageCategories}
                groups={contract.groups}
                hasGroups={contract.hasGroups}
                contractDepartureGroups={contract.contractDepartureGroups}
                contractDayGroups={contract.contractDayGroups}
                contract={contract}
              />

              <Row>
                <Col md="3">
                  <div className="form-group">
                    <label className="form-label" htmlFor={`value-${guid1}`}>
                      {t("priceOnDemand")}
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <CurrencyInput
                        value={cpd.value}
                        id={`value-${guid1}`}
                        onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "value", true)}
                        className="form-control text-right"
                        disabled={!isOnDemandProductType || !accountReducer.isManager}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="form-group">
                    <label className="form-label" htmlFor={`half-board-${guid2}`}>
                      {t("priceHalfBoard")}
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <CurrencyInput
                        value={cpd.halfBoard}
                        id={`half-board-${guid2}`}
                        onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "halfBoard", true)}
                        className="form-control text-right"
                        disabled={!isHalfBoardProductType || !accountReducer.isManager}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="form-group">
                    <label className="form-label" htmlFor={`full-board-${guid3}`}>
                      {t("priceFullBoard")}
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <CurrencyInput
                        value={cpd.fullBoard}
                        id={`full-board-${guid3}`}
                        onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "fullBoard", true)}
                        className="form-control text-right"
                        disabled={!isFullBoardProductType || !accountReducer.isManager}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              {(contract.hasGuidePrice || contract.hasDriverPrice) && (
                <>
                  <hr />
                  <GuideDriverPriceDetail
                    key={`${cpd.id}-GuideDriver-Prices`}
                    contract={contract}
                    cpd={cpd}
                    handleUpdateContractPriceDetail={handleUpdateContractPriceDetail}
                    isManager={accountReducer.isManager}
                  />
                  <hr />
                </>
              )}

              <PaymentInformation
                contractPriceDetail={cpd}
                taxTypeTargets={taxTypeTargetsReducer.taxTypeTargets}
                taxTypeApplications={taxTypeApplicationsReducer.taxTypeApplications}
                updateAction={handleUpdateContractPriceDetail}
                salesTaxes={salesTaxesReducer.salesTaxes}
                isManager={accountReducer.isManager}
              />

              <TaxSection cpd={cpd} contract={contract} />
            </div>
          </div>
        </div>
      </Col>
    </>
  );
}
