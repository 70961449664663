export const projectsReducer = (
  state = {
    projects: [],
    projectsForSelect: [],
    processingProjectDeparture: false,

    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_PROJECTS_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATING_PROJECTS_DEPARTURES_PENDING": {
      state = { ...state, processingProjectDeparture: true };
      break;
    }
    case "UPDATING_PROJECTS_DEPARTURES_FULFILLED": {
      state = { ...state, processingProjectDeparture: false };
      break;
    }
    case "UPDATE_PROJECTS_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_PROJECTS_FULFILLED": {
      state = { ...state, updating: false, updated: true, projects: action.payload.items };
      break;
    }
    case "GET_PROJECTS_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_PROJECT_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_PROJECT_FULFILLED": {
      let projects = state.projects;
      projects = projects.filter(s => s.id !== action.payload.id);
      projects.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        projects
      };
      break;
    }
    case "GET_PROJECTS_SELECT_FULFILLED": {
      let projects = [];
      action.payload.forEach(project => {
        if (!projects.some(t => t.id === project.id)) {
          projects.push(project);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        projectsForSelect: projects
      };
      break;
    }
    case "GET_PROJECT_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default projectsReducer;
