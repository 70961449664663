import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";

import { Row, Col } from "reactstrap";
import SelectWithLabel from "components/FormControls/SelectWithLabel";
import ProductList from "../Products/Components/ProductList";
import ProductModal from "../Products/Components/ProductModal";

import Spinner from "components/Spinner/Spinner";

import { getEstablishmentsByProvider } from "redux/actions/establishments";
import { getProvider } from "redux/actions/providers";
import { getProductsForEstablishment } from "redux/actions/products";
import { getRemarkTypes } from "redux/actions/remark-types";
import { getProductTypes } from "redux/actions/product-types";
import { getTaxTypes } from "redux/actions/tax-types";

function Provider() {
  const updateProducts = (establishmentId) => {
    dispatch(getProductsForEstablishment(establishmentId));
  };

  const { t } = useTranslation();

  const establishmentsReducer = useSelector((state) => state.establishmentsReducer);
  const productsReducer = useSelector((state) => state.productsReducer);
  const productTypesReducer = useSelector((state) => state.productTypesReducer);
  const providersReducer = useSelector((state) => state.providersReducer);
  const remarkTypesReducer = useSelector((state) => state.remarkTypesReducer);
  const taxTypesReducer = useSelector((state) => state.taxTypesReducer);

  const dispatch = useDispatch();
  const { match } = useReactRouter();

  useEffect(() => {
    dispatch(getRemarkTypes());
    dispatch(getProductTypes());
    dispatch(getTaxTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEstablishmentsByProvider(match.params.id));
    dispatch(getProvider(match.params.id));
  }, [match, dispatch]);

  if (
    !(
      establishmentsReducer.providerEstablishments &&
      establishmentsReducer.providerEstablishments.length &&
      providersReducer.providers &&
      providersReducer.providers.length
    )
  )
    return <Spinner />;

  const provider = providersReducer.providers.find((p) => +p.id === +match.params.id);
  const establishments = establishmentsReducer.providerEstablishments;

  return (
    <React.Fragment>
      <ProductModal
        remarkTypes={remarkTypesReducer.remarkTypes}
        productTypes={productTypesReducer.productTypes}
        taxTypes={taxTypesReducer.taxTypes}
        selectedEstablishment={productsReducer.establishmentId}
      />

      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">{t("create")}</h6>
          <h2 className="dashhead-title">{t("products_configuration")}</h2>
          <h5>{`${t("provider")}: ${provider.providerName}`}</h5>
        </div>
      </div>
      <hr />

      <div className="mt-2">
        <Row>
          <Col md="12">
            <SelectWithLabel
              filterable
              labelText={t("establishments")}
              selectPlaceholder={t("establishments")}
              data={establishments}
              textField={"establishmentName"}
              valueField="id"
              value={productsReducer.establishmentId}
              objectToUpdate={productsReducer}
              updateAction={updateProducts}
              fieldToUpdate="establishmentId"
            />
          </Col>
        </Row>

        {productsReducer.establishmentId && (
          <ProductList
            products={productsReducer.establishmentProducts}
            remarkTypes={remarkTypesReducer.remarkTypes}
            productTypes={productTypesReducer.productTypes}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default Provider;
