import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { loginWithCookie } from "redux/actions/account";

import Spinner from "components/Spinner/Spinner";

export default ChildComponent => {
  function Authentication(props) {
    const accountReducer = useSelector(state => state.accountReducer);
    const dispatch = useDispatch();

    useEffect(() => {
      if (accountReducer.access_token && !accountReducer.isLoggedIn) dispatch(loginWithCookie(accountReducer.access_token));
    }, [accountReducer, dispatch]);

    if (accountReducer.isLoggingIn) {
      return <Spinner />;
    }

    if (!accountReducer.isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/connexion",
            state: { from: props.location }
          }}
        />
      );
    }

    return <ChildComponent {...props} />;
  }

  return Authentication;
};
