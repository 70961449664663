import React, { Component } from "react";

import Multiselect from "react-widgets/lib/Multiselect";

class MultiSelectWithLabel extends Component {
  render() {
    const {
      data,
      textField,
      valueField,
      defaultValue,
      labelText,
      selectPlaceholder,
      fieldToUpdate,
      objectToUpdate,
      updateAction,
      hasValidator,
      errorMessage,
      forceShowErrorState = false,
      value,
      disabled,
      groupBy,
      groupComponent,
    } = this.props;
    if (objectToUpdate === undefined) return null;
    return (
      <React.Fragment>
        <div className="form-group">
          <label
            className={errorMessage || forceShowErrorState ? "form-control-label text-danger" : "form-control-label"}
            htmlFor={fieldToUpdate}
          >
            {labelText}
          </label>
          <Multiselect
            busy={data === undefined}
            disabled={disabled}
            containerClassName={errorMessage || forceShowErrorState ? "error" : ""}
            id={fieldToUpdate}
            name={fieldToUpdate}
            placeholder={selectPlaceholder}
            onChange={(e) =>
              updateAction(
                e.map((o) => o[valueField]),
                objectToUpdate,
                fieldToUpdate
              )
            }
            value={value}
            defaultValue={defaultValue}
            valueField={valueField}
            textField={textField}
            data={data}
            filter="contains"
            groupBy={groupBy}
            groupComponent={groupComponent}
          />
          {hasValidator && errorMessage}
        </div>
      </React.Fragment>
    );
  }
}

export default MultiSelectWithLabel;
