import axios from "axios";
import { HOST } from "config/config";

export function getQuickbooksVendor(userGroupId) {
  return (dispatch) => {
    return dispatch({
      type: "GET_QUICKBOOKS_VENDORS_PENDING",
      payload: axios
        .get(`${HOST}/api/quickbooks/vendors?userGroupId=${userGroupId}`)
        .then((response) => response.data)
        .then((json) =>
          dispatch({
            type: "GET_QUICKBOOKS_VENDORS_FULFILLED",
            payload: json,
          })
        ),
    });
  };
}
