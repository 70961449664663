import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { hideDeleteContractModal, deleteEstablishmentContract } from "redux/actions/contracts";

export default function DeleteContractModal() {
  const contractsReducer = useSelector((state) => state.contractsReducer);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteContract = () => {
    dispatch(deleteEstablishmentContract(contractsReducer.deleteContract)).then(() => closeModal());
  };

  const closeModal = () => {
    dispatch(hideDeleteContractModal());
  };

  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  let otherReference = isEnglish ? "nameFrench" : "nameEnglish";
  let contract = contractsReducer.deleteContract;

  return (
    <Modal className="mw-100 w-50" isOpen={contractsReducer.showDeleteContractModal}>
      <ModalHeader>{t("delete_contract_modal_title")}</ModalHeader>
      <ModalBody>
        {t("delete_contract_warning")}
        <h6 className="mt-2">
          {contract && contract[referenceName] && contract[referenceName].length
            ? contract[referenceName]
            : contract
            ? contract[otherReference]
            : null}
        </h6>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleDeleteContract}>
          {t("delete")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
