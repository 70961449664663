import React from "react";
import ReactTooltip from "react-tooltip";

import { getObject } from "utils/utils";

export default function InputRadio({ props }) {
  let isHorizontal = props.horizontaleOption || true;
  return (
    <div className={`form-group ${props.groupClassName}`}>
      {props.iconTooltip ? (
        <ReactTooltip clickable id={`tt-${props.fieldToUpdate}`} type="info" effect="solid" place="right" border />
      ) : null}
      <label>
        {props.labelText}

        {props.icon ? (
          <span
            className="ml-2 text-primary"
            data-tip={props.iconTooltip}
            data-html={props.iconHtml}
            data-for={`tt-${props.fieldToUpdate}`}
          >
            {props.icon}
          </span>
        ) : null}
      </label>

      <div className={isHorizontal ? "horizontal-options" : ""}>
        {props.options &&
          props.options.map((o, index) => (
            <div className="form-check" key={index}>
              <label
                className={
                  props.errorMessage || props.forceShowErrorState
                    ? `form-check-label text-danger ${props.labelClassName}`
                    : `form-check-label ${props.labelClassName}`
                }
              >
                <input
                  className={
                    props.errorMessage || props.forceShowErrorState
                      ? `form-check-input is-invalid ${props.className}`
                      : `form-check-input ${props.className}`
                  }
                  disabled={props.disabled}
                  id={props.id ? props.id : `${props.fieldToUpdate}-${index}`}
                  name={`${props.fieldToUpdate}`}
                  type={props.inputType}
                  checked={
                    getObject(props.objectToUpdate, props.fieldToUpdate) === null ||
                    getObject(props.objectToUpdate, props.fieldToUpdate) === undefined
                      ? false
                      : getObject(props.objectToUpdate, props.fieldToUpdate).toString() === o.value.toString()
                  }
                  value={o.value}
                  onChange={(e) => {
                    props.updateAction(e.target.value, props.objectToUpdate, props.fieldToUpdate, true);
                  }}
                />
                {o.text}
              </label>
            </div>
          ))}
      </div>
    </div>
  );
}
