import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "reactstrap";

import { isEmpty } from "utils/utils";

import { updateProductModal } from "redux/actions/products";
import InputWithLabel from "components/FormControls/InputWithLabel";

function CampingAdditionalSection(props) {
  const productsReducer = useSelector((state) => state.productsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const updateModel = (value, model, field, isBlurring) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    if (field === "deactivationDateTime") {
      if (value && value.getTime() < new Date().getTime()) {
        model.isActive = false;
      } else {
        model.isActive = true;
      }
    }

    model[field] = value;

    dispatch(updateProductModal(model));
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="card mt-2 zIndex-5">
      <div className="card-body">
        <h5 className="card-title">{t("additional_camping_information")}</h5>
        <div className="card-text">
          <hr />
          <Row>
            <Col md="3">
              <InputWithLabel
                labelText={t("qty_tent_by_site")}
                inputType="number"
                fieldToUpdate="campingQtyTentBySite"
                objectToUpdate={productsReducer.productModal}
                disabled={!accountReducer.isManager}
                persistOnBlur
                updateAction={updateModel}
                errorMessage={productsReducer.productModal.campingQtyTentBySiteError ? "error" : ""}
              />
            </Col>
            <Col md="3">
              <InputWithLabel
                labelText={t("capacity")}
                inputType="number"
                fieldToUpdate="campingCapacity"
                objectToUpdate={productsReducer.productModal}
                disabled={!accountReducer.isManager}
                persistOnBlur
                updateAction={updateModel}
                errorMessage={productsReducer.productModal.campingCapacityError ? "error" : ""}
              />
            </Col>
            <Col md="3">
              <InputWithLabel
                labelText={t("adult_number")}
                inputType="number"
                fieldToUpdate="campingQtyAdult"
                objectToUpdate={productsReducer.productModal}
                disabled={!accountReducer.isManager}
                persistOnBlur
                updateAction={updateModel}
                errorMessage={productsReducer.productModal.campingQtyAdultError ? "error" : ""}
              />
            </Col>

            <Col md="3">
              <InputWithLabel
                labelText={t("child_number")}
                inputType="number"
                fieldToUpdate="campingQtyChildren"
                objectToUpdate={productsReducer.productModal}
                disabled={!accountReducer.isManager}
                persistOnBlur
                updateAction={updateModel}
                errorMessage={productsReducer.productModal.campingQtyChildrenError ? "error" : ""}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default CampingAdditionalSection;
