/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";

import DropdownList from "react-widgets/lib/DropdownList";

import NumberInput from "./components/NumberInput";
import DateInput from "./components/DateInput";
import DropdownInput from "./components/DropdownInput";
import StringInput from "./components/StringInput";
import BooleanInput from "./components/BooleanInput";
import { TableDataComparatorGroupEnum } from "utils/enums";
class FilterRow extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.locale !== prevState.locale) {
      return { locale: nextProps.locale };
    } else return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      stringComparators: [
        {
          propertyType: "string",
          label: props.t("contains"),
          internalName: "contains",
          valueForQuery: '{0}.Contains("{1}")',
          group: [TableDataComparatorGroupEnum.OnlyContainsEquals],
        },
        {
          propertyType: "string",
          label: props.t("ends_with"),
          internalName: "endsWith",
          valueForQuery: '{0}.EndsWith("{1}")',
        },
        {
          propertyType: "string",
          label: props.t("equal"),
          internalName: "equal",
          valueForQuery: '{0} == "{1}"',
          group: [TableDataComparatorGroupEnum.OnlyContainsEquals],
        },
        {
          propertyType: "string",
          label: props.t("not_contains"),
          internalName: "notContains",
          valueForQuery: '!({0}.Contains("{1}"))',
        },
        {
          propertyType: "string",
          label: props.t("not_ends_with"),
          internalName: "notEndsWith",
          valueForQuery: '!({0}.EndsWith("{1}"))',
        },
        {
          propertyType: "string",
          label: props.t("not_equal"),
          internalName: "notEqual",
          valueForQuery: '{0} != "{1}"',
        },
        {
          propertyType: "string",
          label: props.t("not_starts_with"),
          internalName: "notStartsWith",
          valueForQuery: '!({0}.StartsWith("{1}"))',
        },
        {
          propertyType: "string",
          label: props.t("starts_with"),
          internalName: "startsWith",
          valueForQuery: '{0}.StartsWith("{1}")',
        },
        {
          propertyType: "string",
          label: props.t("is_empty"),
          internalName: "isEmpty",
          valueForQuery: '{0} == {1} || {0} == ""',
          showInput: false,
        },
      ],
      numberComparators: [
        {
          propertyType: "number",
          label: "<>",
          internalName: "notEqual",
          valueForQuery: "{0} != {1}",
        },
        {
          propertyType: "number",
          label: "<",
          internalName: "lessThan",
          valueForQuery: "{0} < {1}",
        },
        {
          propertyType: "number",
          label: "<=",
          internalName: "lessThanOrEqual",
          valueForQuery: "{0} <= {1}",
        },
        {
          propertyType: "number",
          label: "=",
          internalName: "equal",
          valueForQuery: "{0} == {1}",
        },
        {
          propertyType: "number",
          label: ">",
          internalName: "greaterThan",
          valueForQuery: "{0} > {1}",
        },
        {
          propertyType: "number",
          label: ">=",
          internalName: "greaterThanOrEqual",
          valueForQuery: "{0} >= {1}",
        },
        {
          propertyType: "number",
          label: "Is Empty",
          internalName: "isEmpty",
          valueForQuery: "{0} == {1}",
          showInput: false,
        },
      ],
      dateComparators: [
        {
          propertyType: "date",
          label: "<",
          internalName: "lessThan",
          valueForQuery: "{0} < {1}",
        },
        {
          propertyType: "date",
          label: "<>",
          internalName: "notEqual",
          valueForQuery: "{0} != {1}",
        },
        {
          propertyType: "date",
          label: "<=",
          internalName: "lessThanOrEqual",
          valueForQuery: "{0} <= {1}",
        },
        {
          propertyType: "date",
          label: "=",
          internalName: "equal",
          valueForQuery: "{0} == {1}",
        },
        {
          propertyType: "date",
          label: ">",
          internalName: "greaterThan",
          valueForQuery: "{0} > {1}",
        },
        {
          propertyType: "date",
          label: ">=",
          internalName: "greaterThanOrEqual",
          valueForQuery: "{0} >= {1}",
        },
        {
          propertyType: "date",
          label: "Is Empty",
          internalName: "isEmpty",
          valueForQuery: "{0} == {1}",
          showInput: false,
        },
      ],
      booleanComparators: [
        {
          propertyType: "boolean",
          label: "<>",
          internalName: "notEqual",
          valueForQuery: "{0} != {1}",
        },
        {
          propertyType: "boolean",
          label: "=",
          internalName: "equal",
          valueForQuery: "{0} == {1}",
        },
      ],
      dropdownComparators: [
        {
          propertyType: "dropdown",
          label: props.t("equal"),
          internalName: "equal",
          valueForQuery: "{0} == {1}",
        },
        {
          propertyType: "dropdown",
          label: props.t("except"),
          internalName: "notEqual",
          valueForQuery: "{0} != {1}",
        },
      ],
      options: [{ label: "", value: "" }],
      url: {},
    };

    this.updateValue = this.updateValue.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.getFilterInput = this.getFilterInput.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.getFilterInput();
    }
  }

  updateFilter(value) {
    let column = this.props.columns.find((c) => c.filterColumn === value);
    if (column === undefined) return;

    let filter = this.props.filter;
    filter.selectedColumn = {
      comparators: this.state[column.filterComparatorsName].filter(
        (comp) => !column.comparatorGroup || (comp.group && comp.group.some((g) => g === column.comparatorGroup))
      ),
      column: column,
    };
    filter.comparator = this.state[column.filterComparatorsName][0].valueForQuery;

    this.props.updateFilter(filter);

    if (column.type === "datetime") {
      this.updateValue(moment(new Date()).format("YYYY-MM-DD"));
    } else if (column.type === "string") {
      this.updateValue("");
    } else {
      this.updateValue(null);
    }

    let optionLabelEnglish = this.props.filter.selectedColumn.column.optionLabelEnglish
      ? this.props.filter.selectedColumn.column.optionLabelEnglish
      : "labelEnglish";
    let optionLabelFrench = this.props.filter.selectedColumn.column.optionLabelFrench
      ? this.props.filter.selectedColumn.column.optionLabelFrench
      : "labelFrench";
    let optionValue = this.props.filter.selectedColumn.column.optionValue ? this.props.filter.selectedColumn.column.optionValue : "value";

    let url = this.props.filter.selectedColumn.column.url;
    if (url && this.state.url !== url) {
      axios
        .get(url)
        .then((response) => response.data)
        .then((json) => {
          let options = json.map((item) => ({
            value: item[optionValue],
            labelEnglish: item[optionLabelEnglish],
            labelFrench: item[optionLabelFrench],
            label: item.label,
          }));

          let filter = this.props.filter;
          filter.value = json[0].value;
          this.setState({ options, url });
        });
    }
  }

  getFilterInput() {
    if (
      !this.props.filter.selectedColumn ||
      this.state[this.props.filter.selectedColumn.column.filterComparatorsName].find(
        (c) => c.valueForQuery === this.props.filter.comparator
      ).showInput === false
    )
      return null;

    switch (this.props.filter.selectedColumn.column.filterComparatorsName) {
      case "numberComparators": {
        return <NumberInput filter={this.props.filter} updateValue={this.updateValue} />;
      }
      case "dropdownComparators": {
        return (
          <DropdownInput
            options={this.state.options}
            filter={this.props.filter}
            updateValue={this.updateValue}
            locale={this.props.locale}
          />
        );
      }
      case "dateComparators": {
        return <DateInput filter={this.props.filter} updateValue={this.updateValue} locale={this.props.locale} />;
      }
      case "booleanComparators": {
        return <BooleanInput filter={this.props.filter} updateValue={this.updateValue} />;
      }
      default: {
        return <StringInput filter={this.props.filter} updateValue={this.updateValue} />;
      }
    }
  }

  updateValue(value) {
    let filter = this.props.filter;
    filter.value = value;
    this.props.updateFilter(filter);
  }

  updateComparator(comparator) {
    let filter = this.props.filter;
    filter.comparator = comparator;
    this.props.updateFilter(filter);
  }

  render() {
    let { t } = this.props;

    return (
      <React.Fragment>
        <div className="col-md-4 mt-2">
          <DropdownList
            filter="contains"
            placeholder={t("filter_by")}
            onChange={(e) => this.updateFilter(e.value)}
            defaultValue={this.props.columns.some((c) => c.selected) ? this.props.columns.find((c) => c.selected).filterColumn : undefined}
            data={this.props.columns
              .filter((c) => c.hasOwnProperty("filterComparatorsName"))
              .map((c) => ({ value: c.filterColumn, title: c.title }))}
            valueField="value"
            textField="title"
          />
        </div>
        <div className="col-md-4 mt-2">
          {this.props.filter.selectedColumn && this.props.filter.selectedColumn.comparators.length ? (
            <DropdownList
              placeholder=""
              onChange={(e) => this.updateComparator(e.value)}
              value={this.props.filter.selectedColumn.comparators.find(
                (comparator) => comparator.valueForQuery === this.props.filter.comparator
              )}
              textField="label"
              valueField="value"
              data={this.props.filter.selectedColumn.comparators
                .filter(
                  (comp) =>
                    !this.props.filter.selectedColumn.column.comparatorGroup ||
                    (comp.group && comp.group.some((g) => g === this.props.filter.selectedColumn.column.comparatorGroup))
                )
                .map((comparator) => ({
                  value: comparator.valueForQuery,
                  label: comparator.label,
                }))}
            />
          ) : null}
        </div>
        <div className="col-md-4 mt-2">
          <div className="row">
            <div className={`${this.props.canDelete ? "col-md-10" : "col-md-12"}`}>{this.getFilterInput()}</div>
            {this.props.canDelete ? (
              <div className="col-md-2  ">
                <button className="btn btn-danger float-right" onClick={this.props.removeFilterRow}>
                  <span className="icon icon-circle-with-cross" />
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(FilterRow);
