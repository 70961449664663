export const daysReducer = (
  state = {
    error: null,
    pending: false,
    daysForSelect: [],
  },
  action
) => {
  switch (action.type) {
    case "GET_DAYS_PENDING": {
      state = { ...state, pending: true };

      break;
    }
    case "GET_DAY_FULFILLED": {
      state = { ...state, daysForSelect: action.payload, pending: false };

      break;
    }

    default: {
    }
  }

  return state;
};

export default daysReducer;
