export default {
  locale: "fr",
  date_format: "YYYY-MM-DD",
  date_format_with_time: "YYYY-MM-DD HH:mm",
  date_small_text_no_year: "D MMM",
  other_language: "English",
  other_language_two_letter: "en",
  name: "Nom",
  cancel: "Annuler",
  save: "Sauvegarder",
  close: "Fermer",
  delete: "Effacer",
  details: "Détails",
  add: "Ajouter",
  success: "Succès",
  saved_successful: "Sauvegardé avec succès.",
  overview: "Vue d'ensemble",
  list: "Liste",
  filter: "Filtrer",
  reset: "Réinitialiser",
  filter_by: "Filtrer par...",
  assessments: "Sondages",
  evaluated_by: "Évalué par:",
  see_result: "Consulter les résultats",
  internal_name: "Nom interne",
  remove: "Effacer",
  back: "Retour",

  contains: "Contient",
  ends_with: "Termine par",
  equal: "Égale",
  not_contains: "Ne contient pas",
  not_ends_with: "Ne termine pas par",
  not_equal: "N'égale pas",
  not_starts_with: "Ne commence pas par",
  starts_with: "Commence par",
  is_empty: "Est vide",
  except: "Excepté",

  field_required: "Le champ {{field}} est requis.",
  error_date_format: "La date doit être en format {{format}}",
  error_min_size: "{{field}} doit avoir au moins {{length}} item sélectionné",
  error_unique: "Le champ {{field}} doit être unique.",
  error_url: "Le champ {{field}} doit être un lien valide. Ex. https://test.url ou test.url",
  error_exclusive_with: "Le champ {{field}} est exclusif avec {{exclusiveField}}! Seulement un ou l'autre doit être sélectionné",
  error_date_is_after: "La valeur du champ {{field}} doit être apres la valeur du champ {{exclusiveField}}.",
  error_must_match: "Le champ {{field}} dois être identique avec le champ {{matchField}}",
  error_required_quill: "Le champ {{field}} est requis!",
  error_boolean_at_least_one: "Le champ {{field}} doit avoir au moins une valeur de sélectionnée",
  password_dont_match: "Les mots de passes ne correspondent pas.",

  login: "Connexion",
  username_placeholder: "Nom d'utilisateur",
  password_placeholder: "Mot de passe",
  remember_me: "Se rappeler de moi",
  or_log_in_using: "ou se connecter en utilisant",
  or_sign_up_using: "ou s'enregistrer en utilisant login using",
  registering_code: "Code d'enregistrement",
  registering_code_placeholder: "Entrer votre code",
  firstname_placeholder: "Entrer votre prénom",
  lastname_placeholder: "Entrer votre nom de famille",
  newPassword_placeholder: "Entrer le mot de passe choisi",
  confirmNewPassword_placeholder: "Entrer votre mot de passe à nouveau",
  register: "S'enregistrer",
  register_info:
    "Afin de pouvoir accéder à ce site et compléter votre questionnaire, veuillez s'il vous plait entrer les informations requises ci-dessous.",
  well_done: "Bien joué!",
  register_complete: "Vous vous êtes enregistré avec succès!",
  register_complete_footer: "Vous aller devoir confirmer votre courriel avant de pouvoir accéder à ce site.",
  email_placeholder: "Entrer votre courriel",
  go_to_login: "Aller à la page de connexion",
  code_unavailable: "Le code que vous avez entrer n'est plus disponible.",
  forgot_password_questionmark: "Mot de passe oublié?",
  password_recovery: "Récupération de mot de passe",
  password_recovery_info:
    "Entrer votre courriel puis cliquer sur 'Envoyer' afin de recevoir les informations de récupération de mot de passe par courriel.",
  send_recovery_link: "Envoyer",
  user_non_existant: "Le courriel entrer n'existe pas le système.",
  password_recovery_link_sent_successful: "Le courriel de récupération de mot de passe a été envoyé avec succès.",
  password_recovery_complete: "Vous avez réussi à faire une demande de récupération de mot de passe!",
  password_recovery_complete_footer:
    "Vous allez recevoir un courriel vous spécifiant quel étapes suivre pour récupérer votre mot de passe.",
  password_reset_complete: "Vous avez réinitialiser votre mot de passe avec succès!",
  password_reset_complete_footer: "Vous pouvez maintenant vous connecter en utilisant le nouveau mot de passe.",
  name_french: "Nom français",
  name_english: "Nom anglais",
  created_date: "Date de création",
  edit_and_create: "Création et Modification",
  code: "Code",
  form_title: "Formulaire",
  last_modified_date: "Dernière modification",
  number: "Identifiant",
  unique_name: "Nom unique",
  nameEnglish: "Nom anglais",
  nameFrench: "Nom français",
  general_information: "Information Générale",
  create: "Création",
  contracts: "Contrats",
  products: "Produits",
  product: "Produit",
  options: "Options",
  add_options: "Ajouter une option",
  add_products: "Ajouter un produit",
  add_contracts: "Ajouter un contrat",
  edit_contract: "Modifier le contrat",
  delete_contract: "Effacer le contrat",
  parameters: "Paramètres",
  pricing: "Coûts",
  summary: "Sommaire",
  next: "Suivant",
  previous: "Précédent",
  product_types: "Type de produits",
  filters: "Filtres",
  product_types_placeholder: "Sélectionner un type de produit",
  available_products: "Produits disponibles",
  available_options: "Options disponibles",
  product_cart: "Panier",
  current_items: "Choix précédents",

  application: "S'applique à",
  validity: "Plage de validité",
  from: "De",
  to: "À",
  providers: "Fournisseurs",
  provider: "Fournisseur",
  provider_placeholder: "Choisissez des fournisseurs",
  establishments: "Établissements",
  establishment_placeholder: "Choisissez des établissements",
  subtotal: "Sous-total",
  general_details: "Détails généraux",
  taxes: "Taxes et frais",
  tax_placeholder: "Choisissez les taxes applicables",
  fees: "Frais spéciaux",
  freebieType_Id: "Type de rabais",
  freebieTypeOperator_Id: "Opérateur",
  detailValue: "Valeur",
  feeValue: "Valeur du frais",
  feeType_Id: "Type de frais",
  productType_Ids: "Type de produit applicables",
  productType_Ids_placeholder: "Veuillez sélectionner les types de produit applicables",
  productType_Id: "Type de produit applicable",
  productType_Id_placeholder: "Veuillez sélectionner le type de produit applicable",
  category_Id: "Type de catégorie applicable",
  category_Id2: "Type de catégorie",
  category_Id_placeholder: "Veuillez sélectionner le type de catégorie applicable",
  productType: "Type de produit",
  edit_this_contract: "Modifier ce contrat",
  no_dates_selected: "Aucune dates sélectionnées",
  date_Ids: "Date applicables",
  freebies_title: "Politique de réduction ou gratuité",
  freebies: "Réductions ou gratuités",
  applies_to_all_dates: "S'applique en tout temps",
  edit_freebies: "Ajout et modification de gratuité",
  freebies_information:
    "Veuillez créer autant de reductions ou gratuité que nécessaire. Assurez vous que les réduction sont mutuellement exclusifs.",
  criterias: "Critères",
  effects: "Effets",
  dates: "Dates",
  date_Ids_placeholder: "Veuillez sélectionner les dates applicables",
  product_type: "Type de produit",
  actions: "Actions",
  products_configuration: "Configuration de produits",
  product_name: "Nom de produit",

  edit_provider: "Modifier les produits du fournisseur",
  edit_product: "Modifier le produit",
  remarks: "Remarques",
  remarkType_Id: "Type de remarque",
  remarkType_Id_placeholder: "Veuillez sélectionner un type de remarque",
  description: "Description",
  productTypes: "Types de produit",
  type: "Type",
  noResult: "Aucun résultat",
  is_per_product: "Calculé par produit",

  edit_establishment: "Modifier l'établissement",
  delete_establishment: "Supprimer l'établissement",
  establishment: "Établissement",
  address: "Adresse",
  postalCode: "Code postal",
  phones: "Téléphones",
  id: "Identifiant",
  go_to_establishment: "Aller à l'établissement",
  edit_establishment_products: "Aller aux produits et contrats de l’établissement",
  create_product: "Créer un produit",
  edit_or_create_product: "Créer ou modifier un produit",
  edit_this_product: "Modifier ce produit",
  edition: "Modification",
  no_data_found: "Aucune données trouvées",
  username_or_password_incorrect: "Nom d'utilisateur ou mot de passe incorrect.",
  taxType_Id: "Type de taxe",
  taxType_Id_placeholder: "Sélectionner un type de taxe",
  city: "Ville",
  value: "Valeur",
  edit_this_product_contract: "Modifier les contrats associés à ce produit",
  contract: "Contrat",
  create_or_edit: "Création ou modification",
  isActive: "Est Actif",
  status: "État",
  deactivate: "Désactiver",
  activate: "Activer",
  activated_successfully: "Activé avec succès.",
  payments_imported_success: "Paiements importés avec succès",
  deactivated_successfully: "Désactivé avec succès.",
  deleted_successfully: "Supprimé avec succès.",

  contracts_placeholder: "Sélectionner un contrat",
  groups: "Taille de groupes",
  add_category: "Ajouter un groupe d'âge",
  add_criteria: "Ajouter un critère",
  age_categories: "Groupes d'âge",
  age_category: "Groupe d'âge",
  category: "Groupe d'âge",
  length_categories: "Durées",
  age_criteria_operators: "Opérateur pour le critère d'âge",
  age_criteria_operators_placeholder: "Sélectionner l'opérateur pour le critère d'age",
  group: "Taille de groupe",
  add_group: "Ajouter une taille de groupe",
  group_criteria_operators: "Opérateur pour le critère du groupe",
  group_criteria_operators_placeholder: "Sélectionner l'opérateur pour le critère du groupe",
  fit: "F.I.T.",

  add_tax: "Ajouter une taxe",
  edit_tax: "Modifier la taxe",
  defaultValue: "Valeur par defaut",
  edit: "Modification",
  isPercentage: "Taxe appliquée en pourcentage",
  delete_tax: "Supprimer la taxe",

  yes: "Oui",
  no: "Non",

  add_date: "Ajouter une plage de validité",
  sales_taxes: "Taxes de vente",
  sales_taxes_placeholder: "Sélectionner la ou les taxes de ventes applicables",
  country: "Pays",
  country_placeholder: "Sélectionner un pays",
  provinces: "Provinces",
  provinces_placeholder: "Sélectionner des provinces",

  projects: "Projets",
  project: "Projet",
  departures: "Départs",
  departure: "Départ",
  active: "actif",
  inactive: "inactif",

  departures_placeholder: "Sélectionner la liste des départs associés à ce contrat",
  projects_placeholder: "Sélectionner la liste des projets associés à ce contrat",
  projects_helper_placeholder: "Sélectionner la liste des projets afin d'ajouter ses départs à la liste ci-dessous",
  excluded_projects_placeholder: "Sélectionner la liste des projets à exclure à ce contrat",

  summary_general_info:
    "Le contrat se nomme {{contractName}} ({{otherLanguageName}}). Ce contrat est pour le type de catégorie {{service}} et a été identifié {{isActive}}. Il sera valide entre les dates suivantes : {{startDate}} et {{endDate}}. Ce contrat est disponible pour les compagnies: {{companies}}. ",
  summary_contract_periods: "Le contrat possède {{count}} périodes ({{periodes}}). ",
  summary_contract_groups: "Le contrat possède {{count}} groupes ({{groups}}). ",
  summary_contract_day_groups: "Le contrat possède {{count}} regroupement de journées ({{contractDayGroups}}). ",
  summary_contract_age_categories: "Le contrat possède {{count}} regroupement d'âge des participants ({{contractAgeCategories}}). ",
  summary_contract_freebie: "Le contrat possède {{count}} gratuitées ({{contractFreebies}}). ",
  summary_contract_departure_group: "Le contrat possède {{count}} regroupement de départ ({{contractDepartureGroups}}). ",
  summary_date_line: "du {{start}} au {{end}}",

  maximumNumberOfUser: "Nombre maximum d'utilisateur",
  specifications: "Spécifications produit",
  comparators: "Comparateur",
  age: "Âge",
  comparators_placeholder: "Sélectionner un comparateur",
  contract_type: "Configuration du contrat",
  has_age_categories: "Contient des prix différent basé sur l'âges du participant",
  has_age_categories_tooltip: "Exemple: Prix enfants et prix adultes",
  has_pricing_by_group: "Contient des prix différents selon la taille du groupe",
  has_pricing_by_group_tooltip: "Exemple: 10 participants et moins/ 11 participants et plus",
  has_pricing_by_length: "Contient des prix différents basé sur la durée (location)",
  has_freebies: "Contient des politiques de réduction ou gratuité",
  has_freebies_tooltip: " Exemples: rabais pour les guides/chauffeurs, prestations en gratuité à partir de..., etc",
  has_contract_periods: "Contient des prix différent basé sur des périodes",
  has_contract_periods_tooltip: "Exemple: Haute et Basse saison, événements, etc.",
  has_specificProjectDeparture: "Contient des prix différents selon les projets/départs",
  has_specificProjectDeparture_tooltip: "Vous devrez sélectionner tous les projets et/ou départs pour lesquels les prix s’appliquent",
  length_category: "Durées",
  add_length: "Ajouter une durée",
  duration: "Durée",
  durations: "Duration",
  durations_placeholder: "Sélectionner une duration",

  hasAdditionalPricing: "Contient des frais additionnels pour la durée",
  additionalPricing: "Frais additionnel pour la durée",
  specification: "Spécification",
  isAdditionalPricingPerPerson: "Applicable pour chaques personnes",
  maximumParticipantPerGuide: "Nombre de participant maximum par guide",

  add_freebie: "Ajouter une politique de réduction ou gratuité",
  freebie_criteria_operators: "Opérateur pour le critère de la gratuité",
  freebie_criteria_operators_placeholder: "Sélectionner l'opérateur pour le critère de la gratuité",

  periods: "Périodes",
  add_period: "Ajouter une période",
  specific_project_departures: "Projets/Départs",
  except: "À l'exception de",
  project_departures: "Projets et départs",
  is_percentage: "Calculé en pourcentage",
  is_sales_taxable: "Taxable",
  is_per_person: "Calculé par personne",
  tax_type_target_required: "Une taxe ou un frais nécessite une définition de calcul",
  some_tax_type_application_required: "Une taxe ou un frais calculé(e) par personne ou par produit nécessite une application de paiement",

  establishment_configuration: "Configurations de l'établissement",
  contracts_tab: "Structures de contrats",
  products_tab: "Produits et listes de prix",
  create_contract_tt: "Créer un nouveau contrat pour cet établissement",
  create_contract: "Créer un nouveau contrat",
  show_active_only: "Afficher uniquement les contrats actifs",
  search_contract: "Rechercher un contrat",
  search_product: "Rechercher un produit",
  edit_establishment_tt: "Modifier les propriétés de l'établissement (taxes)",
  establishment_tab: "Établissement - Taxes et frais",

  coordinates: "Coordonnées",
  generate_prices: "Générer les prix",
  create_product_placeholder: "Créer un produit pour cet établissement",
  create_price_list: "Créer une liste de prix",
  create_price_list_placeholder: "Créer une liste de prix pour les produits sélectionnés",
  merge_product_placeholder: "Fusionner les produits sélectionnés",
  merge_products: "Fusionner les produits",
  number_for_company: "Numéro pour la compagnie",
  number_for_company_tooltip: "C'est ce numéro qui se reporte sur le Day by Day, Rapport de réservation et Liste d'hébergement",
  freebie: "Gratuité",

  select_main_product: "Sélectionner le produit principal",
  merge_successfully: "La fusion des produits a été effectué avec succès",
  merge_warning_placeholder:
    "Le produit principal sélectionné sera conservé. Les autres produits seront supprimés, les liens dans les évènements, s'il a lieu, sera changer pour le produit principal sélectionné.",
  establishment_taxes: "Taxes configurés au niveau de l'établissement",
  product_taxes: "Taxes du produit",
  companies: "Compagnies",
  companies_placeholder: "Sélectionner les compagnies",
  drop_files_here: "Déposer un fichier ou cliquer ici",
  drop_files_here_active: "Déposer le fichier",
  northwind_providers: "Aller aux fournisseurs Northwind",
  northwind_establishments: "Aller aux établissements Northwind",
  go_to_provider: "Aller au fournisseur Northwind",
  go_to_establishment_nw: "Aller à l'établissement Northwind",
  go_to_reservation_nw: "Aller au réservation de l'établissement Northwind",
  group_namefrench_placeholder: "Ex: 20 personnes et moins",
  group_nameenglish_placeholder: "Ex: 20 people or less",
  period_header_information:
    "Veuillez créer autant de périodes que nécessaire. Assurez-vous que les périodes sont mutuellement exclusives et couvrent toutes les dates où il pourrait y avoir des événements.",
  group_header_information:
    "Veuillez créer autant de tailles de groupe que nécessaire. Assurez vous que les tailles de groupe sont mutuellement exclusives et couvrent toutes les tailles possibles.",
  age_category_header_information:
    "Veuillez créer autant de groupes d'âge que nécessaire. Assurez vous que les groupes sont mutuellement exclusifs et couvrent tous les âges. Pour les groupes d'âges enfants, il faudra que la date de naissance du voyageur soit remplie. Un voyageur dont la date de naissance n'est pas remplie sera considéré comme adulte par défaut.",

  cannot_delete_producttype:
    "Si cette catégorie a été sélectionnée par erreur, nous suggérons de modifier les événements concernés pour ne plus qu'il y ait d'événements associés avant de revenir pour enlever la catégorie ici. Si tous les événements associés sont dans des années antérieures, vous pouvez aussi désactiver ce produit, et en créer un nouveau avec les bonnes catégories.",
  error: "Erreur",
  dateStart: "Date de début",
  dateEnd: "Date de fin",
  contract_files: "Fichiers",
  contract_Id_placeholder: "Choisir le contrat à utiliser",
  contract_periods: "Périodes",
  contract_period: "Période",
  single: "Occupation simple",
  double: "Occupation double",
  triple: "Occupation triple",
  quad: "Occupation quad",
  twin: "Occupation twin",
  change_language: "Changer la langue",
  form: "Formulaire",
  app: "Application",
  fr: "Français",
  see_this_product_pricing: "Voir les prix configurés",
  project_departures_information:
    "Créer des regroupements de départs pour lesquels vous voulez paramétrer les mêmes prix. Lorsque vous créerez de nouveaux projets/départs, n'oubliez pas de revenir les ajouter au bon regroupement.",
  project_departures_information_except:
    "Vous pouvez sélectionner un projet pour lequel ce contrat s’applique, et ensuite ajouter des départs qui font exception, c’est-à-dire que ce contrat ne s’applique pas à ces départs. Vous devrez créer un autre contrat pour ces départs.",
  contract_taxes: "Taxes",
  regenerate: "Regénerer",
  payment_calculation: "Application du paiement",
  applicable_sales_taxes: "Taxes de vente applicables",
  regenerate_contract: "",
  payment_target: "Calculé",
  payment_application: "Application du paiement",
  category_Ids: "Catégories",
  clear_filters: "Effacer les filtres",
  table_view: "Vue en mode tableau",
  add_contract_departure_group: "Ajouter un groupe de départs",
  contract_departure_group: "Groupe de départs",
  add_departures_from_projects: "Ajouter les départs de ces projets",
  delete_product: "Effacer ce produit",
  delete_product_modal_title: "Confirmation",
  delete_product_warning: "Êtes vous certain de vouloir effacer ce produit?",
  delete_contract_modal_title: "Confirmation",
  delete_contract_warning: "Êtes vous certain de vouloir effacer ce contrat?",
  cancel_sort: "Effacer le tri",
  minimum_capacity: "Capacité minimale (en nb de personnes) par produit",
  maximum_capacity: "Capacité maximale (en nb de personnes) par produit",
  minimum_age: "Âge minimum",
  capacity: "Capacité (nbre de personne)",

  deactivate_product_modal_title: "Désactivation d'un produit",
  deactivate_product_warning:
    "Ce produit est actuellement utilisé dans certains Projets/Departs, c'est pour cette raison que ce produit ne peux pas être désactivé pour cette année.",
  confirm_deactivate_product_warning:
    "Voulez-vous désactiver le produit <strong>{{productName}}</strong> à partir de l'année <strong>{{year}}</strong>?",
  free_products: "Produits additionnels inclus (gratuit)",
  free_products_placeholder: "Choisissez des produits qui seront inclus avec ce produit",

  duplicate_contract: "Dupliquer ce contrat",
  duplicate_successful: "Dupliqué avec succès.",
  duplicate_contract_modal_info: "Voulez-vous vraiment dupliquer ce contract :",
  duplicate_contract_modal_new_name: "Veuillez fournir le nom pour le nouveau contract.",
  duplicate: "Dupliquer",

  child_age: "Âge maximum pour être considéré comme un enfant (inclusif)",

  freebie_products_placeholder: "Sélectionner des Produits",
  guide: "Guide",
  driver: "Chauffeur",
  traveler: "Voyageur",
  reduction_percentage: "Pourcentage de réduction",
  quantity: "Quantité",
  detail_products_placeholder: "Sélectionner les produits réduits",

  has_pricing_by_days: "Contient des prix différent basé sur la journée",
  has_price_by_days_tooltip: "Exemple: Fins de semaine et/ou Lundi au vendredi",
  price_by_days: "Journées",

  specific_days: "Regroupement de journée",
  specific_days_information: "Créer des regroupements de journées pour lesquels vous voulez paramétrer les mêmes prix.",
  add_specific_days: "Ajouter un regroupement de journées",
  days: "Journées",
  days_placeholder: "Sélectionner les journées associées à ce regroupement",
  contract_day_group: "Groupe de journée",

  contract_type_disabled_info: "Vous ne pouvez pas modifier les configuration car il y a des products associés à ce contrat",
  delete_confirmation: "Confirmation de la suppression",
  delete_confirmation_message:
    "L'élément ayant le nom {{name}} est associé a des prix dans un ou plusieurs contrats. Supprimer cet élément supprimera aussi les prix associées.",
  date_periods: "Périodes",
  view_summary_contract: "Voir le sommaire",
  summary_contract: "Sommaire du contrat",
  contain_errors: "Le formulaire contient certaines erreurs",
  freebies_required: "Un contrat avec la spécification des gratuités et réduction doit avoir au moins une gratuité",
  periods_required: "Un contrat avec la spécification des périodes doit avoir au moins une periode",
  periods_dates_overlap: "L'une des périodes chevauche une autre période",
  groups_required: "Un contrat avec la spécification des groups doit avoir au moins un group",
  price_by_days_required: "Un contrat avec la spécification des prix par journée doit avoir au moins un regroupement de journée",
  price_by_days_overlaps: "Certaines journées se chevauchent",
  price_by_days_missing_day: "Les regroupements de journée doivent couvrir tous les jours de la semaine",
  criteria_overlap: "Certains critères se chevauchent",
  project_departures_required: "Un contrat avec la spécification des prix par projet doit avoir au moins un regroupement de projet",
  departure_not_unique: "Certain départ se retrouvent dans plusieurs groupements",
  dates_outside_contrat_range: "Certaines dates sont à l'extérieur de la durée du contrat",
  freebies_details_required: "Un contrat avec des gratuités ou réductions doit avoir au moins un detail de reductions ou gratuités",
  age_categories_required: "Un contrat avec la spécification d'âge du participant doit avoir au moins un regroupement d'âge",
  freebies_products_required: "Un contrat avec des gratuités ou réductions doit avoir au moins un critère produit",
  specific_company_information: "Informations spécifiques à {{companyName}}",
  reservations_instructions: "Instructions de Réservation / Annulation",
  reservations_instructions_placeholder:
    "Ajouter ici des précisions sur les conditions et politiques du fournisseur, des compléments d'informations sur les prestations, etc. Cette inforamtion n'est pas reportée nulle part",
  day_by_day_instructions: "Instructions Day By Day",
  day_by_day_instructions_placeholder:
    "Ajouter ici des informations qui se retrouveront sur tous les Day by Dayt, dans la page du jour où il y a un événement chez cet établissement",
  reservation_type_id: "Type de réservation",
  reservation_type_id_tooltip: "Détermine le statut de réservation par defaut (module réservation)",
  is_taxable: "Est taxable",
  homeTitle: "Home",
  home: "Accueil",
  reservations: "Réservations",
  billing: "Facturation",
  manage: "Gérer",
  reports: "Rapports",
  purchase_order: "Bon de commande",
  listCurrentFolder: "Liste des dossiers en cours",
  calendar: "Calendrier",
  reservations_by_status: "Réservations par statut",
  suppliers: "Fournisseurs",
  agencies: "Agences",
  user: "Utilisateur",
  guides: "Guides",
  biglist: "Biglist",
  fid_transfer: "Transfert FID",
  operations: "Opérations",
  current: "Current",
  individual_camping: "Camping individuel",
  price: "Prix",
  adult_number: "Nombre d'adultes inclus",
  child_number: "Nombre d'enfants inclus",
  additionnal_price_adult: "Prix par adulte additionnel",
  additionnal_price_child: "Prix par enfant additionnel",
  price_adult: "Prix par adulte",
  price_child: "Prix par enfant",
  ferry: "Traversier",
  group_camping: "Camping de groupe",
  minimum_person_number: "Nombre minimum de personne payante (par personne)",
  maximum_person_number: "Nombre maximum de personne (par produit)",
  additionnal_price_person: "Prix par personne additionnelle",
  vehicule_price: "Prix véhicule",

  à: "Capacité",
  non_standard_room: "Hébergement non standard",
  standard_room: "Hébergement standard",
  description_french: "Description française",
  description_english: "Description anglaise",
  isTaxExemption: "Exemption des taxes de vente",
  product_info_tab: "Modification produit",
  product_events_tab: "Utilisations",
  product_events: "Événements du produit",
  go_to_reservation_nw_project: "Aller au réservation du projet Northwind",
  search: "Recherche",
  additional_non_standard_information: "Information additionnel chambre non standard",
  qty_room: "Nombre de chambre",
  qty_bed: "Nombre de lit",
  additional_camping_information: "Information additionnel camping",
  qty_tent_by_site: "# de tente admise par site",
  qty_adults: "# d'adulte si spécifique",
  qty_children: "# d'enfants si spécifique",
  product_contracts_tab: "Contrats associés",
  product_associated_contracts: "Liste des contracts ayant des prix associé a ce produit",
  no_associated_contracts: "Aucun contrat associé a ce produit",
  delete_product_contract: "Supprimer les prix de ce contract",
  delete_associated_product_contract_modal_title: "Confirmation de la suppression des prix pour le contrat",
  delete_associated_product_contract_impact: "Ceci supprimera les PRIX enregistré pour ce produit pour le contrat suivant",
  delete_associated_product_contract_warning: "Êtes-vous sur de vouloir supprimer les prix associé a ce contrat?",
  delete_invoice_modal_title: "Confirmation du retrait de la facture",
  delete_invoice_impact: "Cette action va empêcher cette version de la facture d'être synchronisée avec Quickbooks à tout jamais.",
  delete_invoice_warning: "Êtes-vous sûr de vouloir procéder?",
  sync_invoice_modal_title: "Confirmation de la synchronisation de la facture",
  sync_invoice_impact: "Cette action va synchroniser cette version de la facture avec Quickbooks.",
  sync_invoice_warning: "Êtes-vous sûr de vouloir procéder?",
  delete_po_modal_title: "Confirmation du retrait du bon de commande",
  delete_po_impact: "Cette action va empêcher cette version du bon de commande d'être synchronisée avec Quickbooks à tout jamais.",
  delete_po_warning: "Êtes-vous sûr de vouloir procéder?",
  sync_po_modal_title: "Confirmation de la synchronisation du bon de commande",
  sync_po_impact: "Cette action va synchroniser cette version du bon de commande avec Quickbooks.",
  sync_po_warning: "Êtes-vous sûr de vouloir procéder?",
  invoice_change_date_modal_title: "Confirmation de la mise à jour de la date de création de la facture",
  invoice_change_date_impact:
    "Cette action remplacera la date de création de cette facture par la date sélectionnée. Cela aura un impact sur la date de transaction de Quickbook (TxnDate).",
  invoice_change_date_warning: "Êtes-vous sûr de vouloir procéder?",

  priceOnDemand: "Prix à la carte",
  priceHalfBoard: "Prix demi-pension",
  priceFullBoard: "Prix pension complète",
  fixedPrice: "Prix fixe",
  isFixed: "Est un prix fixe",
  delete_tax_type_title: "Confirmer la suppression d'une taxe/frais",
  delete_tax_type_warning: "Êtes-vous certain de vouloir supprimer cette taxe/frais?",
  modifing_tax_type_warning:
    "Modifiez le type Fixe/Variable a un impact important sur toute les Établissements, Produits et Contrat qui utilise cette taxe. Veuillez-vous assurer que cette modification est valide.",
  confirmer: "Confirmer",
  taxTypeFixed: "Fixe",
  taxTypeVariable: "Variable",
  reservation_type_id_placeholder: "Type de réservation",
  purchase_orders_to_sync: "Bons de commande à synchroniser",
  invoices_to_sync: "Factures à synchroniser",
  departure_date: "Date du départ",
  sync_date_asked: "Date sync.",
  retries: "Essais",
  po_amount: "Montant du PO",
  po_actions: "Actions PO",
  synchronize: "Synchroniser",
  guide_impacts_capacity: "Guide(s) inclut dans la capacité",
  skips_PO: "Ne requiert pas de bon de commande",
  windigocan_id: "Windigo Canada Id",
  windigousa_id: "Windigo USA Id",
  receptour_id: "Receptour Id",
  push_purchase_order: "Pousser bon de commande",
  push_invoice: "Pousser facture",
  valid: "Valide",
  transport_percentage: "% transport",
  po_is_invalid: "ID manquant (Agence/Fournisseur)",
  invoice_is_invalid: "ID manquant (Agence)",
  invoice_is_invalid_transport: "% de transport manquant",
  po_already_synched: "Il y a déjà un PO synchronisé pour ce départ/établissement",
  po_is_negative: "Le PO ne peut pas être synchronisé lorsqu'il est en négatif",
  invoice_already_synched: "Il y a déjà une facture synchronisé",
  invoice: "Facture",
  agency_name: "Nom Agence",
  invoice_amount: "Montant facture",
  invoice_actions: "Actions facture",
  hold: "Pause",
  unhold: "Dé-pauser",
  price_per_adult_explain:
    "Pour que le système ajoute le prix par personne au prix du véhicule, veuillez sélectionner Calculé par personne",
  groups_target_required: "La méthode de calcul de prix est requise",
  quickbooks_companies: "Compagnies Quickbooks/Token",
  contract_additional_configurations: "Configurations additionnelles du contrat",
  has_guide_price: "Contient des prix différents pour le(la) guide",
  has_guide_price_tooltip: "Exemples: Chambre moins chère, transport payé, etc.",
  has_driver_price: "Contient des prix différents pour le(la) conducteur(trice)",
  has_driver_price_tooltip: "Exemples: Chambre moins chère, transport payé, etc.",
  price_for_guide: "Prix pour le(la) guide",
  price_for_driver: "Prix pour le(la) conducteur(trice)",
  quickbooks_vendor: "Fournisseur Quickbooks",
  quickbooks_vendors: "Fournisseurs Quickbooks",
  quickbooks_customer: "Client Quickbooks",
  quickbooks_customers: "Clients Quickbooks",
  force_synchronize_payment: "Synchronizer les paiements",
  force_synchronize_payment_label: "#Facture quickbooks",
  NWInvoiceNotFound: "Facture Quickbooks introuvable dans Northwind",
  force_synchronize_payment_warning:
    "Veuillez entrer l'Id de la facture quickbooks dont vous voulez importer les paiments. Attention, cette action va importer tous les paiements quickbooks de la facture vers Northwind. Cependant, si un paiement a déjà été importé, il ne le sera pas une 2e fois.",
  specific_case_application: "Cas spécifique (facultatif)",
  usergroup: "Groupes",
  no_records: "Aucune données diponibles",
  po_without_sync: "Création du PO sans synchronisation",
  special_accommodation_qty_room_required: "Le nombre de chambre est requis",
  special_accommodation_qty_bed_required: "Le nombre de lit est requis",
  product_capacity_required: "La capacité est requise",
  camping_qty_tent_by_site_required: "Le nombre de tente admise par site est requise",
  camping_qty_adult_required: "Le nombre d'adultes inclus est requise",
  camping_qty_children_required: "Le nombre d'enfants inclus est requise",
};
