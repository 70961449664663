import axios from "axios";
import { HOST } from "config/config";
import cookie from "react-cookies";

import { QuickbooksState } from "utils/enums";

let access_token = cookie.load("nwpo_access_token");

export const appReducer = (
  state = {
    login: {},
    loginResult: {},
    isLoggingIn: false,
    isLoggedIn: false,
    roles: [],
    availableGroups: [],
    username: null,
    fullName: null,
    userCompanyId: 0,
    userGroupId: 0,
    access_token: access_token === "undefined" || !access_token ? null : access_token,
    errorMessages: [],
    profile: {},
    northWindUrl: null,
    isManager: false,
    quickbooksState: QuickbooksState.UNCONNECTED,
    quickbooksWindow: null,
  },
  action
) => {
  switch (action.type) {
    case "OPEN_QUICKBOOKS_LOGIN_WINDOW": {
      let quickbooksLoginWindow = window.open(
        `${HOST}/api/quickbooks/prompt-qb-login?username=${action.payload.username}&quickbooksCompanyId=${action.payload.quickbooksCompanyId}`,
        "targetWindow",
        "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=480,height=700"
      );

      return { ...state, quickbooksWindow: quickbooksLoginWindow };
    }
    case "CLOSE_QUICKBOOKS_LOGIN_WINDOW": {
      state.quickbooksWindow.close();

      return { ...state, quickbooksWindow: null };
    }

    case "SET_QB_STATE": {
      return { ...state, quickbooksState: action.payload.quickbooksState };
    }
    case "LOGIN_PENDING": {
      state = {
        ...state,
        isLoggingIn: true,
      };
      break;
    }
    case "LOGIN_FULFILLED": {
      let bearer = `bearer ${action.payload.access_token}`;
      axios.defaults.headers.common["Authorization"] = bearer;
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Pragma"] = "no-cache";

      if (action.payload.rememberme) {
        cookie.save("nwpo_access_token", action.payload.access_token);
      }
      let profile = {
        id: action.payload.id,
        fullName: action.payload.fullName,
      };
      state = {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        roles: action.payload.roles,
        access_token: action.payload.access_token,
        loginResult: action.payload,
        username: action.payload.username,
        fullName: action.payload.fullName,
        userCompanyId: action.payload.userCompanyId,
        userGroupId: action.payload.userGroupId,
        profile: profile,
        error: null,
        northWindUrl: action.payload.northWindUrl,
        isManager: action.payload.isManager,
        quickbooksState: action.payload.quickbooksState,
        availableGroups: action.payload.availableGroups,
      };
      break;
    }
    case "LOGIN_REJECTED": {
      state = {
        ...state,
        isLoggingIn: false,
        error: action.payload,
        redirectToEmailConfirmation: action.payload.response ? action.payload.response.data.redirectToEmailConfirmation : "",
        username: action.payload.response
          ? action.payload.response.data.redirectToEmailConfirmation
            ? action.payload.response.data.userName
            : ""
          : "",
      };
      break;
    }
    case "LOGOUT": {
      delete axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["Content-Type"];
      delete axios.defaults.headers.common["Accept"];
      delete axios.defaults.headers.common["Pragma"];

      cookie.remove("nwpo_access_token");

      state = {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        roles: [],
        token_type: null,
        access_token: null,
        loginResult: {},
        error: null,
      };
      break;
    }

    default: {
    }
  }

  return state;
};

export default appReducer;
