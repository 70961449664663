import axios from "axios";
import { HOST } from "config/config";

export function persistProductContractPriceDetail(updateProductPriceDetailModel) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      const productPriceDetail = {
        ...updateProductPriceDetailModel.contractPriceDetail,
      };

      updateProductPriceDetailModel.fieldValues.forEach((fieldValue) => {
        productPriceDetail[fieldValue.fieldName] = fieldValue.value;
      });

      return axios
        .put(`${HOST}/api/contract-price-details`, productPriceDetail)
        .then((response) => response.data)
        .then((json) => {
          dispatch({
            type: "PERSIST_PRODUCT_CONTRACT_PRICE_DETAIL_FULFILLED",
            payload: {
              contract: updateProductPriceDetailModel.contract,
              contractPriceDetail: json,
            },
          });

          dispatch({
            type: "SHOW_MESSAGE",
            payload: {
              type: "success",
              titleKey: "success",
              messageKey: "saved_successful",
            },
          });

          return resolve();
        })
        .catch((error) => {
          dispatch({
            type: "SHOW_MESSAGE",
            payload: {
              type: "error",
              titleKey: "error",
              message: error.response,
            },
          });
          return reject();
        });
    });
}
