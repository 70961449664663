import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/utils";

import { removeContractDepartureGroup, updateContractDepartureGroup, showConfirmDeleteWithPriceDetailModal } from "redux/actions/contracts";

import { Row, Col } from "reactstrap";
import InputWithLabel from "components/FormControls/InputWithLabel";
import { Multiselect } from "react-widgets";
import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";
import Spinner from "components/Spinner/Spinner";
import { processingProjectDepartureStart, processingProjectDepartureEnd } from "redux/actions/projects";

export default function ContractDepartureGroup({ contractDepartureGroup, contract, index, isEstablishmentContract, validator }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [projectIds, setProjectIds] = useState([]);

  const departuresReducer = useSelector((state) => state.departuresReducer);
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);
  const contractsReducer = useSelector((state) => state.contractsReducer);

  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  let labelReferenceName = isEnglish ? "labelEnglish" : "labelFrench";
  const { processingProjectDeparture } = projectsReducer;
  const currentProjectDeparture = [].concat.apply(
    [],
    contract.contractDepartureGroups.map((cdg) => cdg.departure_Ids || [])
  );

  var start = new Date(contractsReducer.establishmentContract.start);
  var end = new Date(contractsReducer.establishmentContract.end);

  const filteredDepartures = departuresReducer.departuresForSelect.filter(
    (d) =>
      (contractDepartureGroup.departure_Ids || []).includes(d.id) ||
      !currentProjectDeparture.includes(d.id) ||
      (new Date(d.departureDate) >= start && new Date(d.departureDate) <= end && projectIds.includes(d.projectId))
  );

  const filteredProjects = projectsReducer.projectsForSelect.filter((p) => p.date >= start.getFullYear() && p.date <= end.getFullYear());

  const handleUpdateContractDepartureGroup = (value, model, field) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;
    if (field === "departure_Ids") {
    }

    dispatch(updateContractDepartureGroup(model, isEstablishmentContract));
  };

  const handleRemoveContractDepartureGroup = (contractDepartureGroup) => {
    if (contract.hasProductPrice && contractDepartureGroup.id > 0) {
      dispatch(
        showConfirmDeleteWithPriceDetailModal(`${contractDepartureGroup.nameFrench} / ${contractDepartureGroup.nameEnglish}`, (_) =>
          dispatch(removeContractDepartureGroup(contractDepartureGroup, isEstablishmentContract))
        )
      );
    } else {
      dispatch(removeContractDepartureGroup(contractDepartureGroup, isEstablishmentContract));
    }

    validator.purgeFields();
  };

  const handleUpdateProjects = (value) => {
    setProjectIds(value);
  };

  const handleAddDepartureFromProjects = () => {
    dispatch(processingProjectDepartureStart()).then((_) => {
      let departures = filteredDepartures.filter((d) => projectIds.includes(d.projectId) && !d.isCanceled);
      //let currentDepartures = contractDepartureGroup.departure_Ids
      departures.forEach((d) => {
        if (!contractDepartureGroup.departure_Ids) {
          contractDepartureGroup.departure_Ids = [];
        }

        if (!contractDepartureGroup.departure_Ids.includes(d.id)) {
          contractDepartureGroup.departure_Ids.push(d.id);
        }
      });

      dispatch(updateContractDepartureGroup(contractDepartureGroup, isEstablishmentContract));
      dispatch(processingProjectDepartureEnd()).then((_) => setProjectIds([]));
    });
  };

  return (
    <React.Fragment>
      <div className={`card mt-3 zIndex-${10 - index}`}>
        <div className="card-body">
          <h5 className="card-title">
            <button
              className="btn btn-danger float-right"
              onClick={() => handleRemoveContractDepartureGroup(contractDepartureGroup)}
              disabled={!accountReducer.isManager}
            >
              <span className="fa fa-trash" />
            </button>
            {contractDepartureGroup[referenceName] || `${t("contract_departure_group")} ${index + 1}`}
          </h5>
          <div className="card-text">
            <hr />
            <Row>
              <Col md="6">
                <InputWithLabel
                  labelText={t("nameFrench")}
                  inputType="text"
                  fieldToUpdate="nameFrench"
                  objectToUpdate={contractDepartureGroup}
                  persistOnBlur
                  disabled={!accountReducer.isManager || (contract.hasProductPrice && contractDepartureGroup.id > 0)}
                  updateAction={handleUpdateContractDepartureGroup}
                  hasValidator
                  errorMessage={validator.message(t("nameFrench"), contractDepartureGroup.nameFrench, "required")}
                />
              </Col>
              <Col md="6">
                <InputWithLabel
                  labelText={t("nameEnglish")}
                  inputType="text"
                  fieldToUpdate="nameEnglish"
                  objectToUpdate={contractDepartureGroup}
                  persistOnBlur
                  disabled={!accountReducer.isManager || (contract.hasProductPrice && contractDepartureGroup.id > 0)}
                  updateAction={handleUpdateContractDepartureGroup}
                  hasValidator
                  errorMessage={validator.message(t("nameEnglish"), contractDepartureGroup.nameEnglish, "required")}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <hr />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="d-flex flex-nowrap">
                  <div className="form-group flex-fill">
                    <label className="form-control-label" htmlFor="contracts-projects">
                      {t("projects")}
                    </label>
                    <Multiselect
                      id="contracts-projects"
                      name="contracts-projects"
                      placeholder={t("projects_helper_placeholder")}
                      onChange={(e) => handleUpdateProjects(e.map((o) => o.id))}
                      value={projectIds}
                      valueField="id"
                      disabled={!accountReducer.isManager}
                      textField={labelReferenceName}
                      data={filteredProjects}
                      filter="contains"
                    />
                  </div>
                  <div className="ml-2">
                    <button
                      className="btn btn-primary button-field-equal"
                      type="button"
                      onClick={handleAddDepartureFromProjects}
                      disabled={!accountReducer.isManager}
                    >
                      <i className="fas fa-plus-circle"></i> {t("add_departures_from_projects")}
                    </button>
                  </div>
                </div>
              </Col>
              <Col md="12">
                <div className="form-group flex-fill">
                  {processingProjectDeparture ? (
                    <Spinner />
                  ) : (
                    <MultiSelectWithLabel
                      labelText={t("departures")}
                      id="departures"
                      name="departures"
                      selectPlaceholder={t("departures_placeholder")}
                      objectToUpdate={contractDepartureGroup}
                      updateAction={handleUpdateContractDepartureGroup}
                      fieldToUpdate="departure_Ids"
                      textField={labelReferenceName}
                      valueField="id"
                      disabled={!accountReducer.isManager || filteredDepartures.filter((x) => x.isCanceled)}
                      value={contractDepartureGroup.departure_Ids}
                      data={filteredDepartures}
                      filter="contains"
                      forceShowErrorState={contractDepartureGroup.isInvalid}
                      hasValidator
                      errorMessage={validator.message(t("days"), contractDepartureGroup.departure_Ids, "required")}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
