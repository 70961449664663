export const taxTypeTargetsReducer = (
  state = {
    taxTypeTargets: [],
    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_TAX_TYPE_TARGETS_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_TAX_TYPE_TARGETS_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_TAX_TYPE_TARGETS_FULFILLED": {
      state = { ...state, updating: false, updated: true, taxTypeTargets: action.payload.items };
      break;
    }
    case "GET_TAX_TYPE_TARGETS_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_TAX_TYPE_TARGET_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_TAX_TYPE_TARGET_FULFILLED": {
      let taxTypeTargets = state.taxTypeTargets;
      taxTypeTargets = taxTypeTargets.filter(s => s.id !== action.payload.id);
      taxTypeTargets.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        taxTypeTargets
      };
      break;
    }
    case "GET_TAX_TYPE_TARGETS_SELECT_FULFILLED": {
      let taxTypeTargets = state.taxTypeTargets;
      action.payload.forEach(taxTypeTarget => {
        if (!taxTypeTargets.some(t => t.id === taxTypeTarget.id)) {
          taxTypeTargets.push(taxTypeTarget);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        taxTypeTargets: taxTypeTargets
      };
      break;
    }
    case "GET_TAX_TYPE_TARGET_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default taxTypeTargetsReducer;
