import React from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/utils";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { addContractPeriodDate, removeContractPeriodDate } from "redux/actions/contracts";
import { Col } from "reactstrap";
import DatePickerWithLabel from "components/FormControls/DatePickerWithLabel";

function ContractPeriodDate({ date, validator, contractPeriod, isLast, updateAction, disabled }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accountReducer = useSelector((state) => state.accountReducer);

  const updateDate = (value, model, field, isBlurring) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;

    updateAction(model);
  };

  const handleAddContractPeriodDate = () => dispatch(addContractPeriodDate(contractPeriod));
  const handleRemoveContractPeriodDate = (contractPeriodDate) => dispatch(removeContractPeriodDate(contractPeriod, contractPeriodDate));

  return (
    <React.Fragment>
      <Col md="5">
        <DatePickerWithLabel
          labelText={t("from")}
          objectToUpdate={date}
          fieldToUpdate={`start`}
          updateAction={updateDate}
          dateFormat={t("date_format")}
          locale={t("locale")}
          persistOnBlur
          disabled={!accountReducer.isManager || disabled}
          hasValidator
          forceShowErrorState={date.isInvalid}
          errorMessage={validator.message(t("from"), date.start, "required|date_format")}
        />
      </Col>

      <Col md="5">
        <DatePickerWithLabel
          labelText={t("to")}
          objectToUpdate={date}
          fieldToUpdate={`end`}
          updateAction={updateDate}
          dateFormat={t("date_format")}
          locale={t("locale")}
          persistOnBlur
          disabled={!accountReducer.isManager || disabled}
          hasValidator
          forceShowErrorState={date.isInvalid}
          errorMessage={validator.message(
            t("to"),
            date.end,
            `required|date_format|date_is_after:${moment(date.start).format(t("date_format"))},${t("from")}`
          )}
        />
      </Col>
      <Col md="1" className="text-right">
        {isLast ? (
          <button
            className="btn btn-success "
            style={{ marginTop: "1.3rem" }}
            onClick={handleAddContractPeriodDate}
            disabled={!accountReducer.isManager}
          >
            <span className="far fa-plus-square" />
          </button>
        ) : (
          <button
            className="btn btn-danger "
            style={{ marginTop: "1.3rem" }}
            onClick={() => handleRemoveContractPeriodDate(date)}
            disabled={!accountReducer.isManager}
          >
            <span className="fa fa-trash" />
          </button>
        )}
      </Col>
    </React.Fragment>
  );
}

export default ContractPeriodDate;
