import React, { Component } from "react";
import InputRadio from "components/FormControls/Components/InputRadio";
import InputCheckbox from "components/FormControls/Components/InputCheckbox";
import InputNumber from "components/FormControls/Components/InputNumber";
import InputText from "components/FormControls/Components/InputText";

export default class InputWithLabel extends Component {
  render() {
    if (this.props.objectToUpdate === undefined) return null;

    switch (this.props.inputType) {
      case "radio":
        return <InputRadio props={this.props}></InputRadio>;
      case "checkbox":
        return <InputCheckbox props={this.props}></InputCheckbox>;
      case "number":
        return <InputNumber props={this.props}></InputNumber>;
      case "text":
        return <InputText props={this.props}></InputText>;
      default:
        return null;
    }
  }
}
