export const datesReducer = (
  state = {
    dates: [],
    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_DATES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_DATES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_DATES_FULFILLED": {
      state = { ...state, updating: false, updated: true, dates: action.payload.items };
      break;
    }
    case "GET_DATES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_DATE_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_DATE_FULFILLED": {
      let dates = state.dates;
      dates = dates.filter(s => s.id !== action.payload.id);
      dates.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        dates
      };
      break;
    }
    case "GET_DATES_SELECT_FULFILLED": {
      let dates = state.dates;
      action.payload.forEach(date => {
        if (!dates.some(t => t.id === date.id)) {
          dates.push(date);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        dates: dates
      };
      break;
    }
    case "GET_DATE_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default datesReducer;
