import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { addContractAge, removeContractAge, showConfirmDeleteWithPriceDetailModal } from "redux/actions/contracts";
import { setActiveStep } from "redux/actions/contracts";
import { Row, Col, Alert } from "reactstrap";
import AgeCategory from "./Components/AgeCategory";

export default function AgeCategories({ contract, steps, isEstablishmentContract, validator }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  useEffect(() => {
    let currentStep = steps.findIndex((s) => s.name === "age-categories");
    if (contractsReducer.activeStepIndex !== currentStep) dispatch(setActiveStep(currentStep));
  }, [contractsReducer.activeStepIndex, contractsReducer.steps, dispatch, steps]);

  const addAge = () => {
    dispatch(addContractAge(isEstablishmentContract));
  };

  const removeAge = (ageCategory) => {
    if (contract.hasProductPrice && ageCategory.id > 0) {
      dispatch(
        showConfirmDeleteWithPriceDetailModal(`${ageCategory.nameFrench} / ${ageCategory.nameEnglish}`, (_) =>
          dispatch(removeContractAge(ageCategory, isEstablishmentContract))
        )
      );
    } else {
      dispatch(removeContractAge(ageCategory, isEstablishmentContract));
    }

    validator.purgeFields();
  };

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h4 className="dashhead-title">{t("age_category")}</h4>
          <Alert type="primary" className="mt-2">
            {t("age_category_header_information")}
          </Alert>
          <hr />
        </div>
      </div>

      <Row>
        <Col md="12">
          <button className="btn btn-primary float-right" onClick={addAge} disabled={!accountReducer.isManager}>
            {t("add_category")}
          </button>
        </Col>
      </Row>
      {contract &&
        contract.ageCategories &&
        contract.ageCategories.map((a, index) => (
          <AgeCategory
            key={a.uniqueIdentifier}
            removeAge={removeAge}
            contract={contract}
            ageCategory={a}
            index={index}
            isEstablishmentContract={isEstablishmentContract}
            validator={validator}
          />
        ))}

      {contract && contract.ageCategories && contract.ageCategories.length > 0 && (
        <Row className={"mt-2"}>
          <Col md="12">
            <button className="btn btn-primary float-right" onClick={addAge} disabled={!accountReducer.isManager}>
              {t("add_category")}
            </button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}
