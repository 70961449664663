import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import InputWithLabel from "components/FormControls/InputWithLabel";
import { isEmpty } from "utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { updateEstablishment } from "redux/actions/establishments";
import { getEstablishmentReservationTypesForSelect } from "redux/actions/establishment-reservation-types";
import SelectWithLabel from "components/FormControls/SelectWithLabel";
import ReactTooltip from "react-tooltip";

function EstablishmentProviderCompanyInformation({ establishmentProviderCompany }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const establishmentsReducer = useSelector((state) => state.establishmentsReducer);
  const establishmentReservationTypesReducer = useSelector((state) => state.establishmentReservationTypesReducer);

  useEffect(() => {
    dispatch(getEstablishmentReservationTypesForSelect());
  }, [dispatch]);

  const handleUpdateCompany = (value, model, field) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;

    const establishment = establishmentsReducer.establishment;

    dispatch(updateEstablishment(establishment));
  };

  const labelName = t("locale") === "en" ? "labelEnglish" : "labelFrench";
  const { establishmentReservationTypesForSelect } = establishmentReservationTypesReducer;

  return (
    <Col md="12">
      <div className="card mt-2 zIndex-3">
        <div className="card-body">
          <h5 className="card-title">{t("specific_company_information", { companyName: establishmentProviderCompany.companyName })}</h5>
          <hr className="clearfix" />
          <div className="card-text">
            <Row>
              <Col md="3">
                <InputWithLabel
                  labelText={t("number_for_company")}
                  inputType="text"
                  fieldToUpdate="phoneNumber"
                  objectToUpdate={establishmentProviderCompany}
                  persistOnBlur
                  updateAction={handleUpdateCompany}
                  appendtooltip={
                    <>
                      <ReactTooltip id="tt_number_for_company" type="info" effect="solid" place="top" border />
                      <i
                        className="fas fa-info-circle text-info ml-1"
                        data-tip={t("number_for_company_tooltip")}
                        data-for="tt_number_for_company"
                      ></i>
                    </>
                  }
                />
              </Col>
              <Col md="3">
                <SelectWithLabel
                  filterable
                  labelText={t("reservation_type_id")}
                  selectPlaceholder={t("reservation_type_id_placeholder")}
                  data={establishmentReservationTypesForSelect}
                  textField={labelName}
                  valueField="id"
                  value={establishmentProviderCompany.reservationType_Id}
                  objectToUpdate={establishmentProviderCompany}
                  updateAction={handleUpdateCompany}
                  fieldToUpdate="reservationType_Id"
                  appendtooltip={
                    <>
                      <ReactTooltip id="tt_reservation_type_id" type="info" effect="solid" place="top" border />
                      <i
                        className="fas fa-info-circle text-info ml-1"
                        data-tip={t("reservation_type_id_tooltip")}
                        data-for="tt_reservation_type_id"
                      ></i>
                    </>
                  }
                />
              </Col>
              <Col md="3">
                <label>{t("skips_PO")}</label>
                <InputWithLabel
                  inputType="checkbox"
                  fieldToUpdate="skipsPurchaseOrder"
                  objectToUpdate={establishmentProviderCompany}
                  value={establishmentProviderCompany.skipsPurchaseOrder}
                  persistOnBlur
                  disabled={establishmentProviderCompany.poWithoutSynchronization}
                  updateAction={handleUpdateCompany}
                />
              </Col>
              <Col md="3">
                <label>{t("po_without_sync")}</label>
                <InputWithLabel
                  inputType="checkbox"
                  fieldToUpdate="poWithoutSynchronization"
                  objectToUpdate={establishmentProviderCompany}
                  value={establishmentProviderCompany.poWithoutSynchronization}
                  persistOnBlur
                  disabled={establishmentProviderCompany.skipsPurchaseOrder}
                  updateAction={handleUpdateCompany}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className={`form-group`}>
                  <label className={`form-control-label`} htmlFor={"reservationInstruction"}>
                    {t("reservations_instructions")}
                  </label>
                  <textarea
                    className={`form-control`}
                    name={`reservationInstruction`}
                    placeholder={t("reservations_instructions_placeholder")}
                    rows={"4"}
                    type={"textarea"}
                    defaultValue={establishmentProviderCompany.reservationInstructions}
                    onBlur={(e) => handleUpdateCompany(e.target.value, establishmentProviderCompany, "reservationInstructions", true)}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <div className={`form-group`}>
                  <label className={`form-control-label`} htmlFor={"dayByDayInstruction"}>
                    {t("day_by_day_instructions")}
                  </label>
                  <textarea
                    className={`form-control`}
                    name={`dayByDayInstruction`}
                    placeholder={t("day_by_day_instructions_placeholder")}
                    rows={"4"}
                    type={"textarea"}
                    defaultValue={establishmentProviderCompany.dayByDay}
                    onBlur={(e) => handleUpdateCompany(e.target.value, establishmentProviderCompany, "dayByDay", true)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12"></Col>
            </Row>
          </div>
        </div>
      </div>
    </Col>
  );
}

export default EstablishmentProviderCompanyInformation;
