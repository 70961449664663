import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import LeftNavBar from "layouts/Components/LeftNavBar";
import TopNavBar from "layouts/Components/TopNavBar";

import Home from "views/Home/Home";

import MenuTooltips from "./Components/MenuTooltips";
import Providers from "views/Providers/Providers";
import Provider from "views/Providers/Provider";
import Establishments from "views/Establishments/Establishments";
import Establishment from "views/Establishments/Establishment";
import Products from "views/Products/Products";
import Product from "views/Products/Product";
import ProductContracts from "views/Contracts/ProductContracts";
import TaxTypes from "views/TaxeTypes/TaxTypes";
import TaxType from "views/TaxeTypes/TaxType";
import SalesTaxes from "views/SalesTaxes/SalesTaxes";
import SalesTax from "views/SalesTaxes/SalesTax";
import { Company } from "Enums/company";
import QuickbooksAccounts from "views/QuickbooksAccounts/QuickbooksAccounts";
import QuickbooksCustomers from "views/QuickbooksCustomers/QuickbooksCustomers";
import QuickbooksVendors from "views/QuickbooksVendors/QuickbooksVendors";
import QuickbooksCompanies from "views/QuickbooksCompanies/QuickbooksCompanies";
import QuickbooksPurchaseOrderSynchronizations from "views/QuickbooksPurchaseOrderSynchronizations/QuickbooksPurchaseOrderSynchronizations";
import QuickbooksInvoiceSynchronizations from "views/QuickbooksInvoiceSynchronizations/QuickbooksInvoiceSynchronizations";

const ApplicationLayout = () => {
  const appReducer = useSelector((store) => store.appReducer);
  const accountReducer = useSelector((store) => store.accountReducer);

  const { userCompanyId } = accountReducer;

  useEffect(() => {
    var r = document.querySelector(":root");
    switch (userCompanyId) {
      case Company.WINDIGO:
        r.style.setProperty("--primary-color", "#F68C1D");
        break;
      case Company.RECEPTOUR:
        r.style.setProperty("--primary-color", "#233572");
        break;

      default:
        break;
    }
  }, [userCompanyId]);

  return (
    <React.Fragment>
      {!appReducer.isWrapped ? (
        <div className="with-iconav">
          <TopNavBar />
          <LeftNavBar />
          <div className="container mt-6">
            <Switch>
              <Route path="/establishments" exact component={Establishments} />
              <Route path="/establishments/:id" component={Establishment} />
              <Route path="/providers" exact component={Providers} />
              <Route path="/providers/:id" component={Provider} />
              <Route path="/products" exact component={Products} />
              <Route path="/products/:id" exact component={Product} />
              <Route path="/products/:id/contracts" component={ProductContracts} />
              <Route path="/sales-taxes" exact component={SalesTaxes} />
              <Route path="/sales-taxes/:id" exact component={SalesTax} />
              <Route path="/tax-types" exact component={TaxTypes} />
              <Route path="/tax-types/:id" exact component={TaxType} />
              <Route path="/quickbooks-accounts" exact component={QuickbooksAccounts} />
              <Route path="/quickbooks-customers" exact component={QuickbooksCustomers} />
              <Route path="/quickbooks-vendors" exact component={QuickbooksVendors} />
              <Route path="/quickbooks-companies" exact component={QuickbooksCompanies} />
              <Route path="/quickbooks-sync-purchase-orders" exact component={QuickbooksPurchaseOrderSynchronizations} />
              <Route path="/quickbooks-sync-invoices" exact component={QuickbooksInvoiceSynchronizations} />
              <Redirect to="/providers" />
            </Switch>
          </div>
          <MenuTooltips />
          <div className="container mt-5">
            <hr />
            <footer>
              <p>&copy; {new Date().getFullYear()}</p>
            </footer>
          </div>
        </div>
      ) : (
        <div className="container mt-6">
          <Switch>
            <Route path="/" exact component={Home} />
            {/* <Route path="/inspection-lists" exact component={InspectionForms} />
            <Route path="/inspection-lists/create/(blade)?/:blade_Id?/(form)?/:form_Id?" exact component={CreateInspectionForm} />
            <Route path="/inspection-lists/:id" exact component={InspectionForm} /> */}
          </Switch>
        </div>
      )}
    </React.Fragment>
  );
};

export default ApplicationLayout;
