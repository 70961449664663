import axios from "axios";
import { HOST } from "config/config";
import tableDataQueryStringBuilder from "utils/tableData";

export function getCompanies(tableDataParam, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().companiesReducer.updating || forceRefresh) {
      if (tableDataParam === undefined) {
        return new Promise(resolve =>
          dispatch({
            type: "GET_COMPANIES_PENDING",
            payload: axios
              .get(`${HOST}/api/companies`)
              .then(response => response.data)
              .then(json => {
                dispatch({
                  type: "GET_COMPANIES_FULFILLED",
                  payload: {
                    items: json
                  }
                });

                resolve(json);
              })
              .catch(error =>
                dispatch({
                  type: "SHOW_MESSAGE",
                  payload: {
                    type: "error",
                    titleKey: "error",
                    message: error.message
                  }
                })
              )
          })
        );
      }

      return dispatch({
        type: "GET_COMPANIES_PENDING",
        payload: axios
          .get(`${HOST}/api/companies`, {
            paramsSerializer: params => tableDataQueryStringBuilder(params),
            params: tableDataParam
          })
          .then(response => response.data)
          .then(json => {
            if (tableDataParam) {
              dispatch({
                type: "UPDATE_COMPANIES_TABLEDATAPARAM",
                payload: tableDataParam
              });
            }

            dispatch({
              type: "GET_COMPANIES_FULFILLED",
              payload: json
            });
          })
          .catch(error =>
            dispatch({
              type: "GET_COMPANIES_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}

export function getCompany(companyId, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().companiesReducer.updating || forceRefresh) {
      if (companyId === 0) {
        return dispatch({
          type: "GET_COMPANY_FULFILLED",
          payload: {
            id: 0,
            internalName: "",
            nameFrench: "",
            nameEnglish: "",
            companyType_Id: 0
          }
        });
      }
      return dispatch({
        type: "GET_COMPANY_PENDING",
        payload: axios
          .get(`${HOST}/api/companies/${companyId}`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_COMPANY_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "GET_COMPANY_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}

export function getCompaniesForSelect() {
  return (dispatch, getState) => {
    if (!getState().companiesReducer.selectUpdating) {
      return dispatch({
        type: "GET_COMPANIES_FOR_SELECT_PENDING",
        payload: axios
          .get(`${HOST}/api/filters/companies`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_COMPANIES_SELECT_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message
              }
            })
          )
      });
    }
  };
}
