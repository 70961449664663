import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  addContractPeriod,
  removeContractPeriod,
  updateContractPeriod,
  showConfirmDeleteWithPriceDetailModal,
} from "redux/actions/contracts";
import { setActiveStep } from "redux/actions/contracts";
import { Row, Col, Alert } from "reactstrap";
import ContractPeriod from "./Components/ContractPeriod";

export default function ContractPeriods({ contract, validator, steps, isEstablishmentContract }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  useEffect(() => {
    let currentStep = steps.findIndex((s) => s.name === "contract-periods");
    if (contractsReducer.activeStepIndex !== currentStep) dispatch(setActiveStep(currentStep));
  }, [contractsReducer.activeStepIndex, contractsReducer.steps, dispatch, steps]);

  const handleAddContractPeriod = () => {
    dispatch(addContractPeriod(isEstablishmentContract));
  };

  const handleRemoveContractPeriod = (rangeDate) => {
    if (contract.hasProductPrice && rangeDate.id > 0) {
      dispatch(
        showConfirmDeleteWithPriceDetailModal(`${rangeDate.nameFrench} / ${rangeDate.nameEnglish}`, (_) =>
          dispatch(removeContractPeriod(rangeDate, isEstablishmentContract))
        )
      );
    } else {
      dispatch(removeContractPeriod(rangeDate, isEstablishmentContract));
    }

    validator.purgeFields();
  };

  const handleUpdateContractPeriod = (contractPeriod, index) => {
    dispatch(updateContractPeriod(contractPeriod, index, isEstablishmentContract));
  };

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h4 className="dashhead-title">{t("contract_periods")}</h4>
          <Alert type="primary" className="mt-2">
            {t("period_header_information")}
          </Alert>
          <hr />
        </div>
      </div>

      <Row>
        <Col md="12">
          <button className="btn btn-primary float-right" onClick={handleAddContractPeriod} disabled={!accountReducer.isManager}>
            {t("add_period")}
          </button>
        </Col>
      </Row>
      <Row>
        {contract &&
          contract.contractPeriods &&
          contract.contractPeriods.map((cp, index) => (
            <ContractPeriod
              key={cp.uniqueIdentifier}
              updateAction={handleUpdateContractPeriod}
              removeContractPeriod={handleRemoveContractPeriod}
              contract={contract}
              contractPeriod={cp}
              index={index}
              validator={validator}
            />
          ))}
      </Row>
      {contract && contract.contractPeriods && contract.contractPeriods.length > 1 && (
        <Row>
          <Col md="12">
            <button className="btn btn-primary float-right" onClick={handleAddContractPeriod} disabled={!accountReducer.isManager}>
              {t("add_period")}
            </button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}
