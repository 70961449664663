import axios from "axios";
import { HOST } from "config/config";

export function getQuickbooksCompanies() {
  return (dispatch) => {
    return dispatch({
      type: "GET_QUICKBOOKS_COMPANIES_PENDING",
      payload: axios
        .get(`${HOST}/api/quickbooks/companies`)
        .then((response) => response.data)
        .then((json) =>
          dispatch({
            type: "GET_QUICKBOOKS_COMPANIES_FULFILLED",
            payload: json,
          })
        ),
    });
  };
}
