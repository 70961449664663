import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { hideSyncPurchaseOrderConfirmationModal, forcePushPurchaseOrder } from "redux/quickbooks/purchase-order/purchase-order-action";

export default function SyncPurchaseOrderModal() {
  const purchaseOrderReducer = useSelector((state) => state.purchaseOrderReducer);

  const { showSyncPurchaseOrderModal, syncPurchaseOrderModalEntity } = purchaseOrderReducer;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSyncPurchaseOrder = () => {

    dispatch(forcePushPurchaseOrder(syncPurchaseOrderModalEntity.id)).then(() => {
      closeModal();
    });
  };

  const closeModal = () => {
    dispatch(hideSyncPurchaseOrderConfirmationModal());
  };

  if (!showSyncPurchaseOrderModal) return null;

  return (
    <Modal className="mw-100 w-50" isOpen={showSyncPurchaseOrderModal}>
      <ModalHeader>{t("sync_po_modal_title")}</ModalHeader>
      <ModalBody>
        <h6>
          <strong>{t("sync_po_impact")}</strong>
        </h6>
        <div className={"mt-2"}>{t("sync_po_warning")}</div>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleSyncPurchaseOrder}>
          {t("confirmer")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
