/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Pager from "./Pager/Pager";
import PageSizeSelector from "./PageSizeSelector/PageSizeSelector";
import Filter from "./Filter/Filter";

class TableData extends Component {
  constructor(props) {
    super(props);

    this.columnRender = this.columnRender.bind(this);
  }

  columnRender(column) {
    if (column.hasOwnProperty("hideHeader")) return null;
    if (!column.hasOwnProperty("title")) return <th scope="col" />;
    if (!column.hasOwnProperty("sortColumn"))
      return (
        <th scope="col" key={column.title} className={column.className}>
          {column.title}
        </th>
      );

    return (
      <th scope="col" onClick={() => this.props.sortMethod(column)} key={column.title}>
        {column.title}
      </th>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.props.hideTitle ? null : (
          <div className="dashhead mt-4">
            <div className="dashhead-titles">
              <h6 className="dashhead-subtitle">{this.props.subtitle}</h6>
              <h2 className="dashhead-title">{this.props.title}</h2>
            </div>
          </div>
        )}

        {this.props.hasFilter ? (
          <Filter
            columns={this.props.columns}
            filterMethod={this.props.filterMethod}
            filter={this.props.filter}
            locale={this.props.locale}
            reset={this.props.reset}
          />
        ) : null}
        {this.props.addAction ? (
          <div className="row mt-lg-2">
            <div className="col-md-12">
              <div className="float-right">
                <button className="btn btn-border btn-success" onClick={() => this.props.addAction()}>
                  <span className="icon icon-circle-with-plus"> {this.props.addButtonText}</span>
                </button>
              </div>
            </div>
          </div>
        ) : null}
        {this.props.quickLink ? (
          <div className="row mt-lg-2">
            <div className="col-md-12">
              <div className="float-right">{this.props.quickLink}</div>
            </div>
          </div>
        ) : null}
        <div>
          <div className="card mt-2">
            <div className="card-body">
              <div className="card-text">
                <table className={`table ${this.props.className}`}>
                  <thead>
                    <tr>{this.props.columns.filter((c) => !c.hidden).map((c) => this.columnRender(c))}</tr>
                  </thead>
                  {this.props.children}
                </table>
                <div>
                  <PageSizeSelector pageSizes={this.props.pageSizes} updatePageSize={this.props.updatePageSize} />

                  <Pager numberOfItems={this.props.numberOfItems} pageSize={this.props.pageSize} updatePage={this.props.updatePage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(TableData);
