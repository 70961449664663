import React from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/utils";
import moment from "moment";

import { Col, Row } from "reactstrap";
import DatePickerWithLabel from "components/FormControls/DatePickerWithLabel";
import { useSelector } from "react-redux";

function FreebiePeriodDate({ freebiePeriodDate, updateAction, removeAction, periodDateIndex, hasProductPrice, validator }) {
  const { t } = useTranslation();
  const accountReducer = useSelector((state) => state.accountReducer);

  const updateDate = (value, model, field, isBlurring) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;

    updateAction(model);
  };

  return (
    <Row>
      <Col md="5">
        <DatePickerWithLabel
          labelText={t("from")}
          objectToUpdate={freebiePeriodDate}
          fieldToUpdate={`start`}
          updateAction={updateDate}
          dateFormat={t("date_format")}
          locale={t("locale")}
          persistOnBlur
          disabled={!accountReducer.isManager || (hasProductPrice && freebiePeriodDate.id > 0)}
          hasValidator
          forceShowErrorState={freebiePeriodDate.isInvalid}
          errorMessage={validator.message(t("from"), freebiePeriodDate.start, "required|date_format")}
        />
      </Col>

      <Col md="5">
        <DatePickerWithLabel
          labelText={t("to")}
          objectToUpdate={freebiePeriodDate}
          fieldToUpdate={`end`}
          updateAction={updateDate}
          dateFormat={t("date_format")}
          locale={t("locale")}
          persistOnBlur
          disabled={!accountReducer.isManager || (hasProductPrice && freebiePeriodDate.id > 0)}
          hasValidator
          forceShowErrorState={freebiePeriodDate.isInvalid}
          errorMessage={validator.message(
            t("to"),
            freebiePeriodDate.end,
            `required|date_format|date_is_after:${moment(freebiePeriodDate.start).format(t("date_format"))},${t("from")}`
          )}
        />
      </Col>
      <Col md="2" className="text-right">
        <button
          className="btn btn-danger "
          style={{ marginTop: "1.3rem" }}
          onClick={() => removeAction(periodDateIndex)}
          disabled={!accountReducer.isManager}
        >
          <span className="fa fa-trash" />
        </button>
      </Col>
    </Row>
  );
}

export default FreebiePeriodDate;
