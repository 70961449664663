import React from "react";
import ReactTooltip from "react-tooltip";
import uuidv4 from "uuid/v4";

export default function InputText({ props }) {
  return (
    <div className={`form-group ${props.groupClassName || ""}`}>
      {props.labelText && (
        <label
          className={
            props.errorMessage || props.forceShowErrorState
              ? `form-control-label text-danger ${props.labelClassName || ""}`
              : `form-control-label ${props.labelClassName || ""}`
          }
          htmlFor={props.fieldToUpdate}
        >
          {props.labelText}
          {props.appendtooltip ? props.appendtooltip : null}
        </label>
      )}
      {props.icon ? (
        <div className={`input-with-icon${props.groupPrepend ? " input-group" : ""}`}>
          {props.groupPrepend ? (
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-group-prepend">
                {props.groupPrepend}
              </span>
            </div>
          ) : null}
          <input
            aria-describedby="basic-group-prepend"
            placeholder={props.placeholder}
            className={
              props.errorMessage || props.forceShowErrorState
                ? `form-control is-invalid ${props.className || ""}`
                : `form-control ${props.className || ""}`
            }
            id={`${props.fieldToUpdate}-${uuidv4()}`}
            name={props.fieldToUpdate}
            type={props.inputType}
            disabled={props.disabled}
            defaultValue={
              props.objectToUpdate[props.fieldToUpdate] === null || props.objectToUpdate[props.fieldToUpdate] === undefined
                ? ""
                : props.objectToUpdate[props.fieldToUpdate]
            }
            onBlur={(e) => props.persistOnBlur && props.updateAction(e.target.value, props.objectToUpdate, props.fieldToUpdate, true)} // onChange={e => updateAction(e.target.value, objectToUpdate, fieldToUpdate, false)}
          />
          {props.icon ? (
            <span className="icon" data-tip={props.iconTooltip} data-html={props.iconHtml} data-for={`tt-${props.fieldToUpdate}`}>
              {props.icon}
            </span>
          ) : null}
          {props.iconTooltip ? (
            <ReactTooltip clickable id={`tt-${props.fieldToUpdate}`} type="info" effect="solid" place="right" border />
          ) : null}
          {props.hasValidator && props.errorMessage}
        </div>
      ) : (
        <React.Fragment>
          <input
            placeholder={props.placeholder}
            className={
              props.errorMessage || props.forceShowErrorState
                ? `form-control is-invalid ${props.className || ""}`
                : `form-control ${props.className || ""}`
            }
            id={props.fieldToUpdate}
            name={props.fieldToUpdate}
            type={props.inputType}
            disabled={props.disabled}
            defaultValue={
              props.objectToUpdate[props.fieldToUpdate] === null || props.objectToUpdate[props.fieldToUpdate] === undefined
                ? ""
                : props.objectToUpdate[props.fieldToUpdate]
            }
            onBlur={(e) => props.persistOnBlur && props.updateAction(e.target.value, props.objectToUpdate, props.fieldToUpdate, true)} // onChange={e => updateAction(e.target.value, objectToUpdate, fieldToUpdate, false)}
          />
          {props.hasValidator && props.errorMessage}
        </React.Fragment>
      )}
    </div>
  );
}
