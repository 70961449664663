export const messageReducer = (
  state = {
    messageKey: null,
    titleKey: null,
    type: null,
    showMessage: false,
    showModal: false,
    messages: [],
    messageKeys: []
  },
  action
) => {
  switch (action.type) {
    case "RESET_MESSAGE_STORE": {
      state = {
        type: null,
        messageKey: null,
        titleKey: null,
        showMessage: false,
        messages: [],
        messageKeys: [],
        showModal: false
      };

      break;
    }
    case "SHOW_MESSAGE": {
      state = { ...state, ...action.payload, showMessage: true };
      break;
    }

    case "SHOW_MODAL_MESSAGE": {
      state = { ...state, ...action.payload, showModal: true };

      break;
    }


    default:
  }

  return state;
};

export default messageReducer;
