import React from "react";

import { Label } from "reactstrap";

export default function LabelField({ fieldToDisplay, labelText, value, groupClassName ="" }) {
  return (
    <React.Fragment>
      <div className={`form-group ${groupClassName}`}>
        <Label htmlFor={fieldToDisplay}>{labelText}</Label>
        <Label className="form-text text-muted">{value}</Label>
      </div>
    </React.Fragment>
  );
}
