import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { IsProduction } from "config/config";

import app_en from "./en/app-en";
import app_fr from "./fr/app-fr";

i18n.use(LanguageDetector).init({
  fallbackLng: "fr",
  resources: {
    en: {
      app: app_en      
    },
    fr: {
      app: app_fr      
    }
  },

  debug: !IsProduction,
  ns: ["app"],
  defaultNS: "app",
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  }
});

export default i18n;
