import axios from "axios";
import { HOST } from "config/config";

export function openQuickbooksLoginWindow(username, quickbooksCompanyId) {
  return (dispatch) => {
    dispatch({
      type: "OPEN_QUICKBOOKS_LOGIN_WINDOW",
      payload: { username, quickbooksCompanyId },
    });
  };
}

export function closeQuickbooksLoginWindow() {
  return (dispatch) => {
    dispatch({
      type: "CLOSE_QUICKBOOKS_LOGIN_WINDOW",
    });
  };
}

export function setQuickbooksState(quickbooksState) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: "SET_QB_STATE",
        payload: { quickbooksState },
      });

      return resolve(quickbooksState);
    });
  };
}

export function loginWithToken(token) {
  return (dispatch, getState) => {
    if (!getState().accountReducer.isLoggedIn && !getState().accountReducer.isLoggingIn) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "LOGIN_PENDING",
          payload: axios
            .get(`${HOST}/api/account/token?accessToken=${token}`, {
              headers: {
                Authorization: `bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((response) => response.data)
            .then((bearerJson) => {
              dispatch({
                type: "LOGIN_FULFILLED",
                payload: {
                  id: bearerJson.id,
                  roles: bearerJson.roles,
                  access_token: bearerJson.token,
                  username: bearerJson.username,
                  firstName: bearerJson.firstName,
                  userCompanyId: bearerJson.userCompanyId,
                  userGroupId: bearerJson.userGroupId,
                  lastName: bearerJson.lastName,
                  phoneNumber: bearerJson.phoneNumber,
                  rememberme: false,
                  northWindUrl: bearerJson.northWindUrl,
                  isManager: bearerJson.isManager,
                  quickbooksState: bearerJson.quickbooksState,
                  availableGroups: bearerJson.availableGroups,
                },
              });

              return resolve(bearerJson);
            })
            .catch((error) =>
              dispatch({
                type: "LOGIN_REJECTED",
                payload: error,
              })
            ),
        })
      );
    }
  };
}

export function login(username, password, rememberme) {
  return (dispatch, getState) => {
    if (!getState().accountReducer.isLoggedIn && !getState().accountReducer.isLoggingIn) {
      var data = {
        username: username,
        password: password,
        grant_type: "password",
      };

      return dispatch({
        type: "LOGIN_PENDING",
        payload: axios
          .post(`${HOST}/api/account/authenticate`, data)
          .then((response) => response.data)
          .then((json) => {
            if (json.message) {
              return dispatch({
                type: "LOGIN_REJECTED",
                payload: json,
              });
            }
            axios
              .get(`${HOST}/api/account/token?accessToken=${json.token}`, {
                headers: {
                  Authorization: `bearer ${json.token}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((response) => response.data)
              .then((json) => {
                return dispatch({
                  type: "LOGIN_FULFILLED",
                  payload: {
                    id: json.id,
                    roles: json.roles,
                    access_token: json.token,
                    username: json.username,
                    fullName: json.fullName,
                    userCompanyId: json.userCompanyId,
                    userGroupId: json.userGroupId,
                    rememberme: rememberme,
                    northWindUrl: json.northWindUrl,
                    isManager: json.isManager,
                    quickbooksState: json.quickbooksState,
                    availableGroups: json.availableGroups,
                  },
                });
              })
              .catch((error) =>
                dispatch({
                  type: "LOGIN_REJECTED",
                  payload: error,
                })
              );
          })
          .catch((error) =>
            dispatch({
              type: "LOGIN_REJECTED",
              payload: error,
            })
          ),
      });
    }
  };
}

export function logout() {
  return (dispatch, getState) => {
    if (getState().accountReducer.isLoggedIn) {
      return dispatch({
        type: "LOGOUT",
      });
    }
  };
}

export function loginWithCookie(access_token) {
  return (dispatch, getState) => {
    if (!getState().accountReducer.isLoggedIn && !getState().accountReducer.isLoggingIn) {
      return dispatch({
        type: "LOGIN_PENDING",
        payload: axios
          .get(`${HOST}/api/account/token?accessToken=${access_token}`, {
            headers: {
              Authorization: `bearer ${access_token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((response) => response.data)
          .then((json) => {
            dispatch({
              type: "LOGIN_FULFILLED",
              payload: {
                id: json.id,
                roles: json.roles,
                access_token: json.token,
                username: json.username,
                fullName: json.fullName,
                userCompanyId: json.userCompanyId,
                userGroupId: json.userGroupId,
                northWindUrl: json.northWindUrl,
                isManager: json.isManager,
                quickbooksState: json.quickbooksState,
                availableGroups: json.availableGroups,
              },
            });
          })
          .catch((error) =>
            dispatch({
              type: "LOGIN_REJECTED",
              payload: error,
            })
          ),
      });
    }
  };
}
