/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";

import DateTimePicker from "react-widgets/lib/DateTimePicker";

momentLocalizer();

class DateInput extends Component {
  render() {
    return (
      <div className="btn-toolbar-item input-with-icon-right">
        <DateTimePicker
          value={
            moment(this.props.filter.value, "YYYY-MM-DD").isValid()
              ? moment(this.props.filter.value, "YYYY-MM-DD").toDate()
              : new Date()
          }
          time={false}
          culture={this.props.locale === "en" ? "en-CA" : "fr-CA"}
          format="MMMM D, YYYY"
          onChange={e => this.props.updateValue(moment(e).format("YYYY-MM-DD"))}
          containerClassName="form-control"
        />
      </div>
    );
  }
}

export default DateInput;
