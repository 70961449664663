export const freebieTypesReducer = (
  state = {
    freebieTypes: [],
    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_FREEBIE_TYPES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_FREEBIE_TYPES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_FREEBIE_TYPES_FULFILLED": {
      state = { ...state, updating: false, updated: true, freebieTypes: action.payload.items };
      break;
    }
    case "GET_FREEBIE_TYPES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_FREEBIE_TYPE_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_FREEBIE_TYPE_FULFILLED": {
      let freebieTypes = state.freebieTypes;
      freebieTypes = freebieTypes.filter(s => s.id !== action.payload.id);
      freebieTypes.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        freebieTypes
      };
      break;
    }
    case "GET_FREEBIE_TYPES_SELECT_FULFILLED": {
      let freebieTypes = state.freebieTypes;
      action.payload.forEach(freebieType => {
        if (!freebieTypes.some(t => t.id === freebieType.id)) {
          freebieTypes.push(freebieType);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        freebieTypes: freebieTypes
      };
      break;
    }
    case "GET_FREEBIE_TYPE_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default freebieTypesReducer;
