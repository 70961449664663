import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Currency from "react-currency-formatter";

// actions
import {
  getPurchaseOrdersToSync,
  persistPurchaseOrder,
  showDeletePurchaseOrderConfirmationModal,
  showSyncPurchaseOrderConfirmationModal,
} from "redux/quickbooks/purchase-order/purchase-order-action";
// components
import DeletePurchaseOrderModal from "./DeletePurchaseOrderModal";
import SyncPurchaseOrderModal from "./SyncPurchaseOrderModal";
// others
import ReactTooltip from "react-tooltip";
import { useState } from "react";
import SelectWithLabel from "components/FormControls/SelectWithLabel";
import Spinner from "components/Spinner/Spinner";

function QuickbooksPurchaseOrderSynchronizations() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const purchaseOrderReducer = useSelector((state) => state.purchaseOrderReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const { purchaseOrdersToSynchronize, pushingPurchaseOrder, gettingPurchaseOrderToSynchronize } = purchaseOrderReducer;
  const { isManager, availableGroups } = accountReducer;
  const [selectedGroupId, setSelectedGroupId] = useState(accountReducer.userGroupId);

  useEffect(() => {
    dispatch(getPurchaseOrdersToSync(selectedGroupId));
  }, [selectedGroupId, dispatch]);

  const handleUpdatePurchaseOrder = (purchaseOrder, value, name) => {
    purchaseOrder[name] = value;

    dispatch(persistPurchaseOrder(purchaseOrder));
  };

  const handleOpenDeleteConfirmationModal = (e, purchaseOrder) => {
    e.stopPropagation();
    dispatch(showDeletePurchaseOrderConfirmationModal(purchaseOrder));
  };

  const handleOpenSyncConfirmationModal = (e, purchaseOrder) => {
    e.stopPropagation();
    dispatch(showSyncPurchaseOrderConfirmationModal(purchaseOrder));
  };

  return (
    isManager && (
      <React.Fragment>
        <DeletePurchaseOrderModal />
        <SyncPurchaseOrderModal />
        <div className="dashhead mt-4">
          <div className="dashhead-titles">
            <h6 className="dashhead-subtitle">{t("overview")}</h6>
            <h2 className="dashhead-title">{t("purchase_orders_to_sync")}</h2>
            <SelectWithLabel
              filterable
              clearable
              labelText={t("usergroup")}
              selectPlaceholder={t("usergroup")}
              data={availableGroups}
              textField={"name"}
              valueField="userGroup_Id"
              value={selectedGroupId}
              objectToUpdate={selectedGroupId}
              updateAction={setSelectedGroupId}
              fieldToUpdate="userGroup_Id"
            />
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="card-text">
              <table className={`table`}>
                <thead>
                  <tr>
                    <td>{t("number")}</td>
                    <td>{t("establishment")}</td>
                    <td>{t("po_amount")}</td>
                    <td>{t("departure")}</td>
                    <td>{t("departure_date")}</td>
                    <td>{t("sync_date_asked")}</td>
                    <td>{t("retries")}</td>
                    <td>{t("valid")}</td>
                    <td className="px-4">{t("po_actions")}</td>
                  </tr>
                </thead>
                <tbody>
                  {gettingPurchaseOrderToSynchronize ? (
                    <tr>
                      <td colspan="10" style={{ textAlign: "center" }}>
                        <Spinner />
                      </td>
                    </tr>
                  ) : !purchaseOrdersToSynchronize || purchaseOrdersToSynchronize.length === 0 ? (
                    <tr>
                      <td colspan="10" style={{ textAlign: "center" }}>
                        {t("no_records")}
                      </td>
                    </tr>
                  ) : (
                    purchaseOrdersToSynchronize.map((purchaseOrder) => (
                      <tr
                        className={
                          purchaseOrder.isOnHold
                            ? "grayBackground"
                            : purchaseOrder.synchronizationRetryCount > 0 || moment(purchaseOrder.toBeSynchronizedByDateTime) < moment()
                            ? "error-background"
                            : ""
                        }
                      >
                        <td>
                          <b>{purchaseOrder.name}</b>
                        </td>
                        <td>{purchaseOrder.establishmentName}</td>
                        <td>
                          <Currency quantity={purchaseOrder.grandTotal || 0} currency="CAD" />
                        </td>
                        <td>{purchaseOrder.departureName}</td>
                        <td>{moment(purchaseOrder.departureDate).format("LL")}</td>
                        <td>{moment(purchaseOrder.toBeSynchronizedByDateTime).format("LL")}</td>
                        <td>{purchaseOrder.synchronizationRetryCount}</td>
                        <td>
                          {purchaseOrder.isQuickbooksValid &&
                          !purchaseOrder.quickbooksSynchronizationProhibited &&
                          purchaseOrder.grandTotal >= 0 ? (
                            <span className="fas fa-check-circle" style={{ color: "green" }} />
                          ) : purchaseOrder.quickbooksSynchronizationProhibited ? (
                            <React.Fragment>
                              <ReactTooltip id="tt-po-already-synched" type="info" effect="solid" place="top" border />
                              <span
                                data-tip={t("po_already_synched")}
                                data-for="tt-po-already-synched"
                                className="fas fa-exclamation-circle"
                                style={{ color: "red" }}
                              />
                            </React.Fragment>
                          ) : purchaseOrder.grandTotal < 0 ? (
                            <React.Fragment>
                              <ReactTooltip id="tt-po-negative" type="info" effect="solid" place="top" border />
                              <span
                                data-tip={t("po_is_negative")}
                                data-for="tt-po-negative"
                                className="fas fa-exclamation-circle"
                                style={{ color: "red" }}
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <ReactTooltip id="tt-po-invalid" type="info" effect="solid" place="top" border />
                              <span
                                data-tip={t("po_is_invalid")}
                                data-for="tt-po-invalid"
                                className="fas fa-exclamation-circle"
                                style={{ color: "red" }}
                              />
                            </React.Fragment>
                          )}
                        </td>
                        <td>
                          <button
                            title="Synchronize"
                            onClick={(e) => handleOpenSyncConfirmationModal(e, purchaseOrder)}
                            disabled={
                              !purchaseOrder.isQuickbooksValid ||
                              purchaseOrder.isOnHold ||
                              purchaseOrder.grandTotal < 0 ||
                              pushingPurchaseOrder ||
                              purchaseOrder.quickbooksSynchronizationProhibited
                            }
                          >
                            <span className="fas fa-sync" style={{ fontSize: "22px" }} />
                          </button>
                          <button
                            title={purchaseOrder.isOnHold ? "Resume" : "Hold"}
                            onClick={() => handleUpdatePurchaseOrder(purchaseOrder, !purchaseOrder.isOnHold, "isOnHold")}
                            disabled={
                              !purchaseOrder.isQuickbooksValid ||
                              purchaseOrder.grandTotal < 0 ||
                              pushingPurchaseOrder ||
                              purchaseOrder.quickbooksSynchronizationProhibited
                            }
                          >
                            {purchaseOrder.isOnHold ? (
                              <span className="fas fa-play-circle" style={{ fontSize: "22px", marginLeft: "5px" }} />
                            ) : (
                              <span className="fas fa-pause-circle" style={{ fontSize: "22px", marginLeft: "5px" }} />
                            )}
                          </button>
                          <button
                            title="Delete"
                            onClick={(e) => handleOpenDeleteConfirmationModal(e, purchaseOrder)}
                            disabled={!purchaseOrder.isQuickbooksValid || pushingPurchaseOrder}
                          >
                            <span style={{ fontSize: "22px", marginLeft: "5px" }} className="fas fa-trash-alt" />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  );
}

export default QuickbooksPurchaseOrderSynchronizations;
