export const quickbooksVendorReducer = (
  state = {
    quickbooksVendor: [],
    gettingQuickbooksVendor: false,
  },
  action
) => {
  switch (action.type) {
    case "GET_QUICKBOOKS_VENDORS_PENDING": {
      return { ...state, gettingQuickbooksVendor: true };
    }
    case "GET_QUICKBOOKS_VENDORS_FULFILLED": {
      return { ...state, gettingQuickbooksVendor: false, quickbooksVendor: action.payload };
    }
    default: {
    }
  }

  return state;
};

export default quickbooksVendorReducer;
