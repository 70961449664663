import React from "react";
import moment from "moment";

export const validatorConfig = {
  getConfig(t) {
    return {
      validators: {
        date_format: {
          message: t("error_date_format", { format: t("date_format") }),
          rule: (val) => {
            return moment(val).isValid();
          },
        },
        postal_code: {
          message: t("error_postal_code"),
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);
          },
          required: true,
        },
        required_quill: {
          message: t("error_required_quill", {
            field: ":attribute",
          }),
          rule: (val) => {
            return val !== "" && val !== "<p><br></p>" && val !== "<p></p>" && val !== null && val !== undefined;
          },
        },
        exclusive_with: {
          message: t("error_exclusive_with", {
            field: ":attribute",
            exclusiveField: ":exclusiveField",
          }),
          rule: (val, params) => {
            return (
              (val !== undefined && (params[0] === "undefined" || params[0] === "null")) ||
              (val === undefined && (params[0] !== "undefined" || params[0] !== "null"))
            );
          },
          messageReplace: (message, params) => message.replace(":exclusiveField", params[1]),
        },
        date_is_after: {
          message: t("error_date_is_after", {
            field: ":attribute",
            exclusiveField: ":exclusiveField",
          }),
          rule: (val, params) => {
            const momentVal = moment(val);
            const momentParam = moment(params[0]);
            return momentParam.isBefore(momentVal) && momentParam.format(t("date_format")) !== momentVal.format(t("date_format"));
          },
          messageReplace: (message, params) => message.replace(":exclusiveField", params[1]),
        },
        must_match: {
          message: t("error_must_match", {
            field: ":attribute",
            matchField: ":matchField",
          }),
          rule: (val, params) => {
            return val !== undefined && params[0] === params[1];
          },
          messageReplace: (message, params) => message.replace(":matchField", params[2]),
        },
        boolean_at_least_one: {
          message: t("error_boolean_at_least_one", {
            field: ":attribute",
            matchField: ":matchField",
          }),
          rule: (_, params) => {
            return params.some((p) => p !== "undefined" && p !== "null" && p !== "" && p !== "false");
          },
          required: true,
          messageReplace: (message, params) => message.replace(":matchField", params[2]),
        },
      },
      messages: {
        required: t("field_required", { field: ":attribute" }),
        date: t("error_date_format", { format: t("date_format") }),
        date_format: t("error_date_format", {
          format: t("date_format_with_time"),
        }),
        min: t("error_min_size", { field: ":attribute", length: ":min" }),
        not_in: t("error_unique", { field: ":attribute" }),
        url: t("error_url", { field: ":attribute" }),
      },
      element: (message) => <small className="form-control-feedback text-danger">{message}</small>,
    };
  },
};
