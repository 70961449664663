export const productsReducer = (
  state = {
    products: [],
    productsForSelect: [],
    productModal: {
      id: 0,
      nameFrench: "",
      nameEnglish: "",
      remarks: [],
      productType_Ids: [],
    },

    selectUpdating: false,

    establishmentProducts: [],
    establishmentSelectedProducts: [],
    mergeMainProductIdModal: null,
    showMergeProductModal: false,
    establishmentId: null,

    showCreatePriceListModal: false,
    priceListProducts: [],

    showDeleteProductModal: false,
    deleteProduct: null,

    isGettingExtraInformation: false,
    obtainedExtraInformation: false,
    productExtraInformations: [],

    showDeactivationProductModal: false,
    deactivationProduct: null,

    showRegenerateProductContractModal: false,
    regenerateProductContractProduct: {},

    updating: false,
    updated: false,
    error: null,

    showModal: false,

    count: 0,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: [],
    },

    gettingProductEventsDetails: false,
    productEventsDetails: null,

    gettingProductAssociatedContracts: false,
    productAssociatedContracts: [],
    showDeleteAssociatedProductContractModal: false,
    deleteAssociatedProductContractModalEntity: null,
    deletingAssociatedProductContract: false,
  },
  action
) => {
  switch (action.type) {
    case "GET_PRODUCTS_EXTRA_INFORMATION_PENDING": {
      state = { ...state, isGettingExtraInformation: true, obtainedExtraInformation: false, productExtraInformations: [] };
      break;
    }

    case "GET_PRODUCTS_EXTRA_INFORMATION_FULFILLED": {
      let productExtraInformations = action.payload;
      let stateEstablishmentProducts = state.establishmentProducts;

      state = {
        ...state,
        establishmentProducts: stateEstablishmentProducts,
        productExtraInformations,
        isGettingExtraInformation: false,
        obtainedExtraInformation: true,
      };
      break;
    }

    case "DELETED_PRODUCT": {
      let product = action.payload;
      state.establishmentProducts = state.establishmentProducts.filter((ep) => ep.id !== product.id);
      state.establishmentSelectedProducts = state.establishmentSelectedProducts.filter((ep) => ep.id !== product.id);

      state = { ...state };
      break;
    }

    case "ADD_CONTRACT_TO_PRODUCT": {
      let product = action.payload.product;
      let productFromReducer = state.products.find((p) => p.id === product.id);
      productFromReducer.contracts = productFromReducer.contracts || [];
      productFromReducer.contracts.push(action.payload.contract);

      state = { ...state, products: state.products };
      break;
    }
    case "ACTIVATE_PRODUCT_FULFILLED": {
      let product = action.payload;
      let productFromReducer = state.products.find((p) => p.id === product.id);

      if (productFromReducer) {
        productFromReducer.isActive = product.isActive;
        productFromReducer.deactivationDateTime = product.deactivationDateTime;
      }

      let establishmentProductFromReducer = state.establishmentProducts.find((p) => p.id === product.id);
      if (establishmentProductFromReducer) {
        establishmentProductFromReducer.isActive = product.isActive;
        establishmentProductFromReducer.deactivationDateTime = product.deactivationDateTime;
      }

      state = { ...state };
      break;
    }
    case "DEACTIVATE_PRODUCT_FULFILLED": {
      let product = action.payload;
      let productFromReducer = state.products.find((p) => p.id === product.id);

      if (productFromReducer) {
        productFromReducer.isActive = product.isActive;
        productFromReducer.deactivationDateTime = product.deactivationDateTime;
      }

      let establishmentProductFromReducer = state.establishmentProducts.find((p) => p.id === product.id);
      if (establishmentProductFromReducer) {
        establishmentProductFromReducer.isActive = product.isActive;
        establishmentProductFromReducer.deactivationDateTime = product.deactivationDateTime;
      }

      state = { ...state };
      break;
    }

    case "SHOW_DEACTIVATE_PRODUCT_MODAL": {
      state = { ...state, showDeactivationProductModal: true, deactivationProduct: action.payload };
      break;
    }

    case "HIDE_DEACTIVATE_PRODUCT_MODAL": {
      state = { ...state, showDeactivationProductModal: false, deactivationProduct: null };
      break;
    }

    case "GET_PRODUCTS_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_PRODUCTS_FOR_SELECT_PENDING": {
      state = { ...state, selectUpdating: true };
      break;
    }

    case "UPDATE_PRODUCTS_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_PRODUCTS_FULFILLED": {
      state = {
        ...state,
        updating: false,
        updated: true,
        products: action.payload.items,
        count: action.payload.count,
      };
      break;
    }
    case "GET_PRODUCTS_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_PRODUCT_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_PRODUCT_FULFILLED": {
      let products = state.products;
      products = products.filter((s) => s.id !== action.payload.id);
      products.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        products,
      };
      break;
    }
    case "GET_PRODUCTS_SELECT_FULFILLED": {
      state = {
        ...state,
        selectUpdating: false,
        updated: true,
        productsForSelect: action.payload,
      };
      break;
    }
    case "GET_PRODUCT_REJECTED": {
      state = { ...state, selectUpdating: false, error: action.payload };
      break;
    }
    case "PERSISTING_PRODUCT": {
      state = { ...state, updating: true, persisting: true };

      break;
    }
    case "PERSISTED_PRODUCT": {
      let establishmentProducts = state.establishmentProducts;
      let products = state.products;

      if (!action.payload.isNew) {
        establishmentProducts.forEach((d) => {
          if (d.id === action.payload.product.id) {
            Object.assign(d, action.payload.product);
          }
        });

        products.forEach((d) => {
          if (d.id === action.payload.product.id) {
            Object.assign(d, action.payload.product);
          }
        });
      } else {
        establishmentProducts.push(action.payload.product);
        products.push(action.payload.product);
      }

      state = { ...state, establishmentProducts, products, updating: false, persisting: false };

      break;
    }
    case "UPDATE_PRODUCT": {
      let products = state.products;
      products.forEach((d) => {
        if (d.id === action.payload.id) {
          d = Object.assign(action.payload, {});
        }
      });

      let establishmentProducts = state.establishmentProducts;
      establishmentProducts.forEach((d) => {
        if (d.id === action.payload.id) {
          d = Object.assign(action.payload, {});
        }
      });

      state = { ...state, products, establishmentProducts, updating: false };

      break;
    }

    case "RESET_SELECTED_PRODUCT": {
      state = { ...state, establishmentSelectedProducts: [] };
      break;
    }

    case "GET_PRODUCTS_FOR_ESTABLISHMENT_PENDING": {
      state = { ...state, selectUpdating: true };
      break;
    }

    case "UPDATE_ESTABLISHMENT_SELECTED_PRODUCTS": {
      state = { ...state, establishmentSelectedProducts: action.payload.selectedProducts };
      break;
    }

    case "MERGE_PRODUCT_FULFILLED": {
      state = { ...state, establishmentSelectedProducts: [] };
      break;
    }

    case "GET_PRODUCTS_ESTABLISHMENT_FULFILLED": {
      state = { ...state, selectUpdating: false, establishmentProducts: action.payload, establishmentId: action.establishmentId };
      break;
    }

    case "ASSIGN_PRODUCT_MODAL": {
      state = { ...state, productModal: action.payload };
      break;
    }

    case "HIDE_PRODUCT_MODAL": {
      state = { ...state, showModal: false };
      break;
    }

    case "HIDE_DELETE_PRODUCT_MODAL": {
      state = { ...state, showDeleteProductModal: false, deleteProduct: null };
      break;
    }

    case "SHOW_DELETE_PRODUCT_MODAL": {
      state = { ...state, deleteProduct: action.payload, showDeleteProductModal: true };
      break;
    }

    case "HIDE_MERGE_PRODUCT_MODAL": {
      state = { ...state, showMergeProductModal: false };
      break;
    }

    case "SHOW_MERGE_PRODUCT_MODAL": {
      state = { ...state, showMergeProductModal: true, mergeMainProductIdModal: null };
      break;
    }

    case "HIDE_CREATE_PRICE_LIST_MODAL": {
      state = { ...state, showCreatePriceListModal: false };
      break;
    }

    case "SHOW_CREATE_PRICE_LIST_MODAL": {
      state = {
        ...state,
        showCreatePriceListModal: true,
        priceListProducts: action.payload.map((product) => ({
          nameFrench: product.nameFrench,
          nameEnglish: product.nameEnglish,
          product_Id: product.id,
          establishmentContract_Id: null,
          product: product,
        })),
      };
      break;
    }

    case "UPDATE_CREATE_PRICE_LIST_PRODUCT": {
      let priceListProducts = state.priceListProducts;
      priceListProducts.forEach((priceListProduct) => {
        if (priceListProduct.product_Id === action.payload.productPriceList.product_Id) {
          priceListProduct.establishmentContract_Id = action.payload.establishmentContract_Id;
        }
      });

      state = { ...state, priceListProducts };

      break;
    }

    case "SHOW_REGENERATE_PRODUCT_CONTRACT_MODAL": {
      state = {
        ...state,
        showRegenerateProductContractModal: true,
        regenerateProductContractProduct: {
          nameFrench: action.payload.nameFrench,
          nameEnglish: action.payload.nameEnglish,
          product_Id: action.payload.id,
          establishmentContract_Id: null,
          product: action.payload,
        },
      };
      break;
    }

    case "HIDE_REGENERATE_PRODUCT_CONTRACT_MODAL": {
      state = { ...state, showRegenerateProductContractModal: false };
      break;
    }

    case "UPDATE_REGENERATE_PRODUCT_CONTRACT_PRODUCT": {
      let regenerateProductContractProduct = state.regenerateProductContractProduct;
      regenerateProductContractProduct.establishmentContract_Id = action.payload;

      state = { ...state, regenerateProductContractProduct };

      break;
    }

    case "UPDATE_MERGE_MAIN_PRODUCT_MODAL": {
      state = { ...state, mergeMainProductIdModal: action.payload.productId };
      break;
    }

    case "UPDATE_PRODUCT_MODAL_MODEL": {
      state = {
        ...state,
        productModal: action.payload,
      };
      break;
    }

    case "UPDATE_CONTRACT_PRICE_DETAIL": {
      let contractPriceDetail = action.payload;
      let products = state.products;
      products.forEach((product) =>
        product.establishmentProducts.forEach((ep) =>
          ep.contractPriceDetails.forEach((cpd) => {
            if (cpd.id === contractPriceDetail.id) {
              for (var property in cpd) cpd[property] = contractPriceDetail[property];
            }
          })
        )
      );

      state = { ...state, products };

      break;
    }

    case "UPDATE_PRODUCT_TAXES_CONTRACT_MODEL": {
      state = {
        ...state,
      };
      break;
    }

    case "UPDATE_PRODUCT_TAXES_MODAL_MODEL": {
      let productModal = state.productModal;
      productModal.taxes = Object.assign(action.payload, {});

      state = {
        ...state,
        productModal,
      };
      break;
    }

    case "RESET_PRODUCT_ESTABLISHMENT": {
      state = { ...state, establishmentProducts: [], establishmentId: null };
      break;
    }

    case "GET_PRODUCT_EVENTS_DETAILS_PENDING": {
      state = { ...state, gettingProductEventsDetails: true, productEventsDetails: null };
      break;
    }

    case "GET_PRODUCT_EVENTS_DETAILS_FULFILLED": {
      state = { ...state, gettingProductEventsDetails: false, productEventsDetails: action.payload };
      break;
    }

    case "GET_PRODUCT_ASSOCIATED_CONTRACTS_PENDING": {
      state = { ...state, gettingProductAssociatedContracts: true, productAssociatedContracts: [] };
      break;
    }

    case "GET_PRODUCT_ASSOCIATED_CONTRACTS_FULFILLED": {
      state = { ...state, gettingProductAssociatedContracts: false, productAssociatedContracts: action.payload };
      break;
    }

    case "SHOW_DELETE_ASSOCIATED_PRODUCT_CONTRACT_MODAL": {
      state = { ...state, showDeleteAssociatedProductContractModal: true, deleteAssociatedProductContractModalEntity: action.payload };
      break;
    }

    case "HIDE_DELETE_ASSOCIATED_PRODUCT_CONTRACT_MODAL": {
      state = { ...state, showDeleteAssociatedProductContractModal: false, deleteAssociatedProductContractModalEntity: null };
      break;
    }

    case "DELETING_ASSOCIATED_PRODUCT_CONTRACT": {
      state = { ...state, deletingAssociatedProductContract: true };
      break;
    }

    case "DELETED_ASSOCIATED_PRODUCT_CONTRACT": {
      state = {
        ...state,
        deletingAssociatedProductContract: false,
        productAssociatedContracts: state.productAssociatedContracts.filter((p) => p.id !== action.payload),
      };
      break;
    }

    default: {
    }
  }

  return state;
};

export default productsReducer;
