/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import DropdownList from "react-widgets/lib/DropdownList";

class BooleanInput extends Component {
  render() {
    let options = [
      { value: true, label:  this.props.locale === "en" ? "Yes" : "Oui"},
      { value: false, label:  this.props.locale === "en" ? "No" : "Non"}
    ];
     
    return (
      <DropdownList
        filter="contains"
        key={this.props.filter.id}
        placeholder=""
        onChange={e => this.props.updateValue(e.value)}
        value={options.find(j => j.value === this.props.filter.value)}
        textField="label"
        valueField="value"
        data={options}
      />
    );
  }
}

export default BooleanInput;
