import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { hideProductContractTableView } from "redux/actions/contracts";
import { ContractPriceType } from "Enums/contractPriceType";

import AccomodationTableViewHeader from "./Components/Header/AccomodationTableViewHeader";
import ActivityTableViewHeader from "./Components/Header/ActivityTableViewHeader";
import RestaurationTableViewHeader from "./Components/Header/RestaurationTableViewHeader";
import ServiceTableViewHeader from "./Components/Header/ServiceTableViewHeader";
import TransportTableViewHeader from "./Components/Header/TransportTableViewHeader";

import AccomodationTableViewBody from "./Components/Body/AccomodationTableViewBody";
import ActivityTableViewBody from "./Components/Body/ActivityTableViewBody";
import RestaurationTableViewBody from "./Components/Body/RestaurationTableViewBody";
import ServiceTableViewBody from "./Components/Body/ServiceTableViewBody";
import TransportTableViewBody from "./Components/Body/TransportTableViewBody";

export default function ProductContractTableViewModal() {
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleHideProductContractTableView = () => {
    dispatch(hideProductContractTableView());
  };

  const renderContractViewHeader = (contract) => {
    if (contract.contractPriceDetails.length) {
      switch (contract.contractPriceDetails[0].contractPriceType) {
        case ContractPriceType.ACCOMODATION:
          return <AccomodationTableViewHeader contract={contract} />;
        case ContractPriceType.ACTIVITY:
          return <ActivityTableViewHeader contract={contract} />;
        case ContractPriceType.RESTAURATION:
          return <RestaurationTableViewHeader contract={contract} />;
        case ContractPriceType.SERVICE:
          return <ServiceTableViewHeader contract={contract} />;
        case ContractPriceType.TRANSPORT:
          return <TransportTableViewHeader contract={contract} />;
        default:
          break;
      }
    }
    return;
  };

  const renderContractViewBody = (contract) => {
    if (contract.contractPriceDetails.length) {
      switch (contract.contractPriceDetails[0].contractPriceType) {
        case ContractPriceType.ACCOMODATION:
          return <AccomodationTableViewBody contract={contract} />;
        case ContractPriceType.ACTIVITY:
          return <ActivityTableViewBody contract={contract} />;
        case ContractPriceType.RESTAURATION:
          return <RestaurationTableViewBody contract={contract} />;
        case ContractPriceType.SERVICE:
          return <ServiceTableViewBody contract={contract} />;
        case ContractPriceType.TRANSPORT:
          return <TransportTableViewBody contract={contract} />;
        default:
          break;
      }
    }
    return;
  };

  const { pricingProductContracts, showPricingProductContractTableViewModal, pricingProductContractFilters } = contractsReducer;

  const filteredPriceProductContracts = pricingProductContracts.filter(
    (filteredContractPriceDetail) =>
      (!pricingProductContractFilters.contract_Ids ||
        !pricingProductContractFilters.contract_Ids.length ||
        pricingProductContractFilters.contract_Ids.includes(filteredContractPriceDetail.id)) &&
      (!pricingProductContractFilters.contractPeriod_Ids ||
        !pricingProductContractFilters.contractPeriod_Ids.length ||
        pricingProductContractFilters.contractPeriod_Ids.some((id) =>
          filteredContractPriceDetail.contractPeriods.map((p) => p.id).includes(id)
        )) &&
      (!pricingProductContractFilters.ageCategory_Ids ||
        !pricingProductContractFilters.ageCategory_Ids.length ||
        pricingProductContractFilters.ageCategory_Ids.some((id) =>
          filteredContractPriceDetail.ageCategories.map((a) => a.id).includes(id)
        )) &&
      (!pricingProductContractFilters.group_Ids ||
        !pricingProductContractFilters.group_Ids.length ||
        pricingProductContractFilters.group_Ids.some((id) => filteredContractPriceDetail.groups.map((g) => g.id).includes(id))) &&
      (!pricingProductContractFilters.contractDepartureGroup_Ids ||
        !pricingProductContractFilters.contractDepartureGroup_Ids.length ||
        pricingProductContractFilters.contractDepartureGroup_Ids.some((id) =>
          filteredContractPriceDetail.contractDepartureGroups.map((g) => g.id).includes(id)
        )) &&
      (!pricingProductContractFilters.lengthCategory_Ids ||
        !pricingProductContractFilters.lengthCategory_Ids.length ||
        pricingProductContractFilters.lengthCategory_Ids.some((id) =>
          filteredContractPriceDetail.lengthCategories.map((l) => l.id).includes(id)
        )) &&
      (!pricingProductContractFilters.contractDayGroup_Ids ||
        !pricingProductContractFilters.contractDayGroup_Ids.length ||
        pricingProductContractFilters.contractDayGroup_Ids.some((id) =>
          filteredContractPriceDetail.contractDayGroups.map((d) => d.id).includes(id)
        ))
  );
  return (
    <Modal size="xl" isOpen={showPricingProductContractTableViewModal}>
      <ModalHeader toggle={handleHideProductContractTableView}>{t("table_view")}</ModalHeader>
      <ModalBody>
        {filteredPriceProductContracts.map((contract) => (
          <table className="table table-striped table-responsive" key={contract.id}>
            {renderContractViewHeader(contract)}
            {renderContractViewBody(contract)}
          </table>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleHideProductContractTableView}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
