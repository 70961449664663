import React from "react";
import uuidv4 from "uuid/v4";

import { useTranslation } from "react-i18next";

import CurrencyInput from "react-currency-input";

import { Row, Col } from "reactstrap";
import ReactTooltip from "react-tooltip";

export default function FerryTransportPriceDetail({ cpd, handleUpdateContractPriceDetail, isManager }) {
  const { t } = useTranslation();

  let guid1 = uuidv4();
  let guid2 = uuidv4();
  let guid3 = uuidv4();

  return (
    <Row>
      <Col sm="12">
        <h5>{t("ferry")}</h5>

        <div className="d-flex flex-nowrap">
          <div className="form-group pt-2 pb-2 pr-2">
            <label className="form-label" htmlFor={`value-${guid1}`}>
              {t("vehicule_price")}
            </label>
            <div className="input-group mt-1">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <CurrencyInput
                value={cpd.value}
                id={`value-${guid1}`}
                onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "value", true)}
                className="form-control text-right"
                disabled={!isManager}
              />
            </div>
          </div>

          <div className="form-group p-2">
            <label className="form-label" htmlFor={`additionnal-price-adult-${guid3}`}>
              {t("price_adult")}
              <ReactTooltip id="tt-price-per-adult-explain" type="info" effect="solid" place="top" border />

              <i
                className="fas fa-info-circle text-info ml-1"
                data-tip={t("price_per_adult_explain")}
                data-for="tt-price-per-adult-explain"
              ></i>
            </label>
            <div className="input-group mt-1">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <CurrencyInput
                id={`additionnal-price-adult-${guid2}`}
                value={cpd.ferry_AdultPrice}
                onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "ferry_AdultPrice", true)}
                className="form-control text-right"
                disabled={!isManager}
              />
            </div>
          </div>

          <div className="form-group p-2">
            <label className="form-label" htmlFor={`additionnal-price-adult-${guid3}`}>
              {t("price_child")}
            </label>
            <div className="input-group mt-1">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <CurrencyInput
                id={`additionnal-price-adult-${guid3}`}
                value={cpd.ferry_ChildPrice}
                onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "ferry_ChildPrice", true)}
                className="form-control text-right"
                disabled={!isManager}
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
