import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/utils";

import {
  addFreebieCriteria,
  removeFreebieCriteria,
  updateContractFreebie,
  updateContractFreebieCriteria,
  addFreebieDetail,
  removeFreebieDetail,
  updateContractFreebieDetail,
  addFreebiePeriodDate,
  removeFreebiePeriodDate,
} from "redux/actions/contracts";

import { Row, Col, CardBody, CardTitle, Card } from "reactstrap";
import InputWithLabel from "components/FormControls/InputWithLabel";
import Criteria from "./Criteria";
import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";
import Detail from "./Detail";
import FreebiePeriodDate from "./FreebiePeriodDate";
import freebieTypesReducer from "redux/reducers/freebie-types";

function Freebie({ freebie, index, removeFreebie, isEstablishmentContract, products, contract, validator }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accountReducer = useSelector((state) => state.accountReducer);

  const updateFreebie = (value, model, field, isBlurring) => {
    setTimeout(function () {
      if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

      model[field] = value;

      dispatch(updateContractFreebie(model, index, isEstablishmentContract));
    }, 0);
  };

  const addCriteria = () => {
    dispatch(addFreebieCriteria(freebie, index, isEstablishmentContract));
  };

  const addDateCriteria = () => {
    dispatch(addFreebiePeriodDate(freebie, index, isEstablishmentContract));
  };

  const updateFreebiePeriodDate = (freebiePeriodDate, freebieIndex, currentPeriodDateIndex) => {
    dispatch(updateContractFreebieCriteria(freebiePeriodDate, freebieIndex, currentPeriodDateIndex, isEstablishmentContract));
  };

  const removeCriteria = (currentCriteriaIndex) => {
    dispatch(removeFreebieCriteria(freebie, index, currentCriteriaIndex, isEstablishmentContract));
    validator.purgeFields();
  };

  const updateFreebieCriteria = (freebieCriteria, freebieIndex, currentCriteriaIndex) => {
    dispatch(updateContractFreebieCriteria(freebieCriteria, freebieIndex, currentCriteriaIndex, isEstablishmentContract));
  };

  const addDetail = () => {
    dispatch(addFreebieDetail(freebie, index, isEstablishmentContract));
  };

  const removeDetail = (currentDetailIndex) => {
    dispatch(removeFreebieDetail(freebie, index, currentDetailIndex, isEstablishmentContract));
  };

  const handleRemovePeriodDate = (currentPeriodDateIndex) => {
    dispatch(removeFreebiePeriodDate(freebie, index, currentPeriodDateIndex, isEstablishmentContract));
  };

  const updateFreebieDetail = (freebieDetail, freebieIndex, currentDetailIndex) => {
    dispatch(updateContractFreebieDetail(freebieDetail, freebieIndex, currentDetailIndex, isEstablishmentContract));
  };

  let referenceName = t("locale") === "en" ? "nameEnglish" : "nameFrench";

  return (
    <React.Fragment>
      <div className={`card mt-3 zIndex-${10 - index}`}>
        <div className="card-body">
          <h5 className="card-title">
            <button className="btn btn-danger float-right" onClick={() => removeFreebie(freebie)} disabled={!accountReducer.isManager}>
              <span className="fa fa-trash" />
            </button>
            {freebie[referenceName] || `${t("freebie")} ${index + 1}`}
          </h5>
          <div className="card-text">
            <hr />
            <Row>
              <Col md="12">
                <InputWithLabel
                  labelText={t("name")}
                  inputType="text"
                  fieldToUpdate="nameFrench"
                  objectToUpdate={freebie}
                  persistOnBlur
                  updateAction={(value, freebie, fieldName, isBlurring) => {
                    updateFreebie(value, freebie, fieldName, isBlurring);
                    updateFreebie(value, freebie, "nameEnglish", isBlurring);
                  }}
                  disabled={!accountReducer.isManager || (contract.hasProductPrice && freebie.id > 0)}
                  hasValidator
                  errorMessage={validator.message(t("nameFrench"), freebie.nameFrench, `required`)}
                />
              </Col>
            </Row>

            <Row>
              <Col md={"12"}>
                <Card>
                  <CardBody style={{ zIndex: 2 }}>
                    <CardTitle>
                      <button className="btn btn-primary float-right" onClick={addDateCriteria} disabled={!accountReducer.isManager}>
                        <i className="fas fa-plus-circle"></i>
                      </button>
                      <h5>{t("date_periods")}</h5>
                      <hr />
                    </CardTitle>
                    <Row>
                      <Col md="12">
                        {freebie.periodDates &&
                          freebie.periodDates.map((r, periodDateIndex) => (
                            <FreebiePeriodDate
                              key={r.uniqueIdentifier}
                              freebiePeriodDate={r}
                              periodDateIndex={periodDateIndex}
                              removeAction={handleRemovePeriodDate}
                              updateAction={updateFreebiePeriodDate}
                              isEstablishmentContract={isEstablishmentContract}
                              hasProductPrice={contract.hasProductPrice}
                              validator={validator}
                            />
                          ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className={"mt-2"}>
              <Col md={"12"}>
                <Card>
                  <CardBody style={{ zIndex: 1 }}>
                    <CardTitle>
                      <button className="btn btn-primary float-right" onClick={addCriteria} disabled={!accountReducer.isManager}>
                        <i className="fas fa-plus-circle"></i>
                      </button>
                      <h5>{t("criterias")}</h5>
                      <hr />
                    </CardTitle>
                    <Row>
                      <Col md="12">
                        <MultiSelectWithLabel
                          labelText={t("products")}
                          selectPlaceholder={t("freebie_products_placeholder")}
                          objectToUpdate={freebie}
                          updateAction={updateFreebie}
                          fieldToUpdate="product_Ids"
                          textField={referenceName}
                          valueField="id"
                          defaultValue={freebie.product_Ids}
                          data={products}
                          disabled={!accountReducer.isManager || (contract.hasProductPrice && freebie.id > 0)}
                          hasValidator
                          errorMessage={validator.message(t("products"), freebie.product_Ids, `required`)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        {freebie.criterias &&
                          freebie.criterias.map((r, criteriaIndex) => (
                            <Criteria
                              key={r.id > 0 ? r.id : `${criteriaIndex}-${r.comparator_Id || "_"}-${r.value || "_"}`}
                              criteria={r}
                              criteriaIndex={criteriaIndex}
                              index={index}
                              removeCriteria={removeCriteria}
                              updateAction={updateFreebieCriteria}
                              labelKey={"freebie_criteria_operators"}
                              placeholderKey={"freebie_criteria_operators_placeholder"}
                              isEstablishmentContract={isEstablishmentContract}
                              hasProductPrice={contract.hasProductPrice}
                              validator={validator}
                            />
                          ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className={"mt-2"}>
              <Col md={"12"}>
                <Card>
                  <CardBody style={{ zIndex: 0 }}>
                    <CardTitle>
                      <button className="btn btn-primary float-right" onClick={addDetail} disabled={!accountReducer.isManager}>
                        <i className="fas fa-plus-circle"></i>
                      </button>
                      <h5>{t("freebies")}</h5>
                      <hr />
                    </CardTitle>
                    <Row>
                      <Col md="12">
                        {freebie.details &&
                          freebie.details.map((r, detailIndex) => (
                            <Detail
                              key={r.id || r.uniqueIdentifier}
                              detail={r}
                              detailIndex={detailIndex}
                              index={index}
                              removeDetail={removeDetail}
                              updateAction={updateFreebieDetail}
                              labelKey={"freebie_detail_operators"}
                              placeholderKey={"freebie_detail_operators_placeholder"}
                              isEstablishmentContract={isEstablishmentContract}
                              products={products}
                              hasProductPrice={contract.hasProductPrice}
                              validator={validator}
                            />
                          ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Freebie;
