export const groupTargetsReducer = (
  state = {
    groupTargets: [],
    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_GROUP_TARGETS_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_GROUP_TARGETS_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_GROUP_TARGETS_FULFILLED": {
      state = { ...state, updating: false, updated: true, groupTargets: action.payload.items };
      break;
    }
    case "GET_GROUP_TARGETS_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_GROUP_TARGET_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_GROUP_TARGET_FULFILLED": {
      let groupTargets = state.groupTargets;
      groupTargets = groupTargets.filter(s => s.id !== action.payload.id);
      groupTargets.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        groupTargets
      };
      break;
    }
    case "GET_GROUP_TARGETS_SELECT_FULFILLED": {
      let groupTargets = state.groupTargets;
      action.payload.forEach(groupTarget => {
        if (!groupTargets.some(t => t.id === groupTarget.id)) {
          groupTargets.push(groupTarget);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        groupTargets: groupTargets
      };
      break;
    }
    case "GET_GROUP_TARGET_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default groupTargetsReducer;
