export const freebieTypeOperatorsReducer = (
  state = {
    freebieTypeOperators: [],
    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_FREEBIE_TYPE_OPERATORS_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_FREEBIE_TYPE_OPERATORS_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_FREEBIE_TYPE_OPERATORS_FULFILLED": {
      state = { ...state, updating: false, updated: true, freebieTypeOperators: action.payload.items };
      break;
    }
    case "GET_FREEBIE_TYPE_OPERATORS_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_FREEBIE_TYPE_OPERATOR_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_FREEBIE_TYPE_OPERATOR_FULFILLED": {
      let freebieTypeOperators = state.freebieTypeOperators;
      freebieTypeOperators = freebieTypeOperators.filter(s => s.id !== action.payload.id);
      freebieTypeOperators.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        freebieTypeOperators
      };
      break;
    }
    case "GET_FREEBIE_TYPE_OPERATORS_SELECT_FULFILLED": {
      let freebieTypeOperators = state.freebieTypeOperators;
      action.payload.forEach(freebieTypeOperator => {
        if (!freebieTypeOperators.some(t => t.id === freebieTypeOperator.id)) {
          freebieTypeOperators.push(freebieTypeOperator);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        freebieTypeOperators: freebieTypeOperators
      };
      break;
    }
    case "GET_FREEBIE_TYPE_OPERATOR_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default freebieTypeOperatorsReducer;
