import React from "react";
import { useTranslation } from "react-i18next";

export default function AccomodationTableViewHeader({ contract }) {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <th>{t("contract")}</th>
        {contract.hasContractPeriods ? <th>{t("contract_periods")}</th> : null}
        {contract.hasAgeCategories ? <th>{t("age_categories")}</th> : null}
        {contract.hasGroups ? <th>{t("groups")}</th> : null}
        {contract.hasPriceByDays ? <th>{t("specific_days")}</th> : null}
        {contract.projects && contract.projects.length ? <th>{t("projects")}</th> : null}
        {contract.departures && contract.departures.length ? <th>{t("departures")}</th> : null}
        <th>{t("payment_target")}</th>
        <th>{t("payment_application")}</th>
        <th>{t("sales_taxes")}</th>
        <th>{t("taxes")}</th>
        <th>{t("single")}</th>
        <th>{t("double")}</th>
        <th>{t("twin")}</th>
        <th>{t("triple")}</th>
        <th>{t("quad")}</th>
      </tr>
    </thead>
  );
}
