import axios from "axios";
import { HOST } from "config/config";
import tableDataQueryStringBuilder from "utils/tableData";

export function getProjects(tableDataParam, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().projectsReducer.updating || forceRefresh) {
      if (tableDataParam === undefined) {
        return dispatch({
          type: "GET_PROJECTS_PENDING",
          payload: axios
            .get(`${HOST}/api/projects`)
            .then((response) => response.data)
            .then((json) =>
              dispatch({
                type: "GET_PROJECTS_FULFILLED",
                payload: {
                  items: json,
                },
              })
            )
            .catch((error) =>
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.message,
                },
              })
            ),
        });
      }

      return dispatch({
        type: "GET_PROJECTS_PENDING",
        payload: axios
          .get(`${HOST}/api/projects`, {
            paramsSerializer: (params) => tableDataQueryStringBuilder(params),
            params: tableDataParam,
          })
          .then((response) => response.data)
          .then((json) => {
            if (tableDataParam) {
              dispatch({
                type: "UPDATE_PROJECTS_TABLEDATAPARAM",
                payload: tableDataParam,
              });
            }

            dispatch({
              type: "GET_PROJECTS_FULFILLED",
              payload: json,
            });
          })
          .catch((error) =>
            dispatch({
              type: "GET_PROJECTS_REJECTED",
              payload: error,
            })
          ),
      });
    }
  };
}

export function getProjectsForSelect(establishmentId, startDate = null, endDate = null) {
  return (dispatch, getState) => {
    if (!getState().projectsReducer.updating) {
      return dispatch({
        type: "GET_PROJECTS_PENDING",
        payload: axios
          .get(
            establishmentId
              ? `${HOST}/api/filters/projects?establishment_Id=${establishmentId}
              ${startDate !== null ? "&startDate=" + startDate : ""}
              ${endDate !== null ? "&endDate=" + endDate : ""}`
              : `${HOST}/api/filters/projects`
          )
          .then((response) => response.data)
          .then((json) =>
            dispatch({
              type: "GET_PROJECTS_SELECT_FULFILLED",
              payload: json,
            })
          )
          .catch((error) =>
            dispatch({
              type: "SHOW_MESSAGE",
              payload: {
                type: "error",
                titleKey: "error",
                message: error.message,
              },
            })
          ),
      });
    }
  };
}

export function getProject(projectId, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().projectsReducer.updating || forceRefresh) {
      if (projectId === 0) {
        return dispatch({
          type: "GET_PROJECT_FULFILLED",
          payload: {
            id: 0,
            internalName: "",
            nameFrench: "",
            nameEnglish: "",
            createdDate: new Date(),
          },
        });
      }
      return dispatch({
        type: "GET_PROJECT_PENDING",
        payload: axios
          .get(`${HOST}/api/projects/${projectId}`)
          .then((response) => response.data)
          .then((json) =>
            dispatch({
              type: "GET_PROJECT_FULFILLED",
              payload: json,
            })
          )
          .catch((error) =>
            dispatch({
              type: "GET_PROJECT_REJECTED",
              payload: error,
            })
          ),
      });
    }
  };
}

export function processingProjectDepartureStart() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (!getState().projectsReducer.processingProjectDeparture) {
        dispatch({
          type: "UPDATING_PROJECTS_DEPARTURES_PENDING",
        });

        resolve();
      } else {
        resolve();
      }
    });
}

export function processingProjectDepartureEnd() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (getState().projectsReducer.processingProjectDeparture) {
        dispatch({
          type: "UPDATING_PROJECTS_DEPARTURES_FULFILLED",
        });

        resolve();
      } else {
        resolve();
      }
    });
}
