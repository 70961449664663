import DatePickerWithLabel from "components/FormControls/DatePickerWithLabel";
import React, { useState } from "react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { hideInvoiceChangeDateModal, persistInvoiceRevision } from "redux/quickbooks/invoice/invoice-action";

export default function InvoiceChangeDateModal() {
  const invoiceReducer = useSelector((state) => state.invoiceReducer);

  const { showInvoiceChangeDateModal, invoiceChangeDateModalEntity } = invoiceReducer;
  const [creationDateState, setCreationDateState] = useState({ date: invoiceChangeDateModalEntity["createdDateTime"] });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setCreationDateState({ date: invoiceChangeDateModalEntity["createdDateTime"] });
  }, [invoiceChangeDateModalEntity]);

  const closeModal = () => {
    setCreationDateState({ date: undefined });
    dispatch(hideInvoiceChangeDateModal());
  };

  const handleChangeDateInvoice = () => {
    invoiceChangeDateModalEntity["createdDateTime"] = creationDateState.date;
    dispatch(persistInvoiceRevision(invoiceChangeDateModalEntity)).then(() => {
      closeModal();
    });
  };

  if (!showInvoiceChangeDateModal) return null;

  return (
    <Modal className="mw-100 w-50" isOpen={showInvoiceChangeDateModal}>
      <ModalHeader>{t("invoice_change_date_modal_title")}</ModalHeader>
      <ModalBody>
        <h6>
          <strong>{t("invoice_change_date_impact")}</strong>
        </h6>
        <div className={"mt-2"}>{t("invoice_change_date_warning")}</div>
        <br />

        <DatePickerWithLabel
          labelText={t("created_date")}
          objectToUpdate={creationDateState}
          fieldToUpdate={`date`}
          updateAction={(a) => {
            setCreationDateState({ date: a });
          }}
          dateFormat={t("date_format")}
          locale={t("locale")}
          persistOnBlur
          hasValidator
        />
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleChangeDateInvoice}>
          {t("confirmer")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
