import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getQuickbooksCustomer } from "redux/quickbooks/quickbooks-customer/quickbooks-customer-action";
import SelectWithLabel from "components/FormControls/SelectWithLabel";
import { useState } from "react";
import Spinner from "components/Spinner/Spinner";

function QuickbooksCustomers() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const quickbooksCustomerReducer = useSelector((state) => state.quickbooksCustomerReducer);

  const { quickbooksCustomer, gettingQuickbooksCustomer } = quickbooksCustomerReducer;
  const accountReducer = useSelector((state) => state.accountReducer);
  const { isManager, availableGroups } = accountReducer;
  const [selectedGroupId, setSelectedGroupId] = useState(accountReducer.userGroupId);

  useEffect(() => {
    dispatch(getQuickbooksCustomer(selectedGroupId));
  }, [dispatch, selectedGroupId]);

  return (
    isManager && (
      <React.Fragment>
        <div className="dashhead mt-4">
          <div className="dashhead-titles">
            <h6 className="dashhead-subtitle">{t("overview")}</h6>
            <h2 className="dashhead-title">{t("quickbooks_customer")}</h2>
            <SelectWithLabel
              filterable
              clearable
              labelText={t("usergroup")}
              selectPlaceholder={t("usergroup")}
              data={availableGroups}
              textField={"name"}
              valueField="userGroup_Id"
              value={selectedGroupId}
              objectToUpdate={selectedGroupId}
              updateAction={setSelectedGroupId}
              fieldToUpdate="userGroup_Id"
            />
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-body">
            <div className="card-text">
              <table className={`table`}>
                <thead>
                  <tr>
                    <td>Id</td>
                    <td>Fully Qualified Name</td>
                    <td>Account Manager</td>
                    <td>Billing Address</td>
                  </tr>
                </thead>
                <tbody>
                  {gettingQuickbooksCustomer ? (
                    <tr>
                      <td colspan="10" style={{ textAlign: "center" }}>
                        <Spinner />
                      </td>
                    </tr>
                  ) : !quickbooksCustomer || quickbooksCustomer.length === 0 ? (
                    <tr>
                      <td colspan="10" style={{ textAlign: "center" }}>
                        {t("no_records")}
                      </td>
                    </tr>
                  ) : (
                    quickbooksCustomer?.map((customer) => (
                      <tr>
                        <td>{customer.id}</td>
                        <td>{customer.fullyQualifiedName}</td>
                        <td>
                          {customer.givenName} {customer.familyName}
                        </td>
                        <td>
                          {customer.billAddr?.line1}
                          <br />
                          {customer.billAddr?.line2}
                          {customer.billAddr?.city} {customer.billAddr?.countrySubDivisionCode} {customer.billAddr?.postalCode}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  );
}

export default QuickbooksCustomers;
