import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";

import { addContractFreebie, removeContractFreebie, showConfirmDeleteWithPriceDetailModal } from "redux/actions/contracts";
import { setActiveStep } from "redux/actions/contracts";
import { Row, Col, Alert } from "reactstrap";
import Freebie from "./Components/Freebie";
import { getProductsForEstablishment } from "redux/actions/products";
import Spinner from "components/Spinner/Spinner";

export default function Freebies({ contract, steps, isEstablishmentContract, validator }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { match } = useReactRouter();
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const productsReducer = useSelector((state) => state.productsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  useEffect(() => {
    let currentStep = steps.findIndex((s) => s.name === "freebies");
    if (contractsReducer.activeStepIndex !== currentStep) dispatch(setActiveStep(currentStep));
  }, [contractsReducer.activeStepIndex, contractsReducer.steps, dispatch, steps]);

  useEffect(() => {
    dispatch(getProductsForEstablishment(Number(match.params.id)));
  }, [dispatch, match.params.id]);

  const addFreebie = () => {
    dispatch(addContractFreebie(isEstablishmentContract));
  };

  const removeFreebie = (freebieCategory) => {
    if (contract.hasProductPrice && freebieCategory.id > 0) {
      dispatch(
        showConfirmDeleteWithPriceDetailModal(`${freebieCategory.nameFrench} / ${freebieCategory.nameEnglish}`, (_) =>
          dispatch(removeContractFreebie(freebieCategory, isEstablishmentContract))
        )
      );
    } else {
      dispatch(removeContractFreebie(freebieCategory, isEstablishmentContract));
    }

    validator.purgeFields();
  };

  let referenceName = t("locale") === "en" ? "nameEnglish" : "nameFrench";
  let sortedProducts = (productsReducer.establishmentProducts?.filter((x) => x.isActive) || []).sort((a, b) =>
    a[referenceName] ? a[referenceName].localeCompare(b[referenceName]) : 0
  );

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h4 className="dashhead-title">{t("freebies_title")}</h4>
          <Alert type="primary" className="mt-2">
            {t("freebies_information")}
          </Alert>
          <hr />
        </div>
      </div>

      <Row>
        <Col md="12">
          <button className="btn btn-primary float-right" onClick={addFreebie} disabled={!accountReducer.isManager}>
            {t("add_freebie")}
          </button>
        </Col>
      </Row>
      {sortedProducts && sortedProducts.length ? (
        contract && contract.freebies && contract.freebies.length ? (
          contract.freebies.map((f, index) => (
            <Freebie
              key={f.uniqueIdentifier}
              removeFreebie={removeFreebie}
              contract={contract}
              freebie={f}
              index={index}
              products={sortedProducts}
              isEstablishmentContract={isEstablishmentContract}
              validator={validator}
            />
          ))
        ) : null
      ) : (
        <Row>
          <Col md="12" className="text-center">
            <Spinner />
          </Col>
        </Row>
      )}
      {contract && contract.freebies && contract.freebies.length > 0 && (
        <Row className={"mt-2"}>
          <Col md="12">
            <button className="btn btn-primary float-right" onClick={addFreebie} disabled={!accountReducer.isManager}>
              {t("add_freebie")}
            </button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}
