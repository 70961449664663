import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

// actions
import { openQuickbooksLoginWindow } from "redux/actions/account";
import { getQuickbooksCompanies } from "redux/quickbooks/quickbooks-company/quickbooks-company-action";

// components

// others

function QuickbooksCompanies() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const quickbooksCompanyReducer = useSelector((state) => state.quickbooksCompanyReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const { quickbooksCompanies } = quickbooksCompanyReducer;
  const { username, isManager } = accountReducer;

  useEffect(() => {
    dispatch(getQuickbooksCompanies());
  }, [dispatch]);

  const handleOpenQuickbooksLoginWindow = (quickbooksCompanyId) => {
    dispatch(openQuickbooksLoginWindow(username, quickbooksCompanyId));
  };

  return (
    isManager &&
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">{t("overview")}</h6>
          <h2 className="dashhead-title">{t("quickbooks_company")}</h2>
        </div>
      </div>

      <div className="card mt-2">
        <div className="card-body">
          <div className="card-text">
            <table className={`table`}>
              <thead>
                <tr>
                  <td>Company</td>
                  <td>Name</td>
                  <td>Token Expiration</td>
                  <td>Refresh Token Expiration</td>
                  <td>Realm Id</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                {quickbooksCompanies.map((company) => (
                  <tr>
                    <td>{company.id}</td>
                    <td>{company.companyName}</td>
                    <td>{moment(company.quickbooksExpiresOn).format("LLL")}</td>
                    <td>{moment(company.quickbooksRefreshTokenExpiresOn).format("LLL")}</td>
                    <td>{company.quickbooksRealmId}</td>
                    <td>
                      <button onClick={() => handleOpenQuickbooksLoginWindow(company.id)}>
                        <span className="fas fa-file-invoice" /> {t("QB")}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
                
  );
}

export default QuickbooksCompanies;
