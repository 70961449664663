import axios from "axios";
import { HOST } from "config/config";
import tableDataQueryStringBuilder from "utils/tableData";

export function getTaxTypes(tableDataParam, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().taxTypesReducer.updating || forceRefresh) {
      if (tableDataParam === undefined) {
        return new Promise((resolve) =>
          dispatch({
            type: "GET_TAX_TYPES_PENDING",
            payload: axios
              .get(`${HOST}/api/tax-types`)
              .then((response) => response.data)
              .then((json) => {
                dispatch({
                  type: "GET_TAX_TYPES_FULFILLED",
                  payload: {
                    items: json,
                  },
                });

                resolve(json);
              })
              .catch((error) =>
                dispatch({
                  type: "SHOW_MESSAGE",
                  payload: {
                    type: "error",
                    titleKey: "error",
                    message: error.message,
                  },
                })
              ),
          })
        );
      }

      return dispatch({
        type: "GET_TAX_TYPES_PENDING",
        payload: axios
          .get(`${HOST}/api/tax-types`, {
            paramsSerializer: (params) => tableDataQueryStringBuilder(params),
            params: tableDataParam,
          })
          .then((response) => response.data)
          .then((json) => {
            if (tableDataParam) {
              dispatch({
                type: "UPDATE_TAX_TYPES_TABLEDATAPARAM",
                payload: tableDataParam,
              });
            }

            dispatch({
              type: "GET_TAX_TYPES_FULFILLED",
              payload: json,
            });
          })
          .catch((error) =>
            dispatch({
              type: "GET_TAX_TYPES_REJECTED",
              payload: error,
            })
          ),
      });
    }
  };
}

export function getTaxType(taxTypeId, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().taxTypesReducer.updating || forceRefresh) {
      return new Promise((resolve, reject) => {
        if (taxTypeId === 0) {
          const newObject = {
            id: 0,
            internalName: "",
            nameFrench: "",
            nameEnglish: "",
            isPercentage: false,
          };
          dispatch({
            type: "GET_TAX_TYPE_FULFILLED",
            payload: newObject,
          });

          return resolve(newObject);
        }

        return dispatch({
          type: "GET_TAX_TYPE_PENDING",
          payload: axios
            .get(`${HOST}/api/tax-types/${taxTypeId}`)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "GET_TAX_TYPE_FULFILLED",
                payload: json,
              });

              return resolve(json);
            })
            .catch((error) => {
              dispatch({
                type: "GET_TAX_TYPE_REJECTED",
                payload: error,
              });

              return reject(error);
            }),
        });
      });
    }
  };
}

export function updateEditTaxType(taxType) {
  return (dispatch) =>
    dispatch({
      type: "UPDATE_EDIT_TAX_TYPE",
      payload: taxType,
    });
}

export function persistTaxType(taxType) {
  let isNew = taxType.id === 0;
  return (dispatch, getState) => {
    if (!getState().taxTypesReducer.updating) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "PERSISTING_TAX_TYPE",
          payload: (isNew ? axios.post(`${HOST}/api/tax-types`, taxType) : axios.put(`${HOST}/api/tax-types/${taxType.id}`, taxType))
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "PERSISTED_TAX_TYPE",
                payload: {
                  taxType: json,
                  isNew: isNew,
                },
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "saved_successful",
                },
              });
              return resolve();
            })
            .catch((error) => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response,
                },
              });
              return reject();
            }),
        })
      );
    }
  };
}

export function deleteTaxType(taxTypeId) {
  return (dispatch, getState) => {
    if (!getState().taxTypesReducer.updating) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "DELETING_TAX_TYPE",
          payload: axios
            .delete(`${HOST}/api/tax-types/${taxTypeId}`)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "DELETED_TAX_TYPE",
                payload: taxTypeId,
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "saved_successful",
                },
              });
              return resolve();
            })
            .catch((error) => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response,
                },
              });
              return reject();
            }),
        })
      );
    }
  };
}
