import React from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/utils";

import { Row, Col } from "reactstrap";
import InputWithLabel from "components/FormControls/InputWithLabel";
import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";
import { useSelector } from "react-redux";

function Detail({ detail, detailIndex, index, removeDetail, updateAction, products, placeholderKey, hasProductPrice, validator }) {
  const { t } = useTranslation();
  const accountReducer = useSelector((state) => state.accountReducer);

  const updateDetail = (value, model, field, isBlurring) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;

    updateAction(model, index, detailIndex);
  };

  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  return (
    <React.Fragment>
      {detailIndex !== 0 && (
        <Row>
          <Col md={"12"}>
            <hr />
          </Col>
        </Row>
      )}
      <Row>
        <Col md="5">
          <MultiSelectWithLabel
            labelText={t("products")}
            selectPlaceholder={t("detail_products_placeholder")}
            objectToUpdate={detail}
            updateAction={updateDetail}
            fieldToUpdate="product_Ids"
            textField={referenceName}
            valueField="id"
            defaultValue={detail.product_Ids}
            data={products}
            disabled={!accountReducer.isManager || (hasProductPrice && detail.id > 0)}
            hasValidator
            errorMessage={validator.message(t("products"), detail.product_Ids, `required`)}
          />
        </Col>

        <Col md="2" className={"detail-application-checkbox"}>
          <label>{t("application")}</label>
          <InputWithLabel
            inputNamePrefix={detail.uniqueIdentifier}
            labelText={t("guide")}
            inputType="checkbox"
            fieldToUpdate="isAppliedForGuide"
            objectToUpdate={detail}
            persistOnBlur
            updateAction={updateDetail}
            disabled={!accountReducer.isManager || (hasProductPrice && detail.id > 0)}
            hasValidator
            errorMessage={validator.message(
              t("application"),
              detail.isAppliedForGuide,
              `boolean_at_least_one:${detail.isAppliedForGuide},${detail.isAppliedForDriver},${detail.isAppliedForTraveler}`
            )}
          />
          <InputWithLabel
            inputNamePrefix={detail.uniqueIdentifier}
            labelText={t("driver")}
            inputType="checkbox"
            fieldToUpdate="isAppliedForDriver"
            objectToUpdate={detail}
            persistOnBlur
            updateAction={updateDetail}
            disabled={!accountReducer.isManager || (hasProductPrice && detail.id > 0)}
            hasValidator
            errorMessage={validator.message(
              t("application"),
              detail.isAppliedForDriver,
              `boolean_at_least_one:${detail.isAppliedForGuide},${detail.isAppliedForDriver},${detail.isAppliedForTraveler}`
            )}
          />
          <InputWithLabel
            inputNamePrefix={detail.uniqueIdentifier}
            labelText={t("traveler")}
            inputType="checkbox"
            fieldToUpdate="isAppliedForTraveler"
            objectToUpdate={detail}
            persistOnBlur
            updateAction={updateDetail}
            disabled={!accountReducer.isManager || (hasProductPrice && detail.id > 0)}
            hasValidator
            errorMessage={validator.message(
              t("application"),
              detail.isAppliedForTraveler,
              `boolean_at_least_one:${detail.isAppliedForGuide},${detail.isAppliedForDriver},${detail.isAppliedForTraveler}`
            )}
          />
        </Col>

        <Col md="2">
          <InputWithLabel
            labelText={t("reduction_percentage")}
            inputType="text"
            fieldToUpdate="percentage"
            objectToUpdate={detail}
            persistOnBlur
            updateAction={updateDetail}
            disabled={!accountReducer.isManager || (hasProductPrice && detail.id > 0)}
            hasValidator
            errorMessage={validator.message(t("reduction_percentage"), detail.percentage, `required`)}
          />
        </Col>

        <Col md="2">
          <InputWithLabel
            labelText={t("quantity")}
            inputType="text"
            fieldToUpdate="quantity"
            objectToUpdate={detail}
            persistOnBlur
            updateAction={updateDetail}
            disabled={!accountReducer.isManager || (hasProductPrice && detail.id > 0)}
            hasValidator
            errorMessage={validator.message(t("quantity"), detail.quantity, `required`)}
          />
        </Col>

        <Col md="1" className="text-right">
          <button
            className="btn btn-danger "
            style={{ marginTop: "1.3rem" }}
            onClick={() => removeDetail(detailIndex)}
            disable={!accountReducer.isManager}
          >
            <span className="fa fa-trash" />
          </button>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Detail;
