import { HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { HOST } from "config/config";
import { withCallbacks, signalMiddleware } from "redux-signalr";
import { closeQuickbooksLoginWindow, setQuickbooksState } from "redux/actions/account";

const connection = new HubConnectionBuilder()
  .withUrl(`${HOST}/api/quickbooks-hub`, {
    transport: HttpTransportType.LongPolling,
  })
  .configureLogging(LogLevel.Information)
  .withAutomaticReconnect()
  .build();

const callbacks = withCallbacks()
  .add("Message", (message) => (dispatch, getState) => {
    dispatch({
      type: "SHOW_MESSAGE",
      payload: { type: "success", messageKey: message },
    });
  })
  .add("QuickbooksStateChanged", (message) => (dispatch, getState) => {
    if (message.username === getState().accountReducer.username) {
      dispatch(setQuickbooksState(message.quickbooksState));
      dispatch(closeQuickbooksLoginWindow());
    }
  });

export const signalRMiddleware = signalMiddleware({
  callbacks,
  connection,
});
