import React from "react";
import { useSelector } from "react-redux";

export default function ProgressBar({ steps, t, goToSelected }) {
  const contractsReducer = useSelector(state => state.contractsReducer);

  return (
    <div className="card grey">
      <div className="card-body ">
        <div className="card-text text-center">
          <ul id="progressbar" className="m-0">
            {steps.map((s, index) => (
              <li
              key={s.index}
              className={`${contractsReducer.activeStepIndex >= index ? "active pointer" : ""}`}
              onClick={()=> contractsReducer.activeStepIndex >= index ? goToSelected(index) : false}>
                {t(s.key)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
