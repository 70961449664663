import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { hideDeleteInvoiceConfirmationModal, persistInvoiceRevision } from "redux/quickbooks/invoice/invoice-action";

export default function DeleteInvoiceRevisionModal() {
  const invoiceReducer = useSelector((state) => state.invoiceReducer);

  const { showDeleteInvoiceModal, deleteInvoiceModalEntity } = invoiceReducer;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteInvoice = () => {
    deleteInvoiceModalEntity["quickbooksSynchronizationProhibited"] = true;

    dispatch(persistInvoiceRevision(deleteInvoiceModalEntity)).then(() => {
      closeModal();
    });
  };

  const closeModal = () => {
    dispatch(hideDeleteInvoiceConfirmationModal());
  };

  if (!showDeleteInvoiceModal) return null;

  return (
    <Modal className="mw-100 w-50" isOpen={showDeleteInvoiceModal}>
      <ModalHeader>{t("delete_invoice_modal_title")}</ModalHeader>
      <ModalBody>
        <h6>
          <strong>{t("delete_invoice_impact")}</strong>
        </h6>
        <div className={"mt-2"}>{t("delete_invoice_warning")}</div>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleDeleteInvoice}>
          {t("confirmer")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
