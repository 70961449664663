export const purchaseOrderReducer = (
  state = {
    purchaseOrdersToSynchronize: [],
    gettingPurchaseOrderToSynchronize: false,
    pushingPurchaseOrder: false,
    updatingPurchaseOrder: false,
    showDeletePurchaseOrderModal: false,
    showSyncPurchaseOrderModal: false,
    deletePurchaseOrderModalEntity: {},
    syncPurchaseOrderModalEntity: {},
  },
  action
) => {
  switch (action.type) {
    case "GET_PURCHASE_ORDERS_TO_SYNC_PENDING": {
      return { ...state, gettingPurchaseOrderToSynchronize: true };
    }
    case "GET_PURCHASE_ORDERS_TO_SYNC_FULFILLED": {
      return { ...state, gettingPurchaseOrderToSynchronize: false, purchaseOrdersToSynchronize: action.payload };
    }
    case "PERSISTED_PURCHASE_ORDER": {
      let purchaseOrder = action.payload.purchaseOrder;
      if (purchaseOrder.quickbooksSynchronizationProhibited) {
        let filteredPurchaseOrders = state.purchaseOrdersToSynchronize.filter((x) => x.id !== purchaseOrder.id);
        return { ...state, updatingPurchaseOrder: false, purchaseOrdersToSynchronize: filteredPurchaseOrders };
      }

      let purchaseOrderToUpdate = state.purchaseOrdersToSynchronize.find((x) => x.id === purchaseOrder.id);
      purchaseOrderToUpdate.isOnHold = purchaseOrder.isOnHold;

      return { ...state, updatingPurchaseOrder: false };
    }
    case "PUSHING_PURCHASE_ORDER": {
      return { ...state, pushingPurchaseOrder: true };
    }
    case "SHOW_DELETE_PURCHASE_ORDER_MODAL": {
      state = { ...state, showDeletePurchaseOrderModal: true, deletePurchaseOrderModalEntity: action.payload };
      break;
    }
    case "HIDE_DELETE_PURCHASE_ORDER_MODAL": {
      state = { ...state, showDeletePurchaseOrderModal: false, deletePurchaseOrderModalEntity: {} };
      break;
    }
    case "SHOW_SYNC_PURCHASE_ORDER_MODAL": {
      state = { ...state, showSyncPurchaseOrderModal: true, syncPurchaseOrderModalEntity: action.payload };
      break;
    }
    case "HIDE_SYNC_PURCHASE_ORDER_MODAL": {
      state = { ...state, showSyncPurchaseOrderModal: false, syncPurchaseOrderModalEntity: {} };
      break;
    }
    case "PUSHED_PURCHASE_ORDER": {
      let purchaseOrdersToSynchronize = state.purchaseOrdersToSynchronize;

      if (action.payload.status === "success") {
        purchaseOrdersToSynchronize = purchaseOrdersToSynchronize.filter((s) => s.id !== action.payload.purchaseOrderId);
      }

      return { ...state, pushingPurchaseOrder: false, purchaseOrdersToSynchronize };
    }
    default: {
    }
  }

  return state;
};

export default purchaseOrderReducer;
