export const establishmentReservationTypesReducer = (
  state = {
    establishmentReservationTypesForSelect: [],
    selectUpdating: false,
  },
  action
) => {
  switch (action.type) {
    case "GET_ESTABLISHMENT_RESERVATION_TYPES_FOR_SELECT_PENDING": {
      state = { ...state, selectUpdating: true };
      break;
    }
    case "GET_ESTABLISHMENT_RESERVATION_TYPES_FOR_SELECT_FULFILLED": {
      state = { ...state, selectUpdating: false, establishmentReservationTypesForSelect: action.payload.establishmentReservationTypes };

      break;
    }

    default: {
    }
  }

  return state;
};

export default establishmentReservationTypesReducer;
