import React from "react";

export default function InputCheckbox({ props }) {
  let name = props.inputNamePrefix ? `${props.inputNamePrefix}-` : "";
  name += props.fieldToUpdate;

  return (
    <div className={`form-check mb-3`} style={props.style}>
      <input
        className={props.errorMessage || props.forceShowErrorState ? "form-check-input is-invalid" : "form-check-input"}
        disabled={props.disabled}
        id={name}
        name={name}
        type={props.inputType}
        checked={
          props.objectToUpdate[props.fieldToUpdate] === null || props.objectToUpdate[props.fieldToUpdate] === undefined
            ? ""
            : props.objectToUpdate[props.fieldToUpdate]
        }
        onChange={(e) => {
          props.updateAction(e.target.checked, props.objectToUpdate, props.fieldToUpdate, true);
        }}
      />
      <label
        className={props.errorMessage || props.forceShowErrorState ? "form-check-label text-danger" : "form-check-label"}
        htmlFor={name}
      >
        {props.prependtooltip ? props.prependtooltip : null}
        {props.labelText}
        {props.appendtooltip ? props.appendtooltip : null}
      </label>

      {props.hasValidator && props.errorMessage}
    </div>
  );
}
