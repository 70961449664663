import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/utils";

import { Row, Col } from "reactstrap";
import InputWithLabel from "components/FormControls/InputWithLabel";
import SelectWithLabel from "components/FormControls/SelectWithLabel";

function Criteria({
  criteria,
  criteriaIndex,
  index,
  removeCriteria,
  updateAction,
  labelKey,
  placeholderKey,
  hasProductPrice,
  validator,
  showDelete = true,
}) {
  const { t } = useTranslation();
  const comparatorsReducer = useSelector((state) => state.comparatorsReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const updateCriteria = (value, model, field, isBlurring) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;

    updateAction(model, index, criteriaIndex);
  };
  let validOperators = comparatorsReducer.comparatorsForSelect.filter((c) => c.comparatorTypeName === "int");
  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "labelEnglish" : "labelFrench";

  return (
    <React.Fragment>
      <Row>
        <Col md="6">
          <SelectWithLabel
            labelText={t(labelKey)}
            selectPlaceholder={t(placeholderKey)}
            data={validOperators}
            textField={referenceName}
            valueField="id"
            value={criteria.comparator_Id}
            objectToUpdate={criteria}
            updateAction={updateCriteria}
            fieldToUpdate="comparator_Id"
            disabled={!accountReducer.isManager || (hasProductPrice && criteria.id > 0)}
            forceShowErrorState={criteria.isInvalid}
            hasValidator
            errorMessage={validator.message(t(labelKey), criteria.comparator_Id, `required`)}
          />
        </Col>
        <Col md="5">
          <InputWithLabel
            labelText={t("value")}
            inputType="text"
            fieldToUpdate="value"
            objectToUpdate={criteria}
            persistOnBlur
            updateAction={updateCriteria}
            disabled={!accountReducer.isManager || (hasProductPrice && criteria.id > 0)}
            forceShowErrorState={criteria.isInvalid}
            hasValidator
            errorMessage={validator.message(t("value"), criteria.value, `required`)}
          />
        </Col>
        {showDelete && accountReducer.isManager && (
          <Col md="1" className="text-right">
            <button className="btn btn-danger " style={{ marginTop: "1.3rem" }} onClick={() => removeCriteria(criteriaIndex)}>
              <span className="fa fa-trash" />
            </button>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
}

export default Criteria;
