import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/utils";

import { removeContractDayGroup, updateContractDayGroup, showConfirmDeleteWithPriceDetailModal } from "redux/actions/contracts";

import { Row, Col } from "reactstrap";
import InputWithLabel from "components/FormControls/InputWithLabel";
import MultiSelectWithLabel from "components/FormControls/MultiSelectWithLabel";

export default function DayGroup({ contractDay, contract, validator, index, isEstablishmentContract }) {
  const accountReducer = useSelector((state) => state.accountReducer);
  const daysReducer = useSelector((state) => state.daysReducer);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleUpdateContractDay = (value, model, field) => {
    if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

    model[field] = value;

    dispatch(updateContractDayGroup(model, isEstablishmentContract));
  };

  const handleRemoveContractDay = (contractDay) => {
    if (contract.hasProductPrice && contractDay.id > 0) {
      dispatch(
        showConfirmDeleteWithPriceDetailModal(`${contractDay.nameFrench} / ${contractDay.nameEnglish}`, (_) =>
          dispatch(removeContractDayGroup(contractDay, isEstablishmentContract))
        )
      );
    } else {
      dispatch(removeContractDayGroup(contractDay, isEstablishmentContract));
    }

    validator.purgeFields();
  };

  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  let labelReferenceName = isEnglish ? "labelEnglish" : "labelFrench";

  return (
    <React.Fragment>
      <div className={`card mt-3 zIndex-${10 - index}`}>
        <div className="card-body">
          <h5 className="card-title">
            <button
              className="btn btn-danger float-right"
              onClick={() => handleRemoveContractDay(contractDay)}
              disabled={!accountReducer.isManager}
            >
              <span className="fa fa-trash" />
            </button>
            {contractDay[referenceName] || `${t("contract_day_group")} ${index + 1}`}
          </h5>
          <div className="card-text">
            <hr />
            <Row>
              <Col md="12">
                <InputWithLabel
                  labelText={t("name")}
                  inputType="text"
                  fieldToUpdate="nameFrench"
                  objectToUpdate={contractDay}
                  persistOnBlur
                  updateAction={(value, day, fieldName, isBlurring) => {
                    handleUpdateContractDay(value, day, fieldName, isBlurring);
                    handleUpdateContractDay(value, day, "nameEnglish", isBlurring);
                  }}
                  disabled={!accountReducer.isManager || (contract.hasProductPrice && contractDay.id > 0)}
                  hasValidator
                  errorMessage={validator.message(t("nameFrench"), contractDay.nameFrench, "required")}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <hr />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="d-flex flex-nowrap">
                  <div className="form-group flex-fill">
                    <MultiSelectWithLabel
                      labelText={t("days")}
                      id="days"
                      name="days"
                      selectPlaceholder={t("days_placeholder")}
                      objectToUpdate={contractDay}
                      updateAction={handleUpdateContractDay}
                      fieldToUpdate="day_Ids"
                      textField={labelReferenceName}
                      valueField="id"
                      defaultValue={contractDay.day_Ids}
                      data={daysReducer.daysForSelect}
                      disabled={!accountReducer.isManager || (contract.hasProductPrice && contractDay.id > 0)}
                      forceShowErrorState={contractDay.isInvalid}
                      filter="contains"
                      hasValidator
                      errorMessage={validator.message(t("days"), contractDay.day_Ids, "required")}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
