import React from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import LabelField from "components/FormControls/LabelField";
import { Multiselect } from "react-widgets";

export default function SummarySpecificProjectDepartures({ contract }) {
  const { t } = useTranslation();
  const departuresReducer = useSelector((state) => state.departuresReducer);

  const isEnglish = t("locale") === "en";
  const referenceLabel = isEnglish ? "labelEnglish" : "labelFrench";

  return (
    <div className={`card mt-3`}>
      <div className="card-body">
        <h5 className="card-title">{t("project_departures")}</h5>
        <div className="card-text">
          {contract.contractDepartureGroups &&
            contract.contractDepartureGroups.map((a) => (
              <React.Fragment key={a.id}>
                <hr />
                <Row>
                  <Col md="6">
                    <LabelField labelText={t("nameFrench")} value={a.nameFrench} />
                  </Col>
                  <Col md="6">
                    <LabelField labelText={t("nameEnglish")} value={a.nameEnglish} />
                  </Col>
                </Row>

                <h5 className="mb-1">{t("departures")}</h5>
                <Row>
                  <Col md="12">
                    <Multiselect
                      id="departures"
                      name="departures"
                      placeholder={t("departures_placeholder")}
                      onChange={(e) => {}}
                      value={a.departure_Ids}
                      valueField="id"
                      textField={referenceLabel}
                      data={departuresReducer.departuresForSelect}
                      filter="contains"
                      disabled={true}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
}
