import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";

import ProgressBar from "views/Contracts/Components/ProgressBar";

import { isEmpty } from "utils/utils";
import { Row, Col, Button } from "reactstrap";
import Spinner from "components/Spinner/Spinner";

import { getComparators } from "redux/actions/comparators";
import { getProductContract, updateContract, persistProductContract, updateSteps } from "redux/actions/contracts";
import { getDeparturesForSelect } from "redux/actions/departures";
import { getProjectsForSelect } from "redux/actions/projects";
import { getDurations } from "redux/actions/durations";

import GeneralInformation from "views/Contracts/Components/Steps/GeneralInformation";
import AgeCategories from "views/Contracts/Components/Steps/AgeCategories";
import LengthCategories from "views/Contracts/Components/Steps/LengthCategories";
import Groups from "views/Contracts/Components/Steps/Groups";
import Freebies from "views/Contracts/Components/Steps/Freebies";
import Summary from "views/Contracts/Components/Steps/Summary";
import ContractPeriods from "views/Contracts/Components/Steps/ContractPeriods";
import ContractDepartureGroups from "views/Contracts/Components/Steps/ContractDepartureGroups";
import ByDays from "./Components/Steps/ByDays";
import { getDays } from "redux/actions/days";

function ProductContract() {
  const { t } = useTranslation();

  const comparatorsReducer = useSelector((state) => state.comparatorsReducer);
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const daysReducer = useSelector((state) => state.daysReducer);
  const durationsReducer = useSelector((state) => state.durationsReducer);
  const productsReducer = useSelector((state) => state.productsReducer);
  const taxTypesReducer = useSelector((state) => state.taxTypesReducer);
  const departuresReducer = useSelector((state) => state.departuresReducer);
  const projectsReducer = useSelector((state) => state.projectsReducer);

  const dispatch = useDispatch();

  const { history, match } = useReactRouter();

  useEffect(() => {
    if (!contractsReducer.productContract || +contractsReducer.productContract.id !== +match.params.contractId) {
      dispatch(getProductContract(parseInt(match.params.contractId, 10), parseInt(match.params.id, 10))).then((contract) =>
        dispatch(updateSteps(contract))
      );
    }
    if (!comparatorsReducer.comparatorsForSelect || comparatorsReducer.comparatorsForSelect.length === 0) dispatch(getComparators());
    if (!daysReducer.daysForSelect || daysReducer.daysForSelect.length === 0) dispatch(getDays());
    if (!durationsReducer.durationsForSelect || durationsReducer.durationsForSelect.length === 0) dispatch(getDurations());
    if (!departuresReducer.departuresForSelect || departuresReducer.departuresForSelect.length === 0) dispatch(getDeparturesForSelect());
    if (!projectsReducer.projectsForSelect || projectsReducer.projectsForSelect.length === 0) dispatch(getProjectsForSelect());
  }, [
    match,
    dispatch,
    comparatorsReducer.comparatorsForSelect,
    contractsReducer.productContract,
    durationsReducer.durationsForSelect,
    taxTypesReducer.taxTypesForSelect,
    departuresReducer.departuresForSelect,
    projectsReducer.projectsForSelect,
    daysReducer.daysForSelect,
  ]);

  const update = (value, contract, field, isBlurring) => {
    if ((isEmpty(contract[field]) && isEmpty(value)) || contract[field] === value) return;    

    if(field === "nameFrench" || field === "start" || field ==="end" || field === "nameEnglish"){

      let startYear = new Date(contract["start"]).getFullYear();
      let endYear = new Date(contract["end"]).getFullYear();

      let yearPrefix = startYear !== endYear ? startYear + "-" + endYear : startYear;


      contract["finalContractNameFrench"] = yearPrefix + "-" + contract["nameFrench"];
      contract["finalContractNameEnglish"] = yearPrefix + "-" + contract["nameEnglish"];
    }

    contract[field] = value;
    dispatch(updateContract(contract));
  };

  const goToNext = () => {
    history.push(
      `/products/${match.params.id}/contracts/${match.params.contractId}/${
        contractsReducer.steps.filter((s) => s.isActive)[++contractsReducer.activeStepIndex].name
      }`
    );
  };

  const goToPrevious = () => {
    let previous = contractsReducer.steps.filter((s) => s.isActive)[--contractsReducer.activeStepIndex];
    let previousName = previous ? previous.name : contractsReducer.steps.find((s) => s.isActive).name;

    history.push(`/products/${match.params.id}/contracts/${match.params.contractId}/${previousName}`);
  };

  const handlePersistProductContract = (contract) => {
    dispatch(persistProductContract(contract)).then((_) => {
      let establishmentId = productsReducer.products.find((p) => p.id === Number(match.params.id)).establishment_Id;
      history.push(`/establishments/${establishmentId}/products`);
    });
  };

  let steps = JSON.parse(JSON.stringify(contractsReducer.steps.filter((s) => s.isActive)));
  return (
    <div className="card mt-3">
      <div className="card-body">
        <div className="card-text">
          <ProgressBar steps={steps} t={t} />

          {isEmpty(contractsReducer.productContract) ? (
            <Spinner />
          ) : (
            <Switch>
              <Route
                path="/products/:id/contracts/:contractId"
                exact
                render={() => <GeneralInformation t={t} contract={contractsReducer.productContract} update={update} steps={steps} />}
              />
              <Route
                path="/products/:id/contracts/:contractId/general-information"
                exact
                render={() => <GeneralInformation t={t} contract={contractsReducer.productContract} update={update} steps={steps} />}
              />

              <Route
                path="/products/:id/contracts/:contractId/length-categories"
                exact
                render={() => <LengthCategories t={t} contract={contractsReducer.productContract} update={update} steps={steps} />}
              />

              <Route
                path="/products/:id/contracts/:contractId/age-categories"
                exact
                render={() => <AgeCategories t={t} contract={contractsReducer.productContract} update={update} steps={steps} />}
              />

              <Route
                path="/products/:id/contracts/:contractId/groups"
                exact
                render={() => (
                  <Groups
                    t={t}
                    contract={contractsReducer.productContract}
                    update={update}
                    products={productsReducer.products}
                    steps={steps}
                  />
                )}
              />

              <Route
                path="/products/:id/contracts/:contractId/freebies"
                exact
                render={() => (
                  <Freebies
                    t={t}
                    contract={contractsReducer.productContract}
                    update={update}
                    products={productsReducer.products}
                    steps={steps}
                  />
                )}
              />

              <Route
                path="/products/:id/contracts/:contractId/contract-periods"
                exact
                render={() => (
                  <ContractPeriods
                    t={t}
                    contract={contractsReducer.productContract}
                    update={update}
                    products={productsReducer.products}
                    steps={steps}
                  />
                )}
              />

              <Route
                path="/products/:id/contracts/:contractId/specific-project-departures"
                exact
                render={() => (
                  <ContractDepartureGroups
                    t={t}
                    contract={contractsReducer.productContract}
                    update={update}
                    products={productsReducer.products}
                    steps={steps}
                  />
                )}
              />

              <Route
                path="/products/:id/contracts/:contractId/price-by-days"
                exact
                render={() => (
                  <ByDays
                    t={t}
                    contract={contractsReducer.productContract}
                    update={update}
                    products={productsReducer.products}
                    steps={steps}
                  />
                )}
              />

              {/* <Route
                path="/products/:id/contracts/:contractId/pricing"
                exact
                render={() => (
                  <Pricing
                    t={t}
                    contract={contractsReducer.contract}
                    taxTypes={taxTypesReducer.taxTypesForSelect}
                    products={productsReducer.products}
                    steps={steps}
                  />
                )}
              /> */}

              <Route
                path="/products/:id/contracts/:contractId/summary"
                exact
                render={() => (
                  <Summary
                    t={t}
                    contract={contractsReducer.productContract}
                    saveContract={handlePersistProductContract}
                    goToPrevious={goToPrevious}
                    products={productsReducer.products}
                    steps={steps}
                  />
                )}
              />
            </Switch>
          )}
          <Row>
            <Col md="12" className="text-right mt-2">
              <hr />
              {contractsReducer.activeStepIndex !== 0 && (
                <Button color="info" onClick={goToPrevious}>
                  {t("previous")}
                </Button>
              )}
              {contractsReducer.activeStepIndex !== steps.filter((s) => s.isActive).length - 1 ? (
                <Button color="success" className="ml-2" onClick={goToNext}>
                  {t("next")}
                </Button>
              ) : (
                <Button color="success" className="ml-2" onClick={() => handlePersistProductContract(contractsReducer.productContract)}>
                  {t("save")}
                </Button>
              )}
            </Col>
          </Row>
          {/* <div className="card mt-3">
        <div className="card-body">
          <div className="card-text">
            <React.Fragment>
              <Row>
                <Col md="12" className="text-right">
                  <Button color="success">{t("save")}</Button>{" "}
                  <Button color="info" onClick={redirectsToProducts}>
                    {t("cancel")}
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          </div>
        </div>
      </div> */}
        </div>
      </div>
    </div>
  );
}

export default ProductContract;
