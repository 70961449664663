export const comparatorsReducer = (
  state = {
    error: null,
    pending: false,
    comparatorsForSelect: []
  },
  action
) => {
  switch (action.type) {
    case "GET_COMPARATORS_PENDING": {
      state = { ...state, pending: true };

      break;
    }
    case "GET_COMPARATOR_FULFILLED": {
      state = { ...state, comparatorsForSelect: action.payload, pending: false };

      break;
    }

    default: {
    }
  }

  return state;
};

export default comparatorsReducer;
