import React from "react";

import { useTranslation } from "react-i18next";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export default function ConfirmDeleteTaxTypeModal({ taxType, isOpen, handleDelete, handleCloseModal }) {
  const { t } = useTranslation();

  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  let otherReference = isEnglish ? "nameFrench" : "nameEnglish";

  return (
    <Modal className="mw-100 w-50" isOpen={isOpen}>
      <ModalHeader>{t("delete_tax_type_title")}</ModalHeader>
      <ModalBody>
        {t("delete_tax_type_warning")}
        <h6 className="mt-2">
          {taxType && taxType[referenceName] && taxType[referenceName].length
            ? taxType[referenceName]
            : taxType
            ? taxType[otherReference]
            : null}
        </h6>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => handleDelete(taxType.id)}>
          {t("delete")}
        </Button>{" "}
        <Button color="info" onClick={handleCloseModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
