import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/utils";

import { addAgeCategoryCriteria, removeAgeCategoryCriteria, updateContractAge, updateContractAgeCriteria } from "redux/actions/contracts";

import { Row, Col } from "reactstrap";
import InputWithLabel from "components/FormControls/InputWithLabel";
import Criteria from "./Criteria";

function AgeCategory({ ageCategory, index, removeAge, isEstablishmentContract, contract, validator }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accountReducer = useSelector((state) => state.accountReducer);

  const updateAgeCategory = (value, model, field, isBlurring) => {
    setTimeout(function () {
      if ((isEmpty(model[field]) && isEmpty(value)) || model[field] === value) return;

      model[field] = value;

      dispatch(updateContractAge(model, index, isEstablishmentContract));
    }, 0);
  };

  const addCriteria = () => {
    dispatch(addAgeCategoryCriteria(ageCategory, index, isEstablishmentContract));
  };

  const removeCriteria = (currentCriteriaIndex) => {
    dispatch(removeAgeCategoryCriteria(ageCategory, index, currentCriteriaIndex, isEstablishmentContract));
  };

  const updateAgeCriteria = (ageCriteria, ageIndex, currentCriteriaIndex) => {
    dispatch(updateContractAgeCriteria(ageCriteria, ageIndex, currentCriteriaIndex, isEstablishmentContract));
  };

  let referenceName = t("locale") === "en" ? "nameEnglish" : "nameFrench";
  if (!ageCategory.criterias || ageCategory.criterias.length === 0) addCriteria();

  return (
    <React.Fragment>
      <div className={`card mt-3 zIndex-${10 - index}`}>
        <div className="card-body">
          <h5 className="card-title">
            <button className="btn btn-danger float-right" onClick={() => removeAge(ageCategory)} disabled={!accountReducer.isManager}>
              <span className="fa fa-trash" />
            </button>
            {ageCategory[referenceName] || `${t("category")} ${index + 1}`}
          </h5>
          <div className="card-text">
            <hr />
            <Row>
              <Col md="12">
                <InputWithLabel
                  labelText={t("name")}
                  inputType="text"
                  fieldToUpdate="nameFrench"
                  objectToUpdate={ageCategory}
                  persistOnBlur
                  updateAction={(value, ageCategory, fieldName, isBlurring) => {
                    updateAgeCategory(value, ageCategory, fieldName, isBlurring);
                    updateAgeCategory(value, ageCategory, "nameEnglish", isBlurring);
                  }}
                  disabled={!accountReducer.isManager || (contract.hasProductPrice && ageCategory.id > 0)}
                  hasValidator
                  errorMessage={validator.message(t("nameFrench"), ageCategory.nameFrench, `required`)}
                />
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <hr />
                <button className="btn btn-primary float-right" onClick={addCriteria} disabled={!accountReducer.isManager}>
                  {t("add_criteria")}
                </button>
                <h5>{t("criterias")}</h5>
              </Col>
            </Row>
            {ageCategory.criterias &&
              ageCategory.criterias.map((r, criteriaIndex) => (
                <Criteria
                  key={`${criteriaIndex}-${r.uniqueIdentifier}`}
                  criteria={r}
                  criteriaIndex={criteriaIndex}
                  index={index}
                  showDelete={ageCategory.criterias.length > 1}
                  hasProductPrice={contract.hasProductPrice && r.id > 0}
                  removeCriteria={removeCriteria}
                  updateAction={updateAgeCriteria}
                  labelKey={"age_criteria_operators"}
                  placeholderKey={"age_criteria_operators_placeholder"}
                  validator={validator}
                />
              ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AgeCategory;
