import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";

import { setActiveStep } from "redux/actions/contracts";

import Spinner from "components/Spinner/Spinner";
import { getProductsForEstablishment } from "redux/actions/products";
import SummaryBody from "./Components/SummaryBody";

export default function Summary({ contract, steps }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { match } = useReactRouter();

  const comparatorsReducer = useSelector((state) => state.comparatorsReducer);
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const categoriesReducer = useSelector((state) => state.categoriesReducer);
  const productsReducer = useSelector((state) => state.productsReducer);
  const daysReducer = useSelector((state) => state.daysReducer);

  useEffect(() => {
    let currentStep = steps.findIndex((s) => s.name === "summary");
    if (contractsReducer.activeStepIndex !== currentStep) dispatch(setActiveStep(currentStep));

    dispatch(getProductsForEstablishment(Number(match.params.id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractsReducer.activeStepIndex, contractsReducer.steps]);

  if (daysReducer.pending || categoriesReducer.updating || productsReducer.updating || comparatorsReducer.pending) return <Spinner />;

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h2 className="dashhead-title">{t("summary")}</h2>
        </div>
      </div>
      <hr />
      <SummaryBody contract={contract} />
    </React.Fragment>
  );
}
