import React, { useEffect } from "react";
import uuidv4 from "uuid/v4";

import { useDispatch, useSelector } from "react-redux";

import CurrencyInput from "react-currency-input";
import { isEmpty } from "utils/utils";

import { Col, Row } from "reactstrap";
import { getTaxTypeApplicationsForSelect } from "redux/actions/tax-type-applications";
import { getTaxTypeTargetsForSelect } from "redux/actions/tax-type-targets";
import { getSalesTaxes } from "redux/actions/sales-taxes";
import { getTaxTypes } from "redux/actions/tax-types";

import ContractPriceDetailHeader from "./Components/ContractPriceDetailHeader";

import PaymentInformation from "./Components/PaymentInformation";
import Spinner from "components/Spinner/Spinner";
import TaxSection from "./Components/TaxSection";
import { persistProductContractPriceDetail } from "redux/actions/product-price-details";
import { useTranslation } from "react-i18next";
import InputWithLabel from "components/FormControls/InputWithLabel";
import GuideDriverPriceDetail from "./Components/GuideDriverPriceDetail";

export default function ActivityPriceDetail({ contract, cpd, index }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const salesTaxesReducer = useSelector((state) => state.salesTaxesReducer);
  const taxTypeApplicationsReducer = useSelector((state) => state.taxTypeApplicationsReducer);
  const taxTypeTargetsReducer = useSelector((state) => state.taxTypeTargetsReducer);
  const taxTypesReducer = useSelector((state) => state.taxTypesReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  useEffect(() => {
    dispatch(getTaxTypes());
    dispatch(getSalesTaxes());
    dispatch(getTaxTypeApplicationsForSelect());
    dispatch(getTaxTypeTargetsForSelect());
  }, [dispatch]);

  const handleUpdateContractPriceDetail = (e, contractPriceDetail, field, isNumber) => {
    let value = e.target ? e.target.value : e;

    if (isNumber) {
      value = value.replace(/,(?=[\d,]*\.\d{2}\b)/g, "");
      value = Number(value);
    }

    if ((isEmpty(contractPriceDetail[field]) && isEmpty(value)) || contractPriceDetail[field] === value) return;

    const updateModel = { contractPriceDetail, contract, fieldValues: [{ value, fieldName: field }] };

    dispatch(persistProductContractPriceDetail(updateModel));
  };

  let guid1 = uuidv4();
  let referenceName = t("locale") === "en" ? "nameEnglish" : "nameFrench";

  if (!cpd.id) return null;

  if (taxTypesReducer.updating || salesTaxesReducer.updating || taxTypeApplicationsReducer.updating || taxTypeTargetsReducer.updating)
    return <Spinner />;

  return (
    <>
      <Col md="12" style={{ zIndex: 1029 - index /* 1030 is the top navbar */ }}>
        <div className="card mt-3">
          <div className="card-body">
            <div className="card-text">
              <ContractPriceDetailHeader
                contractPriceDetail={cpd}
                contractPeriods={contract.contractPeriods}
                ageCategories={contract.ageCategories}
                groups={contract.groups}
                hasGroups={contract.hasGroups}
                contractDepartureGroups={contract.contractDepartureGroups}
                contractDayGroups={contract.contractDayGroups}
                contract={contract}
              />

              <Row>
                <Col md="4">
                  <div className="form-group">
                    <label className="form-label">{`${t("fixedPrice")} - ${
                      taxTypeTargetsReducer.taxTypeTargets.find((ttt) => ttt.id === cpd.taxTypeTarget_Id)[referenceName]
                    }`}</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <CurrencyInput
                        value={cpd.value}
                        id={`value-${guid1}`}
                        onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "value", true)}
                        className="form-control text-right"
                        disabled={!accountReducer.isManager}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              {contract.productSpecifications?.length > 0 &&
                contract.productSpecifications.map((spec) => (
                  <Row>
                    <Col md="4">
                      <InputWithLabel
                        labelText={t("minimum_capacity")}
                        inputType="number"
                        fieldToUpdate="minimumCapacity"
                        objectToUpdate={spec}
                        disabled
                        updateAction={() => {}}
                      />
                    </Col>
                    <Col md="4">
                      <InputWithLabel
                        labelText={t("maximum_capacity")}
                        inputType="number"
                        fieldToUpdate="maximumCapacity"
                        objectToUpdate={spec}
                        disabled
                        updateAction={() => {}}
                      />
                    </Col>
                    <Col md="4">
                      <InputWithLabel
                        labelText={t("minimum_age")}
                        inputType="number"
                        fieldToUpdate="minimumAge"
                        objectToUpdate={spec}
                        disabled
                        updateAction={() => {}}
                      />
                    </Col>
                  </Row>
                ))}

              {(contract.hasGuidePrice || contract.hasDriverPrice) && (
                <>
                  <hr />
                  <GuideDriverPriceDetail
                    key={`${cpd.id}-GuideDriver-Prices`}
                    contract={contract}
                    cpd={cpd}
                    handleUpdateContractPriceDetail={handleUpdateContractPriceDetail}
                    isManager={accountReducer.isManager}
                  />
                  <hr />
                </>
              )}

              <PaymentInformation
                contractPriceDetail={cpd}
                taxTypeTargets={taxTypeTargetsReducer.taxTypeTargets}
                taxTypeApplications={taxTypeApplicationsReducer.taxTypeApplications}
                updateAction={handleUpdateContractPriceDetail}
                salesTaxes={salesTaxesReducer.salesTaxes}
                isManager={accountReducer.isManager}
              />

              <TaxSection cpd={cpd} contract={contract} />
            </div>
          </div>
        </div>
      </Col>
    </>
  );
}
