import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";

import { Row, Col, Button } from "reactstrap";

import Spinner from "components/Spinner/Spinner";
import ValidationSummary from "components/ValidationSummary/ValidationSummary";
import InputWithLabel from "components/FormControls/InputWithLabel";

import { isEmpty } from "utils/utils";

import { getTaxType, updateEditTaxType, persistTaxType } from "redux/actions/tax-types";
import Alert from "reactstrap/lib/Alert";
import { TaxTypeTarget } from "utils/enums";
import ReactTooltip from "react-tooltip";
import { getTaxTypeApplicationsForSelect } from "redux/actions/tax-type-applications";
import { getTaxTypeTargetsForSelect } from "redux/actions/tax-type-targets";

function TaxType() {
  const { t } = useTranslation();
  const [taxOriginalIsFixedValue, setTaxOriginalIsFixedValue] = useState();
  const [acknowledgeChangeWarning, setAcknowledgeChangeWarning] = useState(false);

  const taxTypesReducer = useSelector((state) => state.taxTypesReducer);
  const accountReducer = useSelector((state) => state.accountReducer);
  const taxTypeApplicationsReducer = useSelector((state) => state.taxTypeApplicationsReducer);
  const taxTypeTargetsReducer = useSelector((state) => state.taxTypeTargetsReducer);

  let taxTypeApplications = taxTypeApplicationsReducer.taxTypeApplications;
  let taxTypeTargets = taxTypeTargetsReducer.taxTypeTargets;

  const dispatch = useDispatch();
  const { history, match } = useReactRouter();

  useEffect(() => {
    dispatch(getTaxType(parseInt(match.params.id, 10))).then((taxType) => {
      setTaxOriginalIsFixedValue(taxType.isFixed);

      if (taxType.isInUse) {
        setAcknowledgeChangeWarning(false);
      }
    });

    if (taxTypeApplicationsReducer.taxTypeApplications.length === 0 && !taxTypeApplicationsReducer.updating)
      dispatch(getTaxTypeApplicationsForSelect());

    dispatch(getTaxTypeTargetsForSelect());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match, dispatch, t]);

  const redirectsToTaxTypes = () => {
    history.push("/tax-types");
  };

  const update = (value, taxType, field) => {
    if ((isEmpty(taxType[field]) && isEmpty(value)) || taxType[field] === value) return;

    taxType[field] = value;
    dispatch(updateEditTaxType(taxType));
  };

  const handlePersistTaxType = (taxType) => {
    dispatch(persistTaxType(taxType)).then(() => redirectsToTaxTypes());
  };

  if (
    !taxTypesReducer.editTaxType ||
    taxTypesReducer.isUpdating ||
    taxTypesReducer.updating ||
    taxTypeApplicationsReducer.updating ||
    taxTypeTargetsReducer.updating
  )
    return <Spinner />;

  let errorMessage = "";

  if (
    taxTypesReducer.error &&
    taxTypesReducer.error.response &&
    taxTypesReducer.error.response.data &&
    taxTypesReducer.error.response.data.message
  ) {
    errorMessage = t(taxTypesReducer.error.response.data.message);
  } else if (taxTypesReducer.error && taxTypesReducer.error.response === undefined && taxTypesReducer.error.message !== undefined) {
    errorMessage = taxTypesReducer.error.message;
  }

  let tax = taxTypesReducer.editTaxType;
  const needAcknoledge = (taxOriginalIsFixedValue !== tax.isFixed || tax.isInUse) && parseInt(match.params.id, 10) !== 0;

  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">{tax.id === 0 ? t("create") : t("edit")}</h6>
          <h2 className="dashhead-title">{t("taxes")}</h2>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="card mt-3">
        <div className="card-body">
          <div className="card-text">
            <React.Fragment>
              <Row>
                <Col md="12">{errorMessage !== "" && errorMessage ? <ValidationSummary messages={[errorMessage]} /> : null}</Col>
              </Row>

              <Row>
                <Col md="12">
                  <InputWithLabel
                    labelText={t("unique_name")}
                    inputType="text"
                    fieldToUpdate="internalName"
                    persistOnBlur
                    disabled={tax.id !== 0}
                    objectToUpdate={tax}
                    updateAction={update}
                  />
                  <InputWithLabel
                    labelText={t("nameFrench")}
                    inputType="text"
                    fieldToUpdate="nameFrench"
                    objectToUpdate={tax}
                    persistOnBlur
                    updateAction={update}
                  />
                  <InputWithLabel
                    labelText={t("nameEnglish")}
                    inputType="text"
                    fieldToUpdate="nameEnglish"
                    objectToUpdate={tax}
                    persistOnBlur
                    updateAction={update}
                  />
                  <InputWithLabel
                    labelText={t("defaultValue")}
                    isDecimal
                    inputType="number"
                    fieldToUpdate="defaultValue"
                    objectToUpdate={tax}
                    persistOnBlur
                    updateAction={update}
                  />

                  <InputWithLabel
                    labelText={t("isFixed")}
                    inputType="checkbox"
                    fieldToUpdate="isFixed"
                    objectToUpdate={tax}
                    persistOnBlur
                    updateAction={update}
                  />

                  {needAcknoledge && parseInt(match.params.id, 10) !== 0 && (
                    <Alert type="danger" color={"danger"} className="mt-2">
                      {t("modifing_tax_type_warning")}
                      {!acknowledgeChangeWarning && (
                        <Button className={"mt-2"} color="success" onClick={() => setAcknowledgeChangeWarning(true)}>
                          {t("confirmer")}
                        </Button>
                      )}
                    </Alert>
                  )}
                </Col>

                {tax.isFixed && (
                  <Col>
                    <div className="form-group">
                      <label className="form-control-label">{t("payment_target")}</label>

                      {taxTypeTargets.map((ttt) => (
                        <div className="form-check" key={`${ttt.id}-${tax.id || tax.uniqueIdentifier}`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`taxTypeTarget_Id-${ttt.id}-${tax.id || tax.uniqueIdentifier}`}
                            name={`taxTypeTarget_Id-${tax.id || tax.uniqueIdentifier}`}
                            value={ttt.id}
                            checked={tax.taxTypeTarget_Id === ttt.id}
                            onChange={(e) => update(Number(e.target.value), tax, "taxTypeTarget_Id")}
                          />
                          <label className="form-check-label" htmlFor={`taxTypeTarget_Id-${ttt.id}-${tax.id || tax.uniqueIdentifier}`}>
                            {ttt[referenceName]}
                          </label>
                        </div>
                      ))}
                    </div>

                    <div className="form-group">
                      <label className="form-control-label">{t("payment_calculation")}</label>

                      {taxTypeApplications.map((tta) => (
                        <div className="form-check" key={`${tta.id}-${tax.uniqueIdentifier}`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`taxTypeApplication_Id-${tta.id}-${tax.uniqueIdentifier}`}
                            name={`taxTypeApplication_Id-${tax.uniqueIdentifier}`}
                            value={tta.id}
                            checked={tax.taxTypeApplication_Id === tta.id}
                            onChange={(e) => update(Number(e.target.value), tax, "taxTypeApplication_Id")}
                            disabled={
                              !tax.taxTypeTarget_Id ||
                              (tax.taxTypeTarget_Id &&
                                taxTypeTargets.find((ttt) => ttt.id === tax.taxTypeTarget_Id).internalName ===
                                  TaxTypeTarget.APPLIED_BY_PERCENTAGE)
                            }
                          />
                          <label className="form-check-label" htmlFor={`taxTypeApplication_Id-${tta.id}-${tax.uniqueIdentifier}`}>
                            {tta[referenceName]}
                            <ReactTooltip
                              id={`taxTypeApplication_Id-tooltip-${tta.id}-${tax.uniqueIdentifier}`}
                              type="info"
                              effect="solid"
                              place="top"
                              border
                            />
                            <i
                              className="fas fa-info-circle text-info ml-1"
                              data-tip={isEnglish ? tta.extraInformationEnglish : tta.extraInformationFrench}
                              data-for={`taxTypeApplication_Id-tooltip-${tta.id}-${tax.uniqueIdentifier}`}
                            ></i>
                          </label>
                        </div>
                      ))}
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col md="12" className="text-right">
                  <Button
                    color="success"
                    onClick={() => handlePersistTaxType(tax)}
                    disabled={
                      !accountReducer.isManager ||
                      (needAcknoledge && !acknowledgeChangeWarning) ||
                      (tax.isFixed &&
                        (!tax.taxTypeTarget_Id ||
                          (!tax.taxTypeApplication_Id &&
                            taxTypeTargets.find((ttt) => ttt.id === tax.taxTypeTarget_Id).internalName !==
                              TaxTypeTarget.APPLIED_BY_PERCENTAGE)))
                    }
                  >
                    {t("save")}
                  </Button>
                  <Button color="info" onClick={redirectsToTaxTypes}>
                    {t("cancel")}
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TaxType;
