import axios from "axios";
import { HOST } from "config/config";
import tableDataQueryStringBuilder from "utils/tableData";

export function getSalesTaxes(tableDataParam, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().salesTaxesReducer.updating || forceRefresh) {
      if (tableDataParam === undefined) {
        return new Promise(resolve =>
          dispatch({
            type: "GET_SALES_TAXES_PENDING",
            payload: axios
              .get(`${HOST}/api/sales-taxes`)
              .then(response => response.data)
              .then(json => {
                dispatch({
                  type: "GET_SALES_TAXES_FULFILLED",
                  payload: {
                    items: json
                  }
                });

                resolve(json);
              })
              .catch(error =>
                dispatch({
                  type: "SHOW_MESSAGE",
                  payload: {
                    type: "error",
                    titleKey: "error",
                    message: error.message
                  }
                })
              )
          })
        );
      }

      return dispatch({
        type: "GET_SALES_TAXES_PENDING",
        payload: axios
          .get(`${HOST}/api/sales-taxes`, {
            paramsSerializer: params => tableDataQueryStringBuilder(params),
            params: tableDataParam
          })
          .then(response => response.data)
          .then(json => {
            if (tableDataParam) {
              dispatch({
                type: "UPDATE_SALES_TAXES_TABLEDATAPARAM",
                payload: tableDataParam
              });
            }

            dispatch({
              type: "GET_SALES_TAXES_FULFILLED",
              payload: json
            });
          })
          .catch(error =>
            dispatch({
              type: "GET_SALES_TAXES_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}

export function getSalesTax(salesTaxId, forceRefresh = false) {
  return (dispatch, getState) => {
    if (!getState().salesTaxesReducer.updating || forceRefresh) {
      if (salesTaxId === 0) {
        return dispatch({
          type: "GET_SALES_TAX_FULFILLED",
          payload: {
            id: 0,
            internalName: "",
            nameFrench: "",
            nameEnglish: "",
            isActive: true,
            province_Ids: []
          }
        });
      }
      return dispatch({
        type: "GET_SALES_TAX_PENDING",
        payload: axios
          .get(`${HOST}/api/sales-taxes/${salesTaxId}`)
          .then(response => response.data)
          .then(json =>
            dispatch({
              type: "GET_SALES_TAX_FULFILLED",
              payload: json
            })
          )
          .catch(error =>
            dispatch({
              type: "GET_SALES_TAX_REJECTED",
              payload: error
            })
          )
      });
    }
  };
}

export function updateEditSalesTax(salesTax) {
  return dispatch =>
    dispatch({
      type: "UPDATE_EDIT_SALES_TAX",
      payload: salesTax
    });
}

export function persistSalesTax(salesTax) {
  let isNew = salesTax.id === 0;
  return (dispatch, getState) => {
    if (!getState().salesTaxesReducer.updating) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "PERSISTING_SALES_TAX_PENDING",
          payload: (isNew ? axios.post(`${HOST}/api/sales-taxes`, salesTax) : axios.put(`${HOST}/api/sales-taxes/${salesTax.id}`, salesTax))
            .then(response => response.data)
            .then(json => {
              dispatch({
                type: "PERSISTED_SALES_TAX_FULFILLED",
                payload: {
                  salesTax: json,
                  isNew: isNew
                }
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "saved_successful"
                }
              });
              return resolve();
            })
            .catch(error => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response
                }
              });
              return reject();
            })
        })
      );
    }
  };
}

export function deleteSalesTax(salesTaxId) {
  return (dispatch, getState) => {
    if (!getState().salesTaxesReducer.updating) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "DELETING_SALES_TAX_PENDING",
          payload: axios
            .delete(`${HOST}/api/sales-taxes/${salesTaxId}`)
            .then(response => response.data)
            .then(json => {
              dispatch({
                type: "DELETED_SALES_TAX_FULFILLED",
                payload: salesTaxId
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "saved_successful"
                }
              });
              return resolve();
            })
            .catch(error => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response
                }
              });
              return reject();
            })
        })
      );
    }
  };
}

export function activateSalesTax(salesTax) {
  return (dispatch, getState) => {
    if (!getState().salesTaxesReducer.updating) {
      return new Promise(resolve =>
        dispatch({
          type: "ACTIVATE_SALES_TAX_PENDING",
          payload: axios
            .post(`${HOST}/api/sales-taxes/activate`, salesTax)
            .then(response => response.data)
            .then(json => {
              dispatch({
                type: "ACTIVATE_SALES_TAX_FULFILLED",
                payload: json
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "activated_successfully"
                }
              });

              resolve(json);
            })
            .catch(error =>
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.message
                }
              })
            )
        })
      );
    }
  };
}

export function deactivateSalesTax(salesTax) {
  return (dispatch, getState) => {
    if (!getState().salesTaxesReducer.updating) {
      return new Promise(resolve =>
        dispatch({
          type: "DEACTIVATE_SALES_TAX_PENDING",
          payload: axios
            .post(`${HOST}/api/sales-taxes/deactivate`, salesTax)
            .then(response => response.data)
            .then(json => {
              dispatch({
                type: "DEACTIVATE_SALES_TAX_FULFILLED",
                payload: json
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "deactivated_successfully"
                }
              });

              resolve(json);
            })
            .catch(error =>
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.message
                }
              })
            )
        })
      );
    }
  };
}
