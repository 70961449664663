import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useReactRouter from "use-react-router";
import { Row, Col, Button } from "reactstrap";
import ReactTooltip from "react-tooltip";

import {
  resetSelectedProduct,
  getProductsForEstablishment,
  showRegenerateProductContractModal,
  showDeleteProductModal,
  getExtraProductInformation,
} from "redux/actions/products";
import { updateSelectedEstablishmentProducts } from "redux/actions/products";
import { resetProductPriceDetailProductIdsPricingProductsContrats } from "redux/actions/contracts";
import { getProductTypes } from "redux/actions/product-types";

import MergeProductsModal from "views/Contracts/Components/MergeProductsModal";
import CreatePriceListModal from "views/Contracts/Components/CreatePriceListModal";

import {
  assignProductModal,
  showMergeProductModal,
  activateProduct,
  deactivateProduct,
  showCreatePriceListModal,
} from "redux/actions/products";
import Spinner from "components/Spinner/Spinner";
import RegenerateProductContractModal from "views/Contracts/Components/RegenerateProductContractModal";
import DeleteProductModal from "views/Contracts/Components/DeleteProductModal";
import ConfirmDeactivationProductModal from "views/Products/Components/Components/ConfirmDeactivationProductModal";
import { getObject } from "utils/utils";
import { getRemarkTypes } from "redux/actions/remark-types";

function ProductList({ remarkTypes = [], selectedProducts = [] }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history, match } = useReactRouter();
  const productsReducer = useSelector((state) => state.productsReducer);
  const productTypesReducer = useSelector((state) => state.productTypesReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const [filter, setFilter] = useState("");
  const [sortColumn, setSortColumn] = useState();
  const [isSortDirectionAscending, setSortDirectionAscending] = useState(true);

  useEffect(() => {
    dispatch(getProductsForEstablishment(Number(match.params.id))).then((products) => {
      if (products?.length > 0) {
        dispatch(getExtraProductInformation(products));
      }
    });

    dispatch(resetProductPriceDetailProductIdsPricingProductsContrats());
    dispatch(getProductTypes());
    dispatch(getRemarkTypes());

    return () => {
      dispatch(resetSelectedProduct());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  const updateFilter = (filter) => {
    setFilter(filter);
  };
  const handleSortColumn = (column) => {
    if (sortColumn === column) {
      setSortDirectionAscending(!isSortDirectionAscending);
    } else {
      setSortDirectionAscending(true);
      setSortColumn(column);
    }
  };
  const addProducts = () => {
    dispatch(assignProductModal());
    history.push(`/establishments/${match.params.id}/products/0`);
  };

  const handleShowMergeProductModal = () => {
    dispatch(showMergeProductModal());
  };

  const redirectToProductEdit = (product) => {
    const cloneProduct = JSON.parse(JSON.stringify(product));
    dispatch(assignProductModal(cloneProduct));
    history.push(`/establishments/${match.params.id}/products/${product.id}`);
  };

  // const redirectToContract = product => {
  //   history.push(`/products/${product.id}/contracts`);
  // };

  const redirectToPricing = (product) => {
    history.push(`/establishments/${match.params.id}/products/${product.id}/pricing`);
  };

  const handleShowDeleteProductModal = (e, product) => {
    e.stopPropagation();
    dispatch(showDeleteProductModal(product));
  };

  const handleActivate = (product) => {
    if (accountReducer.isManager) {
      dispatch(activateProduct(product));
    }
  };

  const handleDeactivate = (product) => {
    if (accountReducer.isManager) {
      dispatch(deactivateProduct(product));
    }
  };

  const handleShowCreatePriceListModal = () => {
    dispatch(showCreatePriceListModal(selectedProducts));
  };

  const handleRegenerateProductContract = (e, product) => {
    e.stopPropagation();
    dispatch(showRegenerateProductContractModal(product));
  };

  const handleSelectProduct = (product) => {
    let selectedProducts = productsReducer.establishmentSelectedProducts || [];
    if (selectedProducts.map((sp) => sp.id).includes(product.id)) selectedProducts = selectedProducts.filter((p) => p.id !== product.id);
    else selectedProducts.push(product);

    dispatch(updateSelectedEstablishmentProducts(selectedProducts));
  };

  let productTypes = productTypesReducer.productTypes;
  let allProductTypeIds = selectedProducts.map((sp) => sp.productType_Ids);
  allProductTypeIds = allProductTypeIds.map((apti) => apti.map((a) => a.id));

  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  let filteredProducts =
    productTypesReducer.productTypes && productTypesReducer.productTypes.length
      ? productsReducer.establishmentProducts
        ? productsReducer.establishmentProducts
            .filter((p) =>
              filter
                ? p.nameEnglish.toLowerCase().includes(filter.toLowerCase()) || p.nameFrench.toLowerCase().includes(filter.toLowerCase())
                : true
            )
            .map((p) => ({
              ...p,
              productTypes: productTypes
                .filter((pt) => p.productType_Ids.some((pti) => pti.id === pt.id))
                .map((pt) => pt[referenceName])
                .sort((a, b) => (a[referenceName] ? a[referenceName].localeCompare(b[referenceName]) : 0))
                .join(", "),
            }))
            .sort(function (a, b) {
              if (sortColumn) {
                if (isSortDirectionAscending) {
                  return a[sortColumn].localeCompare(b[sortColumn]);
                } else {
                  return b[sortColumn].localeCompare(a[sortColumn]);
                }
              }
              return 0;
            })
        : []
      : [];

  return (
    <React.Fragment>
      <ReactTooltip id="tt-create-product" type="info" effect="solid" place="top" border />
      <ReactTooltip id="tt-create-price-list" type="info" effect="solid" place="top" border />
      <ReactTooltip id="tt-merge-product" type="info" effect="solid" place="top" border />

      <MergeProductsModal selectedProducts={selectedProducts} />
      <CreatePriceListModal selectedProducts={selectedProducts} />
      <DeleteProductModal />
      <ConfirmDeactivationProductModal />

      <RegenerateProductContractModal />
      <Row>
        <Col md="3" lg="4">
          <div className="form-group ">
            <label className="form-control-label" htmlFor="contractSearch">
              {t("search_product")}
            </label>
            <div className="input-group">
              <input className="form-control" type="text" id="contractSearch" onChange={(e) => updateFilter(e.target.value)} />
              <div className="input-group-append">
                <span className="input-group-text fa fa-search line-height-1-3" />
              </div>
            </div>
          </div>
        </Col>

        <Col md="9" lg="8">
          <div className="float-right">
            <div className="d-flex flex-nowrap">
              <Button
                color="primary"
                className="button-field-equal  mr-2"
                onClick={addProducts}
                data-tip={t("create_product_placeholder")}
                data-for="tt-create-product"
                disabled={!accountReducer.isManager}
              >
                <span className="icon icon-circle-with-plus mr-2" />
                {t("create_product")}
              </Button>

              <Button
                className="button-field-equal mr-2"
                color="primary"
                onClick={handleShowCreatePriceListModal}
                disabled={!accountReducer.isManager || selectedProducts.length === 0}
                data-tip={t("create_price_list_placeholder")}
                data-for="tt-create-price-list"
              >
                <i className="fas fa-money-bill-wave mr-2"></i>
                {t("create_price_list")}
              </Button>
              <Button
                color="primary"
                className="button-field-equal"
                disabled={
                  !accountReducer.isManager ||
                  selectedProducts.length <= 1 ||
                  //[[2,4], [1], [2]]
                  !allProductTypeIds.every((productTypeIds) =>
                    productTypeIds.some((ptid) => allProductTypeIds.every((apti) => apti.includes(ptid)))
                  )
                  // productTypeIds.some(pti => allProductTypeIds.some(apti => apti.includes(pti)))
                }
                onClick={handleShowMergeProductModal}
                data-tip={t("merge_product_placeholder")}
                data-for="tt-merge-product"
              >
                <i className="far fa-object-ungroup mr-2"></i>
                {t("merge_products")}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <ReactTooltip clickable id="tt-cancel-sort" type="info" effect="solid" place="top" border />

          <table className="table table-hover">
            <thead>
              <tr>
                <th className="text-center">
                  <i
                    className="fas fa-ban pointer"
                    data-tip={t("cancel_sort")}
                    data-for="tt-cancel-sort"
                    onClick={() => handleSortColumn(null)}
                  ></i>
                </th>

                <th
                  className={`pointer ${sortColumn === "nameFrench" ? "table-active" : ""}`}
                  onClick={() => handleSortColumn("nameFrench")}
                >
                  {t("nameFrench")}
                  {sortColumn === "nameFrench" ? (
                    <div className="float-right">
                      {isSortDirectionAscending ? <i className="fas fa-sort-up"></i> : <i className="fas fa-sort-down"></i>}
                    </div>
                  ) : (
                    <div className="float-right">
                      <i className="fa fa-fw fa-sort"></i>
                    </div>
                  )}
                </th>
                <th
                  className={`pointer ${sortColumn === "nameEnglish" ? "table-active" : ""}`}
                  onClick={() => handleSortColumn("nameEnglish")}
                >
                  {t("nameEnglish")}
                  {sortColumn === "nameEnglish" ? (
                    <div className="float-right">
                      {isSortDirectionAscending ? <i className="fas fa-sort-up"></i> : <i className="fas fa-sort-down"></i>}
                    </div>
                  ) : (
                    <div className="float-right">
                      <i className="fa fa-fw fa-sort"></i>
                    </div>
                  )}
                </th>
                <th
                  className={`pointer ${sortColumn === "productTypes" ? "table-active" : ""}`}
                  onClick={() => handleSortColumn("productTypes")}
                >
                  {t("productTypes")}
                  {sortColumn === "productTypes" ? (
                    <div className="float-right">
                      {isSortDirectionAscending ? <i className="fas fa-sort-up"></i> : <i className="fas fa-sort-down"></i>}
                    </div>
                  ) : (
                    <div className="float-right">
                      <i className="fa fa-fw fa-sort"></i>
                    </div>
                  )}
                </th>
                <th>{t("remarks")}</th>
                <th>
                  {t("actions")}
                  <div className="float-right">
                    {productsReducer.isGettingExtraInformation ? <i className="fa fa-spinner fa-spin fa-fw"></i> : null}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {productsReducer.updating || productsReducer.selectUpdating ? (
                <tr>
                  <td colSpan="7" className="text-center">
                    <Spinner />
                  </td>
                </tr>
              ) : (
                filteredProducts &&
                filteredProducts.map((product) => (
                  <tr
                    key={product.id}
                    onClick={(e) => handleSelectProduct(product)}
                    className={selectedProducts.map((sp) => sp.id).includes(product.id) ? "active" : ""}
                  >
                    <td className="text-center">
                      <ReactTooltip clickable id="tt-deactive" type="info" effect="solid" place="top" border />
                      <ReactTooltip clickable id="tt-active" type="info" effect="solid" place="top" border />

                      {product.isActive && !product.deactivationDateTime ? (
                        <i
                          className="fas fa-2x fa-power-off text-success pointer"
                          data-tip={t("deactivate")}
                          data-for="tt-deactive"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeactivate(product);
                          }}
                        ></i>
                      ) : product.isActive && product.deactivationDateTime ? (
                        <i
                          className="fas fa-2x fa-power-off text-warning pointer"
                          data-tip={t("activate")}
                          data-for="tt-active"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleActivate(product);
                          }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-2x fa-power-off text-danger pointer"
                          data-tip={t("activate")}
                          data-for="tt-active"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleActivate(product);
                          }}
                        ></i>
                      )}
                    </td>
                    <td className="vertical-align-middle">{product.nameFrench}</td>
                    <td className="vertical-align-middle">{product.nameEnglish}</td>
                    <td className="vertical-align-middle">
                      {product.productTypes || <label className="form-text text-muted">{t("noResult")}</label>}
                    </td>
                    <td className="vertical-align-middle">
                      {(product.remarks &&
                        product.remarks.length > 0 &&
                        product.remarks.map((remark, index) => (
                          <div key={remark.id}>
                            <ul>
                              {remarkTypes && remarkTypes?.length > 0
                                ? remark.remarkType_Ids.map((rt) => (
                                    <li key={rt}>
                                      {getObject(
                                        remarkTypes.find((rm) => rm.id === rt),
                                        referenceName
                                      ) +
                                        ": " +
                                        remark.description}
                                    </li>
                                  ))
                                : ""}
                            </ul>
                          </div>
                        ))) || (
                        <Row>
                          <Col md="12">
                            <label className="form-text">{t("noResult")}</label>
                          </Col>
                        </Row>
                      )}
                    </td>
                    <td className="vertical-align-middle">
                      <div className="d-flex flex-nowrap">
                        <ReactTooltip id="tt-edit-product" type="info" effect="solid" place="top" border />
                        <button
                          className="btn btn-primary"
                          onClick={() => redirectToProductEdit(product)}
                          data-tip={t("edit_this_product")}
                          data-for="tt-edit-product"
                        >
                          <i className="icon icon-edit" />
                        </button>

                        {product.contracts && product.contracts.length && product.contracts.some((pc) => pc.contractPriceDetails.length) ? (
                          <>
                            <ReactTooltip id="tt-see-pricing" type="info" effect="solid" place="top" border />
                            <button
                              className="btn btn-primary ml-2"
                              onClick={() => redirectToPricing(product)}
                              data-tip={t("see_this_product_pricing")}
                              data-for="tt-see-pricing"
                            >
                              <i className="fas fa-dollar-sign"></i>
                            </button>

                            <ReactTooltip id="tt-regenerate-product-contract" type="info" effect="solid" place="top" border />
                            <button
                              className="btn btn-danger ml-2"
                              onClick={(e) => handleRegenerateProductContract(e, product)}
                              data-tip={t("regenerate_contract")}
                              data-for="tt-regenerate-product-contract"
                              disabled={!accountReducer.isManager}
                            >
                              <i className="fas fa-redo-alt"></i>
                            </button>
                          </>
                        ) : null}
                        {productsReducer.productExtraInformations.find((pei) => pei.id === product.id) &&
                        productsReducer.productExtraInformations.find((pei) => pei.id === product.id).isDeletable ? (
                          <>
                            <ReactTooltip id="tt-delete-product" type="info" effect="solid" place="top" border />
                            <button
                              className="btn btn-danger ml-2"
                              onClick={(e) => handleShowDeleteProductModal(e, product)}
                              data-tip={t("delete_product")}
                              data-for="tt-delete-product"
                              disabled={!accountReducer.isManager}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Col>
      </Row>
      <Row>
        <Col md=""></Col>
      </Row>
    </React.Fragment>
  );
}

export default ProductList;
