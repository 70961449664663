import DragItemTypes from "utils/dragtypes";
import uuidv4 from "uuid/v4";
import moment from "moment";
import { bytesToBase64 } from "utils/base64";

export const contractsReducer = (
  state = {
    contracts: [],
    contractsForSelect: [],
    duplicating: false,
    updating: false,
    updated: false,
    error: null,

    contractEditModel: {},
    errorMessages: [],

    showConfirmDeleteWithPriceDetailModal: false,
    confirmDeleteWithPriceDetailName: "",

    showDuplicateModal: false,
    duplicateContractModel: {},

    showSummaryModal: false,
    summaryContractModel: null,

    establishmentContracts: [],
    establishmentId: null,
    establishmentContract: {},

    productContracts: [],
    productId: null,
    productContract: {},

    pricingProductContracts: [],
    pricingProductIds: [],
    gettingPricingProductContracts: false,
    showPricingProductContractTableViewModal: false,
    pricingProductContractFilters: {},

    showDeleteContractModal: false,
    deleteContract: null,
    deletingEstablishmentContract: false,

    comparatorsForSelect: [],

    count: 0,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "End", asc: false }],
      filters: [],
    },
    activeStepIndex: 0,
    steps: [
      { index: 0, name: "general-information", key: "general_information", isActive: true, isEstablishmentStep: true },
      { index: 1, name: "contract-periods", key: "periods", isActive: false, isEstablishmentStep: true },
      { index: 2, name: "groups", key: "groups", isActive: false, isEstablishmentStep: true },
      { index: 3, name: "age-categories", key: "age_categories", isActive: false, isEstablishmentStep: true },
      { index: 4, name: "freebies", key: "freebies", isActive: false, isEstablishmentStep: true },
      { index: 5, name: "specific-project-departures", key: "specific_project_departures", isActive: false, isEstablishmentStep: true },
      { index: 6, name: "price-by-days", key: "price_by_days", isActive: false, isEstablishmentStep: true },
      { index: 7, name: "summary", key: "summary", isActive: true, isEstablishmentStep: true },
      // { index: 8, name: "length-categories", key: "length_categories", isActive: false, isEstablishmentStep: true },
    ],
    cart: {
      product_Id: null,
      option_Ids: [],
    },
    filter: {
      productType_Id: null,
    },
    freebie: {
      showModal: false,
      freebie: null,
    },
    costs: {
      activeTab: "1",
    },
  },
  action
) => {
  switch (action.type) {
    case "ADD_CONTRACT_PERIOD_DATE": {
      let contractPeriod = action.payload.contractPeriod;

      const existingDates = contractPeriod.contractPeriodDates.map((cpd) => moment(cpd.end));
      const maxDate = moment.max(existingDates);
      const newDate = moment(maxDate).add(1, "day");

      contractPeriod.contractPeriodDates.push({ uniqueIdentifier: uuidv4(), start: newDate, end: moment(newDate).add(4, "month") });

      state = { ...state };

      break;
    }

    case "REMOVE_CONTRACT_PERIOD_DATE": {
      let contractPeriod = action.payload.contractPeriod;
      let contractPeriodDate = action.payload.contractPeriodDate;

      contractPeriod.contractPeriodDates.splice(contractPeriod.contractPeriodDates.indexOf(contractPeriodDate), 1);

      state = { ...state };

      break;
    }

    case "PERSISTED_PRICE_LIST": {
      let productContracts = action.payload;
      state = { ...state, productContracts };
      break;
    }
    case "ADD_CONTRACT_FILE": {
      let contract = action.payload.contract;
      let fileInformation = action.payload.file;
      let content = action.payload.content;

      contract.contractFiles = contract.contractFiles || [];
      contract.contractFiles.push({
        contract_Id: contract.id,
        fileName: fileInformation.name,
        contentType: fileInformation.type,
        size: content.byteLength,
        data: [
          {
            content: bytesToBase64(content),
          },
        ],
      });

      state = { ...state };
      break;
    }

    case "REMOVE_CONTRACT_FILE": {
      let contract = action.payload.contract;
      let file = action.payload.file;

      contract.contractFiles = contract.contractFiles.filter((cf) => cf.fileName !== file.fileName);

      state = { ...state };

      break;
    }
    case "UPDATE_STEPS": {
      let contract = action.payload.contract;
      let steps = state.steps;

      let generalInfosStep = steps.find((s) => s.name === "general-information");
      let ageCategoriesStep = steps.find((s) => s.name === "age-categories");
      //let lengthCategoriesStep = steps.find(s => s.name === "length-categories");
      let groupsStep = steps.find((s) => s.name === "groups");
      let freebisStep = steps.find((s) => s.name === "freebies");
      let contractPeriodsStep = steps.find((s) => s.name === "contract-periods");
      let specificProjectDeparturesStep = steps.find((s) => s.name === "specific-project-departures");
      let priceByDaysStep = steps.find((s) => s.name === "price-by-days");

      generalInfosStep.isActive = true;
      ageCategoriesStep.isActive = contract.hasAgeCategories;
      //lengthCategoriesStep.isActive = contract.hasLengths;
      groupsStep.isActive = contract.hasGroups;
      freebisStep.isActive = contract.hasFreebies;
      contractPeriodsStep.isActive = contract.hasContractPeriods;
      specificProjectDeparturesStep.isActive = contract.hasSpecificProjectDepartures;
      priceByDaysStep.isActive = contract.hasPriceByDays;

      state = { ...state };
      break;
    }
    case "UPDATE_CART": {
      state.cart = action.payload.cart;
      state = { ...state };
      break;
    }
    case "SET_ACTIVE_TAB": {
      state.costs.activeTab = action.payload.activeTab;

      state = { ...state };
      break;
    }
    case "SHOW_FREEBIE_EDIT_MODAL": {
      state.freebie.showModal = true;
      state.freebie.freebie = action.payload.freebie;

      state = { ...state };
      break;
    }
    case "HIDE_FREEBIE_EDIT_MODAL": {
      state.freebie.showModal = false;

      state = { ...state };
      break;
    }
    case "ADD_VALIDITY_DATE": {
      let contract = action.payload.contract;

      state.contracts.forEach((c) => {
        if (c.id === contract.id) {
          c.validityDates.push({
            uniqueIdentifier: uuidv4(),
            contract_Id: contract.id,
          });
        }
      });
      state = { ...state };
      break;
    }

    case "REMOVE_VALIDITY_DATE": {
      let contract = action.payload.contract;
      let validityDate = action.payload.validityDate;

      state.contracts.forEach((c) => {
        if (c.id === contract.id) {
          if (validityDate.uniqueIdentifier) {
            c.validityDates = c.validityDates.filter((vd) => vd.uniqueIdentifier !== validityDate.uniqueIdentifier);
          } else if (validityDate.id) {
            c.validityDates = c.validityDates.filter((vd) => vd.id !== validityDate.id);
          }
        }
      });
      state = { ...state };
      break;
    }

    case "SET_PRODUCT_TYPE_ID": {
      state.filter.productType_Id = action.payload.productType_Id;
      state = { ...state };
      break;
    }
    case "REMOVE_CONTRACT_PRODUCT_FROM_CONTRACT": {
      let contract = action.payload.contract;
      let contractProduct = action.payload.contractProduct;

      contract.contractProducts = contract.contractProducts.filter((cp) => cp.uniqueIdentifier !== contractProduct.uniqueIdentifier);

      state.cart = { product_Id: null, option_Ids: [] };

      state.contracts.forEach((c) => {
        if (c.id === contract.id) {
          c = Object.assign(contract, {});
        }
      });

      state = { ...state };
      break;
    }

    case "ADD_CONTRACT_PRODUCT_TO_CONTRACT": {
      let contract = action.payload.contract;
      let contractProduct = action.payload.contractProduct;

      contract.contractProducts.push({
        uniqueIdentifier: uuidv4(),
        product_Id: contractProduct.product_Id,
        productPrice: 0,
        optionDetails: contractProduct.option_Ids.map((o) => ({ option_Id: o, optionPrice: 0 })),
      });

      state.cart = { product_Id: null, option_Ids: [] };

      state.contracts.forEach((c) => {
        if (c.id === contract.id) {
          c = Object.assign(contract, {});
        }
      });

      state = { ...state };
      break;
    }
    case "EMPTY_CART": {
      state.cart = {
        product_Id: null,
        option_Ids: [],
      };
      state = { ...state };

      break;
    }
    case "ADD_TO_CART": {
      let cart = state.cart;
      if (action.payload.type === DragItemTypes.OPTION) {
        cart.option_Ids.push(action.payload.item.id);
      } else if (action.payload.type === DragItemTypes.PRODUCT) {
        cart.product_Id = action.payload.item.id;
        cart.option_Ids = [];
      }

      state = { ...state };

      break;
    }
    case "SET_ACTIVE_STEP": {
      state = {
        ...state,
        activeStepIndex: action.payload,
      };

      break;
    }
    case "GET_CONTRACTS_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_CONTRACTS_FOR_SELECT_PENDING": {
      state = { ...state, selectUpdating: true };
      break;
    }

    case "GET_CONTRACTS_FOR_ESTABLISHMENT_PENDING": {
      state = { ...state, selectUpdating: true };
      break;
    }

    case "GET_CONTRACTS_ESTABLISHMENT_FULFILLED": {
      state = {
        ...state,
        updating: false,
        selectUpdating: false,
        establishmentContracts: action.payload,
        establishmentId: action.establishmentId,
      };
      break;
    }

    case "UPDATE_CONTRACTS_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }

    case "GET_CONTRACTS_SELECT_FULFILLED": {
      state = {
        ...state,
        selectUpdating: false,
        updated: true,
        contractsForSelect: action.payload,
      };
      break;
    }

    case "GET_CONTRACTS_FULFILLED": {
      state = {
        ...state,
        updating: false,
        updated: true,
        contracts: action.payload.items,
        count: action.payload.count,
      };
      break;
    }
    case "GET_CONTRACTS_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_ESTABLISHMENT_CONTRACT_PENDING": {
      state = { ...state, updating: true, establishmentContract: {} };
      break;
    }
    case "GET_ESTABLISHMENT_CONTRACT_FULFILLED": {
      var steps = state.steps;

      // We call the update step action after the
      for (let index = 0; index < steps.length; index++) {
        const step = steps[index];
        //  We keep the first step (General info) active
        if (step.index !== 0 && step.isActive) {
          step.isActive = false;
        }
      }

      state = {
        ...state,
        updating: false,
        updated: true,
        establishmentContract: action.payload,
      };
      break;
    }

    case "GET_ESTABLISHMENT_CONTRACT_REJECTED": {
      state = { ...state, selectUpdating: false, error: action.payload };
      break;
    }

    case "GET_PRODUCT_CONTRACT_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_PRODUCT_CONTRACT_FULFILLED": {
      state = {
        ...state,
        updating: false,
        updated: true,
        productContract: action.payload,
      };
      break;
    }

    case "GET_PRODUCT_CONTRACT_REJECTED": {
      state = { ...state, selectUpdating: false, error: action.payload };
      break;
    }

    case "PERSISTING_CONTRACT": {
      state = { ...state, updating: true, persisting: true };

      break;
    }
    case "PERSISTED_CONTRACT": {
      let contracts = state.contracts;

      if (action.payload.isNew) {
        contracts.forEach((d) => {
          if (d.id === 0) {
            d.id = action.payload.contract.id;
          }
        });
      }

      state = { ...state, contracts, contract: null, updating: false, persisting: false };

      break;
    }

    case "PERSISTING_ESTABLISHMENT_CONTRACT": {
      state = { ...state, updating: true, persisting: true };

      break;
    }
    case "PERSISTED_ESTABLISHMENT_CONTRACT": {
      let establishmentContracts = state.establishmentContracts;

      if (action.payload.isNew) {
        establishmentContracts.push({ ...state.establishmentContract, id: action.payload.contract.id });
      }

      state = { ...state, establishmentContracts, establishmentContract: {}, contract: null, updating: false, persisting: false };

      break;
    }

    case "UPDATE_CONTRACT": {
      let contracts = state.contracts;
      contracts.forEach((d) => {
        if (d.id === action.payload.id) {
          d = Object.assign(action.payload, {});
        }
      });
      state = { ...state, contracts, updating: false };

      break;
    }
    case "ADD_CONTRACT_AGE": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;

      contract.ageCategories = contract.ageCategories || [];
      let ageCategory = { id: 0, uniqueIdentifier: uuidv4() };
      contract.ageCategories.push(ageCategory);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "ADD_CONTRACT_DEPARTURE_GROUP": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;

      contract.contractDepartureGroups = contract.contractDepartureGroups || [];
      let contractDepartureGroup = { id: 0, uniqueIdentifier: uuidv4() };
      contract.contractDepartureGroups.push(contractDepartureGroup);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          Object.assign(d, contract);
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "ADD_CONTRACT_DAY_GROUP": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;

      contract.contractDayGroups = contract.contractDayGroups || [];
      let contractDayGroup = { id: 0, uniqueIdentifier: uuidv4() };
      contract.contractDayGroups.push(contractDayGroup);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          Object.assign(d, contract);
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "ADD_CONTRACT_FREEBIE": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;

      contract.freebies = contract.freebies || [];
      let freebie = { id: 0, uniqueIdentifier: uuidv4() };
      contract.freebies.push(freebie);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "ADD_CONTRACT_PERIOD": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;

      contract.contractPeriods = contract.contractPeriods || [];
      const isFirstContractPeriod = contract.contractPeriods.length === 0;

      let newDate;
      if (isFirstContractPeriod) {
        newDate = contract.start;
      } else {
        const existingDates = [].concat.apply(
          [],
          contract.contractPeriods.map((cp) => cp.contractPeriodDates.map((cpd) => moment(cpd.end)))
        );
        const maxDate = moment.max(existingDates);
        newDate = moment(maxDate).add(1, "day");
      }

      let contractPeriod = {
        id: 0,
        uniqueIdentifier: uuidv4(),
        start: moment(),
        end: null,
        contractPeriodDates: [{ uniqueIdentifier: uuidv4(), start: newDate, end: moment(newDate).add(4, "month") }],
      };
      contract.contractPeriods.push(contractPeriod);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "REMOVE_CONTRACT_DEPARTURE_GROUP": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;

      contract.contractDepartureGroups = contract.contractDepartureGroups || [];
      contract.contractDepartureGroups = contract.contractDepartureGroups.filter((g) => g !== action.payload.contractDepartureGroup);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "REMOVE_CONTRACT_DAY_GROUP": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;

      contract.contractDayGroups = contract.contractDayGroups || [];
      contract.contractDayGroups = contract.contractDayGroups.filter((g) => g !== action.payload.contractDayGroup);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "UPDATE_CONTRACT_DEPARTURE_GROUP": {
      let contractDepartureGroup = action.payload.contractDepartureGroup;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.contractDepartureGroups.forEach((rd) => {
        if (rd.uniqueIdentifier === contractDepartureGroup.uniqueIdentifier) {
          Object.assign(rd, contractDepartureGroup);
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          Object.assign(d, contract);
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "UPDATE_CONTRACT_DAY_GROUP": {
      let contractDayGroup = action.payload.contractDayGroup;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.contractDayGroups.forEach((rd) => {
        if (rd.uniqueIdentifier === contractDayGroup.uniqueIdentifier) {
          Object.assign(rd, contractDayGroup);
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          Object.assign(d, contract);
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "REMOVE_CONTRACT_AGE": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;

      contract.ageCategories = contract.ageCategories || [];
      contract.ageCategories = contract.ageCategories.filter((g) => g !== action.payload.ageCategory);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "REMOVE_CONTRACT_FREEBIE": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;

      contract.freebies = contract.freebies || [];
      contract.freebies = contract.freebies.filter((g) => g !== action.payload.freebie);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "REMOVE_CONTRACT_PERIOD": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;

      contract.contractPeriods = contract.contractPeriods || [];
      contract.contractPeriods = contract.contractPeriods.filter((g) => g !== action.payload.contractPeriod);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "ADD_CONTRACT_LENGTH": {
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;

      contract.lengthCategories = contract.lengthCategories || [];
      let lengthCategory = { id: 0, uniqueIdentifier: uuidv4() };
      contract.lengthCategories.push(lengthCategory);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "REMOVE_CONTRACT_LENGTH": {
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;

      contract.lengthCategories = contract.lengthCategories || [];
      contract.lengthCategories = contract.lengthCategories.filter((g) => g !== action.payload.lengthCategory);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "ADD_CONTRACT_GROUP": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;

      contract.groups = contract.groups || [];
      let group = {
        id: 0,
        uniqueIdentifier: uuidv4(),
        groupTarget_Id: contract.groups[0]?.groupTarget_Id || action.payload.defaultGroupTargetId,
      };
      contract.groups.push(group);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "REMOVE_CONTRACT_GROUP": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;

      contract.groups = contract.groups || [];
      contract.groups = contract.groups.filter((g) => g !== action.payload.group);

      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "ADD_CONTRACT_AGE_CATEGORY_CRITERIA": {
      let ageCategory = action.payload.ageCategory;
      ageCategory.criterias = ageCategory.criterias || [];
      ageCategory.criterias.push({ uniqueIdentifier: uuidv4() });

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.ageCategories.forEach((a, index) => {
        if (index === action.payload.index) {
          a = Object.assign(ageCategory, {});
        }
      });
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }
      break;
    }

    case "ADD_CONTRACT_FREEBIE_CRITERIA": {
      let freebie = action.payload.freebie;

      freebie.criterias = freebie.criterias || [];
      freebie.criterias.push({ uniqueIdentifier: uuidv4() });

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.freebies.forEach((f, index) => {
        if (index === action.index) {
          f = Object.assign(freebie, {});
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "ADD_CONTRACT_FREEBIE_PERIOD_DATE": {
      let freebie = action.payload.freebie;

      freebie.periodDates = freebie.periodDates || [];
      freebie.periodDates.push({ uniqueIdentifier: uuidv4() });

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.freebies.forEach((f, index) => {
        if (index === action.index) {
          f = Object.assign(freebie, {});
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "ADD_CONTRACT_FREEBIE_DETAIL": {
      let freebie = action.payload.freebie;

      freebie.details = freebie.details || [];
      freebie.details.push({ uniqueIdentifier: uuidv4() });

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.freebies.forEach((f, index) => {
        if (index === action.index) {
          f = Object.assign(freebie, {});
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "REMOVE_CONTRACT_AGE_CATEGORY_CRITERIA": {
      let ageCategory = action.payload.ageCategory;
      ageCategory.criterias = ageCategory.criterias || [];
      ageCategory.criterias.splice(action.payload.criteriaIndex, 1);

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.ageCategories.forEach((a, index) => {
        if (index === action.payload.ageIndex) {
          a = Object.assign(ageCategory, {});
        }
      });
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: { ...contract } };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }
      break;
    }

    case "REMOVE_CONTRACT_FREEBIE_CRITERIA": {
      let freebie = action.payload.freebie;
      freebie.criterias = freebie.criterias || [];
      freebie.criterias.splice(action.payload.criteriaIndex, 1);

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.freebies.forEach((f, index) => {
        if (index === action.payload.index) {
          f = Object.assign(freebie, {});
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }
    case "REMOVE_CONTRACT_FREEBIE_PERIOD_DATE": {
      let freebie = action.payload.freebie;
      freebie.periodDates = freebie.periodDates || [];
      freebie.periodDates.splice(action.payload.periodDateIndex, 1);

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.freebies.forEach((f, index) => {
        if (index === action.payload.index) {
          f = Object.assign(freebie, {});
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "REMOVE_CONTRACT_FREEBIE_DETAIL": {
      let freebie = action.payload.freebie;
      freebie.details = freebie.details || [];
      freebie.details.splice(action.payload.detailIndex, 1);

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.freebies.forEach((f, index) => {
        if (index === action.payload.freebieIndex) {
          f = Object.assign(freebie, {});
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "REMOVE_CONTRACT_GROUP_CRITERIA": {
      let group = action.payload.group;
      group.criterias = group.criterias || [];
      group.criterias.splice(action.payload.criteriaIndex, 1);

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.groups.forEach((a, index) => {
        if (index === action.payload.groupIndex) {
          a = Object.assign(group, {});
        }
      });
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "ADD_CONTRACT_GROUP_CRITERIA": {
      let group = action.payload.group;
      group.criterias = group.criterias || [];
      group.criterias.push({ uniqueIdentifier: uuidv4() });

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.groups.forEach((a, index) => {
        if (index === action.payload.index) {
          a = Object.assign(group, {});
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }
      break;
    }

    case "GET_AGE_CATEGORY_CRITERIA_OPERATORS_SELECT_FULFILLED": {
      state = { ...state, comparatorsForSelect: action.payload };

      break;
    }

    case "UPDATE_CONTRACT_GROUP": {
      let group = action.payload.group;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.groups.forEach((g, index) => {
        if (index === action.payload.index) {
          g = Object.assign(group, {});
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }
      break;
    }

    case "UPDATE_CONTRACT_PERIOD": {
      let contractPeriod = action.payload.contractPeriod;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.contractPeriods.forEach((rd, index) => {
        if (index === action.payload.index) {
          rd = Object.assign(contractPeriod, {});
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "ASSIGN_CONTRACT_EDIT_MODAL": {
      state = { ...state, contractEditModel: action.payload };
      break;
    }

    case "UPDATE_CONTRACT_AGE_CATEGORY": {
      let ageCategory = action.payload.ageCategory;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.ageCategories.forEach((a, index) => {
        if (index === action.payload.index) {
          a = Object.assign(ageCategory, {});
        }
      });
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }
      break;
    }

    case "UPDATE_CONTRACT_FREEBIE": {
      let freebie = action.payload.freebie;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.freebies.forEach((f, index) => {
        if (index === action.payload.index) {
          f = Object.assign(freebie, {});
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "UPDATE_CONTRACT_LENGTH_CATEGORY": {
      let lengthCategory = action.payload.lengthCategory;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.lengthCategories.forEach((a, index) => {
        if (index === action.payload.index) {
          a = Object.assign(lengthCategory, {});
        }
      });
      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }
      break;
    }

    case "UPDATE_CONTRACT_AGE_CATEGORY_CRITERIA": {
      let criteria = action.payload.criteria;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.ageCategories.forEach((a, ageIndex) => {
        if (ageIndex === action.ageIndex) {
          a.criterias.forEach((c, criteriaIndex) => {
            if (criteriaIndex === action.criteriaIndex) c = Object.assign(criteria, {});
          });
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }
      break;
    }

    case "UPDATE_CONTRACT_FREEBIE_CRITERIA": {
      let criteria = action.payload.criteria;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.freebies.forEach((f, freebieIndex) => {
        if (freebieIndex === action.payload.freebieIndex) {
          f.criterias.forEach((c, criteriaIndex) => {
            if (criteriaIndex === action.payload.criteriaIndex) c = Object.assign(criteria, {});
          });
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "UPDATE_CONTRACT_FREEBIE_PERIOD_DATE": {
      let periodDate = action.payload.periodDate;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.freebies.forEach((f, freebieIndex) => {
        if (freebieIndex === action.payload.freebieIndex) {
          f.periodDates.forEach((c, periodDateIndex) => {
            if (periodDateIndex === action.payload.periodDateIndex) c = Object.assign(periodDate, {});
          });
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "UPDATE_CONTRACT_FREEBIE_DETAIL": {
      let detail = action.payload.detail;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.freebies.forEach((f, freebieIndex) => {
        if (freebieIndex === action.payload.freebieIndex) {
          f.details.forEach((c, detailIndex) => {
            if (detailIndex === action.payload.detailIndex) c = Object.assign(detail, {});
          });
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "REMOVE_CONTRACT_DATE": {
      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;

      contract.dates = contract.dates || [];
      contract.dates.splice(action.payload.dateIndex, 1);

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }
      break;
    }

    case "UPDATE_CONTRACT_DATE": {
      let date = action.payload;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.dates.forEach((d, dateIndex) => {
        if (dateIndex === action.payload.dateIndex) {
          d = Object.assign(date, {});
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }
      break;
    }

    case "ADD_CONTRACT_DATE": {
      let date = action.payload.date;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.dates = contract.dates || [];
      contract.dates.push(date);

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "UPDATE_CONTRACT_GROUP_CRITERIA": {
      let criteria = action.payload.criteria;

      let contract = action.payload.isEstablishmentContract ? state.establishmentContract : state.productContract;
      contract.groups.forEach((g, groupIndex) => {
        if (groupIndex === action.payload.groupIndex) {
          g.criterias.forEach((c, criteriaIndex) => {
            if (criteriaIndex === action.payload.criteriaIndex) c = Object.assign(criteria, {});
          });
        }
      });

      let contracts = action.payload.isEstablishmentContract ? state.establishmentContracts : state.productContracts;
      contracts.forEach((d) => {
        if (d.id === contract.id) {
          d = Object.assign(contract, {});
        }
      });

      if (action.payload.isEstablishmentContract) {
        state = { ...state, establishmentContracts: contracts, establishmentContract: contract };
      } else {
        state = { ...state, productContracts: contracts, productContract: contract };
      }

      break;
    }

    case "DELETING_ESTABLISHMENT_CONTRACT": {
      state = { ...state, deletingEstablishmentContract: true };
      break;
    }

    case "DELETED_ESTABLISHMENT_CONTRACT": {
      let contract = action.payload;
      state = {
        ...state,
        establishmentContracts: state.establishmentContracts.filter((ep) => ep.id !== contract.id),
        deletingEstablishmentContract: false,
      };
      break;
    }

    case "HIDE_CONFIRM_DELETE_WITH_PRICE_DETAILS_MODAL": {
      state = {
        ...state,
        showConfirmDeleteWithPriceDetailModal: false,
        confirmDeleteWithPriceDetailName: null,
        confirmDeleteWithPriceDetailSaveAction: null,
      };
      break;
    }
    case "SHOW_CONFIRM_DELETE_WITH_PRICE_DETAILS_MODAL": {
      state = {
        ...state,
        showConfirmDeleteWithPriceDetailModal: true,
        confirmDeleteWithPriceDetailName: action.payload.name,
        confirmDeleteWithPriceDetailSaveAction: action.payload.saveAction,
      };
      break;
    }

    case "UPDATE_PRODUCT_PRICE_DETAIL_PRODUCT_IDS": {
      state = { ...state, pricingProductIds: action.payload.productIds };
      break;
    }

    case "RESET_PRODUCT_PRICE_DETAIL_PRODUCT_IDS": {
      state = { ...state, pricingProductIds: [] };
      break;
    }

    case "RESET_PRODUCT_PRICE_DETAIL_PRODUCT_IDS_PRICING_PRODUCTS_CONTRATS": {
      state = { ...state, pricingProductIds: [], pricingProductContracts: [] };
      break;
    }

    case "GET_PRICING_PRODUCTS_CONTRACTS_PENDING": {
      state = {
        ...state,
        pricingProductContracts: [],
        gettingPricingProductContracts: true,
      };

      break;
    }

    case "GET_PRICING_PRODUCTS_CONTRACTS_FULFILLED": {
      state = {
        ...state,
        pricingProductContracts: action.payload,
        gettingPricingProductContracts: false,
      };

      break;
    }

    case "PERSIST_PRODUCT_CONTRACT_PRICE_DETAIL_FULFILLED": {
      const contractToUpdatePriceDetail = state.pricingProductContracts.find((c) => c.id === action.payload.contract.id);
      let contractPriceDetail = contractToUpdatePriceDetail.contractPriceDetails.find(
        (cpd) => cpd.id === action.payload.contractPriceDetail.id
      );

      for (const property in contractPriceDetail) {
        if (contractPriceDetail.hasOwnProperty(property)) {
          contractPriceDetail[property] = action.payload.contractPriceDetail[property];
        }
      }

      state = { ...state };

      break;
    }

    case "SHOW_PRODUCT_CONTRACT_TABLE_VIEW_MODAL": {
      state = { ...state, showPricingProductContractTableViewModal: true };
      break;
    }

    case "HIDE_PRODUCT_CONTRACT_TABLE_VIEW_MODAL": {
      state = { ...state, showPricingProductContractTableViewModal: false };
      break;
    }

    case "UPDATE_PRODUCT_CONTRACT_FILTERS": {
      let pricingProductContractFilters = action.payload.productContractFilters;
      state = { ...state, pricingProductContractFilters };

      break;
    }

    case "CLEAR_PRODUCT_CONTRACT_FILTERS": {
      state = { ...state, pricingProductContractFilters: {} };
      break;
    }

    case "DUPLICATING_ESTABLISHMENT_CONTRACT": {
      state = { ...state, duplicating: true };
      break;
    }

    case "DUPLICATED_ESTABLISHMENT_CONTRACT": {
      state = { ...state, duplicating: false };
      break;
    }

    case "OPEN_DUPLICATE_CONTRACT_MODAL": {
      state = { ...state, showDuplicateModal: true, duplicateContractModel: action.payload.contract };
      break;
    }

    case "HIDE_DUPLICATE_CONTRACT_MODAL": {
      state = { ...state, showDuplicateModal: false, duplicateContractModel: null };
      break;
    }

    case "OPEN_SUMMARY_CONTRACT_MODAL": {
      state = { ...state, showSummaryModal: true, summaryContractModel: action.payload.contract };
      break;
    }
    case "HIDE_SUMMARY_CONTRACT_MODAL": {
      state = { ...state, showSummaryModal: false, summaryContractModel: null };
      break;
    }

    case "HIDE_DELETE_CONTRACT_MODAL": {
      state = { ...state, showDeleteContractModal: false, deleteContract: null };
      break;
    }

    case "SHOW_DELETE_CONTRACT_MODAL": {
      state = { ...state, deleteContract: action.payload, showDeleteContractModal: true };
      break;
    }

    case "UPDATE_DUPLICATE_CONTRACT_MODAL": {
      let currentDuplicateModel = state.duplicateContractModel;

      currentDuplicateModel[action.payload.fieldname] = action.payload.value;
      state = { ...state, duplicateContractModel: { ...currentDuplicateModel } };
      break;
    }

    case "GET_ESTABLISHMENT_CONTRACTS_PENDING": {
      state = { ...state, updating: true };
      break;
    }

    case "GET_ESTABLISHMENT_CONTRACTS_FULFILLED": {
      state = { ...state, updating: false, establishmentContracts: action.payload.items, count: action.payload.count };
      break;
    }

    default: {
    }
  }

  return state;
};

export default contractsReducer;
