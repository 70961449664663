export default {
  locale: "en",
  date_format: "MM/DD/YYYY",
  date_format_with_time: "MM/DD/YYYY HH:mm",
  date_small_text_no_year: "D MMM",
  other_language: "Français",
  other_language_two_letter: "fr",
  name: "Name",
  cancel: "Cancel",
  save: "Save",
  close: "Close",
  delete: "Delete",
  details: "Details",
  add: "Add",
  success: "Success",
  saved_successful: "Saved successfully",
  overview: "Overview",
  list: "List",
  filter: "Filter",
  reset: "Reset",
  filter_by: "Filter by...",
  assessments: "Assessments",
  evaluated_by: "Evaluated by:",
  see_result: "See results",
  internal_name: "Internal Name",
  remove: "Remove",
  back: "Back",

  contains: "Contains",
  ends_with: "End with",
  equal: "Equal",
  not_contains: "Not contains",
  not_ends_with: "Not ends with",
  not_equal: "Not equal",
  not_starts_with: "Not starts with",
  starts_with: "Starts witdh",
  is_empty: "Is empty",
  except: "Excepté",

  login: "Login",
  field_required: "The field {{field}} is required.",
  error_date_format: "The date must follow this format {{format}}",
  error_min_size: "{{field}} must have at least {{length}} item selected",
  error_unique: "The field {{field}} must be unique.",
  error_url: "The field {{field}} must be a valid URL. Ex. https://test.url or test.url",
  error_postal_code: "The postal code must follow this format(H1H 1H1)",
  error_exclusive_with: "The field {{field}} is exclusive with {{exclusiveField}}! Only one or the other can be selected",
  error_date_is_after: "The field {{field}} value must be after the field {{exclusiveField}} value.",
  error_must_match: "The field {{field}} must match with {{matchField}}",
  error_required_quill: "The field {{field}} is required",
  error_boolean_at_least_one: "The field {{field}} must have at least one value selected",
  password_dont_match: "The passwords don't match.",
  username_placeholder: "Username",
  password_placeholder: "Password",
  remember_me: "Remember Me",
  or_sign_up_using: "or signup using",
  or_log_in_using: "or login using",
  registering_code: "Registering Code",
  registering_code_placeholder: "Enter your registering code",
  firstname_placeholder: "Enter your firstname",
  lastname_placeholder: "Enter your lastname",
  newPassword_placeholder: "Enter a password",
  confirmNewPassword_placeholder: "Enter a password again",
  register: "Register",
  register_info: "In order to access this site and complete your questionnaire, please enter the required information below.",
  well_done: "Well done!",
  register_complete: "You have registered successfully!",
  register_complete_footer: "You will need to confirm your email before accessing this site. ",
  email_placeholder: "Enter your email address",
  code_unavailable: "The code you have entered is not available anymore.",
  forgot_password_questionmark: "Forgot password?",
  password_recovery: "Password Recovery",
  password_recovery_info: "Enter your email then click on 'Send' to receive the password recovery information by email.",
  send_recovery_link: "Send",
  user_non_existant: "The email you entered does not exist in the system.",
  password_recovery_link_sent_successful: "The password recovery email was sent successfully.",
  password_recovery_complete: "You have successfully requested a password recovery!",
  password_recovery_complete_footer:
    "You will be receiving an email that will specify what are the next steps to follow to recover your password.",
  password_reset_complete: "You have successfully reseted your password!",
  password_reset_complete_footer: "You can now login using the newly created password.",
  name_french: "French Name",
  name_english: "English Name",
  created_date: "Creation Date",
  edit_and_create: "Create and Edit",
  code: "Code",

  form_title: "Form",
  last_modified_date: "Last Modification",
  number: "Identification Number",
  unique_name: "Unique Name",
  nameEnglish: "English Name",
  nameFrench: "French Name",
  general_information: "General Information",
  create: "Create",
  contracts: "Contracts",
  products: "Products",
  product: "Product",
  options: "Options",
  add_options: "Add an option",
  add_products: "Add a product",
  add_contracts: "Add a contract",
  edit_contract: "Edit the contract",
  delete_contract: "Delete the contract",
  parameters: "Parameters",
  pricing: "Pricing",
  summary: "Summary",
  next: "Next",
  previous: "Previous",
  product_types: "Product Types",
  filters: "Filters",
  product_types_placeholder: "Select a type of product",
  available_products: "Available Products",
  available_options: "Available Options",
  product_cart: "Basket",
  current_items: "Previous choices",

  application: "Applies to",
  validity: "Valid range",
  from: "From",
  to: "To",
  providers: "Providers",
  provider: "Provider",
  provider_placeholder: "Choose a provider",
  establishments: "Establishments",
  establishment_placeholder: "Choose an establishment",
  subtotal: "Sub total",
  general_details: "General Details",
  taxes: "Taxes",
  tax_placeholder: "Choose applicable taxes",
  fees: "Special fees",
  freebieType_Id: "Freebie type",
  freebieTypeOperator_Id: "Operator",
  detailValue: "Detail Value",
  feeValue: "Fee value",
  feeType_Id: "Fee type",
  productType_Ids: "Available Product Type",
  productType_Ids_placeholder: "Choose the available products",
  no_dates_selected: "No dates selected",
  date_Ids: "Dates available",
  freebies: "Freebies",
  applies_to_all_dates: "Applies all the time",
  edit_freebies: "Edit freebies",
  criterias: "Criterias",
  effects: "Effects",
  dates: "Dates",
  date_Ids_placeholder: "Choose applicable dates",
  product_type: "Product Type",
  actions: "Actions",
  products_configuration: "Product Configuration",
  product_name: "Product Name",

  edit_provider: "Edit provider products",
  edit_product: "Edit product",
  remarks: "Remarks",
  remarkType_Id: "Remark Type",
  remarkType_Id_placeholder: "Choose the remark type",
  description: "Description",
  productTypes: "Product Types",
  type: "Type",
  noResult: "No results",

  edit_establishment: "Edit establishment",
  delete_establishment: "Delete Establishment",
  establishment: "Establishment",
  address: "Address",
  postalCode: "Postal Code",
  phones: "Phones",
  id: "ID",
  go_to_establishment: "Go to establishment",
  edit_establishment_products: "Edit establishment products",
  create_product: "Create product",
  edit_or_create_product: "Edit or create product",
  edit_this_product: "Edit this product",
  edition: "Edition",
  no_data_found: "No data found",
  username_or_password_incorrect: "Username or password incorrect",
  taxType_Id: "Tax Type",
  taxType_Id_placeholder: "Select a tax type",
  city: "City",
  value: "Value",
  edit_this_product_contract: "Edit associated contracts for this product",
  contract: "Contract",
  create_or_edit: "Create or edit",
  isActive: "Is Active",
  status: "Status",
  deleted_successfully: "Deleted successfully",
  tax_type_target_required: "A tax needs a definition on how it's calculated",
  some_tax_type_application_required: "A tax calculated per person or per product needs a payment application",
  payment_target: "Calculated",
  payment_application: "Payment Application",
  payment_calculation: "Payment Application",

  deactivate_product_modal_title: "Deactivation of a product",
  deactivate_product_warning:
    "This product is currently used in some Projects / Departments, it is for this reason that this product cannot be deactivated for this year. The product can be deactivated for the following year:",
  confirm_deactivate_product_warning: "Do you want disabled occurs from year specify?",
  date_periods: "Periods",
  summary_general_info:
    "The contract is called {{contractName}} ({{otherLanguageName}}). This contract is for the {{service}} category type and has been identified as {{isActive}}. It will be valid between the following dates: {{startDate}} and {{endDate}}. This contract is available for companies: {{companies}}. ",
  summary_contract_periods: "The contract has {{count}} periods ({{periodes}}). ",
  summary_contract_groups: "The contract has {{count}} groups ({{groups}}). ",
  summary_contract_day_groups: "The contract has {{count}} day's group ({{contractDayGroups}}). ",
  summary_contract_age_categories: "The contract has {{count}} age categories ({{contractAgeCategories}}). ",
  summary_contract_freebie: "The contract has {{count}} freebies ({{contractFreebies}}). ",
  summary_contract_departure_group: "The contract has {{count}} departure's group ({{contractDepartureGroups}}). ",

  view_summary_contract: "View sumary",
  summary_contract: "Contract summary",
  contain_errors: "The form contains some errors",
  freebies_required: "A contract with the specification of freebie and reduction must have at least one freebie",
  periods_required: "A contract with the specification of periods must have at least one period",
  periods_dates_overlap: "One of the periods overlaps another period",
  groups_required: "A contract with the groups specification must have at least one group",
  price_by_days_required: "A contract with the specification of prices per day must have at least one day grouping",
  price_by_days_overlaps: "Some days overlap",
  price_by_days_missing_day: "Daytime groupings must cover all days of the week",
  criteria_overlap: "Some criteria overlap",
  project_departures_required: "A contract with the price specification per project must have at least one project grouping",
  departure_not_unique: "Some departures are found in several groups",
  dates_outside_contrat_range: "Some dates are outside the contract term",
  freebies_details_required: "A contract with freebie or reductions must have at least one detail of reductions or freebies",
  age_categories_required: "A contract with the participant's age specification must have at least one age grouping",
  freebies_products_required: "A contract with freebie or reductions must have at least one product criteria",
  go_to_provider: "Go to northwind provider",
  go_to_establishment_nw: "Go to northwind establishment",
  go_to_reservation_nw: "Go to northwind reservation",
  is_taxable: "Is taxable",
  homeTitle: "Home",
  home: "Home",
  reservations: "Reservations",
  billing: "Invoicing",
  manage: "Manage",
  reports: "Reports",
  purchase_order: "Purchase Order",
  listCurrentFolder: "Liste of files in progress",
  calendar: "Calendar",
  reservations_by_status: "Reservation by status",
  suppliers: "Providers",
  agencies: "Agencies",
  user: "User",
  guides: "Guides",
  biglist: "Biglist",
  fid_transfer: "Fid Transfer",
  operations: "Operations",
  current: "Current",

  individual_camping: "Individual camping",
  price: "Price",
  adult_number: "Number of adults included",
  child_number: "Number of children included",
  additionnal_price_adult: "Price per additional adult",
  additionnal_price_child: "Price per additional child",
  price_adult: "Price per adult",
  price_child: "Price per child",
  ferry: "Ferry",
  group_camping: "Group camping",
  minimum_person_number: "Minimum number of paying people (per person)",
  maximum_person_number: "Maximum number of people (per product)",
  additionnal_price_person: "Price per additional person",
  vehicule_price: "Vehicule price",

  capacity: "Capacity (number of people)",
  non_standard_room: "Non-standard accommodation",
  standard_room: "Standard accomodation",
  description_french: "French description",
  description_english: "English description",
  isTaxExemption: "Sale Taxes exemption",
  product_info_tab: "Edit Product",
  product_events_tab: "Usages",
  product_events: "Product's events",
  go_to_reservation_nw_project: "Go to northwind project reservation",
  search: "Search",
  additional_non_standard_information: "Additional information special accomodation",
  qty_room: "Number of room",
  qty_bed: "Number of bed ",
  additional_camping_information: "Additional camping information",
  qty_tent_by_site: "Tent's quantity by site",
  qty_adults: "Number of adults",
  qty_children: "Number of children",
  product_contracts_tab: "Associated contracts",
  product_associated_contracts: "List of contracts with prices associated with this product",
  no_associated_contracts: "No contract associated with this product",
  delete_product_contract: "Remove prices from this contract",
  delete_associated_product_contract_modal_title: "Confirmation of price suppression for the contract",
  delete_associated_product_contract_impact: "This will delete the PRICES recorded for this product for the following contract.",
  delete_associated_product_contract_warning: "Are you sure you want to delete the prices associated with this contract?",
  delete_contract_modal_title: "Confirm",
  delete_contract_warning: "Are you sure you want to delete this contract?",
  delete_invoice_modal_title: "Confirmation of invoice withdrawal",
  delete_invoice_impact: "This action will prevent this version of the invoice from being synchronised with Quickbooks forever.",
  delete_invoice_warning: "Are you sure you want to proceed?",
  sync_invoice_modal_title: "Confirmation of invoice synchronization",
  sync_invoice_impact: "This action will synchronize this version of the invoice with Quickbooks.",
  sync_invoice_warning: "Are you sure you want to proceed?",
  delete_po_modal_title: "Confirmation of purchase order withdrawal",
  delete_po_impact: "This action will prevent this version of the purchase order from being synchronised with Quickbooks forever.",
  delete_po_warning: "Are you sure you want to proceed?",
  sync_po_modal_title: "Confirmation of purchase order synchronization",
  sync_po_impact: "This action will synchronize this version of the purchase order with Quickbooks.",
  sync_po_warning: "Are you sure you want to proceed?",

  invoice_change_date_modal_title: "Confirmation of invoice creation date update",
  invoice_change_date_impact:
    "This action will overwrite this invoice's creation date with the selected date. This will impact Quickbook's Transaction Date (TxnDate).",
  invoice_change_date_warning: "Are you sure you want to proceed?",

  priceOnDemand: "Custom meal price",
  priceHalfBoard: "Half board price",
  priceFullBoard: "Full board price",
  fixedPrice: "Fixed price",
  isFixed: "Is a fixed price",
  delete_tax_type_title: "Confirm the deletion of a tax/fee",
  delete_tax_type_warning: "Are you sure you want to delete this tax/fee?",
  modifing_tax_type_warning:
    "Modifying the Fixed / Variable type has a significant impact on all Establishments, Products and Contracts that use this tax. Please make sure this change is valid.",
  confirmer: "Confirm",
  taxTypeFixed: "Fixed",
  taxTypeVariable: "Variable",
  reservation_type_id_placeholder: "Type de réservation",
  purchase_orders_to_sync: "Purchase orders to synchronize",
  departure_date: "Departure date",
  sync_date_asked: "Sync. Date",
  retries: "Retries",
  po_amount: "PO amount",
  po_actions: "PO actions",
  synchronize: "Synchronize",
  guide_impacts_capacity: "Guide included capacity",
  skips_PO: "Does not require Purchase Order",
  windigocan_id: "Windigo Canada Id",
  windigousa_id: "Windigo USA Id",
  receptour_id: "Receptour Id",
  push_purchase_order: "Push purchase order",
  valid: "Valid",
  transport_percentage: "Transport %",
  po_is_invalid: "Missing ID (Agency/Provider)",
  invoice_is_invalid: "Missing ID (Agency)",
  invoice_is_invalid_transport: "Transport % missing",
  po_already_synched: "There's already a synchronized PO for this departure/establishment",
  po_is_negative: "The PO cannot be synchronized when negative",
  invoice_already_synched: "There's already a synchronized Invoice",
  invoice: "Invoice",
  agency_name: "Agency Name",
  invoice_amount: "Invoice amount",
  invoice_actions: "Invoice actions",
  hold: "Pause",
  unhold: "Unpause",
  price_per_adult_explain: "To have the system add the price per person to the vehicle price, please select Calculated per person",
  groups_target_required: "The price calculation method is required",
  quickbooks_companies: "Quickbooks companies/Token",
  contract_additional_configurations: "Additional contract configurations",
  has_guide_price: "Contains different prices for the guide",
  has_guide_price_tooltip: "Examples: Cheaper room, paid transportation, etc.",
  has_driver_price: "Contains different prices for the driver",
  has_driver_price_tooltip: "Examples: Cheaper room, paid transportation, etc.",
  quickbooks_vendor: "Quickbooks provider",
  quickbooks_vendors: "Quickbooks providers",
  quickbooks_customer: "Quickbooks client",
  quickbooks_customers: "Quickbooks clients",
  force_synchronize_payment: "Synchronize payments",
  force_synchronize_payment_label: "#Quickbooks invoice",
  NWInvoiceNotFound: "Quickbooks invoice not found in Northwind",
  payments_imported_success: "Payments successfully imported",
  force_synchronize_payment_warning:
    "Please enter the Id of the quickbooks invoice whose payments you wish to import. Please note that this action will import all quickbook payments from the invoice to Northwind. However, if a payment has already been imported, it will not be imported a 2nd time.",
  specific_case_application: "Specific case (optional)",
  invoices_to_sync: "Invoices to synchronize",
  usergroup: "Groups",
  no_records: "No data available",

  productType_Id: "Applicable product type",
  productType_Id_placeholder: "Select an applicable product type",
  category_Id: "Applicable category type",
  category_Id2: "Category type",
  category_Id_placeholder: "Please select the applicable category type",
  productType: "Type de produit",
  edit_this_contract: "Edit this contract",
  freebies_title: "Discount or freebie policy",
  freebies_information: "Please create as many discounts or freebies as necessary. Make sure the discounts are mutually exclusive.",
  is_per_product: "Calculated by product",
  deactivate: "Deactivate",
  activate: "Activate",
  activated_successfully: "Activated with successfully.",
  deactivated_successfully: "Deactivated with successfully.",

  contracts_placeholder: "Select a contract",
  groups: "Groups size",
  add_category: "Add an age group",
  add_criteria: "Add criteria",
  age_categories: "Age groups",
  age_category: "Age group",
  category: "Age group",
  length_categories: "Duration",
  age_criteria_operators: "Age criteria operator",
  age_criteria_operators_placeholder: "Select an operator for the age criteria",
  group: "Group size",
  add_group: "Add age group size",
  group_criteria_operators: "Group operator",
  group_criteria_operators_placeholder: "Select an operator for group",
  fit: "F.I.T.",

  add_tax: "Add tax",
  edit_tax: "Edit tax",
  defaultValue: "Default value",
  edit: "Edit",
  isPercentage: "Tax applied in precentage",
  delete_tax: "Delete tax",

  yes: "Yes",
  no: "No",

  add_date: "Add a validity range",
  sales_taxes: "Sales tax",
  sales_taxes_placeholder: "Select the tax(es) to apply",
  country: "Country",
  country_placeholder: "Select a country",
  provinces: "Provinces",
  provinces_placeholder: "Select a province",

  projects: "Projects",
  project: "Project",
  departures: "Departures",
  departure: "Departure",
  active: "active",
  inactive: "inactive",

  departures_placeholder: "Select the list of departures associated with this contract",
  projects_placeholder: "Select the list of projects associated with this contract",
  projects_helper_placeholder: "Select the list of projects to add your departures to the list below",
  excluded_projects_placeholder: "Select the list of projects to exclude from this contract",

  summary_date_line: "from {{start}} to {{end}}",

  maximumNumberOfUser: "Maximum amount of user",
  specifications: "Product specifications",
  comparators: "Comparator",
  age: "Age",
  comparators_placeholder: "Select a comparator",
  contract_type: "Contract configuration",
  has_age_categories: "Contains different prizes based on the age of the participant",
  has_age_categories_tooltip: "Example: Adults and childrens price",
  has_pricing_by_group: "Contains different prices depending on group size",
  has_pricing_by_group_tooltip: "Example: 10 participants and less/11 participants and more",
  has_pricing_by_length: "Contains different prices based on duration (rental)",
  has_freebies: "Contains discount or freebie policies",
  has_freebies_tooltip: " Examples: discounts for guides/drivers, free services from..., etc.",
  has_contract_periods: "Contains different prices based on periods",
  has_contract_periods_tooltip: "Example: High and Low season, events, etc.",
  has_specificProjectDeparture: "Contain price differences based on projects/departs",
  has_specificProjectDeparture_tooltip: "You will need to select all projects and/or departures for which the prices apply",
  length_category: "Durations",
  add_length: "Add a duration",
  duration: "Duration",
  durations: "Durations",
  durations_placeholder: "Select a duration",

  hasAdditionalPricing: "Contains additional fees for the duration",
  additionalPricing: "Addtionnal fee for the duration",
  specification: "specification",
  isAdditionalPricingPerPerson: "Applicable for each person",
  maximumParticipantPerGuide: "Maximum participant per guide",

  add_freebie: "Add a discount or free policy",
  freebie_criteria_operators: "Operator for the free criterion",
  freebie_criteria_operators_placeholder: "Select the operator for the free criterion",

  periods: "Periods",
  add_period: "Add a period",
  specific_project_departures: "Projects/Departures",
  except: "Except",
  project_departures: "Project and Departures",
  is_percentage: "Calculate by percentage",
  is_sales_taxable: "Taxable",
  is_per_person: "Calculate per person",

  establishment_configuration: "Establishment configuration",
  contracts_tab: "Contracts structures",
  products_tab: "Product and price lists",
  create_contract_tt: "Create a new contract for this establishement",
  create_contract: "Create a new contract",
  show_active_only: "Show only the active contracts",
  search_contract: "Search a contract",
  search_product: "Search a product",
  edit_establishment_tt: "Edit establishment propreties (taxes)",
  establishment_tab: "Establishement - Taxes",

  coordinates: "Coordinates",
  generate_prices: "Generate price",
  create_product_placeholder: "Create a product for this establishement",
  create_price_list: "Create a price list",
  create_price_list_placeholder: "Create a price list for the selected product",
  merge_product_placeholder: "Merge selected products",
  merge_products: "Merge products",
  number_for_company: "Company number",
  number_for_company_tooltip: "This is the number that relates to the Day by Day, Reservation Report and Accommodation List",
  freebie: "Freebie",

  select_main_product: "Select the main product",
  merge_successfully: "The product merge was done successfully.",
  merge_warning_placeholder:
    "The selected main product will be kept. Other products will be deleted, links in events, if applicable, will be changed to the main product selected.",
  establishment_taxes: "Taxes configured at the establishment level",
  product_taxes: "Product taxes",
  companies: "Companies",
  companies_placeholder: "Select company(ies)",
  drop_files_here: "Drag and drop a file or click here",
  drop_files_here_active: "Drop a file",
  northwind_providers: "Go to Northwind providers",
  northwind_establishments: "Go to Northwind establishment",
  group_namefrench_placeholder: "Ex: 20 personnes et moins",
  group_nameenglish_placeholder: "Ex: 20 people or less",
  period_header_information:
    "Please create as many periods as necessary. Make sure the periods are mutually exclusive and cover all dates where there might be events.",
  group_header_information:
    "Please create as many group sizes as necessary. Make sure the group sizes are mutually exclusive and cover all possible sizes.",
  age_category_header_information:
    "Please create as many age groups as necessary. Make sure groups are mutually exclusive and cover all ages. For children age groups, the traveler's date of birth will need to be completed. A traveler whose date of birth is not completed will be considered an adult by default.",

  cannot_delete_producttype:
    "If this category was selected in error, we suggest modifying the relevant events so that there are no longer any associated events before returning to remove the category here. If all associated events are from previous years, you can also deactivate this product, and create a new one with the correct categories.",
  error: "Error",
  dateStart: "Start date",
  dateEnd: "End date",
  contract_files: "Files",
  contract_Id_placeholder: "Choose a contract to use",
  contract_periods: "Periods",
  contract_period: "Period",
  single: "Single occupancy",
  double: "Double occupancy",
  triple: "Triple occupancy",
  quad: "Quad occupancy",
  twin: "Twin occupancy",
  change_language: "Change language",
  form: "From",
  app: "Application",
  fr: "French",
  see_this_product_pricing: "View configured prices",
  project_departures_information:
    "Create groupings of departures for which you want to set the same prices. When you create new projects/starts, don't forget to come back and add them to the correct grouping.",
  project_departures_information_except:
    "You can select a project for which this contract applies, and then add departures which are exceptions, i.e. this contract does not apply to these departures. You will need to create another contract for these departures.",
  contract_taxes: "Taxes",
  regenerate: "Regenerate",
  applicable_sales_taxes: "Applicable sales taxes",
  regenerate_contract: "",
  category_Ids: "Categories",
  clear_filters: "Clear filter",
  table_view: "Table view",
  add_contract_departure_group: "Add departure group",
  contract_departure_group: "Departure group",
  add_departures_from_projects: "Add departures from project",
  delete_product: "Delete product",
  delete_product_modal_title: "Confirmation",
  delete_product_warning: "Are you sure you want to delete this product?",
  cancel_sort: "Cancel sort",
  minimum_capacity: "Minimum capacity (in number of people) per product",
  maximum_capacity: "Maximum capacity (in number of people) per product",
  minimum_age: "Minimum age",

  free_products: "Additional products included (free)",
  free_products_placeholder: "Choose products that will be included with this product",

  duplicate_contract: "Duplicate this contract",
  duplicate_successful: "Duplicated successfully.",
  duplicate_contract_modal_info: "Do you really want to duplicate this contract: ",
  duplicate_contract_modal_new_name: "Please provide the name for the new contract.",
  duplicate: "Duplicate",

  child_age: "Maximum age to be considered a child (inclusive)",

  freebie_products_placeholder: "Select products",
  guide: "Guide",
  driver: "Driver",
  traveler: "Traveler",
  reduction_percentage: "Reduction percentage",
  quantity: "Quantity",
  detail_products_placeholder: "Select reduced products",

  has_pricing_by_days: "Contains different prices based on the day",
  has_price_by_days_tooltip: "Example: Weekends and/or Monday to Friday",
  price_by_days: "Days",

  specific_days: "Day group",
  specific_days_information: "Create groupings of days for which you want to set the same prices.",
  add_specific_days: "Add a day group",
  days: "Days",
  days_placeholder: "Select the days associated with this grouping",
  contract_day_group: "Day group",

  contract_type_disabled_info: "You cannot modify the configurations because there are products associated with this contract",
  delete_confirmation: "Confirmation of deletion",
  delete_confirmation_message:
    "The element with the name {{name}} is associated with prices in one or more contracts. Deleting this item will also delete the associated prices.",
  specific_company_information: "Information specific to {{companyName}}",
  reservations_instructions: "Reservation / Cancellation Instructions",
  reservations_instructions_placeholder:
    "Add details here on the supplier's conditions and policies, additional information on services, etc. This information is not reported anywhere",
  day_by_day_instructions: "Instructions Day By Day",
  day_by_day_instructions_placeholder:
    "Add here information that will be found on all Day by Day, on the page of the day when there is an event at this establishment",
  reservation_type_id: "Reservation type",
  reservation_type_id_tooltip: "Determines the default reservation status (reservation module)",
  push_invoice: "Push invoice",
  price_for_guide: "Price for guide",
  price_for_driver: "Price for driver",
  po_without_sync: "PO creation without synchronization",
  special_accommodation_qty_room_required: "The number of rooms is required",
  special_accommodation_qty_bed_required: "The number of beds is required",
  product_capacity_required: "Capacity is required",
  camping_qty_tent_by_site_required: "The number of tents allowed per site is required",
  camping_qty_adult_required: "The number of adults included is required",
  camping_qty_children_required: "The number of children included is required",
};
