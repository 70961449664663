import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route } from "react-router-dom";

import BodyClassName from "react-body-classname";

import Login from "views/Account/Login";

import "./css/loginlayout.css";

class LoginLayout extends Component {
  render() {
    return (
      <BodyClassName className="dark-grey-bg">
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <Route path="/login" component={Login} />
              <Route path="/connexion" component={Login} />
              <div
                className="login100-more"
                style={{ backgroundImage: "url(assets/img/splash_screen.jpg)" }}
              />
            </div>
          </div>
        </div>
      </BodyClassName>
    );
  }
}

export default withTranslation()(LoginLayout);
