import React from "react";
import uuidv4 from "uuid/v4";

import CurrencyInput from "react-currency-input";

import { Row, Col } from "reactstrap";

export default function BaseTransportPriceDetail({ cpd, handleUpdateContractPriceDetail, isManager }) {
  let guid1 = uuidv4();

  return (
    <Row>
      <Col md="3">
        <div className="form-group ">
          <div className="input-group ">
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <CurrencyInput
              value={cpd.value}
              id={`value-${guid1}`}
              onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "value", true)}
              className="form-control text-right"
              disabled={!isManager}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
