import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useReactRouter from "use-react-router";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from "reactstrap";

import { hideDuplicateModal, duplicateContract, updateDuplicateModel } from "redux/actions/contracts";
import InputWithLabel from "components/FormControls/InputWithLabel";

function ContractDuplicateModal() {
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const { history, match } = useReactRouter();

  const contract = contractsReducer.duplicateContractModel;

  const saveBtn = () => {
    dispatch(duplicateContract(contract.id, contract.newNameFrench, contract.newNameEnglish)).then((response) => {
      history.push(`/establishments/${match.params.id}/contracts/${response.id}`);
      closeModal();
    });
  };

  const handleUpdateDuplicateModal = (value, _, fieldname) => {
    dispatch(updateDuplicateModel(value, fieldname));
  };

  const closeModal = () => {
    dispatch(hideDuplicateModal());
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!contract) return null;

  return (
    <Modal className="mw-100 w-50" isOpen={contractsReducer.showDuplicateModal}>
      <ModalHeader>{t("duplicate_contract")}</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            {t("duplicate_contract_modal_info")}
            <strong>{`${contract.currentContractNameFrench}/${contract.currentContractNameEnglish}`}</strong> ?
          </Col>
          <Col md={12}>{t("duplicate_contract_modal_new_name")}</Col>
        </Row>
        <Row className={"mt-2"}>
          <Col md={12}>
            <InputWithLabel
              labelText={t("name")}
              inputType="text"
              fieldToUpdate="newNameFrench"
              objectToUpdate={contract}
              persistOnBlur
              updateAction={(value, cont, fieldName, isBlurring) => {
                handleUpdateDuplicateModal(value, cont, fieldName, isBlurring);
                handleUpdateDuplicateModal(value, cont, "newNameEnglish", isBlurring);
              }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="success" disabled={!contract.newNameEnglish || !contract.newNameFrench} onClick={saveBtn}>
          {t("duplicate")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ContractDuplicateModal;
