import React from "react";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { hideDeactivationProductModal, deactivateProduct } from "redux/actions/products";

export default function ConfirmDeactivationProductModal() {
  const productsReducer = useSelector((state) => state.productsReducer);
  let product = productsReducer.deactivationProduct;
  let validYear = 0;

  if (
    product &&
    product.deactivationValidation &&
    product.deactivationValidation.validDeactivationDate &&
    product.deactivationValidation.validDeactivationDate
  ) {
    validYear = moment(product.deactivationValidation.validDeactivationDate).format("YYYY");
  }

  const handleDeactivateProduct = () => {
    dispatch(deactivateProduct(productsReducer.deactivationProduct, product.deactivationValidation.validDeactivationDate)).then(() =>
      closeModal()
    );
  };

  const closeModal = () => {
    dispatch(hideDeactivationProductModal());
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEnglish = t("locale") === "en";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";
  let otherReference = isEnglish ? "nameFrench" : "nameEnglish";

  return (
    <Modal className="mw-100 w-50" isOpen={productsReducer.showDeactivationProductModal}>
      <ModalHeader>{t("deactivate_product_modal_title")}</ModalHeader>
      <ModalBody>
        <div className="alert alert-warning">{t("deactivate_product_warning")}</div>

        <span
          dangerouslySetInnerHTML={{
            __html: t("confirm_deactivate_product_warning", {
              productName:
                product && product[referenceName] && product[referenceName].length
                  ? product[referenceName]
                  : product
                  ? product[otherReference]
                  : null,
              year: validYear,
            }),
          }}
        ></span>
        {}
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleDeactivateProduct}>
          {t("deactivate")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
