import React from "react";
import uuidv4 from "uuid/v4";

import { useTranslation } from "react-i18next";

import CurrencyInput from "react-currency-input";

import { Row, Col } from "reactstrap";

export default function GuideDriverPriceDetail({ contract, cpd, handleUpdateContractPriceDetail, isManager }) {
  const { t } = useTranslation();

  let guid1 = uuidv4();

  return (
    <Row>
      <Col md="12">
        <h5>{t("contract_additional_configurations")}</h5>
        <div className="d-flex flex-nowrap">
          {contract.hasGuidePrice && (
            <div className="form-group pt-2 pb-2 pr-2">
              <label className="form-label" htmlFor={`value-${guid1}`}>
                {t("price_for_guide")}
              </label>
              <div className="input-group mt-1">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <CurrencyInput
                  value={cpd.guidePrice}
                  id={`value-${guid1}`}
                  onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "guidePrice", true)}
                  className="form-control text-right"
                  disabled={!isManager}
                />
              </div>
            </div>
          )}
          {contract.hasDriverPrice && (
            <div className="form-group pt-2 pb-2 pr-2">
              <label className="form-label" htmlFor={`value-${guid1}`}>
                {t("price_for_driver")}
              </label>
              <div className="input-group mt-1">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <CurrencyInput
                  value={cpd.driverPrice}
                  id={`value-${guid1}`}
                  onBlur={(e) => handleUpdateContractPriceDetail(e, cpd, "driverPrice", true)}
                  className="form-control text-right"
                  disabled={!isManager}
                />
              </div>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
}
