import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";

import TableData from "components/TableData/TableData";
import Spinner from "components/Spinner/Spinner";

import ReactTooltip from "react-tooltip";

import { getSalesTaxes, activateSalesTax, deactivateSalesTax } from "redux/actions/sales-taxes";
import { getProvincesForSelect } from "redux/actions/provinces";

function SalesTaxes() {
  const { t } = useTranslation();

  const salesTaxesReducer = useSelector((state) => state.salesTaxesReducer);
  const provincesReducer = useSelector((state) => state.provincesReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const dispatch = useDispatch();
  const { history } = useReactRouter();

  useEffect(() => {
    dispatch(getSalesTaxes());
    dispatch(getProvincesForSelect());
  }, [dispatch, salesTaxesReducer.tableDataParam]);

  const renderRows = (value, field, isFilter) => {
    let tableDataParam = salesTaxesReducer.tableDataParam;
    tableDataParam[field] = value;

    if (isFilter) {
      tableDataParam.currentPage = 1;
    }

    dispatch(getSalesTaxes(tableDataParam));
  };

  const updatePage = (page) => {
    renderRows(page, "currentPage");
  };
  const updatePageSize = (pageSize) => {
    renderRows(pageSize, "pageSize");
  };

  const sortColumn = (column) => {
    let tableDataParam = salesTaxesReducer.tableDataParam;
    let existingSort = tableDataParam.sorts.find((s) => s.column === column.sortColumn);

    renderRows(
      [
        {
          column: column.sortColumn,
          asc: existingSort !== undefined ? !existingSort.asc : false,
        },
      ],
      "sorts"
    );
  };

  const filter = (filters) => {
    if (filters === undefined) {
      return renderRows([], "filters");
    }

    let formattedFilters = filters.map((f) => ({
      fieldName: f.selectedColumn.column.filterColumn,
      columnWrapper: f.selectedColumn.column.columnWrapper,
      fieldValue: f.value,
      fieldType: f.selectedColumn.column.type,
      operator: f.comparator,
    }));

    return renderRows(formattedFilters, "filters", true);
  };

  const handleEdit = (salesTaxId) => {
    history.push(`/sales-taxes/${salesTaxId}`);
  };

  const handleActivate = (salesTax) => {
    dispatch(activateSalesTax(salesTax));
  };

  const handleDeactivate = (salesTax) => {
    dispatch(deactivateSalesTax(salesTax));
  };

  const addButton = {};

  if (accountReducer.isManager) {
    addButton.addButtonText = t("add_tax");
    addButton.addAction = () => handleEdit(0);
  }

  let isEnglish = t("locale") === "en";
  let referenceName = isEnglish ? "labelEnglish" : "labelFrench";

  return (
    <React.Fragment>
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">{t("overview")}</h6>
          <h2 className="dashhead-title">{t("sales_taxes")}</h2>
        </div>
      </div>

      <TableData
        className="mb-4"
        numberOfItems={salesTaxesReducer.count}
        pageSize={salesTaxesReducer.tableDataParam.pageSize}
        updatePage={updatePage}
        updatePageSize={updatePageSize}
        pageSizes={[25, 50, 100, 200]}
        hideTitle
        reset={t("reset")}
        filter={t("filter")}
        sortMethod={sortColumn}
        filterMethod={filter}
        locale={t("locale")}
        {...addButton}
        hasFilter
        columns={[
          {
            title: t("status"),
            className: "text-center",
            filterComparatorsName: "booleanComparators",
            filterColumn: "isActive",
            type: "bool",
          },
          {
            title: t("nameFrench"),
            filterComparatorsName: "stringComparators",
            filterColumn: "nameFrench",
            type: "string",
          },
          {
            title: t("nameEnglish"),
            filterComparatorsName: "stringComparators",
            filterColumn: "nameEnglish",
            type: "string",
          },
          {
            title: t("value"),
            filterComparatorsName: "numberComparators",
            filterColumn: "value",
            type: "number",
          },
          {
            title: t("provinces"),
          },
          {
            title: t("actions"),
          },
        ]}
      >
        <tbody>
          {salesTaxesReducer.updating ? (
            <tr>
              <td colSpan={4}>
                <Spinner />
              </td>
            </tr>
          ) : salesTaxesReducer.salesTaxes.length ? (
            salesTaxesReducer.salesTaxes.map((st) => (
              <tr key={st.id}>
                <td className="text-center">
                  <ReactTooltip clickable id="tt-deactive" type="info" effect="solid" place="left" border />
                  <ReactTooltip clickable id="tt-active" type="info" effect="solid" place="left" border />
                  {st.isActive ? (
                    <i
                      className="fas fa-2x fa-power-off text-success pointer"
                      data-tip={t("deactivate")}
                      data-for="tt-deactive"
                      onClick={() => handleDeactivate(st)}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-2x fa-power-off text-danger pointer"
                      data-tip={t("activate")}
                      data-for="tt-active"
                      onClick={() => handleActivate(st)}
                    ></i>
                  )}
                </td>
                <td>{st.nameFrench}</td>
                <td>{st.nameEnglish}</td>
                <td>{st.value}</td>
                <td>
                  {provincesReducer.provincesForSelect
                    .filter((ps) => st.province_Ids && st.province_Ids.some((pid) => pid === ps.id))
                    .map((ps) => ps[referenceName])
                    .join(", ")}
                </td>

                <td>
                  <React.Fragment>
                    <ReactTooltip clickable id="tt-edit-tax" type="info" effect="solid" place="left" border />

                    <i
                      data-tip={t("edit_tax")}
                      data-for="tt-edit-tax"
                      onClick={() => handleEdit(st.id)}
                      style={{ marginLeft: "10px" }}
                      className="fas fa-2x fa-pencil-alt text-primary pointer"
                    />
                  </React.Fragment>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{t("no_data_found")}</td>
            </tr>
          )}
        </tbody>
      </TableData>
    </React.Fragment>
  );
}

export default SalesTaxes;
