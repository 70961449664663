import React, { Component } from "react";
import { DotLoader } from "react-spinners";

class Spinner extends Component {
  render() {
    // try change me to a custom color like "red" or "#000000"
    var color = "#00386c";

    var style = {
      display: "-webkit-flex",
      WebkitFlex: "0 1 auto",
      flex: "0 1 auto",
      WebkitFlexDirection: "column",
      flexDirection: "column",
      WebkitFlexGrow: 1,
      flexGrow: 1,
      WebkitFlexShrink: 0,
      flexShrink: 0,
      WebkitFlexBasis: "25%",
      flexBasis: "25%",
      maxWidth: "25%",
      height: "200px",
      WebkitAlignItems: "center",
      alignItems: "center",
      WebkitJustifyContent: "center",
      justifyContent: "center"
    };

    return (
      <div
        style={{
          boxSizing: "border-box",
          display: "-webkit-flex",
          WebkitFlex: "0 1 auto",
          flex: "0 1 auto",
          WebkitFlexDirection: "row",
          flexDirection: "row",
          WebkitFlexWrap: "wrap",
          flexWrap: "wrap",
          justifyContent: "center"
        }}
      >
        <div style={style}>
          <DotLoader color={color} loading={true} />
        </div>
      </div>
    );
  }
}

export default Spinner;
