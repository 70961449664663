import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useReactRouter from "use-react-router";

import { getProductAssociatedContracts, showDeleteAssociatedProductContractModal } from "redux/actions/products";
import Spinner from "components/Spinner/Spinner";
import { Col, Row, Table } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { getCategories } from "redux/actions/categories";
import DeleteAssociatedProductContractModal from "views/Contracts/Components/DeleteAssociatedProductContractModal";

function ProductAssociatedContracts() {
  const productsReducer = useSelector((state) => state.productsReducer);
  const categoriesReducer = useSelector((state) => state.categoriesReducer);

  const { gettingProductAssociatedContracts, productAssociatedContracts } = productsReducer;

  const dispatch = useDispatch();
  const { match } = useReactRouter();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getProductAssociatedContracts(match.params.productId || match.params.id));
    dispatch(getCategories());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowDeleteAssociatedProductContractModal = (e, product) => {
    e.stopPropagation();
    dispatch(showDeleteAssociatedProductContractModal(product));
  };

  let isEnglish = t("locale") === "en";
  const namePropertyName = isEnglish ? "finalContractNameEnglish" : "finalContractNameFrench";
  let referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  return (
    <Row>
      <DeleteAssociatedProductContractModal />

      <Col md={12}>
        <div className="dashhead mt-4 mb-3">
          <div className="dashhead-titles">
            <h6 className="dashhead-subtitle">{t("product_associated_contracts")}</h6>
          </div>
        </div>
      </Col>

      {gettingProductAssociatedContracts ? (
        <Col md={12}>
          <Spinner />
        </Col>
      ) : (
        <Col md={12}>
          <Table className="table table-striped">
            <thead>
              <tr>
                <td>{t("name")}</td>
                <td>{t("category_Id2")}</td>
                <td>{t("dateStart")}</td>
                <td>{t("dateEnd")}</td>
                <td>{t("actions")}</td>
              </tr>
            </thead>
            <tbody>
              {productAssociatedContracts?.length > 0 ? (
                productAssociatedContracts.map((pac) => (
                  <tr key={pac.id}>
                    <td className="vertical-align-middle">{pac[namePropertyName]}</td>
                    <td className="vertical-align-middle">
                      {(categoriesReducer.categories.length > 0 &&
                        pac.category_Id &&
                        categoriesReducer.categories
                          .filter((c) => pac.category_Id === c.id)
                          .map((c) => c[referenceName])
                          .join(", ")) || <label className="form-text">{t("noResult")}</label>}
                    </td>
                    <td className="vertical-align-middle">{moment(pac.start).format(t("date_format"))}</td>
                    <td className="vertical-align-middle">{moment(pac.end).format(t("date_format"))}</td>
                    <td>
                      <ReactTooltip id="tt-delete-product" type="info" effect="solid" place="top" border />
                      <button
                        className="btn btn-danger ml-2"
                        onClick={(e) => handleShowDeleteAssociatedProductContractModal(e, pac)}
                        data-tip={t("delete_product_contract")}
                        data-for="tt-delete-product"
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>{t("no_associated_contracts")}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      )}
    </Row>
  );
}

export default ProductAssociatedContracts;
