import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import axios from "axios";

import Toastr from "components/Toastr/Toastr.jsx";

import authentication from "components/Authentication/Authentication";
import ApplicationLayout from "layouts/ApplicationLayout";
import LoginLayout from "layouts/LoginLayout";

import { setIsWrapped } from "redux/actions/app";

import "moment/locale/fr";
import "./App.css";
import "react-widgets/dist/css/react-widgets.css";
import "rc-time-picker/assets/index.css";
import MessageModal from "components/Toastr/MessageModal";

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    i18n.changeLanguage("fr");
    axios.defaults.headers.common["Accept-Language"] = "fr";

    var r = document.querySelector(":root");
    r.style.setProperty("--primary-color", "#233572");

    let language = document.querySelector("#language");
    if (language && language.value) i18n.changeLanguage(language.value);

    let isWrapped = document.querySelector("#isWrapped");
    if (isWrapped && isWrapped.value) dispatch(setIsWrapped(isWrapped.value.toLowerCase() === "true"));
  }, [i18n, dispatch]);

  return (
    <BrowserRouter>
      <>
        <Toastr />
        <MessageModal />

        <Switch>
          <Route path="/login" component={LoginLayout} />
          <Route path="/connexion" component={LoginLayout} />
          <Route path="/" component={authentication(ApplicationLayout)} />
        </Switch>
      </>
    </BrowserRouter>
  );
}

export default App;
