import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
import SummaryGeneralInformations from "./SummaryGeneralInformations";
import SummaryContractPeriods from "./SummaryContractPeriods";
import SummaryContractGroups from "./SummaryContractGroups";
import SummaryPriceByDays from "./SummaryPriceByDays";
import SummaryAgeCategories from "./SummaryAgeCategories";
import SummaryFreebie from "./SummaryFreebie";
import SummarySpecificProjectDepartures from "./SummarySpecificProjectDepartures";

function SummaryBody({ contract }) {
  const categoriesReducer = useSelector((state) => state.categoriesReducer);
  const companiesReducer = useSelector((state) => state.companiesReducer);

  const { t } = useTranslation();
  const { companiesForSelect: companies } = companiesReducer;

  const isEnglish = t("locale") === "en";
  const referenceLabel = isEnglish ? "labelEnglish" : "labelFrench";
  const referenceName = isEnglish ? "nameEnglish" : "nameFrench";

  return (
    <Row>
      <Col md="12">
        <Row>
          <Col md="12">
            <p>
              {t("summary_general_info", {
                contractName: contract[isEnglish ? "finalContractNameEnglish" : "finalContractNameFrench"],
                otherLanguageName: contract[!isEnglish ? "finalContractNameEnglish" : "finalContractNameFrench"],
                isActive: contract.isActive ? t("active") : t("inactive"),
                startDate: moment(contract.start).format(t("date_format")),
                endDate: moment(contract.end).format(t("date_format")),
                service:
                  categoriesReducer.categories &&
                  categoriesReducer.categories.length > 0 &&
                  contract.category_Id &&
                  categoriesReducer.categories.find((cat) => cat.id === contract.category_Id)[referenceName],
                companies:
                  contract.company_Ids &&
                  companies.length > 0 &&
                  contract.company_Ids.reduce(
                    (acc, value, index) => acc + `${index !== 0 ? ", " : ""}${companies.find((c) => c.id === value)[referenceLabel]}`,
                    ""
                  ),
              })}
              {contract.hasContractPeriods &&
                contract.contractPeriods.length > 0 &&
                t("summary_contract_periods", {
                  count: contract.contractPeriods.length,
                  periodes: contract.contractPeriods.reduce(
                    (acc, value, index) => acc + `${index !== 0 ? ", " : ""}${value[referenceName]}`,
                    ""
                  ),
                })}
              {contract.hasGroups &&
                contract.groups.length > 0 &&
                t("summary_contract_groups", {
                  count: contract.groups.length,
                  groups: contract.groups.reduce((acc, value, index) => acc + `${index !== 0 ? ", " : ""}${value[referenceName]}`, ""),
                })}
              {contract.hasPriceByDays &&
                contract.contractDayGroups.length > 0 &&
                t("summary_contract_day_groups", {
                  count: contract.contractDayGroups.length,
                  contractDayGroups: contract.contractDayGroups.reduce(
                    (acc, value, index) => acc + `${index !== 0 ? ", " : ""}${value[referenceName]}`,
                    ""
                  ),
                })}
              {contract.hasAgeCategories &&
                contract.ageCategories.length > 0 &&
                t("summary_contract_age_categories", {
                  count: contract.ageCategories.length,
                  contractAgeCategories: contract.ageCategories.reduce(
                    (acc, value, index) => acc + `${index !== 0 ? ", " : ""}${value[referenceName]}`,
                    ""
                  ),
                })}
              {contract.hasFreebies &&
                contract.freebies.length > 0 &&
                t("summary_contract_freebie", {
                  count: contract.freebies.length,
                  contractFreebies: contract.freebies.reduce(
                    (acc, value, index) => acc + `${index !== 0 ? ", " : ""}${value[referenceName]}`,
                    ""
                  ),
                })}
              {contract.hasSpecificProjectDepartures &&
                contract.contractDepartureGroups.length > 0 &&
                t("summary_contract_departure_group", {
                  count: contract.contractDepartureGroups.length,
                  contractDepartureGroups: contract.contractDepartureGroups.reduce(
                    (acc, value, index) => acc + `${index !== 0 ? ", " : ""}${value[referenceName]}`,
                    ""
                  ),
                })}
            </p>
          </Col>
        </Row>

        <SummaryGeneralInformations contract={contract} companies={companies} />

        {contract.hasContractPeriods && <SummaryContractPeriods contract={contract} />}

        {contract.hasGroups && <SummaryContractGroups contract={contract} />}

        {contract.hasPriceByDays && <SummaryPriceByDays contract={contract} />}

        {contract.hasAgeCategories && <SummaryAgeCategories contract={contract} />}

        {contract.hasFreebies && <SummaryFreebie contract={contract} />}

        {contract.hasSpecificProjectDepartures && <SummarySpecificProjectDepartures contract={contract} />}
      </Col>
    </Row>
  );
}

export default SummaryBody;
