/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
class PageSizeSelector extends Component {
  constructor(props) {
    super(props);

    this.updatePageSize = this.updatePageSize.bind(this);
  }

  updatePageSize(pageSize) {
    this.props.updatePageSize(pageSize);
  }

  render() {
    return (
      <div className="input-with-icon select-with-icon float-left mr-2" style={{ width: "auto" }}>
        <select className="form-control" style={{paddingLeft:'30px'}} onChange={e => this.updatePageSize(parseInt(e.target.value))}>
          {this.props.pageSizes.map(ps => (
            <option key={ps} value={ps}>
              {ps}
            </option>
          ))}
        </select>
        <span className="icon icon-documents" />
      </div>
    );
  }
}

export default PageSizeSelector;
