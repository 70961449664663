import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";
import moment from "moment";

import ProgressBar from "./Components/ProgressBar";

import { validatorConfig } from "utils/validation";
import { isEmpty } from "utils/utils";
import { Row, Col, Button } from "reactstrap";
import Spinner from "components/Spinner/Spinner";

import { getComparators } from "redux/actions/comparators";
import { getEstablishmentContract, updateContract, persistEstablishmentContract, updateSteps } from "redux/actions/contracts";
import { getDeparturesForSelect } from "redux/actions/departures";
import { getProjectsForSelect } from "redux/actions/projects";
import { getCompaniesForSelect } from "redux/actions/companies";
import { getDurations } from "redux/actions/durations";

import GeneralInformation from "views/Contracts/Components/Steps/GeneralInformation";
import AgeCategories from "views/Contracts/Components/Steps/AgeCategories";
import LengthCategories from "views/Contracts/Components/Steps/LengthCategories";
import Groups from "views/Contracts/Components/Steps/Groups";
import Freebies from "views/Contracts/Components/Steps/Freebies";
import Pricing from "views/Contracts/Pricing";
import Summary from "views/Contracts/Components/Steps/Summary";
import ContractPeriods from "views/Contracts/Components/Steps/ContractPeriods";
import ContractDepartureGroups from "views/Contracts/Components/Steps/ContractDepartureGroups";
import ConfirmDeleteWithPriceDetail from "./Components/ConfirmDeleteWithPriceDetail";
import ByDays from "./Components/Steps/ByDays";
import { getDays } from "redux/actions/days";

import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import { updateProductPriceDetailProductIds } from "redux/actions/products";

function EstablishmentContract() {
  const { t } = useTranslation();

  const comparatorsReducer = useSelector((state) => state.comparatorsReducer);
  const contractsReducer = useSelector((state) => state.contractsReducer);
  const durationsReducer = useSelector((state) => state.durationsReducer);
  const daysReducer = useSelector((state) => state.daysReducer);
  const establishmentsReducer = useSelector((state) => state.establishmentsReducer);
  const taxTypesReducer = useSelector((state) => state.taxTypesReducer);
  const companiesReducer = useSelector((state) => state.companiesReducer);
  const accountReducer = useSelector((state) => state.accountReducer);

  const dispatch = useDispatch();

  const { history, match } = useReactRouter();

  let steps = JSON.parse(JSON.stringify(contractsReducer.steps.filter((s) => s.isActive && s.isEstablishmentStep)));
  const validator = useRef(new SimpleReactValidator(validatorConfig.getConfig(t))).current;
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    if (
      !contractsReducer.establishmentContract ||
      Number(contractsReducer.establishmentContract.id) !== Number(match.params.contractId) ||
      Number(match.params.contractId) === 0
    ) {
      dispatch(getEstablishmentContract(parseInt(match.params.contractId, 10), parseInt(match.params.id, 10))).then((contract) =>
        dispatch(updateSteps(contract))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  useEffect(() => {
    if (contractsReducer.establishmentContract?.start && contractsReducer.establishmentContract?.end) {
      var start = contractsReducer.establishmentContract?.start ? new Date(contractsReducer.establishmentContract?.start) : new Date();
      var end = contractsReducer.establishmentContract?.end ? new Date(contractsReducer.establishmentContract?.end) : new Date();

      dispatch(getDeparturesForSelect(Number(match.params.id), start.toLocaleDateString("fr-ca"), end.toLocaleDateString("fr-ca")));
      dispatch(getProjectsForSelect(Number(match.params.id), start.toLocaleDateString(), end.toLocaleDateString()));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, contractsReducer.establishmentContract?.start, contractsReducer.establishmentContract?.end]);

  useEffect(() => {
    if (!comparatorsReducer.comparatorsForSelect || comparatorsReducer.comparatorsForSelect.length === 0) dispatch(getComparators());
    if (!durationsReducer.durationsForSelect || durationsReducer.durationsForSelect.length === 0) dispatch(getDurations());

    if (!companiesReducer.companiesForSelect || companiesReducer.companiesForSelect.length === 0) dispatch(getCompaniesForSelect());

    if (!daysReducer.daysForSelect || daysReducer.daysForSelect.length === 0) dispatch(getDays());
  }, [
    match,
    dispatch,
    comparatorsReducer.comparatorsForSelect,
    contractsReducer.establishmentContract,
    durationsReducer.durationsForSelect,
    taxTypesReducer.taxTypesForSelect,
    companiesReducer.companiesForSelect,
    daysReducer.daysForSelect,
  ]);

  const update = (value, contract, field, isBlurring) => {
    if ((isEmpty(contract[field]) && isEmpty(value)) || contract[field] === value) return;

    contract[field] = value;

    if (field === "start" && contract[field] > contract["end"]) {
      contract["end"] = new Date(contract[field].getFullYear(), 11, 31);
    }

    if (field === "nameFrench" || field === "start" || field === "end" || field === "nameEnglish") {
      let startYear = new Date(contract["start"]).getFullYear();
      let endYear = new Date(contract["end"]).getFullYear();

      let yearPrefix = startYear !== endYear ? startYear + "-" + endYear : startYear;

      let parsedNameFrench = parseInt(contract["nameFrench"], 10);
      let parsedNameEnglish = parseInt(contract["nameEnglish"], 10);

      contract["finalContractNameFrench"] = yearPrefix !== parsedNameFrench ? yearPrefix + "-" + contract["nameFrench"] : yearPrefix;
      contract["finalContractNameEnglish"] = yearPrefix !== parsedNameEnglish ? yearPrefix + "-" + contract["nameEnglish"] : yearPrefix;
    }

    dispatch(updateContract(contract));
  };

  const customValidateContractPeriods = () => {
    const contractPeriods = contract.contractPeriods;

    if (!contractPeriods || contractPeriods.length === 0) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("periods_required"),
        },
      });

      return true;
    }

    const currentPeriodDates =
      contractPeriods && contractPeriods.length > 0
        ? [].concat.apply(
            [],
            contract.contractPeriods.map((m) =>
              m.contractPeriodDates.map((d) => {
                return { start: d.start, end: d.end, uniqueIdentifier: d.uniqueIdentifier };
              })
            )
          )
        : [];

    return contractPeriods.some((period) => {
      return period.contractPeriodDates.some((dates) => {
        return validateDateRanges(
          dates,
          currentPeriodDates.filter((d) => d.uniqueIdentifier !== dates.uniqueIdentifier),
          contract.start,
          contract.end
        );
      });
    });
  };

  const validateDateRanges = (dates, currentDates, contractStartDate, contractEndDate) => {
    if (
      currentDates.some((otherCurrentDates, otherDateIndex) => {
        return (
          moment(dates.start).isBetween(otherCurrentDates.start, otherCurrentDates.end, "day", "[]") ||
          moment(dates.end).isBetween(otherCurrentDates.start, otherCurrentDates.end, "day", "[]")
        );
      })
    ) {
      //error
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("periods_dates_overlap"),
        },
      });

      dates.isInvalid = true;
      setForceUpdate(!forceUpdate);
      return true;
    } else if (
      !moment(dates.start).isBetween(contractStartDate, contractEndDate, "day", "[]") ||
      !moment(dates.end).isBetween(contractStartDate, contractEndDate, "day", "[]")
    ) {
      //error
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("dates_outside_contrat_range"),
        },
      });

      dates.isInvalid = true;
      setForceUpdate(!forceUpdate);
      return true;
    } else {
      dates.isInvalid = false;
      setForceUpdate(!forceUpdate);
      return false;
    }
  };

  const customValidateFreebies = () => {
    const freebies = contract.freebies;

    if (!freebies || freebies.length === 0) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("freebies_required"),
        },
      });

      return true;
    }

    if (!freebies || freebies.some((f) => !f.details || f.details.length === 0)) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("freebies_details_required"),
        },
      });

      return true;
    }

    if (freebies.some((f) => !f.product_Ids || f.product_Ids.length === 0)) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("freebies_products_required"),
        },
      });

      return true;
    }

    let criteriaInvalid = freebies
      .filter((f) => f.criterias && f.criterias.length > 0)
      .some((freebie, index) => {
        return validateCriteria(
          freebie.criterias,
          index,
          [
            freebie.criterias.map((c) => {
              return { comparator_Id: c.comparator_Id, value: c.value, id: c.id };
            }),
          ],
          true
        );
      });

    if (criteriaInvalid) {
      console.log("hewre");
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("freebies_products_required"),
        },
      });

      return true;
    }

    return freebies.some((freebie) => {
      const currentDates =
        freebie.periodDates && freebie.periodDates.length > 0
          ? freebie.periodDates.map((d) => {
              return { start: d.start, end: d.end };
            })
          : [];

      return (
        currentDates &&
        currentDates.length > 0 &&
        freebie.periodDates.some((dates, index) => {
          return validateDateRanges(
            dates,
            currentDates.filter((d, dIndex) => dIndex !== index),
            contract.start,
            contract.end
          );
        })
      );
    });
  };

  const customValidateGroups = () => {
    const groups = contract.groups;

    if (!groups || groups.length === 0) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("groups_required"),
        },
      });

      if (groups.some((g) => !g.groupTarget_Id))
        dispatch({
          type: "SHOW_MESSAGE",
          payload: {
            type: "error",
            titleKey: "error",
            message: t("groups_target_required"),
          },
        });

      return true;
    }

    const groupCriterias = groups.map((m) =>
      m.criterias.map((c) => {
        return { comparator_Id: c.comparator_Id, value: c.value };
      })
    );

    return groups.some((group, index) => {
      return validateCriteria(group.criterias, index, groupCriterias);
    });
  };

  const customValidateAgeCategories = () => {
    const ageCategories = contract.ageCategories;

    if (!ageCategories || ageCategories.length === 0) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("age_categories_required"),
        },
      });

      return true;
    }

    const ageCriterias = ageCategories.map((m) =>
      m.criterias.map((c) => {
        return { comparator_Id: c.comparator_Id, value: c.value };
      })
    );

    return ageCategories.some((ageCategory, index) => {
      return validateCriteria(ageCategory.criterias, index, ageCriterias);
    });
  };

  const validateCriteria = (currentCriteria, index, allCriterias, isFreebie = false) => {
    const validOperators = comparatorsReducer.comparatorsForSelect.filter((c) => c.comparatorTypeName === "int");
    let hasError = false;

    currentCriteria.forEach((crit, i) => {
      const currentComparator = validOperators.find((o) => o.id === crit.comparator_Id);
      let value = Number(crit.value);
      if (!currentComparator.operator.includes("="))
        if (currentComparator.operator.includes("<")) value--;
        else value++;

      let otherCriterias = allCriterias.filter((_, i) => index !== i);
      if (isFreebie) {
        var otherValidCriteria = allCriterias[0].filter((criteria, j) => j !== i);
        if (otherValidCriteria.length > 0) {
          otherCriterias = [otherValidCriteria];
        } else {
          otherCriterias = [];
        }
      }

      const currentHasError = otherCriterias.some((oc) => {
        return oc.every((otherCrit) => {
          const currentOtherComparator = validOperators.find((o) => o.id === otherCrit.comparator_Id);
          let otherValue = Number(otherCrit.value);

          const formatedStringMethod = `return ${currentOtherComparator.operator.format(value, otherValue)}`;
          // eslint-disable-next-line no-new-func
          const compiledFunction = Function(formatedStringMethod);
          return compiledFunction();
        });
      });

      crit.isInvalid = currentHasError;
      setForceUpdate(!forceUpdate);

      if (!hasError && currentHasError) hasError = currentHasError;
    });

    if (hasError) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("criteria_overlap"),
        },
      });
    }

    return hasError;
  };

  const customValidatePriceByDays = () => {
    const priceByDays = contract.contractDayGroups;

    if (!priceByDays || priceByDays.length === 0) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("price_by_days_required"),
        },
      });

      return true;
    }

    const currentDays =
      priceByDays && priceByDays.length > 0
        ? [].concat.apply(
            [],
            priceByDays.map((d) => d.day_Ids)
          )
        : [];

    for (let index = 0; index < currentDays.length; index++) {
      const element = currentDays[index];
      const filteredDays = currentDays.filter((day, dIndex) => index !== dIndex);

      if (filteredDays.some((day) => day === element)) {
        dispatch({
          type: "SHOW_MESSAGE",
          payload: {
            type: "error",
            titleKey: "error",
            message: t("price_by_days_overlaps"),
          },
        });

        const errorProjectDepartures = priceByDays.filter((d) => d.day_Ids.some((id) => id === element));
        errorProjectDepartures.forEach((dp) => (dp.isInvalid = true));

        return true;
      }
    }

    if (currentDays.length !== 7) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("price_by_days_missing_day"),
        },
      });

      return true;
    }

    priceByDays.forEach((dp) => (dp.isInvalid = false));

    return false;
  };

  const customValidateProjectDepartures = () => {
    const projectDepartures = contract.contractDepartureGroups;

    if (!projectDepartures || projectDepartures.length === 0) {
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("project_departures_required"),
        },
      });

      return true;
    }

    const currentDepartures =
      projectDepartures && projectDepartures.length > 0
        ? [].concat.apply(
            [],
            projectDepartures.map((d) => d.departure_Ids)
          )
        : [];

    for (let index = 0; index < currentDepartures.length; index++) {
      const element = currentDepartures[index];
      const filteredDepartures = currentDepartures.filter((day, dIndex) => index !== dIndex);

      if (filteredDepartures.some((day) => day === element)) {
        dispatch({
          type: "SHOW_MESSAGE",
          payload: {
            type: "error",
            titleKey: "error",
            message: t("departure_not_unique"),
          },
        });

        const errorProjectDepartures = projectDepartures.filter((d) => d.departure_Ids.some((id) => id === element));
        errorProjectDepartures.forEach((dp) => (dp.isInvalid = true));

        return true;
      }
    }

    projectDepartures.forEach((dp) => (dp.isInvalid = false));

    return false;
  };

  const goToNext = () => {
    if (validator.allValid()) {
      let customValidationError = false;

      switch (steps[contractsReducer.activeStepIndex].name) {
        case "contract-periods":
          customValidationError = customValidateContractPeriods();
          break;

        case "groups":
          customValidationError = customValidateGroups();
          break;

        case "freebies":
          customValidationError = customValidateFreebies();
          break;

        case "price-by-days":
          customValidationError = customValidatePriceByDays();
          break;

        case "age-categories":
          customValidationError = customValidateAgeCategories();
          break;

        case "specific-project-departures":
          customValidationError = customValidateProjectDepartures();
          break;

        default:
          break;
      }

      if (!customValidationError) {
        validator.hideMessages();
        history.push(
          `/establishments/${match.params.id}/contracts/${match.params.contractId}/${steps[++contractsReducer.activeStepIndex].name}`
        );
      } else {
        setForceUpdate(!forceUpdate);
      }
    } else {
      validator.showMessages();
      setForceUpdate(!forceUpdate);
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("contain_errors"),
        },
      });
    }
  };

  const goToPrevious = () => {
    let previous = steps[--contractsReducer.activeStepIndex];
    let previousName = previous ? previous.name : steps.find((s) => s.isActive).name;

    validator.purgeFields();
    history.push(`/establishments/${match.params.id}/contracts/${match.params.contractId}/${previousName}`);
  };

  const goToSelected = (index) => {
    let selectedStep = steps[index];
    let selectedStepName = selectedStep ? selectedStep.name : steps.find((s) => s.isActive).name;

    validator.purgeFields();
    history.push(`/establishments/${match.params.id}/contracts/${match.params.contractId}/${selectedStepName}`);
  };

  const handlePersistEstablishmentContract = (contract) => {
    if (!validator.allValid()) {
      validator.showMessages();
      setForceUpdate(!forceUpdate);
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          type: "error",
          titleKey: "error",
          message: t("contain_errors"),
        },
      });
    } else {
      validator.hideMessages();
      dispatch(persistEstablishmentContract(contract)).then((contract) => {
        if (contract.productContractProductIds && contract.productContractProductIds.length > 0) {
          dispatch(updateProductPriceDetailProductIds(contract.productContractProductIds)).then((_) =>
            history.push(`/establishments/${match.params.id}/products/price-list`)
          );
        } else {
          history.push(`/establishments/${match.params.id}/contracts/`);
        }
      });
    }
  };

  const contract = contractsReducer.establishmentContract;

  return (
    <div className="card mt-3">
      <div className="card-body">
        <div className="card-text">
          <ProgressBar steps={steps} t={t} goToSelected={goToSelected} />
          <ConfirmDeleteWithPriceDetail />

          {isEmpty(contract) ? (
            <Spinner />
          ) : (
            <Switch>
              <Route
                path="/establishments/:id/contracts/:contractId"
                exact
                render={() => (
                  <GeneralInformation
                    t={t}
                    update={update}
                    steps={steps}
                    contract={contract}
                    companies={companiesReducer.companiesForSelect}
                    isEstablishmentContract
                    validator={validator}
                  />
                )}
              />
              <Route
                path="/establishments/:id/contracts/:contractId/general-information"
                exact
                render={() => (
                  <GeneralInformation
                    t={t}
                    contract={contract}
                    update={update}
                    steps={steps}
                    companies={companiesReducer.companiesForSelect}
                    isEstablishmentContract
                    validator={validator}
                  />
                )}
              />

              <Route
                path="/establishments/:id/contracts/:contractId/length-categories"
                exact
                render={() => (
                  <LengthCategories t={t} contract={contract} update={update} steps={steps} isEstablishmentContract validator={validator} />
                )}
              />

              <Route
                path="/establishments/:id/contracts/:contractId/age-categories"
                exact
                render={() => (
                  <AgeCategories t={t} contract={contract} update={update} steps={steps} isEstablishmentContract validator={validator} />
                )}
              />

              <Route
                path="/establishments/:id/contracts/:contractId/groups"
                exact
                render={() => (
                  <Groups
                    t={t}
                    contract={contract}
                    update={update}
                    establishment={establishmentsReducer.establishment}
                    steps={steps}
                    isEstablishmentContract
                    validator={validator}
                  />
                )}
              />

              <Route
                path="/establishments/:id/contracts/:contractId/freebies"
                exact
                render={() => (
                  <Freebies
                    t={t}
                    contract={contract}
                    update={update}
                    establishment={establishmentsReducer.establishment}
                    steps={steps}
                    isEstablishmentContract
                    validator={validator}
                  />
                )}
              />

              <Route
                path="/establishments/:id/contracts/:contractId/contract-periods"
                exact
                render={() => (
                  <ContractPeriods
                    t={t}
                    contract={contract}
                    update={update}
                    establishment={establishmentsReducer.establishment}
                    steps={steps}
                    isEstablishmentContract
                    validator={validator}
                  />
                )}
              />

              <Route
                path="/establishments/:id/contracts/:contractId/specific-project-departures"
                exact
                render={() => (
                  <ContractDepartureGroups
                    t={t}
                    contract={contract}
                    update={update}
                    establishment={establishmentsReducer.establishment}
                    steps={steps}
                    isEstablishmentContract
                    validator={validator}
                  />
                )}
              />

              <Route
                path="/establishments/:id/contracts/:contractId/price-by-days"
                exact
                render={() => (
                  <ByDays
                    t={t}
                    contract={contract}
                    update={update}
                    establishment={establishmentsReducer.establishment}
                    steps={steps}
                    isEstablishmentContract
                    validator={validator}
                  />
                )}
              />

              <Route
                path="/establishments/:id/contracts/:contractId/pricing"
                exact
                render={() => (
                  <Pricing
                    t={t}
                    contract={contract}
                    taxTypes={taxTypesReducer.taxTypesForSelect}
                    establishment={establishmentsReducer.establishment}
                    steps={steps}
                    isEstablishmentContract
                    validator={validator}
                  />
                )}
              />

              <Route
                path="/establishments/:id/contracts/:contractId/summary"
                exact
                render={() => (
                  <Summary
                    t={t}
                    contract={contract}
                    saveContract={handlePersistEstablishmentContract}
                    goToPrevious={goToPrevious}
                    establishment={establishmentsReducer.establishment}
                    companies={companiesReducer.companiesForSelect}
                    steps={steps}
                    isEstablishmentContract
                    validator={validator}
                  />
                )}
              />
            </Switch>
          )}
          <Row>
            <Col md="12" className="text-right mt-2">
              <hr />
              {contractsReducer.activeStepIndex !== 0 && (
                <Button color="info" onClick={goToPrevious}>
                  {t("previous")}
                </Button>
              )}
              {contractsReducer.activeStepIndex !== steps.filter((s) => s.isActive).length - 1 ? (
                <Button color="success" className="ml-2" onClick={goToNext}>
                  {t("next")}
                </Button>
              ) : (
                <Button
                  color="success"
                  className="ml-2"
                  onClick={() => handlePersistEstablishmentContract(contract)}
                  disabled={!accountReducer.isManager}
                >
                  {t("save")}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default EstablishmentContract;
