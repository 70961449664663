import axios from "axios";
import { HOST } from "config/config";

export function getQuickbooksAccount() {
  return (dispatch) => {
    return dispatch({
      type: "GET_QUICKBOOKS_ACCOUNTS_PENDING",
      payload: axios
        .get(`${HOST}/api/quickbooks/accounts`)
        .then((response) => response.data)
        .then((json) =>
          dispatch({
            type: "GET_QUICKBOOKS_ACCOUNTS_FULFILLED",
            payload: json,
          })
        ),
    });
  };
}
