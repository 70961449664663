export const feeTypesReducer = (
  state = {
    feeTypes: [],
    updating: false,
    updated: false,
    error: null,
    tableDataParam: {
      pageSize: 25,
      currentPage: 1,
      sorts: [{ column: "id", asc: false }],
      filters: []
    }
  },
  action
) => {
  switch (action.type) {
    case "GET_FEE_TYPES_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "UPDATE_FEE_TYPES_TABLEDATAPARAM": {
      state = { ...state, tableDataParam: action.payload };

      break;
    }
    case "GET_FEE_TYPES_FULFILLED": {
      state = { ...state, updating: false, updated: true, feeTypes: action.payload.items };
      break;
    }
    case "GET_FEE_TYPES_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }
    case "GET_FEE_TYPE_PENDING": {
      state = { ...state, updating: true };
      break;
    }
    case "GET_FEE_TYPE_FULFILLED": {
      let feeTypes = state.feeTypes;
      feeTypes = feeTypes.filter(s => s.id !== action.payload.id);
      feeTypes.push(action.payload);

      state = {
        ...state,
        updating: false,
        updated: true,
        feeTypes
      };
      break;
    }
    case "GET_FEE_TYPES_SELECT_FULFILLED": {
      let feeTypes = state.feeTypes;
      action.payload.forEach(feeType => {
        if (!feeTypes.some(t => t.id === feeType.id)) {
          feeTypes.push(feeType);
        }
      });

      state = {
        ...state,
        updating: false,
        updated: true,
        feeTypes: feeTypes
      };
      break;
    }
    case "GET_FEE_TYPE_REJECTED": {
      state = { ...state, updating: false, error: action.payload };
      break;
    }

    default: {
    }
  }

  return state;
};

export default feeTypesReducer;
