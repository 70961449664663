export const appReducer = (state = {
  maximumNumberOfPagesShown: 5,
  isLoggedIn: false,
  isWrapped: false,
}, action) => {
  switch (action.type) {  
    case "SET_ISWRAPPED": {
      state = { ...state, isWrapped: action.payload };
      break;
    }
    default:
      {}
  }

  return state;
};

export default appReducer;