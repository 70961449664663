import React from "react";

import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import ReactTooltip from "react-tooltip";

momentLocalizer();

const timeComponent = (props) => {
  return (
    <React.Fragment>
      <i className="fas fa-history" key={props.index} /> {props.text}
    </React.Fragment>
  );
};

export default function DatePickerWithLabel({
  fieldToUpdate,
  objectToUpdate,
  labelText,
  dateFormat,
  locale,
  disabled,
  updateAction,
  hasValidator,
  errorMessage,
  forceShowErrorState = false,
  time = false,
  icon,
  iconHtml,
  iconTooltip,
}) {
  const parseDate = (dateString) => {
    if (moment(dateString).isValid()) {
      return moment(dateString).toDate();
    }

    return null;
  };

  if (objectToUpdate === undefined) return null;
  let value =
    objectToUpdate[fieldToUpdate] && moment(objectToUpdate[fieldToUpdate]).isValid()
      ? moment(objectToUpdate[fieldToUpdate]).toDate()
      : null;
  return (
    <React.Fragment>
      <div className="form-group">
        <label
          className={errorMessage || forceShowErrorState ? "form-control-label text-danger" : "form-control-label"}
          htmlFor={fieldToUpdate}
        >
          {labelText}
        </label>
        <div
          className={
            time ? "btn-toolbar-item input-with-icon-right input-with-icon-time" : "btn-toolbar-item input-with-icon-right input-with-icon"
          }
        >
          {icon ? (
            <span className="icon-date text-primary" data-tip={iconTooltip} data-html={iconHtml} data-for={`tt-${fieldToUpdate}`}>
              {icon}
            </span>
          ) : null}
          {iconTooltip ? <ReactTooltip clickable id={`tt-${fieldToUpdate}`} type="info" effect="solid" place="right" border /> : null}

          <DateTimePicker
            value={value}
            defaultValue={null}
            time={time}
            culture={locale === "en" ? "en-CA" : "fr-CA"}
            format={dateFormat}
            step={15}
            timeComponent={timeComponent}
            disabled={disabled}
            parse={parseDate}
            onChange={(e) => updateAction(e, objectToUpdate, fieldToUpdate)}
            containerClassName={errorMessage || forceShowErrorState ? "form-control is-invalid" : "form-control"}
          />

          {hasValidator && errorMessage}
        </div>
      </div>
    </React.Fragment>
  );
}
