import axios from "axios";
import { HOST } from "config/config";

export function getPurchaseOrdersToSync(groupId) {
  return (dispatch) => {
    return dispatch({
      type: "GET_PURCHASE_ORDERS_TO_SYNC_PENDING",
      payload: axios
        .get(`${HOST}/api/purchase-orders/get-to-sync?groupId=${groupId}`)
        .then((response) => response.data)
        .then((json) =>
          dispatch({
            type: "GET_PURCHASE_ORDERS_TO_SYNC_FULFILLED",
            payload: json,
          })
        ),
    });
  };
}

export function showDeletePurchaseOrderConfirmationModal(purchaseOrder) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SHOW_DELETE_PURCHASE_ORDER_MODAL",
        payload: purchaseOrder,
      });

      return resolve();
    });
}

export function hideDeletePurchaseOrderConfirmationModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_DELETE_PURCHASE_ORDER_MODAL",
      });

      return resolve();
    });
}

export function persistPurchaseOrder(purchaseOrder) {
  return (dispatch, getState) => {
    if (!getState().purchaseOrderReducer.updatingPurchaseOrder) {
      return new Promise((resolve, reject) =>
        dispatch({
          type: "PERSISTING_PURCHASE_ORDER",
          payload: axios
            .put(`${HOST}/api/purchase-orders/${purchaseOrder.id}`, purchaseOrder)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "PERSISTED_PURCHASE_ORDER",
                payload: {
                  purchaseOrder: json,
                },
              });

              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "success",
                  titleKey: "success",
                  messageKey: "saved_successful",
                },
              });
              return resolve();
            })
            .catch((error) => {
              dispatch({
                type: "SHOW_MESSAGE",
                payload: {
                  type: "error",
                  titleKey: "error",
                  message: error.response,
                },
              });
              return reject();
            }),
        })
      );
    }
  };
}

export function showSyncPurchaseOrderConfirmationModal(purchaseOrder) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "SHOW_SYNC_PURCHASE_ORDER_MODAL",
        payload: purchaseOrder,
      });

      return resolve();
    });
}

export function hideSyncPurchaseOrderConfirmationModal() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "HIDE_SYNC_PURCHASE_ORDER_MODAL",
      });

      return resolve();
    });
}

export function forcePushPurchaseOrder(purchaseOrderId) {
  return (dispatch, getState) => {
    if (!getState().purchaseOrderReducer.pushingPurchaseOrder) {
      return new Promise((resolve) =>
        dispatch({
          type: "PUSHING_PURCHASE_ORDER",
          payload: axios
            .post(`${HOST}/api/purchase-orders/force-synchronize/${purchaseOrderId}`)
            .then((response) => response.data)
            .then((json) => {
              dispatch({
                type: "PUSHED_PURCHASE_ORDER",
                payload: { purchaseOrderId: purchaseOrderId, status: json.status },
              });

              if (json.status === "success") {
                dispatch({
                  type: "SHOW_MESSAGE",
                  payload: {
                    type: "success",
                    titleKey: "success",
                    messageKey: "activated_successfully",
                  },
                });
              } else {
                dispatch({
                  type: "SHOW_MESSAGE",
                  payload: {
                    type: "error",
                    titleKey: "error",
                    messageKey: json.message,
                  },
                });
              }

              resolve(json);
            }),
        })
      );
    }
  };
}
