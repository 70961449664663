import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { hideConfirmDeleteWithPriceDetailModal } from "redux/actions/contracts";

function ConfirmDeleteWithPriceDetail() {
  const contractsReducer = useSelector((state) => state.contractsReducer);

  const saveBtn = () => {
    contractsReducer.confirmDeleteWithPriceDetailSaveAction();
    closeModal();
  };

  const closeModal = () => {
    dispatch(hideConfirmDeleteWithPriceDetailModal());
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Modal className="mw-100 w-50" isOpen={contractsReducer.showConfirmDeleteWithPriceDetailModal}>
      <ModalHeader>{t("delete_confirmation")}</ModalHeader>
      <ModalBody>{t("delete_confirmation_message", { name: contractsReducer.confirmDeleteWithPriceDetailName })}</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={saveBtn}>
          {t("delete")}
        </Button>{" "}
        <Button color="info" onClick={closeModal}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmDeleteWithPriceDetail;
